import React, { useState, useEffect } from "react";
import styled from "styled-components";

import DaumPostcodeEmbed from "react-daum-postcode";

import PretendardText from "../../atoms/pretendardText";
import useDisplay from "../../../hooks/useDisplay";
import TextBtn from "../../atoms/button/textBtn";

const DaumPostcode = (props) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <ModalWrapper>
      <ModalPosition isMobile={isMobile}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <TextBtn
            style={{
              background: "#ADB4BA",
              width: 96,
              height: 36,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => props.setIsModal(false)}
          >
            <PretendardText
              style={{
                fontSize: 14,
                lineHeight: "20.3",
                color: "#FFF",
                fontWeight: 700,
              }}
            >
              닫기
            </PretendardText>
          </TextBtn>
        </div>
        <ModalContain isMobile={isMobile}>
          <DaumPostcodeEmbed onComplete={props.handleComplete} />;
        </ModalContain>
      </ModalPosition>
    </ModalWrapper>
  );
};

export default DaumPostcode;

const ModalWrapper = styled.div.attrs((props) => {})`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  background: rgba(40, 40, 40, 0.5);
  backdrop-filter: blur(2px);

  overflow: hidden;
  touch-action: none;
`;

const ModalPosition = styled.div.attrs((props) => {})`
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
`;

const ModalContain = styled.div.attrs((props) => {})`
  width: ${(props) => (props.isMobile ? "90vw" : "400px")};
  height: auto;

  box-shadow: 0 0 8.5px 1.5px rgba(165, 161, 155, 0.1);
  background-color: #fff;

  overflow: hidden;
`;
