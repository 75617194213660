import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import TitleInput from "../components/molecules/input/titleInput";
import FileForm from "../components/atoms/fileForm/fileForm";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import MoreApi from "../api/moreApi";
import { route } from "../routes/route";

let timer;

function PwChange(props) {
  let min = 3;
  let sec = 0;

  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [isPNC, setIsPNC] = useState(false);
  const [phoneNumberChk, setPhoneNumberChk] = useState(false);

  const [cNumber, setCNumber] = useState("");
  const [cNumberErr, setCNumberErr] = useState("");
  const [certifyValue, setCertifyValue] = useState("");

  const [minState, setMinState] = useState(3);
  const [secState, setSecState] = useState(0);

  const [pw, setPw] = useState("");
  const [pwErr, setPwErr] = useState("");
  const [pwConfirm, setPwConfirm] = useState("");
  const [pwConfirmErr, setPwConfirmErr] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      clearTimer("타이머 종료");
    };
  }, []);

  useEffect(() => {
    if (phone.length > 0) {
      var regType1 = /^[0-9]+$/;
      if (!regType1.test(phone)) {
        return setPhoneErr("-없이 숫자만 입력해주세요.");
      }

      setPhoneErr("");

      setPhoneNumberChk(false);
      setIsPNC(false);
    }
  }, [phone]);

  useEffect(() => {
    if (pw.length > 0) {
      var regType1 = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/;

      if (!regType1.test(pw)) {
        return setPwErr("8-16자 영문, 숫자, 특수문자 조합으로 입력해주세요.");
      }

      if (pw.length < 8) {
        return setPwErr("비밀번호 8자리 이상을 입력해주세요.");
      }

      setPwErr("");
    }
  }, [pw]);

  useEffect(() => {
    if (pwConfirm.length > 0) {
      if (pwConfirm !== pw) {
        return setPwConfirmErr("비밀번호가 일치하지 않습니다.");
      }

      setPwConfirmErr("");
    }
  }, [pwConfirm]);

  const countTimerStart = () => {
    timer = setInterval(countTimer, 1000);
  };

  const countTimer = () => {
    if (sec != 0) {
      sec = sec - 1;
      setSecState(sec);
    } else {
      if (min != 0) {
        min = min - 1;
        sec = 59;

        setSecState(sec);
        setMinState(min);
      } else {
        clearTimer("타이머 종료");
        setPhoneErr("인증에 실패하였습니다. 인증을 다시 시도하세요.");
      }
    }
  };

  function clearTimer(text) {
    clearInterval(timer);

    if (text === "타이머 종료") {
      min = 3;
      setMinState(3);
      sec = 0;
      setSecState(0);
    }
  }

  const pwChangeFunc = async () => {
    try {
      let formData = new FormData();

      formData.append("password", pw);

      await MoreApi.UpdatePW(formData);

      logoutFunc();
      toast("비밀번호 재설정 완료! 다시 로그인해주세요.");
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const logoutFunc = async () => {
    localStorage.removeItem("autoLogin");
    localStorage.removeItem("account");
    localStorage.removeItem("password");

    sessionStorage.removeItem("token");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("memberType");

    history.push({
      pathname: route.main,
    });
  };

  const sendSMSAPI = async () => {
    try {
      if (phone.length != 11) {
        setIsPNC(false);
        setPhoneErr("휴대폰 번호가 올바르지 않습니다.");

        return;
      }

      clearTimer("타이머 종료");

      const data = {
        phoneNumber: phone,
      };
      const response = await MoreApi.UPWPhoneCertify(data);

      if (response.data.data.randomNumber) {
        setPhoneNumberChk(false);
        setCertifyValue(response.data.data.randomNumber);
        setPhoneErr("");
        setCNumber("");
        setCNumberErr("");

        setIsPNC(true);
        countTimerStart();
      }
    } catch (err) {
      setIsPNC(false);
      setPhoneErr("휴대폰 번호가 일치하지 없습니다.");
    }
  };

  const phoneNumberChkFunc = () => {
    if (certifyValue === cNumber) {
      setPhoneNumberChk(true);
      setCNumberErr("");

      clearTimer("완");
    } else {
      setCNumberErr("인증번호가 올바르지 않습니다.");
      setPhoneNumberChk(false);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header />

          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                marginTop: 40,
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 32,
                  lineHeight: "44.16px",
                  color: "#262C31",
                  fontWeight: 600,
                }}
              >
                비밀번호 재설정
              </PretendardText>

              <div
                style={{
                  marginTop: 66,
                }}
              >
                <TitleInput
                  title={"휴대폰번호"}
                  placeholder="번호만 입력가능합니다"
                  type={"text"}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  value={phone}
                  btn={true}
                  btnText={isPNC ? "재전송" : "인증요청"}
                  onClick={() => {
                    sendSMSAPI();
                  }}
                  warningMessage={phoneErr}
                  maxLength={11}
                  btnStyle={{
                    marginTop: phoneErr ? -23 : 0,
                    background: isPNC ? "transparent" : "#262C31",
                    border: isPNC ? "2px solid #262C31" : "none",
                  }}
                  btnTextStyle={{
                    lineHeight: phoneErr && isMobile ? "17.75px" : "18.75px",
                    color: isPNC ? "#262C31" : "#ffffff",
                  }}
                  inputStyle={{
                    letterSpacing: "0.6px",
                  }}
                />

                {isPNC ? (
                  <TitleInput
                    style={{
                      marginTop: 17,
                    }}
                    title={"인증번호 입력"}
                    placeholder="인증번호 6자리를  입력하세요"
                    type={"text"}
                    disabled={phoneNumberChk ? true : false}
                    onChange={(e) => {
                      setCNumber(e.target.value);
                    }}
                    warningMessage={cNumberErr}
                    maxLength={6}
                    value={cNumber}
                    timer={phoneNumberChk ? false : true}
                    min={minState}
                    sec={secState}
                    timerColor={"#9D56FB"}
                    btn={true}
                    btnText={phoneNumberChk ? "인증완료" : "인증하기"}
                    onClick={() => {
                      phoneNumberChkFunc();
                    }}
                    btnStyle={{
                      marginTop: cNumberErr || phoneNumberChk ? -23 : 0,
                      background: phoneNumberChk ? "#ADB4BA" : "#262C31",
                    }}
                    btnTextStyle={{
                      lineHeight:
                        cNumberErr || (phoneNumberChk && isMobile)
                          ? "17.75px"
                          : "18.75px",
                    }}
                    checkMessage={phoneNumberChk ? "인증되었습니다." : ""}
                  />
                ) : null}

                <TitleInput
                  style={{
                    marginTop: 17,
                  }}
                  title={"비밀번호"}
                  placeholder="8-16자리 영문, 숫자, 특수문자 조합"
                  type={"password"}
                  onChange={(e) => {
                    setPw(e.target.value);
                  }}
                  value={pw}
                  warningMessage={pwErr}
                  maxLength={"16"}
                />

                <TitleInput
                  style={{
                    marginTop: 17,
                  }}
                  title={"비밀번호 재입력"}
                  placeholder="비밀번호를 다시 입력하세요"
                  type={"password"}
                  onChange={(e) => {
                    setPwConfirm(e.target.value);
                  }}
                  value={pwConfirm}
                  warningMessage={pwConfirmErr}
                  maxLength={"16"}
                />
              </div>
            </div>

            <MainBtn
              disabled={
                phone &&
                phoneErr == "" &&
                phoneNumberChk &&
                pw &&
                pwErr == "" &&
                pwConfirm &&
                pwConfirmErr == ""
                  ? false
                  : true
              }
              onClick={() => {
                pwChangeFunc();
              }}
              style={{
                marginTop: 22,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                비밀번호 재설정하기
              </PretendardText>
            </MainBtn>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default PwChange;
