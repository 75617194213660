import styled from "styled-components";
import useDisplay from "../../../hooks/useDisplay";
import Input from "../../atoms/input/input";
import PretendardText from "../../atoms/pretendardText";
import BlackBtn from "../../atoms/button/blackBtn";

function TitleInput(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          ...props.style,
        }}
      >
        <PretendardText
          style={{
            fontSize: 13,
            lineHeight: "18.75px",
            color: "#778088",
            fontWeight: 600,
            marginBottom: 4,
          }}
        >
          {props.title}
        </PretendardText>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "relative",
            flex: 1,
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            {props.inputOnclick ? (
              <Input
                value={props.value}
                onChange={props.onChange}
                type={props.type}
                placeholder={props.placeholder}
                maxLength={props.maxLength}
                disabled={props.disabled}
                style={{
                  flex: 1,
                  borderColor: props.warningMessage ? "#ff3333" : "rgb(244, 246, 246)",

                  ...props.inputStyle,
                }}
                onKeyDown={props.onKeyDown}
                onClick={props.onClick}
              />
            ) : (
              <Input
                value={props.value}
                onChange={props.onChange}
                type={props.type}
                placeholder={props.placeholder}
                maxLength={props.maxLength}
                disabled={props.disabled}
                style={{
                  flex: 1,
                  borderColor: props.warningMessage ? "#ff3333" : "rgb(244, 246, 246)",

                  ...props.inputStyle,
                }}
                onKeyDown={props.onKeyDown}
              />
            )}
            {props.warningMessage ? (
              <PretendardText
                style={{
                  fontWeight: 500,
                  fontSize: 13,
                  lineHeight: "18.2px",
                  color: "#F24147",
                  marginTop: 4,
                }}
              >
                {props.warningMessage}
              </PretendardText>
            ) : null}

            {props.checkMessage ? (
              <PretendardText
                style={{
                  fontWeight: 500,
                  fontSize: 13,
                  lineHeight: "18.2px",
                  color: "#547CF1",
                  marginTop: 4,
                }}
              >
                {props.checkMessage}
              </PretendardText>
            ) : null}
          </div>
          {props.btn ? (
            <BlackBtn
              onClick={props.onClick}
              style={{
                marginLeft: 10,
                ...props.btnStyle,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#ffffff",
                  fontWeight: 700,
                  ...props.btnTextStyle,
                }}
              >
                {props.btnText}
              </PretendardText>
            </BlackBtn>
          ) : null}

          {props.timer ? (
            <div
              style={{
                position: "absolute",
                top: props.warningMessage ? -22 : 0,
                bottom: 0,
                justifyContent: "center",
                alignItems: "center",
                right: 100,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <PretendardText
                style={{
                  fontWeight: 500,
                  fontSize: 15,
                  color: props.timerColor ? props.timerColor : "#5ECECE",
                  lineHeight: "22.3px",
                }}
              >
                {props.min}
              </PretendardText>
              <PretendardText
                style={{
                  fontWeight: 500,
                  fontSize: 15,
                  color: props.timerColor ? props.timerColor : "#5ECECE",
                  lineHeight: "22.3px",
                }}
              >
                :
              </PretendardText>
              <PretendardText
                style={{
                  fontWeight: 500,
                  fontSize: 15,
                  color: props.timerColor ? props.timerColor : "#5ECECE",
                  lineHeight: "22.3px",
                }}
              >
                {props.sec < 10 ? "0" + props.sec : props.sec}
              </PretendardText>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default TitleInput;
