import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";

import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import TextBtn from "../components/atoms/button/textBtn";
import styled from "styled-components";
import TermApi from "../api/termApi";
import { route } from "../routes/route";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";

function TermList(props) {
  const history = useHistory();
  const [termList, setTermList] = useState([]);
  const [baseInfo, setBaseInfo] = useState({
    address: "서울특별시 강남구 도산대로 145 (신사동) 인우빌딩 13층 1303호",
    businessNumber: "480-86-02455",
    ceo: "손진국",
    companyName: "(주)스타일디택트",
    createdAt: "2023-08-16T18:05:38.708951",
    email: "sonjk@styledtact.com",
    id: 1,
    titlePhoneNumber: "02-6959-1816",
    updatedAt: "2023-08-16T18:38:36.328188",
  });

  useEffect(() => {
    termListFunc();
    baseInfoFunc();

    window.scrollTo(0, 0);
  }, []);

  const termListFunc = async () => {
    try {
      let response = await TermApi.List();

      setTermList(response.data.data);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const baseInfoFunc = async () => {
    try {
      let response = await TermApi.BaseInfo();

      setBaseInfo(response.data.data);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header title={"약관 및 정책"} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            {termList.map((item, index) => {
              return (
                <TextBtn
                  key={index}
                  onClick={() => {
                    history.push({
                      pathname: route.term_content,
                      state: {
                        title: item.title,
                      },
                    });
                  }}
                  style={{
                    height: 41,
                    alignItems: "center",
                    marginTop: index === 0 ? 24 : 14,
                  }}
                >
                  <StyledTextBtnText>{item.title}</StyledTextBtnText>
                </TextBtn>
              );
            })}
          </div>

          <div
            style={{
              background: "#F4F6F6",
              padding: "18px 20px 13px 20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PretendardText
              style={{
                fontSize: 14,
                lineHeight: "20px",
                color: "#5A636A",
                fontWeight: 600,
                marginBottom: 15,
              }}
            >
              사업자 정보
            </PretendardText>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "15.6px",
                  color: "#ADB4BA",
                  fontWeight: 600,
                  width: 97,
                }}
              >
                상호명
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "15.6px",
                  color: "#ADB4BA",
                  fontWeight: 400,
                }}
              >
                {baseInfo.companyName}
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "15.6px",
                  color: "#ADB4BA",
                  fontWeight: 600,
                  width: 97,
                }}
              >
                대표자
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "15.6px",
                  color: "#ADB4BA",
                  fontWeight: 400,
                }}
              >
                {baseInfo.ceo}
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "15.6px",
                  color: "#ADB4BA",
                  fontWeight: 600,
                  width: 97,
                }}
              >
                사업자등록번호
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "15.6px",
                  color: "#ADB4BA",
                  fontWeight: 400,
                }}
              >
                {baseInfo.businessNumber}
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "15.6px",
                  color: "#ADB4BA",
                  fontWeight: 600,
                  width: 97,
                }}
              >
                주소
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "15.6px",
                  color: "#ADB4BA",
                  fontWeight: 400,
                }}
              >
                {baseInfo.address}
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "15.6px",
                  color: "#ADB4BA",
                  fontWeight: 600,
                  width: 97,
                }}
              >
                전화번호
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "15.6px",
                  color: "#ADB4BA",
                  fontWeight: 400,
                }}
              >
                {baseInfo.titlePhoneNumber}
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "15.6px",
                  color: "#ADB4BA",
                  fontWeight: 600,
                  width: 97,
                }}
              >
                이메일
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "15.6px",
                  color: "#ADB4BA",
                  fontWeight: 400,
                }}
              >
                {baseInfo.email}
              </PretendardText>
            </div>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default TermList;

const StyledTextBtnText = styled(PretendardText).attrs((props) => {})`
  font-size: 15px;
  line-height: 22.3px;
  color: #262c31;
  font-weight: 500;
`;
