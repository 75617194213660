import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import Empty from "../components/molecules/empty";
import InquirySection from "../components/molecules/inquiry/inquirySection";
import PointSection from "../components/molecules/point/pointSection";
import FollowSection from "../components/molecules/follow/followSection";
import MoreApi from "../api/moreApi";

import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import { route } from "../routes/route";
import { useInView } from "react-intersection-observer";
import InfluencerApi from "../api/influencerApi";

function FollowList(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [ref, inView] = useInView();
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setData = async () => {
    try {
      setLoading(true);
      const response = await MoreApi.FInfluencerList({
        page: currentPage,
        size: 10,
      });

      const data = response.data.data;

      setDataList(dataList.concat(data.content));
      setIsLast(data.last);
      setTotalCount(data.totalElements);
      setLoading(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    setData();
  }, [currentPage]);

  useEffect(() => {
    if (inView && !isLast && !loading) {
      setCurrentPage(currentPage + 1);
      setData();
    }
  }, [inView, loading]);

  const unFollowFunc = async (id) => {
    try {
      const response = await InfluencerApi.UnFollow(id);

      const dataTemp = dataList.filter((item) => item.followedMember.id !== id);
      setDataList([...dataTemp]);

      let totalCountTemp = totalCount;
      setTotalCount(totalCountTemp - 1);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header title={"팔로우 인플루언서"} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                marginTop: 24,
                marginBottom: 12,
                paddingLeft: 20,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "20px",
                  color: "#778088",
                  fontWeight: 600,
                  marginRight: 5,
                }}
              >
                전체
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "20px",
                  color: "#778088",
                  fontWeight: 600,
                }}
              >
                {Number(totalCount).toLocaleString("ko-KR")}
              </PretendardText>
            </div>

            {dataList.length > 0 ? (
              dataList.map((item, index) => {
                return (
                  <FollowSection
                    key={index}
                    data={item}
                    unFollowFunc={unFollowFunc}
                  />
                );
              })
            ) : (
              <Empty text={"팔로우한 인플루언서가 없어요"} />
            )}

            <div ref={ref}>{/* loading */}</div>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default FollowList;
