import { Route, Switch, Redirect } from "react-router-dom";

import Main from "../pages/main";
import Login from "../pages/login";
import Register from "../pages/register";
import ProfileRegister from "../pages/profileRegister";
import IdFind from "../pages/idFind";
import IdFindComplete from "../pages/idFindComplete";
import PwFind from "../pages/pwFind";
import PwReset from "../pages/pwReset";
import TermDetail from "../pages/termDetail";
import BHome from "../pages/bottomTab/home";
import BSearch from "../pages/bottomTab/search";
import BChallenge from "../pages/bottomTab/challenge";
import ChallengeDetail from "../pages/challengeDetail";
import BMyChallenge from "../pages/bottomTab/myChallenge";
import ProfileEdit from "../pages/profileEdit";
import Payment from "../pages/payment";
import Refund from "../pages/refund";
import ChallengeReviewWrite from "../pages/challengeReviewWrite";
import EntryCertification from "../pages/entryCertification";
import GeneralUserProfile from "../pages/generalUserProfile";
import ChallengeReviewList from "../pages/challengeReviewList";
import ChallengeReviewDetail from "../pages/challengeReviewDetail";
import BMore from "../pages/bottomTab/more";
import AccountManager from "../pages/accountManager";
import PwChange from "../pages/pwChange";
import NoticeList from "../pages/noticeList";
import NoticeDetail from "../pages/noticeDetail";
import AlarmList from "../pages/alarmList";
import AlarmDetail from "../pages/alarmDetail";
import AlarmSetting from "../pages/alarmSetting";
import TermList from "../pages/termList";
import TermContent from "../pages/termContent";
import Withdraw from "../pages/withdraw";
import InquiryList from "../pages/inquiryList";
import InquiryWrite from "../pages/inquiryWrite";
import PointList from "../pages/pointList";
import FollowList from "../pages/followList";
import DeliveryManager from "../pages/deliveryManager";
import DeliveryEdit from "../pages/deliveryEdit";
import OrderList from "../pages/orderList";
import OrderDetail from "../pages/orderDetail";
import MyReviewList from "../pages/myReviewList";
import MyStorageList from "../pages/myStorageList";
import ReportWrite from "../pages/reportWrite";
import ShortForm from "../pages/shortForm";
import ShortFormDetail from "../pages/shortFormDetail";
import InfluencerProfile from "../pages/influencerProfile";
import InfluencerSearch from "../pages/influencerSearch";
import ChallengeSearch from "../pages/challengeSearch";
import MypageCertificationList from "../pages/mypageCertificationList";
import MyPageCertificationDetail from "../pages/mypageCertificationDetail";
import ChallengeCertification from "../pages/challengeCertification";
import InfluencerMain from "../pages/influencerMain";
import BChallengeManager from "../pages/bottomTab/challengeManager";
import BMyProfile from "../pages/bottomTab/myProfile";
import { route } from "./route";
import RegisterInfluencer from "../pages/registerInfluencer";
import ProfileRegisterInfluencer from "../pages/profileRegisterInfluencer";
import SearchCertification from "../pages/bottomTab/search_certification";
import InfluencerMore from "../pages/influencerMore";
import CreateChallenge from "../pages/createChallenge";
import UpdateChallenge from "../pages/updateChallenge";

import BoardUpload from "../pages/boardUpload";

import InfluencerProfileEdit from "../pages/influencerProfileEdit";
import RegisterSocial from "../pages/registerSocial";
import ProfileRegisterSocial from "../pages/profileRegisterSocial";
import PaymentComplete from "../pages/paymentComplete";
import RestartChallenge from "../pages/restartChallenge";
import AllShortForm from "../pages/allShortForm";

const Router = () => {
  return (
    <Switch>
      {/* 공통 */}
      <Route path={route.login} component={Login} />

      {/* 일반회원 */}
      <Route exact={true} path={route.main} component={Main} />
      <Route path={route.register} component={Register} />
      <Route path={route.register_social} component={RegisterSocial} />
      <Route path={route.id_find} component={IdFind} />
      <Route path={route.pw_find} component={PwFind} />
      <Route path={route.id_find_complete} component={IdFindComplete} />
      <Route path={route.pw_reset} component={PwReset} />
      <Route path={route.profile_register} component={ProfileRegister} />
      <Route
        path={route.profile_register_social}
        component={ProfileRegisterSocial}
      />
      <Route path={route.term_detail} component={TermDetail} />
      <Route path={route.bottom_home} component={BHome} />
      <Route path={route.bottom_search} component={BSearch} />
      <Route
        path={route.bottom_search_certification}
        component={SearchCertification}
      />
      <Route path={route.bottom_challenge} component={BChallenge} />
      <Route
        path={route.challenge_detail + "/:id"}
        component={ChallengeDetail}
      />
      <Route path={route.payment + "/:id"} component={Payment} />
      <Route path={route.refund} component={Refund} />
      <Route
        path={route.challenge_review_write}
        component={ChallengeReviewWrite}
      />
      <Route
        path={route.entry_certification + "/:id"}
        component={EntryCertification}
      />
      <Route
        path={route.general_user_profile + "/:id"}
        component={GeneralUserProfile}
      />
      <Route
        path={route.challenge_review_list}
        component={ChallengeReviewList}
      />
      <Route
        path={route.challenge_review_detail + "/:rId"}
        component={ChallengeReviewDetail}
      />
      <Route path={route.bottom_myChallenge} component={BMyChallenge} />
      <Route path={route.profile_edit} component={ProfileEdit} />
      <Route path={route.bottom_more} component={BMore} />
      <Route path={route.account_manager} component={AccountManager} />
      <Route path={route.pw_change} component={PwChange} />
      <Route path={route.notice_list} component={NoticeList} />
      <Route path={route.notice_detail} component={NoticeDetail} />
      <Route path={route.alarm_list} component={AlarmList} />
      <Route path={route.alarm_detail} component={AlarmDetail} />
      <Route path={route.alarm_setting} component={AlarmSetting} />
      <Route path={route.term_list} component={TermList} />
      <Route path={route.term_content} component={TermContent} />
      <Route path={route.withdraw} component={Withdraw} />
      <Route path={route.inquiry_list} component={InquiryList} />
      <Route path={route.inquiry_write} component={InquiryWrite} />
      <Route path={route.point_list} component={PointList} />
      <Route path={route.follow_list} component={FollowList} />
      <Route path={route.delivery_manager} component={DeliveryManager} />
      <Route path={route.delivery_edit} component={DeliveryEdit} />
      <Route path={route.order_list} component={OrderList} />
      <Route path={route.order_detail} component={OrderDetail} />
      <Route path={route.my_review_list} component={MyReviewList} />
      <Route path={route.my_storage_list} component={MyStorageList} />
      <Route path={route.report_write} component={ReportWrite} />
      <Route path={route.short_form} component={ShortForm} />
      <Route path={route.all_short_form} component={AllShortForm} />
      <Route
        path={route.short_form_detail + "/:id"}
        component={ShortFormDetail}
      />
      <Route
        path={route.influencer_profile + "/:ifId"}
        component={InfluencerProfile}
      />
      <Route path={route.influencer_search} component={InfluencerSearch} />
      <Route path={route.challenge_search} component={ChallengeSearch} />
      <Route
        path={route.certification + "/:id"}
        component={MypageCertificationList}
      />
      <Route
        path={route.certification_detail + "/:id"}
        component={MyPageCertificationDetail}
      />
      <Route
        path={route.challenge_certification + "/:id"}
        component={ChallengeCertification}
      />

      {/* 인플루언서 */}
      <Route path={route.influencer_main} component={InfluencerMain} />
      <Route
        path={route.bottom_manager_challenge}
        component={BChallengeManager}
      />
      <Route path={route.bottom_my_profile} component={BMyProfile} />
      <Route path={route.register_influencer} component={RegisterInfluencer} />
      <Route
        path={route.profile_register_influencer}
        component={ProfileRegisterInfluencer}
      />
      <Route path={route.influencer_more} component={InfluencerMore} />
      <Route path={route.create_challenge} component={CreateChallenge} />

      <Route path={route.board_upload} component={BoardUpload} />

      <Route
        path={route.update_challenge + "/:id"}
        component={UpdateChallenge}
      />
      <Route
        path={route.restart_challenge + "/:id"}
        component={RestartChallenge}
      />
      <Route
        path={route.influencer_profile_edit}
        component={InfluencerProfileEdit}
      />
      <Route path={route.payment_complete} component={PaymentComplete} />

      {/* Not Found */}
      <Route component={() => <Redirect to={route.bottom_home} />} />
    </Switch>
  );
};

export default Router;
