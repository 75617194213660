import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";

import styled from "styled-components";
import TextBtn from "../components/atoms/button/textBtn";
import ClPaymentSection from "../components/molecules/challenge/clPaymentSection";
import ProductSection from "../components/molecules/product/productSection";
import Input from "../components/atoms/input/input";
import RightLabelRadio from "../components/molecules/radio/rightLabelRadio";
import RightImgRadio from "../components/molecules/radio/rightImgRadio";
import ConfirmModal from "../components/templates/modals/confirmModal";
import DeliveryModal from "../components/templates/modals/deliveryModal";

import ChallengeApi from "../api/challengeApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import UserApi from "../api/userApi";
import { route } from "../routes/route";
import MoreApi from "../api/moreApi";
import ProfileApi from "../api/profileApi";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Payment(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const { id } = useParams();
  let query = useQuery();

  const [challenge, setChallenge] = useState({});

  const [usePointFocus, setUsePointFocus] = useState(false);
  const [dataList, setDataList] = useState([
    { value: false, label: "신용카드" },
    { value: false, label: "무통장입금" },
    // { value: false, label: "휴대폰소액 결제" },
    // { value: false, label: "계좌이체" },
    // { value: false, label: "naver", src: "/assets/icons/nPay.png" },
    // { value: false, label: "kakao", src: "/assets/icons/kPay.png" },
  ]);
  const [isDeliveryModal, setIsDeliveryModal] = useState(false);
  const [myEmail, setMyEmail] = useState("");

  const [isModal, setIsModal] = useState(false);
  const [isDEPOSITModal, setDEPOSITIsModal] = useState(false);
  const [depositInfo, setDepositInfo] = useState();

  // 주문 정보
  const [payInfo, setPayInfo] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);

  // 주문자 정보
  const [deliveryInfo, setDeliveryInfo] = useState([]);
  const [selectedDeliveryInfo, setSelectedDeliveryInfo] = useState();

  // 배송메시지
  const [dMessage, setDMessage] = useState("");

  // 포인트
  const [myPoint, setMyPoint] = useState(0);
  const [usePoint, setUsePoint] = useState("");

  // 배송지 삭제
  const [deleteModal, setDeleteModal] = useState(0);

  useEffect(() => {
    let totalPriceTemp = Number(
      totalPrice -
        Number(challenge.merch?.originPrice - challenge.merch?.price) +
        challenge.merch?.shippingFee
    );

    if (Number(usePoint) > Number(myPoint)) {
      setUsePoint(String(myPoint));
    }

    if (usePoint > totalPriceTemp) {
      setUsePoint(String(totalPriceTemp));
    }
  }, [usePoint]);

  useEffect(() => {
    mobilePayment();
  }, [query]);

  useEffect(() => {
    if (query.get("imp_uid")) {
      if (query.get("imp_success") == "true") {
        if (
          selectedDeliveryInfo &&
          payInfo &&
          dataList.some((item) => item.value === true)
        ) {
          enterClickFunc(query.get("imp_uid"));
        }
      } else {
        if (
          selectedDeliveryInfo &&
          payInfo &&
          dataList.some((item) => item.value === true)
        ) {
          alert(`결제 실패: ${query.get("error_msg")}`);
        }
      }
    }
  }, [selectedDeliveryInfo, dMessage, usePoint, payInfo, dataList]);

  const mobilePayment = async () => {
    try {
      await getChallenge();
      await getDeliveryInfo();
      await getProfileInfo();
      await getpointInfo();

      const payInfo = await JSON.parse(sessionStorage.getItem("payInfo"));
      const totalPrice = await JSON.parse(sessionStorage.getItem("totalPrice"));

      setPayInfo(payInfo);
      setTotalPrice(totalPrice);

      const pDataTemp = await JSON.parse(sessionStorage.getItem("pDataTemp"));

      if (pDataTemp) {
        setSelectedDeliveryInfo(pDataTemp.memberAddressId);
        setDMessage(pDataTemp.message);
        setUsePoint(pDataTemp.redeemPoints);
        setDataList(pDataTemp.paymentMethod);
      }
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const radioClickFunc = (text) => {
    const temp = dataList.map((item) => {
      return item.label === text
        ? {
            value: true,
            label: item.label,
            src:
              item.label === "naver"
                ? "/assets/icons/nPay.png"
                : "/assets/icons/kPay.png",
          }
        : {
            value: false,
            label: item.label,
            src:
              item.label === "naver"
                ? "/assets/icons/nPay.png"
                : "/assets/icons/kPay.png",
          };
    });

    setDataList(temp);
  };

  // init
  const getChallenge = async () => {
    try {
      const response = await ChallengeApi.getChallengeDetail(id);

      setChallenge(response.data.data);
      setDepositInfo(response.data.data.merch);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  // init
  const getDeliveryInfo = async () => {
    try {
      const response = await UserApi.getDeliveryList();
      const data = response.data.data;

      setDeliveryInfo([...data]);
      if (data.length > 0) {
        setSelectedDeliveryInfo(data[0]);
      } else {
        setIsModal(true);
      }
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const getProfileInfo = async () => {
    try {
      const response = await ProfileApi.Info();
      setMyEmail(response.data.data.email);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const delDMFunc = async () => {
    try {
      await MoreApi.DMDel(deleteModal);
      toast("삭제되었습니다.");
      getDeliveryInfo();
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  // init
  const getpointInfo = async () => {
    try {
      const response = await UserApi.getPointInfo();
      const data = response.data.data.point;
      setMyPoint(data);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const onClickPayment = () => {
    const dataTemp = {
      memberAddressId: selectedDeliveryInfo,
      message: dMessage,
      redeemPoints: usePoint ? usePoint : 0,
      payInfo: payInfo,
      paymentMethod: dataList,
    };

    sessionStorage.setItem("pDataTemp", JSON.stringify(dataTemp));

    /* 1. 가맹점 식별하기 */
    const { IMP } = window;
    IMP.init("imp76257003"); //테스트 가맹점

    const data = {
      pg: "html5_inicis", // PG사
      pay_method: "card", // 결제수단
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      amount:
        challenge.merch?.merchOptions.length <= 0
          ? Number(
              totalPrice -
                Number(challenge.merch?.price * payInfo[0]?.amount) +
                challenge.merch?.shippingFee -
                Number(usePoint)
            )
          : Number(
              totalPrice -
                Number(challenge.merch?.originPrice - challenge.merch?.price) +
                challenge.merch?.shippingFee -
                Number(usePoint)
            ), // 결제금액
      name: challenge.title, // 주문명
      buyer_name: selectedDeliveryInfo.receiverName, // 구매자 이름
      buyer_tel: selectedDeliveryInfo.phoneNumber, // 구매자 전화번호
      buyer_email: myEmail, // 구매자 이메일
      buyer_addr: `${selectedDeliveryInfo.addressName} ${selectedDeliveryInfo.detailAddress}`, // 구매자 주소
      buyer_postcode: selectedDeliveryInfo.zipCode, // 구매자 우편번호
      // m_redirect_url: `http://192.168.1.3:3000/healala/payment/${id}`, //로컬 테스트 주소
      m_redirect_url: `https://healala.co.kr/healala/payment/${id}`, //실주소
    };

    /* 4. 결제 창 호출하기 */
    IMP.request_pay(data, callback);
  };

  /* 3. 콜백 함수 정의하기 */
  const callback = (response) => {
    const { success, error_msg } = response;

    if (success) {
      enterClickFunc(response.imp_uid);
    } else {
      alert(`결제 실패: ${error_msg}`);
    }
  };

  const enterClickFunc = async (portonePayId) => {
    try {
      let formData = new FormData();

      const amountTemp =
        challenge.merch?.merchOptions.length <= 0
          ? Number(
              totalPrice -
                Number(challenge.merch?.price * payInfo[0]?.amount) +
                challenge.merch?.shippingFee -
                Number(usePoint)
            )
          : Number(
              totalPrice -
                Number(challenge.merch?.originPrice - challenge.merch?.price) +
                challenge.merch?.shippingFee -
                Number(usePoint)
            );

      formData.append("memberAddressId", selectedDeliveryInfo.id);
      formData.append("message", dMessage);
      if (usePoint) {
        formData.append("redeemPoints", usePoint);
      } else {
        formData.append("redeemPoints", 0);
      }

      for (let k = 0; k < payInfo.length; k++) {
        if (payInfo[k].option.id) {
          formData.append(
            `optionList[${k}].optionValueId`,
            payInfo[k].option.id
          );
          formData.append(`optionList[${k}].quantity`, payInfo[k].amount);
        }
      }

      if (dataList[0].value || amountTemp == 0) {
        formData.append("paymentMethod", "CREDIT_CARD");
        formData.append("portonePayId", portonePayId);
      }

      if (dataList[1].value) {
        formData.append("paymentMethod", "DEPOSIT_TO_ACCOUNT");
      }

      if (challenge.merch?.merchOptions.length <= 0) {
        formData.append("quantity", payInfo[0]?.amount);
      }

      formData.append("amount", amountTemp);

      await UserApi.enterPayChallenge(id, formData);

      const memberType = sessionStorage.getItem("memberType");

      if (memberType === "INFLUENCER") {
        history.push({
          pathname: route.payment_complete,
        });
      } else {
        history.push({
          pathname: route.payment_complete,
        });
      }

      sessionStorage.removeItem("pDataTemp");

      if (dataList[1].value && amountTemp != 0) {
        toast("입금이 확인되면 챌린지 참여가 가능합니다.");
      }
    } catch (error) {
      // if (error.response.status === 400) {
      //   toast(`이미 참가한 챌린지입니다.`);
      // }
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout
        style={{
          overflow: isDeliveryModal ? "hidden" : "",
        }}
      >
        <AppContainer>
          <Header title={"결제"} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              paddingTop: 24,
            }}
          >
            <ClPaymentSection
              style={{
                padding: "0px 20px 24px 20px",
              }}
              data={challenge}
            />

            <Division />

            {/* <ProductSection
              style={{
                padding: "24px 20px",
              }}
              data={challenge.merch}
            /> */}
            <div
              style={{
                padding: "24px 20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 15,
                    lineHeight: "20.3px",
                    fontWeight: 700,
                    color: "#000000",
                    marginBottom: 12,
                  }}
                >
                  상품
                </PretendardText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      challenge.merch?.merchImages.length > 0
                        ? challenge.merch.merchImages[0].imageUrl
                        : "/assets/images/image_default.png"
                    }
                    style={{
                      height: 72,
                      minWidth: 72,
                      maxWidth: 72,
                      borderRadius: 4,
                      marginRight: 16,
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <StyledTextTitle>{challenge.merch?.name}</StyledTextTitle>

                    {challenge.merch?.merchOptions.length <= 0 ? (
                      <PretendardText
                        style={{
                          fontSize: 15,
                          lineHeight: "22.3px",
                          fontWeight: 500,
                          color: "#778088",
                          marginBottom: 3,
                        }}
                      >
                        {`${payInfo[0]?.amount}개`}
                      </PretendardText>
                    ) : (
                      <PretendardText
                        style={{
                          fontSize: 15,
                          lineHeight: "22.3px",
                          fontWeight: 500,
                          color: "#778088",
                          marginBottom: 3,
                        }}
                      >
                        {payInfo.length > 0
                          ? `${
                              challenge.merch?.merchOptions.find(
                                (item) => item.id === payInfo[0].optionId
                              ).name
                            }/ ${payInfo[0].option.name} ${
                              payInfo[0].amount
                            }개 등`
                          : ""}
                      </PretendardText>
                    )}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 15,
                            lineHeight: "20.3px",
                            fontWeight: 700,
                            color: "#262C31",
                            marginRight: 4,
                          }}
                        >
                          {Number(challenge.merch?.originPrice).toLocaleString(
                            "ko-KR"
                          )}
                          원
                        </PretendardText>
                        {challenge.merch?.originPrice <
                        challenge.merch?.price ? null : (
                          <PretendardText
                            style={{
                              fontSize: 13,
                              lineHeight: "18.75px",
                              fontWeight: 700,
                              color: "#F24147",
                            }}
                          >
                            {100 -
                              Number(
                                (challenge.merch?.price /
                                  challenge.merch?.originPrice) *
                                  100
                              ).toFixed(0)}
                            %
                          </PretendardText>
                        )}
                      </div>

                      {challenge.merch?.originPrice <
                      challenge.merch?.price ? null : (
                        <img
                          src="/assets/icons/sale_text.svg"
                          alt=""
                          style={{
                            width: "66.72px",
                            height: "18px",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Division />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "24px 20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 14,
                }}
              >
                <StyledTextTitle>주문자 정보</StyledTextTitle>
                <TextBtn
                  onClick={() => {
                    setIsDeliveryModal(true);
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 13,
                      lineHeight: "18.75px",
                      color: "#778088",
                      fontWeight: 600,
                    }}
                  >
                    배송지 선택
                  </PretendardText>
                </TextBtn>
              </div>
              {selectedDeliveryInfo ? (
                <>
                  <StyledTextContent
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    {selectedDeliveryInfo.receiverName}
                  </StyledTextContent>
                  <StyledTextContent
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    {selectedDeliveryInfo.phoneNumber}
                  </StyledTextContent>
                  <StyledTextContent
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    {myEmail}
                  </StyledTextContent>
                  <StyledTextContent
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    {`${selectedDeliveryInfo.addressName} ${selectedDeliveryInfo.detailAddress}`}
                  </StyledTextContent>
                </>
              ) : null}
            </div>

            <div
              style={{
                width: "100%",
                border: "8px solid #F5F5F5",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "24px 20px",
              }}
            >
              <StyledTextTitle
                style={{
                  marginBottom: 12,
                }}
              >
                배송 메시지 입력
              </StyledTextTitle>
              <Input
                value={dMessage}
                onChange={(e) => {
                  setDMessage(e.target.value);
                }}
                type={"text"}
                placeholder={"ex. 부재시 경비실에 맡겨주세요"}
              />
            </div>

            <div
              style={{
                width: "100%",
                border: "8px solid #F5F5F5",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "24px 20px",
              }}
            >
              <StyledTextTitle
                style={{
                  marginBottom: 6,
                }}
              >
                사용 포인트 입력
              </StyledTextTitle>
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "21.5px",
                  color: "#778088",
                  fontWeight: 500,
                  marginBottom: 14,
                }}
              >
                5만원 이상 구매 시 1000 포인트부터 사용 가능합니다.
              </PretendardText>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  height: 51,
                  background: "rgb(244, 246, 246)",
                  border: usePointFocus
                    ? "1px solid #ff3333"
                    : "1px solid rgb(244, 246, 246)",
                  borderRadius: 12,
                  padding: "14px 16px",
                }}
              >
                <InputWrapper
                  disabled={
                    Number(myPoint) >= 1000 && Number(totalPrice) >= 50000
                      ? false
                      : true
                  }
                  totalLength={usePoint.length === 0 ? 2 : usePoint.length}
                  onFocus={() => {
                    setUsePointFocus(true);
                  }}
                  onBlur={() => {
                    setUsePointFocus(false);
                  }}
                  value={usePoint}
                  onChange={(e) => {
                    setUsePoint(e.target.value);
                  }}
                  type={"text"}
                  placeholder={"0"}
                />

                <PretendardText
                  style={{
                    fontSize: 15,
                    lineHeight: "22.3px",
                    color: "#262C31",
                    fontWeight: 500,
                  }}
                >
                  원
                </PretendardText>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 6,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 13,
                      lineHeight: "18.2px",
                      color: "#5A636A",
                      fontWeight: 500,
                    }}
                  >
                    사용가능 포인트&nbsp;
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: 13,
                      lineHeight: "18.2px",
                      color: "#5ECECE",
                      fontWeight: 500,
                    }}
                  >
                    {Number(Number(myPoint) - Number(usePoint)).toLocaleString(
                      "ko-KR"
                    )}
                    P
                  </PretendardText>
                </div>
                {Number(myPoint) >= 1000 && Number(totalPrice) >= 50000 ? (
                  <TextBtn
                    onClick={() => {
                      setUsePoint(JSON.stringify(myPoint));
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 13,
                        lineHeight: "18.75px",
                        color: "#5A636A",
                        fontWeight: 600,
                      }}
                    >
                      전액 사용
                    </PretendardText>
                  </TextBtn>
                ) : null}
              </div>
            </div>

            <div
              style={{
                width: "100%",
                border: "8px solid #F5F5F5",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "24px 20px",
              }}
            >
              <StyledTextTitle
                style={{
                  marginBottom: 16,
                }}
              >
                결제 정보
              </StyledTextTitle>

              {dataList.map((item, index) => {
                return (
                  <div
                    style={{
                      marginBottom: dataList.length === index + 1 ? 0 : 24,
                    }}
                  >
                    {item.label === "naver" || item.label === "kakao" ? (
                      <RightImgRadio
                        checked={item.value}
                        src={item.src}
                        onClick={() => {
                          radioClickFunc(item.label);
                        }}
                        imgStyle={{
                          width: item.label === "naver" ? 56 : 54,
                          height: item.label === "naver" ? 25 : 22,
                        }}
                      />
                    ) : (
                      <RightLabelRadio
                        checked={item.value}
                        text={item.label}
                        onClick={() => {
                          radioClickFunc(item.label);
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>

            <div
              style={{
                width: "100%",
                border: "8px solid #F5F5F5",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "24px 20px",
              }}
            >
              <StyledTextTitle
                style={{
                  marginBottom: 16,
                }}
              >
                결제 금액
              </StyledTextTitle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <StyledPayTextLeft>상품 가격</StyledPayTextLeft>
                <StyledPayTextRight>
                  {Number(totalPrice).toLocaleString("ko-KR")} 원
                </StyledPayTextRight>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <StyledPayTextLeft>할인 금액</StyledPayTextLeft>
                <StyledPayTextRight>
                  -
                  {challenge.merch?.merchOptions.length <= 0
                    ? Number(
                        challenge.merch?.price * payInfo[0]?.amount
                      ).toLocaleString("ko-KR")
                    : Number(
                        challenge.merch?.originPrice - challenge.merch?.price
                      ).toLocaleString("ko-KR")}
                  원
                </StyledPayTextRight>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <StyledPayTextLeft>배송비</StyledPayTextLeft>
                <StyledPayTextRight>
                  {Number(challenge.merch?.shippingFee).toLocaleString("ko-KR")}{" "}
                  원
                </StyledPayTextRight>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <StyledPayTextLeft>포인트 사용금액</StyledPayTextLeft>
                <StyledPayTextRight>
                  {usePoint
                    ? `-${Number(usePoint).toLocaleString("ko-KR")}`
                    : 0}{" "}
                  원
                </StyledPayTextRight>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "22.96px",
                    color: "#262C31",
                    fontWeight: 600,
                  }}
                >
                  최종 결제 금액
                </PretendardText>

                <PretendardText
                  style={{
                    fontSize: 18,
                    lineHeight: "25.2px",
                    color: "#F24147",
                    fontWeight: 600,
                  }}
                >
                  {challenge.merch?.merchOptions.length <= 0
                    ? Number(
                        totalPrice -
                          Number(challenge.merch?.price * payInfo[0]?.amount) +
                          challenge.merch?.shippingFee -
                          Number(usePoint)
                      ).toLocaleString("ko-KR")
                    : Number(
                        totalPrice -
                          Number(
                            challenge.merch?.originPrice -
                              challenge.merch?.price
                          ) +
                          challenge.merch?.shippingFee -
                          Number(usePoint)
                      ).toLocaleString("ko-KR")}
                  원
                </PretendardText>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginTop: 16,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 13,
                    lineHeight: "18.75px",
                    color: "#5ECECE",
                    fontWeight: 600,
                  }}
                >
                  챌린지 완수하고 리뷰작성 시!
                </PretendardText>
                <div
                  style={{
                    marginLeft: 11,
                    position: "relative",
                    padding: "4px 12px",
                    borderRadius: 4,
                    background: "#262C31",
                  }}
                >
                  <TipImg src="/assets/icons/tip.svg" alt="" />
                  <PretendardText
                    style={{
                      fontSize: 13,
                      lineHeight: "18.75px",
                      color: "#5ECECE",
                      fontWeight: 600,
                    }}
                  >
                    {challenge.rewardUsingPercentage
                      ? Number(
                          Number(
                            totalPrice -
                              Number(
                                challenge.merch?.originPrice -
                                  challenge.merch?.price
                              )
                          ) *
                            (challenge.rewardPoint / 100)
                        )
                          .toFixed(0)
                          .toLocaleString("ko-KR")
                      : Number(challenge.rewardPoint).toLocaleString("ko-KR")}
                    원
                  </PretendardText>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "sticky",
              padding: "10px 20px",
              width: "100%",
              background: "#fff",
              bottom: 0,
              left: 0,
              zIndex: 99,
            }}
          >
            <MainBtn
              disabled={
                id &&
                selectedDeliveryInfo &&
                (dataList[0].value || dataList[1].value)
                  ? false
                  : true
              }
              onClick={() => {
                let totalPriceTemp =
                  challenge.merch?.merchOptions.length <= 0
                    ? Number(
                        totalPrice -
                          Number(challenge.merch?.price * payInfo[0]?.amount) +
                          challenge.merch?.shippingFee -
                          Number(usePoint)
                      )
                    : Number(
                        totalPrice -
                          Number(
                            challenge.merch?.originPrice -
                              challenge.merch?.price
                          ) +
                          challenge.merch?.shippingFee -
                          Number(usePoint)
                      );

                if (totalPriceTemp == 0) {
                  enterClickFunc(0);
                } else {
                  if (dataList[0].value) {
                    onClickPayment();
                  } else {
                    setDEPOSITIsModal(true);
                  }
                }
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                결제하기
              </PretendardText>
            </MainBtn>
          </div>

          {/* WELCOME */}
          <DeliveryModal
            isOpen={isDeliveryModal}
            setIsDeliveryModal={setIsDeliveryModal}
            deliveryInfo={deliveryInfo}
            selectedDeliveryInfo={selectedDeliveryInfo}
            setSelectedDeliveryInfo={setSelectedDeliveryInfo}
            setDeleteModal={setDeleteModal}
          />

          {isDEPOSITModal ? (
            <ConfirmModal
              setVisible={setDEPOSITIsModal}
              title={"무통장입금 안내"}
              content={`은행명 : ${depositInfo.bankName}`}
              contentStyle={{
                textAlign: "left",
              }}
              content02={`계좌번호 : ${depositInfo.bankAccount}`}
              content03={`예금주 : ${depositInfo.accountOwner}`}
              content04={`입금금액 : ${Number(
                totalPrice -
                  Number(
                    challenge.merch?.originPrice - challenge.merch?.price
                  ) +
                  challenge.merch?.shippingFee -
                  Number(usePoint)
              ).toLocaleString("ko-KR")}`}
              onClickText={"닫기"}
              onCancleText={"입금완료"}
              onClick={() => {
                setDEPOSITIsModal(false);
              }}
              onCancle={() => {
                enterClickFunc();
              }}
            />
          ) : null}

          {isModal ? (
            <ConfirmModal
              setVisible={setIsModal}
              title={"배송지를 선택해 주세요."}
              content={"등록한 주소가 없어요."}
              onClickText={"닫기"}
              onCancleText={"새로운 주소 등록"}
              onClick={() => {
                setIsModal(false);
              }}
              onCancle={() => {
                history.push({
                  pathname: route.delivery_edit,
                });
              }}
              isNoneCloseBtn={true}
            />
          ) : null}

          {deleteModal !== 0 ? (
            <ConfirmModal
              title={"배송지를 삭제하시겠습니까?"}
              onClickText={"닫기"}
              onCancleText={"삭제"}
              onClick={() => {
                setDeleteModal(0);
              }}
              onCancle={() => {
                delDMFunc();
                setDeleteModal(0);
              }}
            />
          ) : null}
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default Payment;

const StyledTextTitle = styled(PretendardText).attrs((props) => {})`
  font-size: 15px;
  line-height: 20.3px;
  color: #000000;
  font-weight: 700;
`;

const StyledTextContent = styled(PretendardText).attrs((props) => {})`
  font-size: 15px;
  line-height: 22.3px;
  color: #262c31;
  font-weight: 500;
`;

const StyledPayTextLeft = styled(PretendardText).attrs((props) => {})`
  font-size: 15px;
  line-height: 22.3px;
  color: #5a636a;
  font-weight: 500;
`;

const StyledPayTextRight = styled(PretendardText).attrs((props) => {})`
  font-size: 15px;
  line-height: 22.3px;
  color: #262c31;
  font-weight: 500;
`;

const Division = styled.div.attrs((props) => {})`
  width: 100%;
  border: 8px solid #f5f5f5;
`;

const InputWrapper = styled.input.attrs((props) => {})`
  width: ${(props) => `${(props.totalLength / 1.5) * 5}%`};

  height: 48px;
  background: rgb(244, 246, 246);
  border: 1px solid rgb(244, 246, 246);
  padding: 0px;

  font-family: "Pretendard";
  color: #262c31;
  font-weight: 500;
  font-size: 15px;
  line-height: 22.3px;

  :focus {
    outline: none !important;
    border-color: rgb(244, 246, 246);
  }

  ::placeholder {
    color: #adb4ba;
    font-weight: 500;
    font-size: 15px;
    line-height: 22.3px;
  }
`;

const TipImg = styled.img.attrs((props) => {})`
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;

  position: absolute;
  top: 50%;

  transform: translate(-50%, -50%);
`;
