import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import PretendardText from "../../atoms/pretendardText";

const ProductSection = ({ data, style, index }) => {
  const history = useHistory();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        ...style,
      }}
    >
      <PretendardText
        style={{
          fontSize: 15,
          lineHeight: "20.3px",
          fontWeight: 700,
          color: "#000000",
          marginBottom: 12,
        }}
      >
        상품
      </PretendardText>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <img
          src={data ? data.merchImageUrl : ""}
          style={{
            height: 72,
            minWidth: 72,
            maxWidth: 72,
            borderRadius: 4,
            marginRight: 16,
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <StyledTextTitle>{data ? data.merchName : ""}</StyledTextTitle>

          {data && data.orderOptions?.length > 0
            ? data.orderOptions?.map((item, index) => {
                return (
                  <PretendardText
                    key={index}
                    style={{
                      fontSize: 15,
                      lineHeight: "22.3px",
                      fontWeight: 500,
                      color: "#778088",
                      marginBottom: 3,
                    }}
                  >
                    {item.optionName}/ {item.optionValueName} {item.quantity}개
                  </PretendardText>
                );
              })
            : null}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  fontWeight: 700,
                  color: "#262C31",
                  marginRight: 4,
                }}
              >
                {data
                  ? Number(
                      data.totalPrice +
                        data.shippingFee -
                        data.redeemPoints -
                        data.discountAmount
                    ).toLocaleString("ko-KR")
                  : 0}
                원
              </PretendardText>
              {data ? (
                data.discountAmount <= 0 ? null : (
                  <PretendardText
                    style={{
                      fontSize: 13,
                      lineHeight: "18.75px",
                      fontWeight: 700,
                      color: "#F24147",
                    }}
                  >
                    {100 -
                      Number((data.salePrice / data.merchPrice) * 100).toFixed(
                        0
                      )}
                    %
                  </PretendardText>
                )
              ) : null}
            </div>

            {data ? (
              data.discountAmount <= 0 ? null : (
                <img
                  src="/assets/icons/sale_text.svg"
                  alt=""
                  style={{
                    width: "66.72px",
                    height: "18px",
                  }}
                />
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSection;

const StyledTextTitle = styled(PretendardText).attrs((props) => {})`
  font-size: 16px;
  line-height: 22.96px;
  font-weight: 600;

  display: -webkit-box;
  display: -ms-flexbox;
  display: box;

  overflow: hidden;
  vertical-align: top;
  text-overflow: ellipsis;
  word-break: break-all;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;
