import React, { useRef } from "react";
import useDisplay from "../../../hooks/useDisplay";
import styled from "styled-components";
import PretendardText from "../pretendardText";

function FileForm(props) {
  // useRef를 이용해 input태그에 접근한다.
  const imageInput = useRef();

  // 버튼클릭시 input태그에 클릭이벤트를 걸어준다.
  const onCickImageUpload = () => {
    imageInput.current.click();
  };

  return (
    <>
      <input
        type="file"
        style={{ display: "none" }}
        accept="image/jpg, image/png, image/jpeg"
        ref={imageInput}
        onChange={props.handleChange}
      />
      <div
        onClick={onCickImageUpload}
        style={{
          cursor: "pointer",
          height: 80,
          width: 80,
          borderRadius: 12,
          borderWidth: 1,
          borderColor: "#ECEDF0",
          background: "#F4F6F6",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="/assets/icons/camera.png"
          style={{
            height: 24,
            width: 24,
            marginBottom: 3,
          }}
        />
        <PretendardText
          style={{
            fontSize: 12,
            lineHeight: "16.5px",
            color: "#778088",
            fontWeight: 500,
          }}
        >
          {props.text ? props.text : "이미지 등록"}
        </PretendardText>
      </div>
    </>
  );
}

export default FileForm;
