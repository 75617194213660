import api from "./api.js";

export default class ChallengeApi {
  static async getList(data) {
    return await api.get(`chn-001_F1`, data);
  }

  static async getListInfluencer(data) {
    return await api.get(`chn-001_F0`, data);
  }

  static async SearchList(data) {
    return await api.post(`/ser-003_F0`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async ReviewWrite(id, data) {
    return await api.post(`user/chn-005_I0/${id}`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async getSearchData(data) {
    return await api.get(`ser-003_F0`, data);
  }

  static async getChallengeDetail(id, data) {
    return await api.get(`chn-002_F0/${id}`, data);
  }

  static async getChallengeReviewList(id, data) {
    return await api.get(`chn-006_F0/${id}`, data);
  }
  static async CertificationInfo(id, data) {
    return await api.get(`ifn-008_F0/${id}`, data);
  }
  static async CommentList(data) {
    return await api.get(`comments`, data);
  }
  static async CommentCreate(data) {
    return await api.post(`user/comment/create`, data);
  }
  static async CommentUpdate(id, data) {
    return await api.put(`user/comment/update/${id}`, data);
  }
  static async CommentDelete(id) {
    return await api.del(`user/comment/delete/${id}`);
  }
  static async ReplyList(id, data) {
    return await api.get(`replies/${id}`, data);
  }
  static async ReplyCreate(data) {
    return await api.post(`user/replie/create`, data);
  }
  static async ReplyUpdate(id, data) {
    return await api.put(`user/reply/update/${id}`, data);
  }
  static async ReplyDelete(id) {
    return await api.del(`user/reply/delete/${id}`);
  }
  static async CertificationList(id, data) {
    return await api.get(`chn-006_F0/${id}`, data);
  }
  static async postCertification(id, data) {
    return await api.post(`user/myc-001_I0/${id}`, data);
  }
  static async putCertification(id, data) {
    return await api.put(`user/myc-001_U0/${id}`, data);
  }
  static async GetMerchList(data) {
    return await api.get(`user/i-MYC-022_F0`, data);
  }
  static async postChallenge(data) {
    return await api.post(`user/challenge`, data);
  }
  static async putChallenge(id, data) {
    return await api.put(`user/challenge/${id}`, data);
  }
  static async restartChallenge(id, data) {
    return await api.put(`user/challenge/resume/${id}`, data);
  }

  static async ReviewEdit(id, data) {
    return await api.put(`user/chn-005_U0/${id}`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async ReviewDel(id) {
    return await api.del(`user/chn-005_D0/${id}`);
  }

  static async ReviewDetail(id) {
    return await api.put(`chn-004_F1/${id}`);
  }

  static async ReviewListAvg(id) {
    return await api.put(`chn-003_F0/${id}`);
  }

  static async ReviewList(id, data) {
    return await api.put(`chn-003_F1/${id}`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async ReviewPartiInfo(id) {
    return await api.get(`user/chn-005_F0/${id}`);
  }

  static async DepositRefund(id) {
    return await api.put(`user/chn-002_U3/${id}`);
  }

  static async getDetailShortFormList(id, challengeId) {
    return await api.get(`mai-002_F3/${id}/${challengeId}`, {
      sort: "id,ASC",
    });
  }
}
