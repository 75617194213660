import React, { useState, useEffect } from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import PullToRefresh from "react-simple-pull-to-refresh";

import { BrowserRouter } from "react-router-dom";
import Router from "./routes/router";
import MainLayout from "./components/atoms/layouts/mainLayout";
import PCLeftLayout from "./components/atoms/layouts/pcLeftLayout";

import useDisplay from "./hooks/useDisplay";
import WelcomeModal from "./components/templates/modals/welcomeModal";
import { route } from "./routes/route";
import UserApi from "./api/userApi";

function App() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  useEffect(() => {
    setScreenSize();
  }, []);

  // 방문자 등록
  let visitFlag = false;
  const visitFunc = async () => {
    try {
      if (visitFlag) {
        return;
      }
      await UserApi.createVisit();
      visitFlag = true;
    } catch (error) {}
  };

  useEffect(() => {
    visitFunc();
  }, []);

  const handleRefresh = async () => {
    try {
      window.location.reload();
    } catch (error) {}
  };

  return (
    <div className="App">
      <PullToRefresh onRefresh={handleRefresh}>
        <MainLayout>
          {isSmall ? null : <PCLeftLayout />}

          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: isSmall ? "center" : "flex-start",
              marginLeft: isSmall ? 0 : "6%",
            }}
          >
            <BrowserRouter>
              <Router />
              <WelcomeModal></WelcomeModal>
            </BrowserRouter>
          </div>
        </MainLayout>
      </PullToRefresh>

      <ToastContainer
        className="toast"
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
      />
    </div>
  );
}

export default App;
