import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import TitleInput from "../components/molecules/input/titleInput";
import FileForm from "../components/atoms/fileForm/fileForm";
import { route } from "../routes/route";
import UserApi from "../api/userApi";
import { ko } from "../utils/ko";
import { toast } from "react-toastify";

function PwReset(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();

  const [pw, setPw] = useState("");
  const [pwErr, setPwErr] = useState("");
  const [pwConfirm, setPwConfirm] = useState("");
  const [pwConfirmErr, setPwConfirmErr] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const email = location?.state ? location.state.email : null;

    if (email) {
      setEmail(email);
    }

    window.scrollTo(0, 0);

    return () => {};
  }, []);

  useEffect(() => {
    if (pw.length > 0) {
      var regType1 = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/;

      if (!regType1.test(pw)) {
        return setPwErr("8-16자 영문, 숫자, 특수문자 조합으로 입력해주세요.");
      }

      if (pw.length < 8) {
        return setPwErr("비밀번호 8자리 이상을 입력해주세요.");
      }

      setPwErr("");
    }
  }, [pw]);

  useEffect(() => {
    if (pwConfirm.length > 0) {
      if (pwConfirm !== pw) {
        return setPwConfirmErr("비밀번호가 일치하지 않습니다.");
      }

      setPwConfirmErr("");
    }
  }, [pwConfirm]);

  const pwChangeFunc = async () => {
    try {
      const requestDto = {
        email: email,
        password: pw,
      };

      await UserApi.FindPWChange(requestDto);

      history.push({
        pathname: route.login,
      });
      toast("비밀번호 재설정 완료");
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header />

          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                marginTop: 40,
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 32,
                  lineHeight: "44.16px",
                  color: "#262C31",
                  fontWeight: 600,
                }}
              >
                비밀번호 재설정
              </PretendardText>

              <div
                style={{
                  marginTop: 66,
                }}
              >
                <TitleInput
                  title={"비밀번호"}
                  placeholder="8-16자리 영문, 숫자, 특수문자 조합"
                  type={"password"}
                  onChange={(e) => {
                    setPw(e.target.value);
                  }}
                  value={pw}
                  warningMessage={pwErr}
                  maxLength={"16"}
                />

                <TitleInput
                  style={{
                    marginTop: 17,
                  }}
                  title={"비밀번호 재입력"}
                  placeholder="비밀번호를 다시 입력하세요"
                  type={"password"}
                  onChange={(e) => {
                    setPwConfirm(e.target.value);
                  }}
                  value={pwConfirm}
                  warningMessage={pwConfirmErr}
                  maxLength={"16"}
                />
              </div>
            </div>

            <MainBtn
              disabled={
                pw && pwErr == "" && pwConfirm && pwConfirmErr == ""
                  ? false
                  : true
              }
              onClick={() => {
                pwChangeFunc();
              }}
              style={{
                marginTop: 22,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                비밀번호 재설정하기
              </PretendardText>
            </MainBtn>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default PwReset;
