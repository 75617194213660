import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";
import RightLabelCheckbox from "../components/atoms/checkbox/rightLabelCheckbox";

import Banner from "../components/molecules/banner/banner";
import Header from "../components/molecules/header/header";
import TitleInput from "../components/molecules/input/titleInput";
import { route } from "../routes/route";
import UserApi from "../api/userApi";
import BannerApi from "../api/bannerApi";

function Login(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [emailErrorM, setEmailErrorM] = useState("");
  const [pw, setPw] = useState("");
  const [pwErrorM, setPwErrorM] = useState("");
  const [autoLogin, setAutoLogin] = useState(false);

  const [bannerInfo, setBannerInfo] = useState();
  const [urlState, setUrlState] = useState("");

  useEffect(() => {
    const storedInformation = localStorage.getItem("autoLogin");
    const urlState = sessionStorage.getItem("urlState") ? sessionStorage.getItem("urlState") : null;
    setUrlState(urlState);

    if (storedInformation) {
      autoLoginFunc(urlState);
    }

    bannerGetFunc();
  }, []);

  const bannerGetFunc = async () => {
    try {
      let response = await BannerApi.List();

      if (response.data.data.content.length > 0) {
        let bannerTemp = response.data.data.content.find((item) => item.title === "로그인 배너");

        setBannerInfo(bannerTemp);
      }
    } catch (error) {}
  };

  const autoLoginFunc = async (urlStateP) => {
    try {
      const account = localStorage.getItem("account");
      const password = localStorage.getItem("password");

      const requestDto = {
        account: account,
        password: password,
      };

      let response = await UserApi.Login(requestDto);

      sessionStorage.setItem("memberType", response.data.data.memberType);
      sessionStorage.setItem("token", response.data.data.accessToken);
      sessionStorage.setItem("id", response.data.data.id);

      if (urlStateP) {
        sessionStorage.removeItem("urlState");
        history.push({
          pathname: urlStateP,
        });
      } else {
        history.push({
          pathname: route.bottom_home,
        });
      }
    } catch (error) {
      localStorage.removeItem("autoLogin");
      localStorage.removeItem("account");
      localStorage.removeItem("password");
    }
  };

  useEffect(() => {
    if (email.length > 0) {
      setEmailErrorM("");
    }
  }, [email]);

  useEffect(() => {
    if (pw.length > 0) {
      setPwErrorM("");
    }
  }, [pw]);

  const loginFunc = async () => {
    try {
      const requestDto = {
        account: email,
        password: pw,
      };

      let response = await UserApi.Login(requestDto);

      sessionStorage.setItem("memberType", response.data.data.memberType);
      sessionStorage.setItem("token", response.data.data.accessToken);
      sessionStorage.setItem("id", response.data.data.id);

      if (autoLogin) {
        localStorage.setItem("autoLogin", autoLogin);
        localStorage.setItem("account", email);
        localStorage.setItem("password", pw);
      }

      if (urlState) {
        sessionStorage.removeItem("urlState");
        history.push({
          pathname: urlState,
        });
      } else {
        history.push({
          pathname: route.bottom_home,
        });
      }
    } catch (error) {
      if (error.response.status === 404) {
        setEmailErrorM("존재하지 않는 회원입니다.");
      }
      if (error.response.status === 401) {
        setPwErrorM(`비밀번호가 일치하지 않습니다.`);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header />

          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Banner
              src={bannerInfo && bannerInfo.imageUrl ? bannerInfo.imageUrl : ""}
              style={{
                marginTop: 17,
              }}
              link={bannerInfo && bannerInfo.link ? bannerInfo.link : ""}
            />

            <div
              style={{
                marginTop: "20%",
                paddingBottom: 20,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 32,
                  lineHeight: "44.16px",
                  color: "#262C31",
                  fontWeight: 600,
                }}
              >
                로그인
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "20px",
                  color: "#5A636A",
                  fontWeight: 600,
                }}
              >
                헬스라이프 스타일, 즐겁고 신나게!
              </PretendardText>

              <div
                style={{
                  marginTop: 35,
                }}
              >
                <TitleInput
                  title={"이메일"}
                  placeholder="아이디를 입력해주세요"
                  type={"text"}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  warningMessage={emailErrorM}
                />

                <TitleInput
                  style={{
                    marginTop: 17,
                  }}
                  title={"비밀번호"}
                  placeholder="비밀번호를 입력해주세요"
                  type={"password"}
                  onChange={(e) => {
                    setPw(e.target.value);
                  }}
                  value={pw}
                  warningMessage={pwErrorM}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      loginFunc();
                    }
                  }}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 8,
                  }}
                >
                  <RightLabelCheckbox
                    checked={autoLogin}
                    onChange={(e) => {
                      setAutoLogin(e.target.checked);
                    }}
                    size={"18px"}
                    text={"자동로그인"}
                  />
                </div>
              </div>

              <MainBtn
                disabled={email && pw ? false : true}
                style={{
                  marginTop: 22,
                }}
                onClick={() => {
                  loginFunc();
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 15,
                    lineHeight: "20.3px",
                    color: "white",
                    fontWeight: 700,
                  }}
                >
                  로그인
                </PretendardText>
              </MainBtn>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 18,
                }}
              >
                <PretendardText
                  onClick={() => {
                    history.push({
                      pathname: route.id_find,
                    });
                  }}
                  style={{
                    fontSize: 14,
                    lineHeight: "20px",
                    color: "#5A636A",
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                >
                  이메일 찾기
                </PretendardText>
                <PretendardText
                  onClick={() => {
                    history.push({
                      pathname: route.pw_find,
                    });
                  }}
                  style={{
                    fontSize: 14,
                    lineHeight: "20px",
                    color: "#5A636A",
                    fontWeight: 600,
                    cursor: "pointer",
                    marginLeft: 13,
                    marginRight: 13,
                  }}
                >
                  비밀번호 찾기
                </PretendardText>
                <PretendardText
                  onClick={() => {
                    localStorage.removeItem("rDataTemp");
                    history.push({
                      pathname: route.register,
                    });
                  }}
                  style={{
                    fontSize: 14,
                    lineHeight: "20px",
                    color: "#5A636A",
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                >
                  회원가입
                </PretendardText>
              </div>
            </div>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default Login;
