import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";
import MainLayout from "../components/atoms/layouts/mainLayout";
import PretendardText from "../components/atoms/pretendardText";
import PCLeftLayout from "../components/atoms/layouts/pcLeftLayout";
import AppLayout from "../components/atoms/layouts/appLayout";
import Banner from "../components/molecules/banner/banner";
import MainBtn from "../components/atoms/button/mainBtn";
import WhiteBoldBtn from "../components/atoms/button/whiteBoldBtn";
import AppContainer from "../components/atoms/layouts/appContainer";
import { route } from "../routes/route";
import BannerApi from "../api/bannerApi";
import UserApi from "../api/userApi";
import { toast } from "react-toastify";

function Main(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();

  const [bannerInfo, setBannerInfo] = useState();
  const [urlState, setUrlState] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);

    bannerGetFunc();
  }, []);

  useEffect(() => {
    const storedInformation = localStorage.getItem("autoLogin");
    const urlState = sessionStorage.getItem("urlState")
      ? sessionStorage.getItem("urlState")
      : null;
    setUrlState(urlState);

    if (storedInformation) {
      autoLoginFunc(urlState);
    }

    bannerGetFunc();
  }, []);

  const bannerGetFunc = async () => {
    try {
      let response = await BannerApi.List();

      if (response.data.data.content.length > 0) {
        let bannerTemp = response.data.data.content.find(
          (item) => item.title === "로그인 배너"
        );

        setBannerInfo(bannerTemp);
      }
    } catch (error) {}
  };

  const autoLoginFunc = async (urlStateP) => {
    try {
      const account = localStorage.getItem("account");
      const password = localStorage.getItem("password");

      const requestDto = {
        account: account,
        password: password,
      };

      let response = await UserApi.Login(requestDto);

      sessionStorage.setItem("memberType", response.data.data.memberType);
      sessionStorage.setItem("token", response.data.data.accessToken);
      sessionStorage.setItem("id", response.data.data.id);

      if (urlStateP) {
        sessionStorage.removeItem("urlState");
        history.push({
          pathname: urlStateP,
        });
      } else {
        history.push({
          pathname: route.bottom_home,
        });
      }
    } catch (error) {
      localStorage.removeItem("autoLogin");
      localStorage.removeItem("account");
      localStorage.removeItem("password");
    }
  };

  // 네이버 로그인
  const initNaver = new window.naver.LoginWithNaverId({
    clientId: "BCyMy3QyHKfZgPk3Mkit",
    callbackUrl: "https://healala.co.kr/healala/main",
    isPopup: false,
    loginButton: { color: "green", type: 2, height: 40 },
  });

  useEffect(() => {
    initNaver.init();
  }, []);

  useEffect(() => {
    if (initNaver.accessToken) {
      initNaver.getLoginStatus((status) => {
        if (status) {
          const socialId = initNaver.user.id;
          socialLogin(socialId, "NAVER").then((joinFlag) => {
            if (joinFlag) {
              if (urlState) {
                sessionStorage.removeItem("urlState");
                history.push({
                  pathname: urlState,
                });
              } else {
                history.push({
                  pathname: route.bottom_home,
                });
              }
            } else {
              sessionStorage.setItem("socialId", socialId);
              sessionStorage.setItem("joinType", "NAVER");
              sessionStorage.setItem(
                "email",
                initNaver.user.email ? initNaver.user.email : ""
              );
              sessionStorage.setItem("gender", initNaver.user.gender);
              sessionStorage.setItem(
                "name",
                initNaver.user.name ? initNaver.user.name : ""
              );
              sessionStorage.setItem(
                "nickname",
                initNaver.user.nickname ? initNaver.user.nickname : ""
              );
              sessionStorage.setItem(
                "profileUrl",
                initNaver.user.profile_image ? initNaver.user.profile_image : ""
              );

              history.push({
                pathname: route.register_social,
              });
            }
          });
        }
      });
    }
  }, []);

  const naverLogin = () => {
    const naverLoginBtn = document.getElementById("naverIdLogin").firstChild;
    naverLoginBtn.click();
  };

  // 카카오 로그인
  const initKakao = () => {
    const jsKey = "8f7c3e06f4bf4074c82571655d7e20b6";
    const Kakao = window.Kakao;
    if (Kakao && !Kakao.isInitialized()) {
      Kakao.init(jsKey);
    }
  };

  useEffect(() => {
    initKakao();
  }, []);

  const kakaoLogin = () => {
    window.Kakao.Auth.login({
      success() {
        window.Kakao.API.request({
          url: "/v2/user/me",
          success(res) {
            const socialId = res.id;
            const kakaoAccount = res.kakao_account;

            socialLogin(socialId, "KAKAO").then((joinFlag) => {
              if (joinFlag) {
                if (urlState) {
                  sessionStorage.removeItem("urlState");
                  history.push({
                    pathname: urlState,
                  });
                } else {
                  history.push({
                    pathname: route.bottom_home,
                  });
                }
              } else {
                sessionStorage.setItem("socialId", socialId);
                sessionStorage.setItem("joinType", "KAKAO");
                // 필수
                sessionStorage.setItem(
                  "nickname",
                  kakaoAccount.profile.nickname
                    ? kakaoAccount.profile.nickname
                    : ""
                );
                sessionStorage.setItem(
                  "profileUrl",
                  kakaoAccount.profile.profile_image_url
                    ? kakaoAccount.profile.profile_image_url
                    : ""
                );
                // 선택
                sessionStorage.setItem(
                  "email",
                  kakaoAccount.email ? kakaoAccount.email : ""
                );
                // sessionStorage.setItem("birth", kakaoAccount.birthday ? kakaoAccount.birthday : ""); // 년도를 몰라서 못씀
                sessionStorage.setItem(
                  "gender",
                  kakaoAccount.gender ? kakaoAccount.gender : ""
                );

                history.push({
                  pathname: route.register_social,
                });
              }
            });
          },
          fail(error) {},
        });
      },
      fail(error) {},
    });
  };

  const socialLogin = async (socialId, joinType) => {
    try {
      // 가입되어있는지 확인
      const formData = new FormData();
      formData.append("socialToken ", socialId);
      formData.append("joinType", joinType);

      const response = await UserApi.SocialLogin(formData);

      // 가입되어있는 경우
      sessionStorage.setItem("memberType", response.data.data.memberType);
      sessionStorage.setItem("token", response.data.data.accessToken);
      sessionStorage.setItem("id", response.data.data.id);
      return true;
    } catch (error) {
      // 가입되어있지 않은 경우
      if (error.response.status === 404) {
        return false;
      }
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer
          style={{
            backgroundImage: "url(/assets/images/m_bg.png)",
            backgroundSize: "100% 100%",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Banner
            src={bannerInfo && bannerInfo.imageUrl ? bannerInfo.imageUrl : ""}
            style={{
              marginTop: "20%",
            }}
            link={bannerInfo && bannerInfo.link ? bannerInfo.link : ""}
          />

          <div
            style={{
              marginTop: "30%",
              marginBottom: "10%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/assets/images/logo.png"
              style={{
                height: 70,
                width: 230,
              }}
            />
            <PretendardText
              style={{
                fontSize: 15,
                lineHeight: "22.25px",
                color: "white",
                fontWeight: 400,
                marginTop: 19,
              }}
            >
              헬스라이프 스타일, 즐겁고 신나게!
            </PretendardText>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "14%",
                marginBottom: "18%",
              }}
            >
              <div id="naverIdLogin" style={{ display: "none" }}></div>
              <img
                src="/assets/icons/naver.png"
                style={{
                  height: 64,
                  width: 64,
                  marginRight: 15,
                  cursor: "pointer",
                }}
                onClick={() => {
                  naverLogin();
                }}
              />
              <img
                src="/assets/icons/kakao.png"
                style={{
                  height: 64,
                  width: 64,
                  cursor: "pointer",
                }}
                onClick={() => {
                  kakaoLogin();
                }}
              />
            </div>

            <MainBtn
              onClick={() => {
                history.push({
                  pathname: route.login,
                });
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                일반 로그인
              </PretendardText>
            </MainBtn>

            <WhiteBoldBtn
              onClick={() => {
                history.push({
                  pathname: route.bottom_home,
                });
              }}
              style={{
                marginTop: 12,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                둘러보기
              </PretendardText>
            </WhiteBoldBtn>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 26,
              }}
            >
              <PretendardText
                onClick={() => {
                  history.push({
                    pathname: route.id_find,
                  });
                }}
                style={{
                  fontSize: 14,
                  lineHeight: "20px",
                  color: "rgba(255,255,255,0.7)",
                  fontWeight: 400,
                  cursor: "pointer",
                }}
              >
                이메일 찾기
              </PretendardText>
              <PretendardText
                onClick={() => {
                  history.push({
                    pathname: route.pw_find,
                  });
                }}
                style={{
                  fontSize: 14,
                  lineHeight: "20px",
                  color: "rgba(255,255,255,0.7)",
                  fontWeight: 400,
                  cursor: "pointer",
                  marginLeft: 13,
                  marginRight: 13,
                }}
              >
                비밀번호 찾기
              </PretendardText>
              <PretendardText
                onClick={() => {
                  localStorage.removeItem("rDataTemp");
                  history.push({
                    pathname: route.register,
                  });
                }}
                style={{
                  fontSize: 14,
                  lineHeight: "20px",
                  color: "rgba(255,255,255,0.7)",
                  fontWeight: 400,
                  cursor: "pointer",
                }}
              >
                회원가입
              </PretendardText>
            </div>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default Main;
