import React, { useEffect } from "react";
import useDisplay from "../../hooks/useDisplay";
import PretendardText from "../atoms/pretendardText";
import TextBtn from "../atoms/button/textBtn";
import { route } from "../../routes/route";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getExtensionOfFilename, videoExtension } from "../../utils/file";

function StoreChallengeCard02({ item, storeClickFunc }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "228px",
          overflow: "hidden",
          borderRadius: "8px",
          position: "relative",
        }}
      >
        <img
          src={
            videoExtension.includes(
              getExtensionOfFilename(item.certification.fileUrl)
            )
              ? item.certification.thumbFileUrl
              : item.certification.fileUrl
          }
          // src={"/assets/images/profile02.jpg"}
          alt=""
          style={{
            width: "100%",
            height: "228px",
          }}
          onClick={() => {
            history.push({
              pathname:
                route.certification_detail + `/${item?.certification.id}`,
            });
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "15px",
            right: "10px",
          }}
        >
          <TextBtn
            onClick={() => {
              storeClickFunc(
                item?.certification.isStored,
                item?.certification.id,
                item.storeType,
                item.id
              );
            }}
          >
            <img
              src={"/assets/icons/bookmark_white.png"}
              alt=""
              style={{
                width: "24px",
                height: "24px",
                cursor: "pointer",
              }}
            />
          </TextBtn>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "15px",
            left: "10px",
          }}
        >
          <TextBtn
            onClick={() => {
              history.push({
                pathname:
                  route.certification_detail + `/${item?.certification.id}`,
              });
            }}
          >
            <PretendardText
              style={{
                color: "rgba(255, 255, 255, 1)",
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "600",
                width: "130px",
                height: "40px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                display: "-webkit-box",
              }}
            >
              {item.certification.challengeInfo.title}
            </PretendardText>
          </TextBtn>
          <PretendardText
            style={{
              color: "rgba(255, 255, 255, 0.8)",
              fontSize: "11px",
              lineHeight: "14px",
              fontWeight: "600",
              width: "130px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {item.certification.contents}
          </PretendardText>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextBtn
              onClick={() => {
                if (item?.certification.memberInfo.userType === "INFLUENCER") {
                  history.push({
                    pathname:
                      route.influencer_profile +
                      `/${item.shortForm.influencerInfo.id}`,
                  });
                } else {
                  history.push(
                    route.general_user_profile +
                      `/${item.certification.memberInfo.id}`
                  );
                }
              }}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  border: "2px solid rgba(255, 255, 255, 1)",
                  overflow: "hidden",
                }}
              >
                <img
                  src={
                    item.certification.memberInfo.fileUrl
                      ? item.certification.memberInfo.fileUrl
                      : "/assets/images/profile_temp.png"
                  }
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              <PretendardText
                style={{
                  color: "rgba(255, 255, 255, 1)",
                  fontSize: "13px",
                  lineHeight: "18.75px",
                  fontWeight: "600",
                  width: "45px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  marginLeft: "6px",
                }}
              >
                {item.certification.memberInfo.nickName}
              </PretendardText>
            </TextBtn>
            {item.certification.memberInfo.userType === "INFLUENCER" ? (
              <img
                src="/assets/icons/card_checkstar.svg"
                alt=""
                style={{
                  width: "17px",
                  height: "17px",
                  marginLeft: "3px",
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default StoreChallengeCard02;
