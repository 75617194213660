import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import RightLabelRadio from "../components/molecules/radio/rightLabelRadio";
import Input from "../components/atoms/input/input";
import { toast } from "react-toastify";
import { ko } from "date-fns/locale";
import ReportApi from "../api/reportApi";

function ReportWrite(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();

  const [dataList, setDataList] = useState([
    {
      value: false,
      label: "부정확한 정보를 게시하였어요",
      type: "INACCURACY_INFO",
    },
    {
      value: false,
      label: "지나치게 광고성 정보를 게시하였어요",
      type: "ADVERTICEMENT",
    },
    { value: false, label: "해당 글로 도배가 되어 있어요", type: "PAPERING" },
    {
      value: false,
      label: "저작권을 침해하는 정보를 게시하였어요",
      type: "COPYRIGHT",
    },
    { value: false, label: "욕설이나 비방이 심해요", type: "OBSCENE" },
    { value: false, label: "외설적인 내용이에요", type: "ABUSE" },
    { value: false, label: "기타", type: "ETC" },
  ]);

  const [reason, setReason] = useState("");
  const [reportData, setReportData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const radioClickFunc = (text) => {
    const temp = dataList.map((item) => {
      return item.label === text
        ? {
            value: true,
            label: item.label,
            type: item.type,
          }
        : {
            value: false,
            label: item.label,
            type: item.type,
          };
    });

    setDataList(temp);
  };

  useEffect(() => {
    reportData.id = location.state.id;
    reportData.reportType = location.state.reportType;
    setReportData(reportData);
  }, []);

  const reportFunc = async () => {
    try {
      let reasonType = dataList.find((item) => item.value === true);

      let formData = new FormData();

      formData.append("id", reportData.id);
      formData.append("reportReason", reasonType.type);

      if (reasonType.type === "ETC") {
        formData.append("reportReasonDtl", reason);
      } else {
        formData.append("reportReasonDtl", reasonType.label);
      }

      formData.append("reportType", reportData.reportType);

      await ReportApi.Create(formData);

      history.goBack();
      toast("신고가 접수되었습니다.");
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header />

          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                marginTop: 40,
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 32,
                  lineHeight: "44.16px",
                  color: "#262C31",
                  fontWeight: 600,
                  marginBottom: 6,
                }}
              >
                신고하기
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "21.5px",
                  color: "#5A636A",
                  fontWeight: 500,
                }}
              >
                신고 사유를 알려주시면
                <br />
                최대한 빠르게 대응하겠습니다.
              </PretendardText>

              <div
                style={{
                  marginTop: 50,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {dataList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        marginBottom: dataList.length === index + 1 ? 10 : 24,
                      }}
                    >
                      <RightLabelRadio
                        checked={item.value}
                        text={item.label}
                        onClick={() => {
                          radioClickFunc(item.label);
                        }}
                      />
                    </div>
                  );
                })}

                {dataList[6].value ? (
                  <Input
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    type={"text"}
                    placeholder={"기타 사유를 입력해주세요."}
                  />
                ) : null}
              </div>
            </div>

            <MainBtn
              disabled={
                dataList.some((item) => item.value === true) ? false : true
              }
              onClick={() => {
                reportFunc();
              }}
              style={{
                marginTop: 22,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                신고하기
              </PretendardText>
            </MainBtn>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default ReportWrite;
