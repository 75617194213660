import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useDisplay from "../../../hooks/useDisplay";

import PretendardText from "../../atoms/pretendardText";

const Select = ({ dataList, value, setValue, placeholder, style }) => {
  const { isMobile } = useDisplay();
  const [languageClicked, setLanguageClicked] = useState(false);

  return (
    <>
      <SelectWrapper
        onClick={() => {
          setLanguageClicked(!languageClicked);
        }}
        style={{
          border: languageClicked ? "1px solid #ff3333" : "none",
          ...style,
        }}
      >
        <SelectText isSelect={value}>{value ? value : placeholder}</SelectText>
        <div style={{}}>
          {languageClicked ? (
            <img
              style={{
                width: 20,
                height: 20,
                marginLeft: 19,
              }}
              src="/assets/icons/up_arrow_20.svg"
              alt="up_arrow_20"
            />
          ) : (
            <img
              style={{
                width: 20,
                height: 20,
                marginLeft: 19,
              }}
              src="/assets/icons/under_arrow.svg"
              alt="under_arrow"
            />
          )}
        </div>
        {languageClicked ? (
          <SelectItemContain
            style={{
              position: "absolute",
              top: 57,
              left: 0,
              width: "100%",
              background: "#fff",
              borderRadius: 12,

              boxShadow: "0px 0px 5px rgba(0,0,0,0.3)",
            }}
          >
            {dataList.map((item, index) => {
              return (
                <SelectSpanText
                  key={index}
                  onClick={() => {
                    setValue(item.value);
                  }}
                >
                  <SelectValueText>{item.label}</SelectValueText>
                </SelectSpanText>
              );
            })}
          </SelectItemContain>
        ) : null}
      </SelectWrapper>
    </>
  );
};

export default Select;

const SelectWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 51px;
  background-color: #f4f6f6;
  border-radius: 12px;

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
  z-index: 200;
`;

const SelectText = styled(PretendardText).attrs((props) => {})`
  font-size: 15px;
  font-weight: 500;
  line-height: 22.3px;
  color: ${(props) => (props.isSelect ? "#262C31" : "#adb4ba")};

  white-space: nowrap;
`;

const SelectValueText = styled(PretendardText).attrs((props) => {})`
  font-size: 15px;
  font-weight: 500;
  line-height: 22.3px;

  color: #262c31;
  white-space: nowrap;
`;

const SelectSpanText = styled.div.attrs((props) => {})`
  padding-left: 18px;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: #e8e6e6;
    text-decoration: none;
  }
`;

const SelectItemContain = styled.div.attrs((props) => {})`
  position: absolute;
  top: 57px;
  left: 0px;
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  height: 225px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #adb4ba;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(255, 2550, 255, 0.1); /*스크롤바 뒷 배경 색상*/
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;
