import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Rating from "react-rating";

import TextBtn from "../atoms/button/textBtn";
import PretendardText from "../atoms/pretendardText";
import styled from "styled-components";
import { route } from "../../routes/route";

function ReviewBoxDetail({
  style,
  data,
  setIsModal,
  setIsDeleteId,
  reviewEditFunc,
}) {
  const history = useHistory();

  const [moreOpen, setMoreOpen] = useState(false);
  const [isMyComment, setIsMyComment] = useState(false);

  return (
    <>
      <div
        style={{
          position: "relative",
          marginBottom: "40px",
          ...style,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextBtn
            onClick={() => {
              if (data.userType === "INFLUENCER") {
                history.push({
                  pathname:
                    route.influencer_profile + `/${data.commonMember.id}`,
                });
              } else {
                history.push({
                  pathname:
                    route.general_user_profile + `/${data.commonMember.id}`,
                });
              }
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "28px",
                  height: "28px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  marginRight: "7px",
                }}
              >
                <img
                  src={
                    data.commonMember.fileUrl
                      ? data.commonMember.fileUrl
                      : "/assets/images/profile_temp.png"
                  }
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <PretendardText
                    style={{
                      color: "rgba(38, 44, 49, 1)",
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "600",
                      maxWidth: "120px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {data.commonMember.nickName}
                  </PretendardText>
                  {data.userType === "INFLUENCER" ? (
                    <img
                      src="/assets/icons/card_checkstar.svg"
                      alt=""
                      style={{
                        width: "17px",
                        height: "17px",
                        marginLeft: "3px",
                      }}
                    />
                  ) : null}
                </div>
                <PretendardText
                  style={{
                    color: "#ADB4BA",
                    fontSize: "12px",
                    lineHeight: "16.5px",
                    fontWeight: "500",
                  }}
                >
                  {data.dateTitle}
                </PretendardText>
              </div>
            </div>
          </TextBtn>
          <TextBtn onClick={() => setMoreOpen(!moreOpen)}>
            <img
              src="/assets/icons/ic_24_more.png"
              alt=""
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          </TextBtn>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Rating
            initialRating={data.score}
            readonly
            emptySymbol={
              <img
                src="/assets/icons/rating_d.png"
                className="icon"
                style={{
                  width: 16,
                  height: 16,
                }}
              />
            }
            fullSymbol={
              <img
                src="/assets/icons/rating_a.png"
                className="icon"
                style={{
                  width: 16,
                  height: 16,
                }}
              />
            }
          />
          <PretendardText
            style={{
              fontSize: 14,
              lineHeight: "20px",
              fontWeight: 600,
              color: "#262C31",
              marginLeft: 4,
              marginTop: 2,
            }}
          >
            {data.score}
          </PretendardText>
        </div>
        <div
          style={{
            marginTop: 10,
            flexDirection: "column",
          }}
        >
          {data && data.orderInfo ? (
            <div
              style={{
                marginBottom: 4,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  fontWeight: 700,
                  color: "#262C31",
                  display: "inline-block",
                }}
              >
                {data.orderInfo.merchName}&nbsp;
              </PretendardText>
              {data.orderInfo.orderOptions.map((item, index) => {
                if (index > 1) {
                  return (
                    <PretendardText
                      key={index}
                      style={{
                        fontSize: 15,
                        lineHeight: "22.25px",
                        fontWeight: 600,
                        color: "#5A636A",
                        textAlign: "center",
                        display: "inline-block",
                      }}
                    >
                      외 {data.orderInfo.orderOptions.length - 2}개
                    </PretendardText>
                  );
                }
                return (
                  <PretendardText
                    style={{
                      fontSize: 15,
                      lineHeight: "20.3px",
                      fontWeight: 700,
                      color: "#262C31",
                      display: "inline-block",
                    }}
                  >
                    {"("}
                    {item.optionName}/{item.optionValueName}
                    {")"}&nbsp;
                  </PretendardText>
                );
              })}

              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.25px",
                  fontWeight: 600,
                  color: "#778088",
                  display: "inline-block",
                }}
              >
                으로(로) 챌린지 했어요!
              </PretendardText>
            </div>
          ) : null}
          <StyledTextTitle>{data.contents}</StyledTextTitle>

          <div
            style={{
              marginTop: 11,
            }}
          >
            <img
              src={data.imageUrl}
              style={{
                width: "100%",
                height: 335,
                borderRadius: 16,
              }}
            />
          </div>
        </div>
        {moreOpen ? (
          <div
            style={{
              position: "absolute",
              top: "31px",
              right: "0",
              width: "120px",
              borderRadius: "6px",
              boxShadow: "0px 0px 8px rgba(0,0,0,0.3)",
              backgroundColor: "#FFFFFF",
              padding: "10px 14px 10px 14px",
            }}
          >
            {data.isWriter ? (
              <>
                <TextBtn
                  onClick={() => {
                    setIsModal(true);
                    setIsDeleteId(data.id);
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "500",
                      color: "#262C31",
                      height: "28px",
                      padding: "4px 6px 4px 6px",
                    }}
                  >
                    삭제하기
                  </PretendardText>
                </TextBtn>
                <TextBtn
                  onClick={() => {
                    reviewEditFunc(data.id);
                  }}
                  style={{
                    marginTop: "8px",
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "500",
                      color: "#262C31",
                      height: "28px",
                      padding: "4px 6px 4px 6px",
                    }}
                  >
                    수정하기
                  </PretendardText>
                </TextBtn>
              </>
            ) : (
              <TextBtn
                onClick={() => {
                  history.push({
                    pathname: route.report_write,
                    state: {
                      id: data.id,
                      reportType: "REVIEW",
                    },
                  });
                }}
              >
                <PretendardText
                  style={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: "500",
                    color: "#262C31",
                    height: "28px",
                    padding: "4px 6px 4px 6px",
                  }}
                >
                  신고하기
                </PretendardText>
              </TextBtn>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default ReviewBoxDetail;

const StyledTextTitle = styled(PretendardText).attrs((props) => {})`
  font-size: 15px;
  line-height: 22.3px;
  font-weight: 400;

  display: -webkit-box;
  display: -ms-flexbox;
  display: box;

  white-space: pre-wrap;
`;
