import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useDisplay from "../../../hooks/useDisplay";

const Banner = (props) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const handleOpenNewTab = (url) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  return (
    <div
      style={{
        width: "100%",
        height: isSmall ? 89 : 118.18,
        borderRadius: 8,
        cursor: "pointer",
        ...props.style,
      }}
      onClick={() => {
        if (props.link.includes("http") || props.link.includes("https")) {
          handleOpenNewTab(props.link);
        } else {
          handleOpenNewTab(`https://${props.link}`);
        }
      }}
    >
      <img
        src={props.src}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "8px",
        }}
      />
    </div>
  );
};

export default Banner;
