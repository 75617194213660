import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PretendardText from "../../atoms/pretendardText";
import TextBtn from "../../atoms/button/textBtn";
import { route } from "../../../routes/route";

const DeliverySection = ({ data, style, delDMFunc }) => {
  const history = useHistory();

  function phoneFilter(phone) {
    const temp = phone
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

    return temp;
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        ...style,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 4,
        }}
      >
        <PretendardText
          style={{
            fontSize: 16,
            lineHeight: "22.96px",
            color: "#262C31",
            fontWeight: 600,
          }}
        >
          {data.address}
        </PretendardText>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TextBtn
            onClick={() => {
              history.push({
                pathname: route.delivery_edit,
                state: {
                  id: data.id,
                  data: data,
                },
              });
            }}
            style={{
              marginRight: 24,
            }}
          >
            <PretendardText
              style={{
                fontSize: 15,
                lineHeight: "20.3px",
                color: "#778088",
                fontWeight: 700,
              }}
            >
              수정
            </PretendardText>
          </TextBtn>
          <TextBtn
            onClick={() => {
              delDMFunc(data.id);
            }}
            style={{}}
          >
            <PretendardText
              style={{
                fontSize: 15,
                lineHeight: "20.3px",
                color: "#66CCCC",
                fontWeight: 700,
              }}
            >
              삭제
            </PretendardText>
          </TextBtn>
        </div>
      </div>
      <PretendardText
        style={{
          fontSize: 15,
          lineHeight: "22.3px",
          color: "#778088",
          fontWeight: 500,
          marginBottom: 6,
        }}
      >
        ({data.zipCode})
      </PretendardText>
      <PretendardText
        style={{
          fontSize: 15,
          lineHeight: "22.3px",
          color: "#778088",
          fontWeight: 500,
          marginBottom: 6,
        }}
      >
        {data.addressName} {data.detailAddress}
      </PretendardText>
      <PretendardText
        style={{
          fontSize: 15,
          lineHeight: "22.3px",
          color: "#778088",
          fontWeight: 500,
        }}
      >
        {data.receiverName} &nbsp;&nbsp;{phoneFilter(data.phoneNumber)}
      </PretendardText>
    </div>
  );
};

export default DeliverySection;
