import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import TitleInput from "../components/molecules/input/titleInput";

import Input from "../components/atoms/input/input";
import styled from "styled-components";
import GrayBoldBtn from "../components/atoms/button/grayBoldBtn";
import { route } from "../routes/route";
import UserApi from "../api/userApi";
import MoreApi from "../api/moreApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";

let timer;

function AccountManager(props) {
  let min = 3;
  let sec = 0;

  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  const [genderFocus, setGenderFocus] = useState(false);

  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [birth, setBirth] = useState("");
  const [birthErr, setBirthErr] = useState("");
  const [gender, setGender] = useState("");
  const [genderErr, setGenderErr] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [isPNC, setIsPNC] = useState(false);
  const [phoneNumberChk, setPhoneNumberChk] = useState(false);

  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [phoneNumberValueErr, setPhoneNumberValueErr] = useState("");
  const [certifyValue, setCertifyValue] = useState("");

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");

  const [minState, setMinState] = useState(3);
  const [secState, setSecState] = useState(0);

  const [btnDisabled, setBtnDisabled] = useState(false);
  const [isSNS, setIsSNS] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getInfoAPI();

    return () => {
      clearTimer("타이머 종료");
    };
  }, []);

  const getInfoAPI = async () => {
    try {
      let response = await MoreApi.AccountInfo();
      let tempData = response.data.data;

      let birthTemp = tempData.birth;
      let birthTemp01 = birthTemp.slice(0, 4);
      let birthTemp02 = birthTemp.slice(5, 7);
      let birthTemp03 = birthTemp.slice(8, 9);

      setName(tempData.name);
      setBirth(`${birthTemp01}${birthTemp02}${birthTemp03}`);
      setGender(tempData.gender);
      setPhone(tempData.phoneNumber);
      setEmail(tempData.email);
    } catch (error) {}
  };

  useEffect(() => {
    if (phone.length > 0) {
      var regType1 = /^[0-9]+$/;
      if (!regType1.test(phone)) {
        return setPhoneErr("-없이 숫자만 입력해주세요.");
      }

      setPhoneErr("");

      setPhoneNumberChk(false);
      setIsPNC(false);
    }
  }, [phone]);

  const countTimerStart = () => {
    timer = setInterval(countTimer, 1000);
  };

  const countTimer = () => {
    if (sec != 0) {
      sec = sec - 1;
      setSecState(sec);
    } else {
      if (min != 0) {
        min = min - 1;
        sec = 59;

        setSecState(sec);
        setMinState(min);
      } else {
        clearTimer("타이머 종료");
        setPhoneErr("인증에 실패하였습니다. 인증을 다시 시도하세요.");
      }
    }
  };

  function clearTimer(text) {
    clearInterval(timer);

    if (text === "타이머 종료") {
      min = 3;
      setMinState(3);
      sec = 0;
      setSecState(0);
    }
  }

  useEffect(() => {
    if (
      (name &&
        nameErr == "" &&
        birth &&
        birthErr == "" &&
        gender &&
        genderErr == "" &&
        phone &&
        phoneErr == "" &&
        phoneNumberChk &&
        email &&
        emailErr == "") ||
      (isSNS &&
        nameErr == "" &&
        birth &&
        birthErr == "" &&
        gender &&
        genderErr == "" &&
        phone &&
        phoneErr == "" &&
        phoneNumberChk &&
        email &&
        emailErr == "")
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [
    name,
    nameErr,
    birth,
    birthErr,
    gender,
    genderErr,
    phone,
    phoneErr,
    phoneNumberChk,
    email,
    emailErr,
    isSNS,
  ]);

  const sendSMSAPI = async () => {
    try {
      if (phone.length != 11) {
        setIsPNC(false);
        setPhoneErr("휴대폰 번호가 올바르지 않습니다.");

        return;
      }

      clearTimer("타이머 종료");

      const data = {
        phoneNumber: phone,
      };

      const response = await MoreApi.UPhoneCertify(data);

      if (response.data.data.randomNumber) {
        setPhoneNumberChk(false);
        setCertifyValue(response.data.data.randomNumber);
        setPhoneErr("");
        setPhoneNumberValue("");
        setPhoneNumberValueErr("");

        setIsPNC(true);
        countTimerStart();
      }
    } catch (err) {
      setIsPNC(false);
      setPhoneErr("이미 등록된 휴대폰 번호 입니다.");
    }
  };

  const phoneNumberChkFunc = () => {
    if (certifyValue === phoneNumberValue) {
      setPhoneNumberChk(true);
      setPhoneNumberValueErr("");

      clearTimer("완");
    } else {
      setPhoneNumberValueErr("인증번호가 올바르지 않습니다.");
      setPhoneNumberChk(false);
    }
  };

  const saveFunc = async () => {
    try {
      history.goBack();
      let formData = new FormData();

      formData.append("phoneNumber", phone);
      await MoreApi.UpdatePhone(formData);

      toast("저장되었습니다.");
    } catch (e) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header title={"계정관리"} />

          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                marginTop: 26,
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <TitleInput
                title={"이름"}
                placeholder="이름을 입력해주세요"
                type={"text"}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                inputStyle={{
                  color: "#ADB4BA",
                }}
                disabled={true}
              />

              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#778088",
                  fontWeight: 600,
                  marginBottom: 4,
                  marginTop: 17,
                }}
              >
                생년월일
              </PretendardText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ width: "100%" }}>
                  <Input
                    maxLength={"8"}
                    value={birth}
                    onChange={(e) => {
                      setBirth(e.target.value);
                    }}
                    type={"text"}
                    placeholder={"YYYYMMDD"}
                    style={{
                      color: "#ADB4BA",
                    }}
                    disabled={true}
                  />
                  {birthErr ? (
                    <PretendardText
                      style={{
                        fontWeight: 500,
                        fontSize: 13,
                        lineHeight: "18.2px",
                        color: "#F24147",
                        marginTop: 4,
                      }}
                    >
                      {birthErr}
                    </PretendardText>
                  ) : null}
                </div>

                <div
                  style={{
                    height: 2,
                    borderLeft: "8px solid #ADB4BA",
                    marginLeft: 15,
                    marginRight: 15,
                    marginTop: 25,
                  }}
                />

                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      height: 51,
                      background: "rgb(244, 246, 246)",
                      border: genderFocus
                        ? "1px solid #ff3333"
                        : "1px solid rgb(244, 246, 246)",
                      borderRadius: 12,
                      padding: "14px 16px",
                    }}
                  >
                    <InputWrapper
                      maxLength={"1"}
                      onFocus={() => {
                        setGenderFocus(true);
                      }}
                      onBlur={() => {
                        setGenderFocus(false);
                      }}
                      value={gender}
                      onChange={(e) => {
                        setGender(e.target.value);
                      }}
                      type={"text"}
                      placeholder={"N"}
                      style={{
                        color: "#ADB4BA",
                      }}
                      disabled={true}
                    />

                    <PretendardText
                      style={{
                        fontSize: 15,
                        lineHeight: "22.3px",
                        color: "#adb4ba",
                        fontWeight: 500,
                      }}
                    >
                      ●●●●●●
                    </PretendardText>
                  </div>
                  {genderErr ? (
                    <PretendardText
                      style={{
                        fontWeight: 500,
                        fontSize: 13,
                        lineHeight: "18.2px",
                        color: "#F24147",
                        marginTop: 4,
                      }}
                    >
                      {genderErr}
                    </PretendardText>
                  ) : null}
                </div>
              </div>

              <TitleInput
                style={{
                  marginTop: 17,
                }}
                title={"휴대폰번호"}
                placeholder="번호만 입력가능합니다"
                type={"text"}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                value={phone}
                btn={true}
                btnText={isPNC ? "재전송" : "인증요청"}
                onClick={() => {
                  sendSMSAPI();
                }}
                warningMessage={phoneErr}
                maxLength={11}
                btnStyle={{
                  marginTop: phoneErr ? -23 : 0,
                  background: isPNC ? "transparent" : "#262C31",
                  border: isPNC ? "2px solid #262C31" : "none",
                }}
                btnTextStyle={{
                  lineHeight: phoneErr && isMobile ? "17.75px" : "18.75px",
                  color: isPNC ? "#262C31" : "#ffffff",
                }}
                inputStyle={{
                  letterSpacing: "0.6px",
                }}
              />

              {isPNC ? (
                <TitleInput
                  style={{
                    marginTop: 17,
                  }}
                  title={"인증번호 입력"}
                  placeholder="인증번호 6자리를 입력하세요"
                  type={"text"}
                  disabled={phoneNumberChk ? true : false}
                  onChange={(e) => {
                    setPhoneNumberValue(e.target.value);
                  }}
                  warningMessage={phoneNumberValueErr}
                  maxLength={6}
                  value={phoneNumberValue}
                  timer={phoneNumberChk ? false : true}
                  min={minState}
                  sec={secState}
                  timerColor={"#9D56FB"}
                  btn={true}
                  btnText={phoneNumberChk ? "인증완료" : "인증하기"}
                  onClick={() => {
                    phoneNumberChkFunc();
                  }}
                  btnStyle={{
                    marginTop: phoneNumberValueErr || phoneNumberChk ? -23 : 0,
                    background: phoneNumberChk ? "#ADB4BA" : "#262C31",
                  }}
                  btnTextStyle={{
                    lineHeight:
                      phoneNumberValueErr || (phoneNumberChk && isMobile)
                        ? "17.75px"
                        : "18.75px",
                  }}
                  checkMessage={phoneNumberChk ? "인증되었습니다." : ""}
                />
              ) : null}

              <TitleInput
                style={{
                  marginTop: 17,
                }}
                title={"이메일"}
                placeholder="이메일을 입력하세요"
                type={"text"}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
                btn={false}
                inputStyle={{
                  color: "#ADB4BA",
                }}
                disabled={true}
              />

              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#778088",
                  fontWeight: 600,
                  marginBottom: 4,
                  marginTop: 17,
                }}
              >
                비밀번호
              </PretendardText>

              <GrayBoldBtn
                onClick={() => {
                  history.push({
                    pathname: route.pw_change,
                  });
                }}
                style={{
                  marginTop: 4,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 15,
                    lineHeight: "20.3px",
                    color: "#ADB4BA",
                    fontWeight: 700,
                  }}
                >
                  비밀번호 변경
                </PretendardText>
              </GrayBoldBtn>
            </div>

            <MainBtn
              disabled={btnDisabled}
              onClick={() => {
                saveFunc();
              }}
              style={{
                marginTop: 22,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                저장하기
              </PretendardText>
            </MainBtn>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default AccountManager;

const InputWrapper = styled.input.attrs((props) => {})`
  width: 20px;
  height: 48px;
  background: rgb(244, 246, 246);
  border: 1px solid rgb(244, 246, 246);
  padding: 0px;

  font-family: "Pretendard";
  color: #262c31;
  font-weight: 500;
  font-size: 15px;
  line-height: 22.3px;

  :focus {
    outline: none !important;
    border-color: rgb(244, 246, 246);
  }

  ::placeholder {
    color: #adb4ba;
    font-weight: 500;
    font-size: 15px;
    line-height: 22.3px;
  }
`;
