import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";

import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import ReviewBoxDetail from "../components/organisms/reviewBoxDetail";
import TextBtn from "../components/atoms/button/textBtn";
import ChallengeApi from "../api/challengeApi";
import { toast } from "react-toastify";
import ConfirmModal from "../components/templates/modals/confirmModal";
import { route } from "../routes/route";
import { ko } from "../utils/ko";
import { getExtensionOfFilename, videoExtension } from "../utils/file";

function ChallengeReviewDetail(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const { rId } = useParams();

  const history = useHistory();
  const location = useLocation();

  const [reviewId, setReviewId] = useState(0);
  const [dataInfo, setDataInfo] = useState();
  const [isModal, setIsModal] = useState(false);
  const [isDeleteId, setIsDeleteId] = useState();

  useEffect(() => {
    if (rId) {
      setReviewId(rId);
      reviewDetailFunc(rId);
    }

    window.scrollTo(0, 0);
  }, []);

  const reviewDetailFunc = async (id) => {
    try {
      const response = await ChallengeApi.ReviewDetail(id);

      setDataInfo(response.data.data);
    } catch (e) {
      toast(ko.errorText_500);
    }
  };

  const reviewDeleteFunc = async () => {
    try {
      await ChallengeApi.ReviewDel(reviewId);

      history.goBack();
      toast("리뷰가 삭제되었습니다.");
    } catch (e) {
      toast(ko.errorText_500);
    }
  };

  const reviewEditFunc = async (id) => {
    try {
      history.push({
        pathname: route.challenge_review_write,
        state: {
          reviewId: id,
        },
      });
    } catch (e) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header />

          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              padding: "17px 20px 20px 20px",
            }}
          >
            <div>
              {dataInfo ? (
                <ReviewBoxDetail
                  style={{
                    marginBottom: 0,
                  }}
                  data={dataInfo}
                  setIsModal={setIsModal}
                  setIsDeleteId={setIsDeleteId}
                  reviewEditFunc={reviewEditFunc}
                />
              ) : null}
            </div>

            <PretendardText
              style={{
                marginTop: "21px",
                fontSize: 13,
                lineHeight: "18.75px",
                color: "#778088",
                fontWeight: 700,
                marginBottom: 11,
              }}
            >
              챌린지 정보
            </PretendardText>
            <TextBtn
              onClick={() => {
                history.push(
                  route.challenge_detail + `/${dataInfo.challengeInfo.id}`
                );
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "54px",
                    height: "54px",
                    overflow: "hidden",
                    borderRadius: "8px",
                  }}
                >
                  <img
                    src={
                      videoExtension.includes(
                        getExtensionOfFilename(dataInfo?.challengeInfo?.fileUrl)
                      )
                        ? dataInfo?.challengeInfo?.thumbFileUrl
                        : dataInfo?.challengeInfo?.fileUrl
                    }
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                    marginLeft: "12px",
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 15,
                      lineHeight: "22.25px",
                      color: "#262C31",
                      fontWeight: 600,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                      display: "-webkit-box",
                    }}
                  >
                    {dataInfo ? dataInfo.challengeInfo.title : ""}
                  </PretendardText>
                </div>
              </div>
            </TextBtn>
          </div>

          {isModal ? (
            <ConfirmModal
              setVisible={setIsModal}
              title={"정말 리뷰를 삭제하시겠어요?"}
              content={""}
              onClickText={"취소"}
              onCancleText={"삭제하기"}
              onClick={() => {
                setIsModal(false);
              }}
              onCancle={() => {
                reviewDeleteFunc();
              }}
            />
          ) : null}
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default ChallengeReviewDetail;
