import React, { useState, useEffect } from "react";
import useDisplay from "../../../hooks/useDisplay";
import styled from "styled-components";

function Input(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <>
      <InputWrapper
        value={props.value}
        onChange={props.onChange}
        type={props.type}
        placeholder={props.placeholder}
        style={{
          ...props.style,
        }}
        onKeyDown={props.onKeyDown}
        maxLength={props.maxLength}
        disabled={props.disabled}
        onClick={props.onClick}
      />
    </>
  );
}

export default Input;

const InputWrapper = styled.input.attrs((props) => {})`
  width: 100%;
  height: 51px;
  border-radius: 12px;
  padding: 14px 16px;
  background: rgb(244, 246, 246);
  border: 1px solid rgb(244, 246, 246);

  font-family: "Pretendard";
  color: #262c31;
  font-weight: 500;
  font-size: 15px;
  line-height: 22.3px;

  :focus {
    outline: none !important;
    border-color: #ff3333 !important;
  }

  ::placeholder {
    color: #adb4ba;
    font-weight: 500;
    font-size: 15px;
    line-height: 22.3px;
  }
`;
