import React, { useState, useEffect } from "react";
import useDisplay from "../../../hooks/useDisplay";

function GrayBoldBtn(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const [hover, setHover] = useState(false);

  return (
    <>
      <div
        onClick={props.onClick}
        onMouseEnter={() => setHover(true)} // 마우스엔터(호버)시 키값이 저장된다
        onMouseLeave={() => setHover(false)} // 마우스리브 시에는 키값이 지워진다
        style={{
          opacity: hover ? "0.7" : "1",

          background: "#ffffff",
          width: "100%",
          cursor: "pointer",
          height: 45,
          borderRadius: 12,
          border: "1px solid #ADB4BA",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          ...props.style,
        }}
      >
        {props.children}
      </div>
    </>
  );
}

export default GrayBoldBtn;
