import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useDisplay from "../../../hooks/useDisplay";
import styled from "styled-components";

import { Radio } from "../../atoms/radio/radio";
import PretendardText from "../../atoms/pretendardText";
import TextBtn from "../../atoms/button/textBtn";
import { route } from "../../../routes/route";

function DeliverySRadio({
  text,
  size,
  checked,
  inputStyle,
  onClick,
  textStyle,
  item,
  setDeleteModal
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          cursor: "pointer",
          ...inputStyle,
        }}
      >
        <Radio
          style={{
            marginTop: 3,
          }}
          checked={checked}
          onChange={onClick}
          size={size}
        />

        <TextBtn
          onClick={onClick}
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            marginLeft: 8,
          }}
        >
          <PretendardText
            style={{
              fontWeight: checked ? 600 : 500,
              fontSize: checked ? "16px" : "15px",
              lineHeight: checked ? "22.96px" : "22.3px",
              color: "#262C31",
              marginBottom: 4,
            }}
          >
            {item.addressName}
          </PretendardText>

          <StyledP
            style={{
              marginBottom: 6,
            }}
          >
            ({item.zipCode})
          </StyledP>
          <StyledP
            style={{
              marginBottom: 6,
            }}
          >
            {`${item.address} ${item.detailAddress}`}
          </StyledP>
          <StyledP style={{}}>{item.receiverName} &nbsp;{item.phoneNumber}</StyledP>
        </TextBtn>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <TextBtn
            onClick={() => {
              history.push({
                pathname: route.delivery_manager,
              });
            }}
          >
            <PretendardText
              style={{
                fontWeight: 700,
                fontSize: "15px",
                lineHeight: "20.3px",
                color: "#778088",
                marginRight: 24,
              }}
            >
              수정
            </PretendardText>
          </TextBtn>
          <TextBtn
            onClick={() => {
              setDeleteModal(item.id)
            }}
          >
            <PretendardText
              style={{
                fontWeight: 700,
                fontSize: "15px",
                lineHeight: "20.3px",
                color: "#66CCCC",
              }}
            >
              삭제
            </PretendardText>
          </TextBtn>
        </div>
      </div>
    </>
  );
}

export default DeliverySRadio;

const StyledP = styled(PretendardText)`
  color: #778088;
  font-weight: 500;
  font-size: 15px;
  line-height: 22.3px;
`;
