import api from "./api.js";

export default class NoticeApi {
  static async List(data) {
    return await api.get(`user/not-001_F0`, data);
  }

  static async Detail(id, data) {
    return await api.get(`user/not-002_F0/${id}`, data);
  }
}
