import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import PretendardText from "../atoms/pretendardText";
import useDisplay from "../../hooks/useDisplay";
import TextBtn from "../atoms/button/textBtn";
import { route } from "../../routes/route";
import { videoExtension, getExtensionOfFilename } from "../../utils/file";
// 무한스크롤 테스트
function SearchChallengeBox({ marginBottom, isEnd, data, onClick }) {
  const history = useHistory();

  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const [tagList, setTagList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (data) {
      let temp = data.tags?.split(",");
      setTagList(temp);
    }
  }, [data]);

  const dDayFunc = (startDate) => {
    const oldDate = new Date();
    const newDate = new Date(startDate);
    let diff = newDate.getTime() - oldDate.getTime();
    diff = Math.ceil(diff / (1000 * 60 * 60 * 24));

    return diff;
  };

  const isEndDDayFunc = (startDate) => {
    const oldDate = new Date();
    const newDate = new Date(startDate);
    let diff = newDate.getTime() - oldDate.getTime();
    diff = Math.ceil(diff / (1000 * 60 * 60 * 24));

    if (diff < 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <TextBtn
        style={{
          width: isMobile ? "47.8%" : "216px",
          marginRight: isEnd ? "0px" : "13px",
        }}
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            history.push(route.challenge_detail + `/${data.id}`);
          }
        }}
      >
        <div
          style={{
            marginBottom: marginBottom,
            width: isMobile ? "100%" : "216px",
          }}
        >
          <div
            style={{
              position: "relative",
              width: isMobile ? "100%" : "216px",
              height: isMobile ? "161px" : "216px",
              overflow: "hidden",
              borderRadius: "8px",
            }}
          >
            <img
              src={
                videoExtension.includes(getExtensionOfFilename(data.fileUrl))
                  ? data.thumbFileUrl
                  : data.fileUrl
              }
              alt=""
              style={{
                width: "100%",
                height: "100%",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: "12px",
                left: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#FFFFFF",
                  fontWeight: 700,
                  backgroundColor: "#778088",
                  borderRadius: "6px",
                  padding: "2px 6px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {data.challengeStatusTitle}
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 11,
                  lineHeight: "14px",
                  color: "#FFFFFF",
                  fontWeight: 600,
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  borderRadius: "6px",
                  padding: "5px 8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "4px",
                }}
              >
                {data.challengeTypeTitle} 챌린지
              </PretendardText>
            </div>
          </div>
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              width: "200px",
            }}
          >
            <PretendardText
              style={{
                fontSize: 13,
                lineHeight: "18.75px",
                color: "#5A636A",
                fontWeight: 600,
                marginRight: 7,
              }}
            >
              {data.challengeCategoryTitle}
            </PretendardText>
            <PretendardText
              style={{
                fontSize: 13,
                lineHeight: "18.75px",
                color: "#5A636A",
                fontWeight: 600,
                marginRight: 7,
              }}
            >
              {data.challengePeriodTitle}
            </PretendardText>
            {isEndDDayFunc(data.startDate) ? null : (
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#5ECECE",
                  fontWeight: 700,
                }}
              >
                D-{dDayFunc(data.startDate)}
              </PretendardText>
            )}
          </div>
          <PretendardText
            style={{
              marginTop: "3px",
              fontSize: 16,
              lineHeight: "22.96px",
              color: "#262C31",
              fontWeight: 600,
              padding: "0px 2px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              display: "-webkit-box",
            }}
          >
            {data.title}
          </PretendardText>
          <div
            style={{
              display: "flex",
              marginTop: "9px",
              flexWrap: "wrap",
            }}
          >
            {tagList.map((item, index) => {
              return (
                <PretendardText
                  key={index}
                  style={{
                    fontSize: 12,
                    lineHeight: "16.8px",
                    color: "#778088",
                    fontWeight: 600,
                    backgroundColor: "rgba(173, 180, 186, 0.2)",
                    borderRadius: "4px",
                    padding: "2px 7px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "4px",
                    marginTop: "4px",
                  }}
                >
                  {item}
                </PretendardText>
              );
            })}
          </div>
        </div>
      </TextBtn>
    </>
  );
}

export default SearchChallengeBox;
