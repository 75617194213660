import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";

import OrderSection from "../components/molecules/order/orderSection";
import styled from "styled-components";
import TextBtn from "../components/atoms/button/textBtn";
import OrderApi from "../api/orderApi";
import MoreApi from "../api/moreApi";
import { ko } from "../utils/ko";
import { toast } from "react-toastify";

function OrderDetail(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();

  const [orderData, setOrderData] = useState();
  const [orderStatus, setOrderStatus] = useState("");

  useEffect(() => {
    const id = location?.state ? location.state.id : null;

    if (id) {
      getOrderInfoFunc(id);
    }

    window.scrollTo(0, 0);
  }, []);

  const getOrderInfoFunc = async (id) => {
    try {
      let response = await MoreApi.GetOrderDetail(id);
      setOrderData(response.data.data);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    if (orderData) {
      switch (orderData.orderStatus) {
        case "WAITING_PAYMENT":
          setOrderStatus("결제대기");
          break;
        case "COMPLETE_PAYMENT":
          setOrderStatus("결제완료");
          break;
        case "READY_TO_SHIP":
          setOrderStatus("배송준비중");
          break;
        case "SHIPPING_IN_PROGRESS":
          setOrderStatus("배송중");
          break;
        case "DELIVERY_COMPLETED":
          setOrderStatus("배송완료");
          break;
        case "BUYER_REFUND":
          setOrderStatus("구매자 취소/환불 완료");
          break;
        case "BUYER_REFUND_REQUEST":
          setOrderStatus("구매자 취소/환불 요청");
          break;
        case "BUYER_REFUND_REJECTED":
          setOrderStatus("구매자 취소/환불 요청 거부");
          break;
        case "SELLER_CANCEL":
          setOrderStatus("판매자 취소");
          break;
      }
    }
  }, [orderData]);

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header title={"주문상세"} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              paddingTop: 22,
              paddingBottom: 20,
            }}
          >
            {orderData ? (
              <OrderSection
                style={{
                  padding: "0px 20px 24px 20px",
                }}
                data={orderData}
                isDetail={true}
                refundDetail={true}
              />
            ) : null}

            <div
              style={{
                width: "100%",
                border: "8px solid #F5F5F5",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "24px 20px",
              }}
            >
              <StyledTextTitle
                style={{
                  marginBottom: 14,
                }}
              >
                주문자 정보
              </StyledTextTitle>
              <StyledTextContent
                style={{
                  marginBottom: 10,
                }}
              >
                {orderData ? orderData.receiverName : ""}
              </StyledTextContent>
              <StyledTextContent
                style={{
                  marginBottom: 10,
                }}
              >
                {orderData ? orderData.phoneNumber : ""}
              </StyledTextContent>
              <StyledTextContent
                style={{
                  marginBottom: 10,
                }}
              >
                {orderData ? orderData.email : ""}
              </StyledTextContent>
              <StyledTextContent
                style={{
                  marginBottom: 10,
                }}
              >
                {orderData ? orderData.address : ""}
                {orderData ? orderData.detailAddress : ""}
              </StyledTextContent>
            </div>

            <div
              style={{
                width: "100%",
                border: "8px solid #F5F5F5",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "24px 20px",
              }}
            >
              <StyledTextTitle
                style={{
                  marginBottom: 8,
                }}
              >
                배송 메시지
              </StyledTextTitle>
              <StyledTextContent
                style={{
                  marginBottom: 10,
                }}
              >
                {orderData ? orderData.message : ""}
              </StyledTextContent>
            </div>

            <div
              style={{
                width: "100%",
                border: "8px solid #F5F5F5",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "24px 20px",
              }}
            >
              <StyledTextTitle
                style={{
                  marginBottom: 8,
                }}
              >
                결제 정보
              </StyledTextTitle>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <StyledTextLeft>결제수단</StyledTextLeft>
                <StyledTextRight isMobile={isMobile}>
                  {orderData
                    ? orderData.paymentMethod === "DEPOSIT_TO_ACCOUNT"
                      ? "무통장입금"
                      : "신용카드"
                    : ""}
                </StyledTextRight>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <StyledTextLeft>주문상태</StyledTextLeft>
                <StyledTextRight isMobile={isMobile}>
                  {orderStatus}
                </StyledTextRight>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <StyledTextLeft>입금계좌</StyledTextLeft>
                <StyledTextRight isMobile={isMobile}>
                  {orderData ? orderData.bankName : ""}{" "}
                  {orderData ? orderData.bankAccount : ""}{" "}
                  {orderData ? orderData.accountOwner : ""}
                </StyledTextRight>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                border: "8px solid #F5F5F5",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "24px 20px",
              }}
            >
              <StyledTextTitle
                style={{
                  marginBottom: 16,
                }}
              >
                결제 금액
              </StyledTextTitle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <StyledPayTextLeft>상품 가격</StyledPayTextLeft>
                <StyledPayTextRight>
                  {orderData
                    ? Number(orderData.totalPrice).toLocaleString("ko-KR")
                    : 0}{" "}
                  원
                </StyledPayTextRight>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <StyledPayTextLeft>할인 금액</StyledPayTextLeft>
                <StyledPayTextRight>
                  {orderData
                    ? `-${Number(orderData.discountAmount).toLocaleString(
                        "ko-KR"
                      )}`
                    : 0}{" "}
                  원
                </StyledPayTextRight>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <StyledPayTextLeft>배송비</StyledPayTextLeft>
                <StyledPayTextRight>
                  {orderData
                    ? Number(orderData.shippingFee).toLocaleString("ko-KR")
                    : 0}{" "}
                  원
                </StyledPayTextRight>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <StyledPayTextLeft>포인트 사용금액</StyledPayTextLeft>
                <StyledPayTextRight>
                  {orderData
                    ? `-${Number(orderData.redeemPoints).toLocaleString(
                        "ko-KR"
                      )}`
                    : 0}{" "}
                  원
                </StyledPayTextRight>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "22.96px",
                    color: "#262C31",
                    fontWeight: 600,
                  }}
                >
                  최종 결제 금액
                </PretendardText>

                <PretendardText
                  style={{
                    fontSize: 18,
                    lineHeight: "25.2px",
                    color: "#F24147",
                    fontWeight: 600,
                  }}
                >
                  {orderData
                    ? Number(
                        orderData.totalPrice +
                          orderData.shippingFee -
                          orderData.redeemPoints -
                          orderData.discountAmount
                      ).toLocaleString("ko-KR")
                    : 0}{" "}
                  원
                </PretendardText>
              </div>
            </div>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default OrderDetail;

const StyledTextTitle = styled(PretendardText).attrs((props) => {})`
  font-size: 15px;
  line-height: 20.3px;
  color: #000000;
  font-weight: 700;
`;

const StyledTextContent = styled(PretendardText).attrs((props) => {})`
  font-size: 15px;
  line-height: 22.3px;
  color: #262c31;
  font-weight: 500;
`;

const StyledTextLeft = styled(PretendardText).attrs((props) => {})`
  font-size: 14px;
  line-height: 21.5px;
  color: #778088;
  font-weight: 500;
  width: 49px;
  margin-right: 9px;
`;

const StyledTextRight = styled(PretendardText).attrs((props) => {})`
  font-size: 14px;
  line-height: 21.5px;
  color: #262c31;
  font-weight: 500;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;

  width: ${(props) => (props.isMobile ? "184px" : "280px")};
`;

const StyledPayTextLeft = styled(PretendardText).attrs((props) => {})`
  font-size: 15px;
  line-height: 22.3px;
  color: #5a636a;
  font-weight: 500;
`;

const StyledPayTextRight = styled(PretendardText).attrs((props) => {})`
  font-size: 15px;
  line-height: 22.3px;
  color: #262c31;
  font-weight: 500;
`;
