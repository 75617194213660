import React, { useEffect, useState } from "react";
import PretendardText from "../atoms/pretendardText";
import useDisplay from "../../hooks/useDisplay";
import TextBtn from "../atoms/button/textBtn";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { route } from "../../routes/route";

function SearchInfluencerBox({ data, unFollowFunc, followFunc }) {
  let myID = sessionStorage.getItem("id");

  const history = useHistory();

  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [followFlag, setFollowFlag] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "82px",
      }}
    >
      <div
        style={{
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          border: "2px solid rgba(255, 255, 255, 1)",
          overflow: "hidden",
          marginRight: "12px",
        }}
      >
        <img
          src={data.fileUrl ? data.fileUrl : "/assets/images/profile_temp.png"}
          alt=""
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      <div
        style={{
          marginRight: "10px",
          width: isMobile ? "189px" : "300px",
        }}
      >
        <TextBtn
          onClick={() => {
            history.push({
              pathname: route.influencer_profile + `/${data.id}`,
            });
          }}
        >
          <PretendardText
            style={{
              color: "#262C31",
              fontSize: "15px",
              lineHeight: "22.25px",
              fontWeight: "600",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data.nickName}
          </PretendardText>
        </TextBtn>
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              marginRight: "16.5px",
            }}
          >
            <PretendardText
              style={{
                color: "#ADB4BA",
                fontSize: "13px",
                lineHeight: "18.75px",
                fontWeight: "600",
              }}
            >
              Followers
            </PretendardText>
            <PretendardText
              style={{
                color: "#5A636A",
                fontSize: "13px",
                lineHeight: "18.75px",
                fontWeight: "700",
                marginLeft: "4px",
              }}
            >
              {data.followerCount}
            </PretendardText>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <PretendardText
              style={{
                color: "#ADB4BA",
                fontSize: "13px",
                lineHeight: "18.75px",
                fontWeight: "600",
              }}
            >
              Likes
            </PretendardText>
            <PretendardText
              style={{
                color: "#5A636A",
                fontSize: "13px",
                lineHeight: "18.75px",
                fontWeight: "700",
                marginLeft: "4px",
              }}
            >
              {data.likeCount}
            </PretendardText>
          </div>
        </div>
      </div>
      {myID == data.id ? null : (
        <TextBtn
          onClick={() => {
            setFollowFlag(!followFlag);
          }}
        >
          {data.userFollowed ? (
            <TextBtn
              onClick={() => {
                unFollowFunc(data.id);
              }}
            >
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: "13px",
                  lineHeight: "18.75px",
                  fontWeight: "700",
                  width: "74px",
                  height: "35px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "8px",
                  border: "2px solid #DBDDDF",
                }}
              >
                팔로우 취소
              </PretendardText>
            </TextBtn>
          ) : (
            <TextBtn
              onClick={() => {
                followFunc(data.id);
              }}
            >
              <PretendardText
                style={{
                  color: "#FFFFFF",
                  fontSize: "13px",
                  lineHeight: "18.75px",
                  fontWeight: "700",
                  width: "74px",
                  height: "35px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#262C31",
                  borderRadius: "8px",
                  border: "",
                }}
              >
                팔로우
              </PretendardText>
            </TextBtn>
          )}
        </TextBtn>
      )}
    </div>
  );
}

export default SearchInfluencerBox;
