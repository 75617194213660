import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PretendardText from "../../atoms/pretendardText";

import { getExtensionOfFilename, videoExtension } from "../../../utils/file";

const SwiperContain = ({ fileUrl, thumbFileUrl, index, activeIndex }) => {
  const [playClick, setPlayClick] = useState(false);
  const [playTime, setPlayTime] = useState("00:00");

  useEffect(() => {
    if (index !== activeIndex) {
      // 비디오 링크를 클릭하여 해당 비디오 페이지에 들어왔을때
      const videoContainer = document.getElementById(`video${index}`);
      if (videoContainer) {
        videoContainer.pause();
      }
    }

    setPlayClick(false);
  }, [activeIndex]);

  useEffect(() => {
    setTimeout(() => {
      let timeTemp = setTotalTime();

      setPlayTime(timeTemp);
    }, 500);
  }, [fileUrl, thumbFileUrl]);

  // 초를 시,분,초로 변환
  const formatDate = (seconds) => {
    const secondsNumber = parseInt(seconds, 10);
    let hours = Math.floor(secondsNumber / 3600);
    let minutes = Math.floor((secondsNumber - hours * 3600) / 60);
    let totalSeconds = secondsNumber - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (totalSeconds < 10) {
      totalSeconds = `0${totalSeconds}`;
    }

    if (hours == "00") {
      return `${isNaN(minutes) ? "00" : minutes}:${
        isNaN(totalSeconds) ? "00" : totalSeconds
      }`;
    } else {
      return `${isNaN(hours) ? "00" : hours}:${
        isNaN(minutes) ? "00" : minutes
      }:${isNaN(totalSeconds) ? "00" : totalSeconds}`;
    }
  };

  // 비디오 전체 길이를 가져오는 함수
  const setTotalTime = () => {
    // 비디오 링크를 클릭하여 해당 비디오 페이지에 들어왔을때
    const videoContainer = document.getElementById(`video${index}`);

    if (videoContainer) {
      let durationTemp = videoContainer.duration;
      return formatDate(durationTemp);
    } else {
      return "00:00";
    }
  };

  const history = useHistory();

  return (
    <>
      {fileUrl ? (
        <video
          controls={false}
          autoPlay={false}
          muted
          playsInline
          style={{
            width: "0%",
            height: "0%",
            display: "none",
            objectFit: "cover",
          }}
          id={`video${index}`}
        >
          <source src={fileUrl}></source>
        </video>
      ) : null}

      {!videoExtension.includes(getExtensionOfFilename(fileUrl)) ? (
        <img
          src={fileUrl}
          alt=""
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      ) : playClick ? (
        <video
          controls={true}
          autoPlay={true}
          muted
          playsInline
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          id={`video${index}`}
        >
          <source src={fileUrl}></source>
        </video>
      ) : (
        <img
          src={thumbFileUrl}
          alt=""
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      )}

      {playClick ? null : !videoExtension.includes(
          getExtensionOfFilename(fileUrl)
        ) ? null : (
        <img
          src={"/assets/images/dim.png"}
          alt=""
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
          }}
        />
      )}

      {playClick ? null : !videoExtension.includes(
          getExtensionOfFilename(fileUrl)
        ) ? null : (
        <div
          style={{
            position: "absolute",
            borderRadius: 23,
            padding: "5px 8px 5px 8px",
            bottom: 20,
            right: 20,
            background: "rgba(0,0,0,0.5)",
          }}
        >
          <PretendardText
            style={{
              color: "#FFFFFF",
              fontSize: "11px",
              lineHeight: "14px",
              fontWeight: "600",
            }}
          >
            {playTime}
          </PretendardText>
        </div>
      )}

      {playClick ? null : !videoExtension.includes(
          getExtensionOfFilename(fileUrl)
        ) ? null : (
        <img
          onClick={() => {
            setPlayClick(true);
          }}
          src={"/assets/images/play.png"}
          alt="play"
          style={{
            width: 64,
            height: 64,
            position: "absolute",
            zIndex: 99,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            cursor: "pointer",
          }}
        />
      )}

      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          height: "88%",
          width: "15%",
        }}
      />
      <div
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          height: "88%",
          width: "15%",
        }}
      />
    </>
  );
};

export default SwiperContain;
