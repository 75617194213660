import React from "react";
import PretendardText from "../atoms/pretendardText";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { route } from "../../routes/route";
import TextBtn from "../atoms/button/textBtn";

function ProfileCard02({ item, isFirst, isLast }) {
  const history = useHistory();

  return (
    <>
      <div
        onClick={() => {
          history.push({
            pathname: route.all_short_form,
          });
        }}
        style={{
          marginLeft: isFirst ? "20px" : "14px",
          paddingRight: isLast ? "20px" : "",
        }}
      >
        <div
          style={{
            width: "64px",
            height: "64px",
            borderRadius: "50%",
            border: item.progressChallenge
              ? "4px solid transparent"
              : "4px solid #ECEDF0",
            backgroundImage: item.progressChallenge
              ? "linear-gradient(146.86deg, #FFC633 7.41%, #FF3333 52.86%, #E30E9B 88.98%)"
              : "",
            backgroundOrigin: item.progressChallenge ? "border-box" : "",
            overflow: "hidden",
          }}
        >
          <img
            src={
              item.fileUrl ? item.fileUrl : "/assets/images/profile_temp.png"
            }
            alt=""
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <PretendardText
          style={{
            width: "67px",
            textAlign: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginTop: 4,
            fontSize: 13,
            lineHeight: "18.75px",
            fontWeight: 400,
          }}
        >
          {item.nickName}
        </PretendardText>
      </div>
    </>
  );
}

export default ProfileCard02;
