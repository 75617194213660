import styled from "styled-components";
import useDisplay from "../../../hooks/useDisplay";

function AppContainer(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <>
      <div
        style={{
          flex: 1,
          width: isMobile ? "100vw" : "100%",
          minHeight: "calc(var(--vh, 1vh) * 100)",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          ...props.style,
        }}
        onScroll={props.onscroll}
      >
        {props.children}
      </div>
    </>
  );
}

export default AppContainer;
