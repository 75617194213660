import React, { useState, useEffect } from "react";
import useDisplay from "../../../hooks/useDisplay";
import styled from "styled-components";
import TextBtn from "../button/textBtn";

function InputText(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
      }}
    >
      <InputWrapper
        id={props.id ? props.id : "inputSearch"}
        value={props.value}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        type={props.type}
        placeholder={props.placeholder}
        style={{
          ...props.style,
        }}
        onKeyDown={props.onKeyDown}
      />
      {props.value?.length > 0 ? (
        <TextBtn
          onClick={() => {
            props.onChange("");
          }}
        >
          <img
            src="/assets/icons/delete.png"
            alt=""
            style={{
              position: "absolute",
              top: "15px",
              right: "16px",
              width: 20,
              height: 20,
            }}
          />
        </TextBtn>
      ) : null}
    </div>
  );
}

export default InputText;

const InputWrapper = styled.input.attrs((props) => {})`
  width: 100%;
  height: 51px;
  border-radius: 12px;
  padding: 14px 16px;
  background: rgb(244, 246, 246);
  border: 1px solid rgb(244, 246, 246);

  font-family: "Pretendard";
  color: #262c31;
  font-weight: 500;
  font-size: 15px;
  line-height: 22.3px;

  :focus {
    outline: none !important;
    border-color: #ff3333;
  }

  ::placeholder {
    color: #adb4ba;
    font-weight: 500;
    font-size: 15px;
    line-height: 22.3px;
  }
`;
