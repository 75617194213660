import React, { useEffect, useMemo, useState, useRef } from "react";
import useDisplay from "../hooks/useDisplay";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import AppContainer from "../components/atoms/layouts/appContainer";
import AppLayout from "../components/atoms/layouts/appLayout";
import Header from "../components/molecules/header/header";
import PretendardText from "../components/atoms/pretendardText";
import RightLabelRadio from "../components/molecules/radio/rightLabelRadio";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import ChallengeApi from "../api/challengeApi";
import { nullCheck } from "../utils/check";
import InputText from "../components/atoms/input/inputText";
import InputSearch from "../components/atoms/input/inputSearch";
import OptionSelect from "../components/atoms/select/optionSelect";
import OptionSelect2 from "../components/atoms/select/optionSelect2";
import Input from "../components/atoms/input/input";
import styled from "styled-components";
import MainBtn from "../components/atoms/button/mainBtn";
import ConfirmModal from "../components/templates/modals/confirmModal";
import { route } from "../routes/route";
import moment from "moment";
import TextFormEditor from "../components/molecules/input/textFormEditor";
import { getExtensionOfFilename, videoExtension } from "../utils/file";
import ChallengeFileForm from "../components/atoms/fileForm/challengeFileForm";
import InputText02 from "../components/atoms/input/inputText02";
import Loading from "../utils/loading";
import LoadingFile from "../components/templates/loadingFile";

function UpdateChallenge() {
  const isKeyboardOpen = useDetectKeyboardOpen();
  const layout_scroll = useRef();

  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const [isModal, setIsModal] = useState(false);
  const [isMobileChk, setIsMobileChk] = useState(false);
  const [playTime, setPlayTime] = useState("00:00");
  const [position, setPosition] = useState(0);
  const [isBlurEditor, setIsBlurEditor] = useState(false);
  const [currentScroll, setCurrentScroll] = useState(0);

  const [file, setFile] = useState({
    file: "",
    fileUrl: "",
  });
  const [thum, setThum] = useState({
    file: "",
    fileUrl: "",
  });
  const [isWarningChk, setIsWarningChk] = useState();

  const [saveData, setSaveData] = useState({
    challengeType: "CREW",

    description: "",
    challengeCategory: "",
    challengeCategoryTitle: "",
    title: "",
    tags: "",
    challengePeriod: "",
    challengePeriodTitle: "",
    startDate: "",
    endDate: "",
    rewardPoint: "",
    rewardUsingPercentage: false,
    retirePoint: "",
    retireUsingPercentage: false,
    requireCount: "",
    content: "",

    merchId: 0,
    managerId: 0,
    memberId: 0,
  });

  useEffect(() => {
    if (isKeyboardOpen) {
      setCurrentScroll(position);
    }
  }, [isKeyboardOpen]);

  useEffect(() => {
    if (isKeyboardOpen) {
      if (currentScroll + 100 < position || currentScroll - 100 > position) {
        const input01Container = document.getElementById("input01");
        const input02Container = document.getElementById("input02");
        const input03Container = document.getElementById("input03");
        const input04Container = document.getElementById("input04");
        const input05Container = document.getElementById("input05");
        const input06Container = document.getElementById("input06");
        const input07Container = document.getElementById("input07");

        if (saveData.challengeType === "CREW") {
          if (
            input01Container &&
            input02Container &&
            input03Container &&
            input04Container &&
            input06Container &&
            input07Container
          ) {
            input01Container.blur();
            input02Container.blur();
            input03Container.blur();
            input04Container.blur();
            input06Container.blur();
            input07Container.blur();
            setIsBlurEditor(true);
          }
        } else {
          if (
            input01Container &&
            input02Container &&
            input03Container &&
            input04Container &&
            input05Container &&
            input06Container &&
            input07Container
          ) {
            input01Container.blur();
            input02Container.blur();
            input03Container.blur();
            input04Container.blur();
            input05Container.blur();
            input06Container.blur();
            input07Container.blur();
            setIsBlurEditor(true);
          }
        }
      }
    }
  }, [position]);

  useEffect(() => {
    const isMobileChk = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    setIsMobileChk(isMobileChk);

    window.scrollTo(0, 0);
  }, []);

  let { id } = useParams();

  // init
  const getChallenge = async () => {
    try {
      const response = await ChallengeApi.getChallengeDetail(id);
      const data = response.data.data;

      setSaveData({
        ...data,
        memberId: data.commonMemberID,
        managerId: data.managerID,
        merchId: data.merch?.id ? data.merch?.id : 0,
        deleteFileIds: [],
      });

      setFile({
        file: "",
        fileUrl: data.fileUrl,
      });
      setThum({
        file: "",
        fileUrl: data.thumbFileUrl,
      });

      setMerchKeyword(data.merch?.name);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    getChallenge();
  }, []);

  // 대표 이미지 등록
  const inputTitleFiles = (e) => {
    setIsLoadingTitleFile(true);
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (data) => {
      file.file = file;
      if (videoExtension.includes(getExtensionOfFilename(file.name))) {
        file.fileUrl = URL.createObjectURL(file.file);
      } else {
        file.fileUrl = data.target.result;
        thum.fileUrl = "";
      }
      setFile({ ...file });
      setThum({
        file: "",
        fileUrl: "",
      });
    };
    setIsLoadingTitleFile(false);
    e.target.value = "";
  };

  const inputThum = (e) => {
    if (!videoExtension.includes(getExtensionOfFilename(file.file.name))) {
      toast("썸네일은 인증파일이 영상일 경우 등록가능합니다.");
      return;
    }

    const fileTemp = e.target.files[0];
    if (videoExtension.includes(getExtensionOfFilename(fileTemp.name))) {
      toast("썸네일은 이미지만 등록가능합니다.");
      return;
    }
    const fileReader = new FileReader();
    fileReader.readAsDataURL(fileTemp);
    fileReader.onload = (data) => {
      thum.file = fileTemp;
      thum.fileUrl = data.target.result;
      setThum({ ...thum });
    };
    e.target.value = "";
  };

  // 상세 이미지 등록
  // const inputDetailFile = (e) => {
  //   const file = e.target.files[0];
  //   const fileReader = new FileReader();
  //   fileReader.readAsDataURL(file);
  //   fileReader.onload = (data) => {
  //     const detailImage = {
  //       file: file,
  //       fileUrl: data.target.result,
  //     };

  //     saveData.detailImage = detailImage;
  //     setSaveData({ ...saveData });
  //   };

  //   e.target.value = "";
  // };

  // const deleteDetailFile = (index) => {
  //   saveData.imageDeleteFlag = true;
  //   saveData.detailImage = {};
  //   setSaveData({ ...saveData });
  // };

  // 챌린지 유형
  const challengeCategoryList = [
    {
      title: "식습관",
      value: "EATING_HABITS",
    },
    {
      title: "운동",
      value: "EXERCISE",
    },
    {
      title: "건강",
      value: "HEALTH",
    },
    {
      title: "생활",
      value: "LIFE",
    },
    {
      title: "취미",
      value: "HOBBY",
    },
    {
      title: "멘탈",
      value: "MENTAL",
    },
  ];

  const setChallengeCategory = (value, title) => {
    saveData.challengeCategory = value;
    saveData.challengeCategoryTitle = title;
    setSaveData({ ...saveData });
  };

  // 챌린지 기간
  const challengePeriodList = [
    {
      title: "매일",
      value: "EVERYDAY",
    },
    {
      title: "주 1회",
      value: "ONCE_A_WEEK",
    },
    {
      title: "주 2회",
      value: "TWICE_A_WEEK",
    },
    {
      title: "주 3회",
      value: "THREE_TIMES_A_WEEK",
    },
    {
      title: "주 4회",
      value: "FOUR_TIMES_A_WEEK",
    },
    {
      title: "주 5회",
      value: "FIVE_TIMES_A_WEEK",
    },
    {
      title: "주 6회",
      value: "SIX_TIMES_A_WEEK",
    },
  ];

  const setChallengePeriod = (value, title) => {
    saveData.challengePeriod = value;
    saveData.challengePeriodTitle = title;
    setSaveData({ ...saveData });
  };

  // 상품
  const [openFlag3, setOpenFlag3] = useState(false);
  const [merchKeyword, setMerchKeyword] = useState("");
  const [merchList, setMerchList] = useState([]);
  const setMerch = (value, title) => {
    saveData.merchId = value;
    setSaveData({ ...saveData });
    setMerchKeyword(title);
    setOpenFlag3(false);
  };

  const getMerchList = async () => {
    try {
      const data = {
        keyword: merchKeyword,
        page: 0,
        size: 1000,
      };

      const response = await ChallengeApi.GetMerchList(data);

      setMerchList(
        response.data.data.map((item, index) => {
          return {
            title: item.name,
            value: item.id,
          };
        })
      );
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  // 저장
  const btnFlag = useMemo(() => {
    if (Number(saveData.rewardPoint) < Number(saveData.retirePoint)) {
      setIsWarningChk(true);
      return true;
    } else {
      setIsWarningChk(false);
    }

    if (!file.fileUrl) {
      return true;
    }
    // 영상인데 썸네일이 없는 경우
    if (
      videoExtension.includes(
        getExtensionOfFilename(file.file ? file.file.name : file.fileUrl)
      ) &&
      !thum.fileUrl
    ) {
      return true;
    }
    if (nullCheck(saveData.content)) {
      return true;
    }

    if (nullCheck(saveData.challengeType)) {
      return true;
    }
    if (nullCheck(saveData.challengeCategory)) {
      return true;
    }
    if (nullCheck(saveData.title)) {
      return true;
    }

    if (nullCheck(saveData.startDate)) {
      return true;
    }
    if (nullCheck(saveData.endDate)) {
      return true;
    }
    if (nullCheck(saveData.requireCount)) {
      return true;
    }
    if (saveData.challengeType === "GROUPBUYING" && saveData.merchId === 0) {
      return true;
    }
    if (nullCheck(saveData.rewardPoint)) {
      return true;
    }
    if (nullCheck(saveData.retirePoint)) {
      return true;
    }

    return false;
  }, [saveData, file, thum]);

  const [saveOpen, setSaveOpen] = useState(false);
  const saveFunc = async () => {
    setSaveOpen(false);
    setIsLoading(true);
    try {
      const formData = new FormData();
      if (file.file) {
        formData.append("file", file.file);
      }

      if (thum.file) {
        formData.append("thumbFile", thum.file);
      }

      formData.append("content", saveData.content);
      formData.append("description", saveData.description);
      formData.append("challengeCategory", saveData.challengeCategory);
      formData.append("challengePeriod", saveData.challengePeriod);
      formData.append("challengeType", saveData.challengeType);
      formData.append("endDate", saveData.endDate);
      formData.append("merchId", saveData.merchId);
      formData.append("retirePoint", saveData.retirePoint);
      formData.append("retireUsingPercentage", saveData.retireUsingPercentage);
      formData.append("rewardPoint", saveData.rewardPoint);
      formData.append("rewardUsingPercentage", saveData.rewardUsingPercentage);
      formData.append("startDate", saveData.startDate);
      formData.append("tags", saveData.tags);
      formData.append("title", saveData.title);
      formData.append("requireCount", saveData.requireCount);

      await ChallengeApi.putChallenge(id, formData);
      toast(ko.update);
      setIsLoading(false);
      // 챌린지관리로 이동
      history.goBack();
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const dateDiff = useMemo(() => {
    if (saveData.startDate && saveData.endDate) {
      return (
        moment(saveData.endDate).diff(moment(saveData.startDate), "days") + 1
      );
    } else {
      return "0";
    }
  }, [saveData.startDate, saveData.endDate]);

  useEffect(() => {
    getMerchList();
  }, [merchKeyword]);

  // 비디오 전체 길이를 가져오는 함수
  const setTotalTime = () => {
    // 비디오 링크를 클릭하여 해당 비디오 페이지에 들어왔을때
    const videoContainer = document.getElementById("video");

    if (videoContainer) {
      let durationTemp = videoContainer.duration;
      return formatDate(durationTemp);
    } else {
      return "00:00";
    }
  };

  useEffect(() => {
    setTimeout(() => {
      let timeTemp = setTotalTime();

      setPlayTime(timeTemp);
    }, 500);
  }, [file]);

  // 초를 시,분,초로 변환
  const formatDate = (seconds) => {
    const secondsNumber = parseInt(seconds, 10);
    let hours = Math.floor(secondsNumber / 3600);
    let minutes = Math.floor((secondsNumber - hours * 3600) / 60);
    let totalSeconds = secondsNumber - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (totalSeconds < 10) {
      totalSeconds = `0${totalSeconds}`;
    }

    if (hours == "00") {
      return `${isNaN(minutes) ? "00" : minutes}:${
        isNaN(totalSeconds) ? "00" : totalSeconds
      }`;
    } else {
      return `${isNaN(hours) ? "00" : hours}:${
        isNaN(minutes) ? "00" : minutes
      }:${isNaN(totalSeconds) ? "00" : totalSeconds}`;
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isLoadingTitleFile, setIsLoadingTitleFile] = useState(false);

  return (
    <AppLayout
      ref={layout_scroll}
      onScroll={() => {
        setPosition(layout_scroll.current.scrollTop);
      }}
    >
      <AppContainer
        style={{
          paddingBottom: "39px",
        }}
      >
        {/* 헤더 */}
        <div
          style={{
            width: "100%",
            height: 50,
            paddingLeft: 20,
            paddingRight: 20,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "relative",
            marginBottom: "21px",
          }}
        >
          <div
            onClick={() => {
              setIsModal(true);
            }}
            style={{
              position: "absolute",
              left: 20,
              cursor: "pointer",
              width: 24,
              height: 24,
            }}
          >
            <img
              src="/assets/icons/left_btn.png"
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </div>
          <PretendardText
            style={{
              fontWeight: 600,
              fontSize: 15,
              lineHeight: "22.25px",
              color: "#262C31",
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {"챌린지 수정"}
          </PretendardText>
        </div>

        <div style={{ padding: "0px 20px" }}>
          {/* 챌린지 선택 */}
          <div style={{ marginBottom: "32px" }}>
            <PretendardText
              style={{
                color: "#262C31",
                fontWeight: "700",
                fontSize: "15px",
                lineHeight: "20.3px",
                marginBottom: "16px",
              }}
            >
              챌린지 선택
            </PretendardText>
            <div style={{ display: "flex", gap: "22px" }}>
              <RightLabelRadio
                text={"크루챌린지"}
                checked={saveData.challengeType === "CREW"}
                onClick={() => {
                  saveData.challengeType = "CREW";
                  setSaveData({ ...saveData });
                }}
              />
              <RightLabelRadio
                text={"공동구매 챌린지"}
                checked={saveData.challengeType === "GROUPBUYING"}
                onClick={() => {
                  saveData.challengeType = "GROUPBUYING";
                  setSaveData({ ...saveData });
                }}
              />
            </div>
          </div>

          {/* 대표이미지 등록 */}
          <div style={{ marginBottom: "32px" }}>
            <PretendardText
              style={{
                color: "#262C31",
                fontWeight: "700",
                fontSize: "15px",
                lineHeight: "20.3px",
                marginBottom: "12px",
              }}
            >
              이미지 또는 동영상
            </PretendardText>
            <div
              style={{
                display: "flex",
              }}
            >
              <label
                style={{
                  marginRight: "13px",
                }}
              >
                <input
                  id="title"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    inputTitleFiles(e);
                  }}
                  accept="video/*, image/*"
                />
                <div
                  style={{
                    cursor: "pointer",
                    height: 100,
                    width: 100,
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: "#DBDDDF",
                    background: "#F4F6F6",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderStyle: "dotted",
                  }}
                >
                  <img
                    src="/assets/icons/camera.png"
                    style={{
                      height: 24,
                      width: 24,
                      marginBottom: 4,
                    }}
                  />
                  <PretendardText
                    style={{
                      fontSize: 14,
                      lineHeight: "20px",
                      color: "#778088",
                      fontWeight: 600,
                    }}
                  >
                    *최대 1장
                  </PretendardText>
                </div>
              </label>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "13px",
                }}
              >
                {file.fileUrl ? (
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      // borderRadius: 12,
                      // backgroundImage: `url( ${file.fileUrl} )`,
                      // backgroundSize: "100% 100%",
                      position: "relative",
                    }}
                  >
                    {videoExtension.includes(
                      getExtensionOfFilename(
                        file.file ? file.file.name : file.fileUrl
                      )
                    ) ? (
                      <div
                        style={{
                          borderRadius: 12,
                          width: "100%",
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        <video
                          src={file.fileUrl}
                          style={{
                            borderRadius: 12,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            display: isMobileChk ? "none" : "block",
                          }}
                          id="video"
                        />

                        {isMobileChk ? (
                          <img
                            src={file.fileUrl}
                            alt=""
                            style={{
                              borderRadius: 12,
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        ) : null}
                        <div
                          style={{
                            position: "absolute",
                            borderRadius: 23,
                            padding: "5px 8px 5px 8px",
                            bottom: 6,
                            right: 6,
                            background: "rgba(0,0,0,0.5)",
                          }}
                        >
                          <PretendardText
                            style={{
                              color: "#FFFFFF",
                              fontSize: "11px",
                              lineHeight: "14px",
                              fontWeight: "600",
                            }}
                          >
                            {playTime}
                          </PretendardText>
                        </div>
                      </div>
                    ) : (
                      <img
                        src={file.fileUrl}
                        alt=""
                        style={{
                          borderRadius: 12,
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    )}

                    <img
                      onClick={() => {
                        file.file = "";
                        file.fileUrl = "";
                        setFile({ ...file });
                      }}
                      src="/assets/icons/imageDel.png"
                      style={{
                        height: 18,
                        width: 18,
                        position: "absolute",
                        top: 9,
                        right: 9,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {videoExtension.includes(
            getExtensionOfFilename(file.file ? file.file.name : file.fileUrl)
          ) ? (
            <div style={{ marginBottom: "21px" }}>
              <PretendardText
                style={{
                  color: "#262C31",
                  fontWeight: "700",
                  fontSize: "15px",
                  lineHeight: "20.3px",
                  marginBottom: "12px",
                }}
              >
                썸네일 (영상 업로드일 경우)
              </PretendardText>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginTop: 11,
                }}
              >
                <ChallengeFileForm
                  handleChange={inputThum}
                  id={"thum"}
                  accept="image/jpg, image/png, image/jpeg"
                  textStyle={{
                    fontSize: 14,
                    lineHeight: "20px",
                    color: "#778088",
                    fontWeight: 600,
                  }}
                />

                {thum.fileUrl ? (
                  <div
                    style={{
                      marginLeft: 8,
                      width: 100,
                      height: 100,
                      borderRadius: 12,
                      backgroundImage: `url( ${thum.fileUrl} )`,
                      backgroundSize: "100% 100%",
                      position: "relative",
                    }}
                  >
                    <img
                      onClick={() => {
                        thum.file = "";
                        thum.fileUrl = "";
                        setThum({ ...thum });
                      }}
                      src="/assets/icons/imageDel.png"
                      style={{
                        height: 18,
                        width: 18,
                        position: "absolute",
                        top: 9,
                        right: 9,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}

          {/* 챌린지 게시 내용 */}
          <div style={{ marginBottom: "32px" }}>
            <PretendardText
              style={{
                color: "#262C31",
                fontWeight: "700",
                fontSize: "15px",
                lineHeight: "20.3px",
                marginBottom: "12px",
              }}
            >
              챌린지 게시 내용
            </PretendardText>
            <InputText
              id={"input01"}
              placeholder={"챌린지 게시내용을 입력하세요"}
              value={saveData.content}
              onChange={(value) => {
                saveData.content = value;
                setSaveData({ ...saveData });
              }}
            />
          </div>

          {/* 상세이미지 등록 */}
          <div style={{ marginBottom: "32px" }}>
            <PretendardText
              style={{
                color: "#262C31",
                fontWeight: "700",
                fontSize: "15px",
                lineHeight: "20.3px",
                marginBottom: "12px",
              }}
            >
              챌린지 상세
            </PretendardText>
            <div
              style={{
                display: "flex",
              }}
            >
              {/* <label
                style={{
                  marginRight: "13px",
                }}
              >
                <input
                  id="detail"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    inputDetailFile(e);
                  }}
                />
                <div
                  style={{
                    cursor: "pointer",
                    height: 100,
                    width: 100,
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: "#DBDDDF",
                    background: "#F4F6F6",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderStyle: "dotted",
                  }}
                >
                  <img
                    src="/assets/icons/camera.png"
                    style={{
                      height: 24,
                      width: 24,
                      marginBottom: 4,
                    }}
                  />
                  <PretendardText
                    style={{
                      fontSize: 14,
                      lineHeight: "20px",
                      color: "#778088",
                      fontWeight: 600,
                    }}
                  >
                    *최대 1장
                  </PretendardText>
                </div>
              </label>
              {saveData.detailImage.fileUrl ? (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "13px",
                  }}
                >
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      position: "relative",
                    }}
                  >
                    <img
                      src={saveData.detailImage.fileUrl}
                      alt=""
                      style={{
                        borderRadius: 12,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                    <img
                      onClick={() => {
                        deleteDetailFile();
                      }}
                      src="/assets/icons/imageDel.png"
                      style={{
                        height: 18,
                        width: 18,
                        position: "absolute",
                        top: 9,
                        right: 9,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              ) : null} */}
              <TextFormEditor
                placeholder={"상세설명을 입력하세요"}
                value={saveData.description}
                onChange={(contents) => {
                  saveData.description = contents;
                  setSaveData({ ...saveData });
                }}
                editorHeight={254}
                style={{
                  marginBottom: "12px",
                }}
                position={position}
                isBlur={isBlurEditor}
                isLoading={isLoadingFile}
                setIsLoading={setIsLoadingFile}
              />
            </div>
          </div>

          {/* 챌린지 유형 */}
          <div style={{ marginBottom: "32px", marginTop: isMobile ? 60 : 40 }}>
            <PretendardText
              style={{
                color: "#262C31",
                fontWeight: "700",
                fontSize: "15px",
                lineHeight: "20.3px",
                marginBottom: "12px",
              }}
            >
              챌린지 유형
            </PretendardText>
            <OptionSelect2
              title={saveData.challengeCategoryTitle}
              dataList={challengeCategoryList}
              placeholder={"챌린지 유형을 선택하세요"}
              setFunc={setChallengeCategory}
            />
          </div>

          {/* 챌린지 제목 */}
          <div style={{ marginBottom: "32px" }}>
            <PretendardText
              style={{
                color: "#262C31",
                fontWeight: "700",
                fontSize: "15px",
                lineHeight: "20.3px",
                marginBottom: "12px",
              }}
            >
              챌린지 제목
            </PretendardText>
            <InputText
              id={"input02"}
              placeholder={"챌린지 제목을 입력하세요"}
              value={saveData.title}
              onChange={(value) => {
                saveData.title = value;
                setSaveData({ ...saveData });
              }}
            />
          </div>

          {/* 챌린지 태그 */}
          <div style={{ marginBottom: "32px" }}>
            <PretendardText
              style={{
                color: "#262C31",
                fontWeight: "700",
                fontSize: "15px",
                lineHeight: "20.3px",
                marginBottom: "12px",
              }}
            >
              챌린지 태그
            </PretendardText>
            <InputText
              id={"input03"}
              placeholder={"예시)운동,비타민,헬스 (최대 3개 입력)"}
              value={saveData.tags}
              onChange={(value) => {
                saveData.tags = value;
                setSaveData({ ...saveData });
              }}
            />
          </div>

          {/* 챌린지 기간 */}
          {/* <div style={{ marginBottom: "32px" }}>
            <PretendardText
              style={{
                color: "#262C31",
                fontWeight: "700",
                fontSize: "15px",
                lineHeight: "20.3px",
                marginBottom: "12px",
              }}
            >
              챌린지 기간
            </PretendardText>
            <OptionSelect2
              title={saveData.challengePeriodTitle}
              dataList={challengePeriodList}
              placeholder={"챌린지 기간을 선택하세요"}
              setFunc={setChallengePeriod}
            />
          </div> */}

          {/* 챌린지 진행일자 */}
          <div style={{ marginBottom: "32px" }}>
            <PretendardText
              style={{
                color: "#262C31",
                fontWeight: "700",
                fontSize: "15px",
                lineHeight: "20.3px",
                marginBottom: "12px",
              }}
            >
              챌린지 진행일자
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                총 <span style={{ color: "#66CCCC" }}>{dateDiff}</span> 일
              </span>
            </PretendardText>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <PretendardText
                  style={{
                    color: "#778088",
                    fontWeight: "600",
                    fontSize: "13px",
                    lineHeight: "18.75px",
                    marginBottom: "4px",
                  }}
                >
                  시작일
                </PretendardText>
                <Input
                  value={saveData.startDate}
                  onChange={(e) => {
                    const today = moment();
                    if (today.isAfter(e.target.value)) {
                      return toast("챌린지 시작일은 오늘 이후로 가능합니다.");
                    }
                    saveData.startDate = e.target.value;
                    setSaveData({ ...saveData });
                  }}
                  type={"date"}
                  placeholder={"YYYY-MM-DD"}
                />
              </div>

              <div
                style={{
                  height: 2,
                  borderLeft: "8px solid #ADB4BA",
                  marginLeft: "15px",
                  marginRight: "15px",
                  marginTop: "10px",
                }}
              />

              <div
                style={{
                  width: "100%",
                }}
              >
                <PretendardText
                  style={{
                    color: "#778088",
                    fontWeight: "600",
                    fontSize: "13px",
                    lineHeight: "18.75px",
                    marginBottom: "4px",
                  }}
                >
                  종료일
                </PretendardText>
                <Input
                  value={saveData.endDate}
                  onChange={(e) => {
                    if (nullCheck(saveData.startDate)) {
                      return toast("시작일을 먼저 선택해주세요.");
                    }
                    if (
                      moment(e.target.value).isSameOrBefore(
                        moment(saveData.startDate)
                      )
                    ) {
                      return toast("챌린지 종료일은 시작일 이후로 가능합니다.");
                    }
                    saveData.endDate = e.target.value;
                    setSaveData({ ...saveData });
                  }}
                  type={"date"}
                  placeholder={"YYYY-MM-DD"}
                />
              </div>
            </div>
          </div>

          {/* 인증 횟수 */}
          <div style={{ marginBottom: "32px" }}>
            <PretendardText
              style={{
                color: "#262C31",
                fontWeight: "700",
                fontSize: "15px",
                lineHeight: "20.3px",
                marginBottom: "8px",
              }}
            >
              챌린지 인증 횟수
            </PretendardText>
            <PretendardText
              style={{
                color: "#5A636A",
                fontWeight: "500",
                fontSize: "13px",
                lineHeight: "18.2px",
                marginBottom: "10px",
              }}
            >
              인증횟수는 진행일자를 넘을 수 없습니다.
            </PretendardText>
            <InputText02
              id={"input04"}
              type={"number"}
              placeholder={"기간 내 인증 횟수를 입력하세요"}
              value={saveData.requireCount}
              onChange={(value) => {
                if (value > Number(dateDiff) || value < 0) {
                  return;
                }
                saveData.requireCount = value;
                setSaveData({ ...saveData });
              }}
            />
          </div>

          {/* 상품 선택 */}
          {saveData.challengeType === "GROUPBUYING" ? (
            <div style={{ marginBottom: "32px", position: "relative" }}>
              <PretendardText
                style={{
                  color: "#262C31",
                  fontWeight: "700",
                  fontSize: "15px",
                  lineHeight: "20.3px",
                  marginBottom: "12px",
                }}
              >
                상품 선택
              </PretendardText>
              <InputSearch
                id={"input05"}
                placeholder={"상품명을 입력하세요"}
                onChange={(value) => {
                  setMerchKeyword(value);
                  saveData.merchId = 0;
                  setSaveData({ ...saveData });
                  if (value.length <= 0) {
                    setOpenFlag3(false);
                  } else {
                    setOpenFlag3(true);
                  }
                }}
                value={merchKeyword}
                // onKeyDown={(e) => {
                //   if (e.key === "Enter") {
                //     if (merchKeyword.length > 0) {
                //       setOpenFlag3(true);
                //       getMerchList();
                //     }
                //   }
                // }}
              />
              {merchKeyword.length > 0 && openFlag3 ? (
                <SelectItemContain
                  style={{
                    position: "absolute",
                    top: 90,
                    left: 0,
                    width: "100%",
                    background: "#fff",
                    borderRadius: 12,
                    boxShadow: "0px 0px 5px rgba(0,0,0,0.3)",
                  }}
                >
                  {merchList.map((item, index) => {
                    return (
                      <SelectSpanText
                        key={index}
                        onClick={() => {
                          setMerch(item.value, item.title);
                        }}
                      >
                        <SelectValueText>{item.title}</SelectValueText>
                      </SelectSpanText>
                    );
                  })}
                </SelectItemContain>
              ) : null}
            </div>
          ) : null}

          {/* 챌린지 완수 포인트 */}
          <div style={{ marginBottom: "32px" }}>
            <PretendardText
              style={{
                color: "#262C31",
                fontWeight: "700",
                fontSize: "15px",
                lineHeight: "20.3px",
                marginBottom:
                  saveData.challengeType === "GROUPBUYING" ? "16px" : "12px",
              }}
            >
              챌린지 완수 포인트
            </PretendardText>
            {saveData.challengeType === "GROUPBUYING" ? (
              <div
                style={{ display: "flex", gap: "22px", marginBottom: "16px" }}
              >
                <RightLabelRadio
                  text={"포인트 직접입력"}
                  checked={!saveData.rewardUsingPercentage}
                  onClick={() => {
                    saveData.rewardUsingPercentage = false;
                    setSaveData({ ...saveData });
                  }}
                />
                <RightLabelRadio
                  text={"%선택(*구매금의 N%)"}
                  checked={saveData.rewardUsingPercentage}
                  onClick={() => {
                    saveData.rewardUsingPercentage = true;
                    setSaveData({ ...saveData });
                  }}
                />
              </div>
            ) : null}
            <InputText
              id={"input06"}
              type={"number"}
              placeholder={
                saveData.rewardUsingPercentage ? "00% (*최대10%)" : "00,000"
              }
              onChange={(value) => {
                saveData.rewardPoint = value;
                setSaveData({ ...saveData });
              }}
              value={saveData.rewardPoint}
            />
          </div>

          {/* 중도 포기 포인트 */}
          <div style={{ marginBottom: "32px" }}>
            <PretendardText
              style={{
                color: "#262C31",
                fontWeight: "700",
                fontSize: "15px",
                lineHeight: "20.3px",
                marginBottom:
                  saveData.challengeType === "GROUPBUYING" ? "16px" : "12px",
              }}
            >
              중도 포기 포인트
            </PretendardText>
            {saveData.challengeType === "GROUPBUYING" ? (
              <div
                style={{ display: "flex", gap: "22px", marginBottom: "16px" }}
              >
                <RightLabelRadio
                  text={"포인트 직접입력"}
                  checked={!saveData.retireUsingPercentage}
                  onClick={() => {
                    saveData.retireUsingPercentage = false;
                    setSaveData({ ...saveData });
                  }}
                />
                <RightLabelRadio
                  text={"%선택(*구매금의 N%)"}
                  checked={saveData.retireUsingPercentage}
                  onClick={() => {
                    saveData.retireUsingPercentage = true;
                    setSaveData({ ...saveData });
                  }}
                />
              </div>
            ) : null}
            <InputText
              id={"input07"}
              type={"number"}
              placeholder={
                saveData.retireUsingPercentage ? "00% (*최대10%)" : "00,000"
              }
              onChange={(value) => {
                saveData.retirePoint = value;
                setSaveData({ ...saveData });
              }}
              value={saveData.retirePoint}
            />
            {isWarningChk ? (
              <PretendardText
                style={{
                  fontWeight: 500,
                  fontSize: 13,
                  lineHeight: "18.2px",
                  color: "#F24147",
                  marginTop: 4,
                }}
              >
                중도 포기 포인트가 완수 포인트보다 높을 수 없습니다.
              </PretendardText>
            ) : null}
          </div>

          <MainBtn
            onClick={() => {
              setSaveOpen(true);
            }}
            disabled={btnFlag || isLoading}
          >
            {isLoading ? <Loading /> : ""}
            <PretendardText
              style={{
                fontSize: 15,
                lineHeight: "20.3px",
                color: "white",
                fontWeight: 700,
              }}
            >
              챌린지 수정하기
            </PretendardText>
          </MainBtn>
        </div>
        {saveOpen ? (
          <ConfirmModal
            title={"챌린지를 수정하시겠습니까?"}
            onClickText={"닫기"}
            onCancleText={"챌린지 수정하기"}
            onClick={() => {
              setSaveOpen(false);
            }}
            onCancle={() => {
              saveFunc();
            }}
          />
        ) : null}

        {isModal ? (
          <ConfirmModal
            title={"챌린지 수정또는 생성을 취소하시겠습니까?"}
            content={"페이지 나갈 시 작성중이던 내용이 모두 사라집니다."}
            onClickText={"취소"}
            onCancleText={"나가기"}
            onClick={() => {
              setIsModal(false);
            }}
            onCancle={() => {
              history.goBack();
            }}
          />
        ) : null}
      </AppContainer>
      {isLoadingTitleFile || isLoadingFile ? <LoadingFile /> : null}
    </AppLayout>
  );
}

const SelectValueText = styled(PretendardText).attrs((props) => {})`
  font-size: 15px;
  font-weight: 500;
  line-height: 22.3px;

  color: #262c31;
  white-space: nowrap;
`;

const SelectSpanText = styled.div.attrs((props) => {})`
  padding-left: 18px;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: #e8e6e6;
    text-decoration: none;
  }
`;

const SelectItemContain = styled.div.attrs((props) => {})`
  position: absolute;
  top: 57px;
  left: 0px;
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  height: 225px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #adb4ba;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(255, 2550, 255, 0.1); /*스크롤바 뒷 배경 색상*/
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
  z-index: 1;
`;

export default UpdateChallenge;
