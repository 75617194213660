import React, { useEffect } from "react";
import useDisplay from "../../hooks/useDisplay";
import PretendardText from "../atoms/pretendardText";
import TextBtn from "../atoms/button/textBtn";
import { route } from "../../routes/route";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getExtensionOfFilename, videoExtension } from "../../utils/file";

function ChallengeCard({ item }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        style={{
          cursor: "pointer",
          width: "100%",
          height: "228px",
          overflow: "hidden",
          borderRadius: "8px",
          position: "relative",
        }}
      >
        <img
          src={videoExtension.includes(getExtensionOfFilename(item.fileUrl)) ? item.thumbFileUrl : item.fileUrl}
          alt=""
          style={{
            width: "100%",
            height: "100%",
          }}
          onClick={() => {
            if (sessionStorage.getItem("id")) {
              history.push({
                pathname: route.short_form_detail + `/${item?.id}`,
              });
            }
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: "15px",
            left: "10px",
          }}
        >
          <PretendardText
            style={{
              color: "rgba(255, 255, 255, 1)",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "600",
              width: "130px",
              maxHeight: "40px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              display: "-webkit-box",
              marginBottom: 2,
            }}
          >
            {item.challengeInfo.title}
          </PretendardText>

          <PretendardText
            style={{
              color: "rgba(255, 255, 255, 0.8)",
              fontSize: "11px",
              lineHeight: "14px",
              fontWeight: "600",
              width: "130px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {item.contents}
          </PretendardText>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextBtn
              onClick={() => {
                history.push({
                  pathname: route.influencer_profile + `/${item.influencerInfo.id}`,
                });
              }}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  border: "2px solid rgba(255, 255, 255, 1)",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <img
                  src={item.influencerInfo.fileUrl ? item.influencerInfo.fileUrl : "/assets/images/profile_temp.png"}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
              <PretendardText
                style={{
                  color: "rgba(255, 255, 255, 1)",
                  fontSize: "13px",
                  lineHeight: "18.75px",
                  fontWeight: "600",
                  width: "67px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  marginLeft: "4px",
                }}
              >
                {item.influencerInfo.nickName}
              </PretendardText>
            </TextBtn>
            <img
              src="/assets/icons/card_checkstar.svg"
              alt=""
              style={{
                width: "17px",
                height: "17px",
                marginLeft: "3px",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ChallengeCard;
