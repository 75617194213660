import React, { useEffect } from "react";
import useDisplay from "../../hooks/useDisplay";
import PretendardText from "../atoms/pretendardText";
import TextBtn from "../atoms/button/textBtn";
import { route } from "../../routes/route";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getExtensionOfFilename, videoExtension } from "../../utils/file";

function CertificationCard({ item, isFirst, isLast }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        style={{
          marginLeft: isFirst ? "20px" : "8px",
          paddingRight: isLast ? "20px" : "",
        }}
        onClick={() => {
          if (sessionStorage.getItem("id")) {
            history.push({
              pathname: route.certification_detail + `/${item?.id}`,
            });
            // history.push({
            //   pathname: route.bottom_search_certification,
            // });
          }
        }}
      >
        <div
          style={{
            width: "162px",
            height: "162px",
            overflow: "hidden",
            borderRadius: "8px",
            position: "relative",
          }}
        >
          {videoExtension.includes(getExtensionOfFilename(item.fileUrl)) ? (
            <img
              src={
                item.thumbFileUrl
                  ? item.thumbFileUrl
                  : "/assets/images/image_default.png"
              }
              alt=""
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          ) : (
            <img
              src={
                item.fileUrl ? item.fileUrl : "/assets/images/image_default.png"
              }
              alt=""
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          )}

          <div
            style={{
              position: "absolute",
              top: "8px",
              right: "8px",
              borderRadius: "6px",
              width: "38px",
              backgroundColor: "rgba(255, 51, 51, 1)",
            }}
          >
            <PretendardText
              style={{
                fontSize: 10,
                lineHeight: "18.75px",
                fontWeight: 500,
                color: "rgba(248, 248, 248, 1)",
                textAlign: "center",
              }}
            >
              NEW
            </PretendardText>
          </div>
        </div>
      </div>
    </>
  );
}

export default CertificationCard;
