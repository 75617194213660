import React, { useState, useEffect, useMemo } from "react";
import useDisplay from "../hooks/useDisplay";
import AppLayout from "../components/atoms/layouts/appLayout";
import AppContainer from "../components/atoms/layouts/appContainer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import BottomTab from "../components/organisms/bottomTab/bottomTab";
import PretendardText from "../components/atoms/pretendardText";
import ShortFormCard from "../components/organisms/shortFormCard";
import { useHistory, useLocation } from "react-router-dom";
import TextBtn from "../components/atoms/button/textBtn";
import { route } from "../routes/route";
import HomeApi from "../api/homeApi";
import { toast } from "react-toastify";
import { ko } from "date-fns/locale";
import UserApi from "../api/userApi";
import { throttle } from "lodash";
import { useInView } from "react-intersection-observer";
import Empty from "../components/molecules/empty";

function AllShortForm(props) {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [ref, inView] = useInView();
  const [shortFormList, setShortFormList] = useState([]);

  const setData = async () => {
    try {
      setLoading(true);
      const response = await HomeApi.allGetDataShortForm({
        page: currentPage,
        size: 10,
      });
      const data = response.data.data;
      setShortFormList(shortFormList.concat(data.content));
      setIsLast(data.last);
      setLoading(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    setData();
  }, [currentPage]);

  useEffect(() => {
    if (inView && !isLast && !loading) {
      setCurrentPage(currentPage + 1);
      // setData()
    }
  }, [inView, loading]);

  // 숏폼 저장
  const storeClickFunc = async (isStored, id) => {
    try {
      const formData = new FormData();
      formData.append("isCreate", !isStored);
      formData.append("objectId", id);
      formData.append("storeType", "SHORT_FORM");

      await UserApi.putStore(formData);
      const shortForm = shortFormList.find((item) => item.id === id);
      shortForm.isStored = !isStored;
      setShortFormList([...shortFormList]);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  // 숏폼 좋아요
  const likeClickFunc = async (isLiked, id) => {
    try {
      const formData = new FormData();
      formData.append("isCreate", !isLiked);
      formData.append("objectId", id);
      formData.append("likeType", "SHORT_FORM");

      await UserApi.putLike(formData);
      const shortForm = shortFormList.find((item) => item.id === id);
      shortForm.isLiked = !isLiked;
      if (!isLiked) {
        shortForm.likeAmount += 1;
      } else {
        shortForm.likeAmount -= 1;
      }

      setShortFormList([...shortFormList]);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout style={{}}>
        <AppContainer
          style={{
            paddingBottom: "24px",
          }}
        >
          {/* 헤더 */}
          <div
            style={{
              height: "50px",
              paddingLeft: "20px",
              paddingRight: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextBtn
              onClick={() => {
                history.goBack();
              }}
            >
              <img
                src="/assets/icons/left_btn.png"
                alt=""
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </TextBtn>
            {location.state?.id ? null : (
              <TextBtn
                onClick={() => {
                  history.push(route.influencer_search);
                }}
              >
                <img
                  src="/assets/icons/ic_24_search.svg"
                  alt=""
                  style={{
                    cursor: "pointer",
                  }}
                />
              </TextBtn>
            )}
          </div>
          {/* 컨텐츠 */}
          <div
            style={{
              marginTop: "16px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            {shortFormList.length > 0 ? (
              shortFormList.map((item, index) => {
                return (
                  <ShortFormCard
                    key={index}
                    item={item}
                    storeClickFunc={storeClickFunc}
                    likeClickFunc={likeClickFunc}
                  ></ShortFormCard>
                );
              })
            ) : (
              <Empty text={"콘텐츠가 없어요"} style={{ margin: "200px 0px" }} />
            )}
            <div ref={ref}>{/* loading */}</div>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default AllShortForm;
