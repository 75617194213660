import React, { useState, useEffect } from "react";
import useDisplay from "../../../hooks/useDisplay";
import styled from "styled-components";
import PretendardText from "../pretendardText";

function InputReview(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <>
      <div
        className="inputReview"
        style={{
          position: "fixed",
          bottom: "20px",
          width: isMobile ? "90vw" : "450px",
        }}
      >
        <InputWrapper
          id="inputReview"
          value={props.value}
          onChange={props.onChange}
          type={props.type}
          placeholder={props.placeholder}
          style={{
            ...props.style,
          }}
          onKeyDown={props.onKeyDown}
        />
        <PretendardText
          style={{
            position: "absolute",
            right: "8px",
            bottom: "8px",
            width: "63px",
            height: "35px",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: props.value.length > 0 ? "#262C31" : "#ADB4BA",

            fontSize: "13px",
            fontWeight: "600",
            lineHeight: "18.75px",
            color: "#FFFFFF",
            cursor: "pointer",
          }}
          onClick={() => {
            if (props.value.length > 0) {
              props.onClick();
            }
          }}
        >
          등록
        </PretendardText>
      </div>
    </>
  );
}

export default InputReview;

const InputWrapper = styled.input.attrs((props) => {})`
  width: 100%;
  height: 51px;
  border-radius: 12px;
  padding: 14px 16px;
  background: rgb(244, 246, 246);
  border: 1px solid rgb(244, 246, 246);

  font-family: "Pretendard";
  color: #262c31;
  font-weight: 500;
  font-size: 15px;
  line-height: 22.3px;

  :focus {
    outline: none !important;
    border-color: #ff3333;
  }

  ::placeholder {
    color: #dbdddf;
    font-weight: 500;
    font-size: 15px;
    line-height: 22.3px;
  }
`;
