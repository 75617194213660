import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Rating from "react-rating";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";

import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import ReviewBar from "../components/molecules/challenge/reviewBar";
import ReviewBox from "../components/organisms/reviewBox";

import { useInView } from "react-intersection-observer";
import InqueryApi from "../api/inqueryApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import { route } from "../routes/route";
import ChallengeApi from "../api/challengeApi";
import Empty from "../components/molecules/empty";
import ConfirmModal from "../components/templates/modals/confirmModal";

function ChallengeReviewList(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();

  const [challengeId, setChallengeId] = useState(0);

  useEffect(() => {
    const challengeId = location?.state ? location.state.challengeId : null;

    if (challengeId) {
      setChallengeId(challengeId);
      setDataAvgFunc(challengeId);
    }

    window.scrollTo(0, 0);
  }, []);

  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [ref, inView] = useInView();
  const [dataList, setDataList] = useState([]);
  const [dataAvg, setDataAvg] = useState();
  const [score01V, setScore01V] = useState(0);
  const [score02V, setScore02V] = useState(0);
  const [score03V, setScore03V] = useState(0);
  const [score04V, setScore04V] = useState(0);
  const [score05V, setScore05V] = useState(0);
  const [isModal, setIsModal] = useState(false);
  const [isDeleteId, setIsDeleteId] = useState();

  const setDataAvgFunc = async (id) => {
    try {
      const response = await ChallengeApi.ReviewListAvg(id);

      if (response.data.data) {
        setDataAvg(response.data.data);

        let totalVTemp =
          response.data.data.score1 +
          response.data.data.score2 +
          response.data.data.score3 +
          response.data.data.score4 +
          response.data.data.score5;

        let temp01 = Math.floor(response.data.data.score1 / totalVTemp) * 100;
        let temp02 = Math.floor(response.data.data.score2 / totalVTemp) * 100;
        let temp03 = Math.floor(response.data.data.score3 / totalVTemp) * 100;
        let temp04 = Math.floor(response.data.data.score4 / totalVTemp) * 100;
        let temp05 = Math.floor(response.data.data.score5 / totalVTemp) * 100;

        setScore01V(temp01);
        setScore02V(temp02);
        setScore03V(temp03);
        setScore04V(temp04);
        setScore05V(temp05);
      }
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const setDataListFunc = async () => {
    try {
      setLoading(true);
      const response = await ChallengeApi.ReviewList(challengeId, {
        page: currentPage,
        size: 10,
      });

      const data = response.data.data;
      setDataList(dataList.concat(data.content));
      setIsLast(data.last);
      setLoading(false);
      setTotalCount(data.totalElements);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    if (challengeId) {
      setDataListFunc();
    }
  }, [currentPage, challengeId]);

  useEffect(() => {
    if (inView && !isLast && !loading && challengeId) {
      setCurrentPage(currentPage + 1);
      setDataListFunc();
    }
  }, [inView, loading, challengeId]);

  const reviewDeleteFunc = async () => {
    try {
      await ChallengeApi.ReviewDel(isDeleteId);

      setIsDeleteId();
      history.goBack();
      toast("리뷰가 삭제되었습니다.");
    } catch (e) {
      toast(ko.errorText_500);
    }
  };

  const reviewEditFunc = async (id) => {
    try {
      history.push({
        pathname: route.challenge_review_write,
        state: {
          reviewId: id,
        },
      });
    } catch (e) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 16,
              paddingBottom: 35,
              borderBottom: "8px solid #F5F5F5",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginRight: "12%",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  fontWeight: 700,
                  color: "#262C31",
                }}
              >
                참가자 총 평점
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 28,
                  lineHeight: "39.2px",
                  fontWeight: 700,
                  color: "#262C31",
                  marginTop: 21,
                }}
              >
                {dataAvg ? (dataAvg.avg ? dataAvg.avg + ".0" : "0.0") : "0.0"}
              </PretendardText>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Rating
                  initialRating={dataAvg ? dataAvg.avg : 0}
                  readonly
                  emptySymbol={
                    <img
                      src="/assets/icons/rating_d.png"
                      className="icon"
                      style={{
                        width: 20,
                        height: 20,
                        margin: "0px 3px",
                      }}
                    />
                  }
                  fullSymbol={
                    <img
                      src="/assets/icons/rating_a.png"
                      className="icon"
                      style={{
                        width: 20,
                        height: 20,
                        margin: "0px 3px",
                      }}
                    />
                  }
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  fontWeight: 700,
                  color: "#262C31",
                }}
              >
                평점 비율
              </PretendardText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 9,
                }}
              >
                <ReviewBar
                  text={"5점"}
                  style={{
                    marginRight: 8,
                  }}
                  value={score05V}
                />
                <ReviewBar
                  text={"4점"}
                  style={{
                    marginRight: 8,
                  }}
                  value={score04V}
                />
                <ReviewBar
                  text={"3점"}
                  style={{
                    marginRight: 8,
                  }}
                  value={score03V}
                />
                <ReviewBar
                  text={"2점"}
                  style={{
                    marginRight: 8,
                  }}
                  value={score02V}
                />
                <ReviewBar text={"1점"} style={{}} value={score01V} />
              </div>
            </div>
          </div>

          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              padding: "24px 20px 20px 20px",
            }}
          >
            <PretendardText
              style={{
                marginTop: "5px",
                fontSize: 14,
                lineHeight: "20px",
                color: "#778088",
                fontWeight: 600,
              }}
            >
              전체 {totalCount}
            </PretendardText>

            <div
              style={{
                marginTop: "20px",
              }}
            >
              {dataList.length > 0 ? (
                dataList.map((item, index) => {
                  return (
                    <ReviewBox
                      key={index}
                      data={item}
                      setIsModal={setIsModal}
                      setIsDeleteId={setIsDeleteId}
                      reviewEditFunc={reviewEditFunc}
                    />
                  );
                })
              ) : (
                <Empty
                  style={{
                    height: "70vh",
                  }}
                  text={"리뷰가 없어요"}
                />
              )}

              <div ref={ref}>{/* loading */}</div>
            </div>
          </div>

          {isModal ? (
            <ConfirmModal
              setVisible={setIsModal}
              title={"정말 리뷰를 삭제하시겠어요?"}
              content={""}
              onClickText={"취소"}
              onCancleText={"삭제하기"}
              onClick={() => {
                setIsModal(false);
              }}
              onCancle={() => {
                reviewDeleteFunc();
              }}
            />
          ) : null}
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default ChallengeReviewList;
