import React, { useEffect, useMemo } from "react";
import useDisplay from "../../hooks/useDisplay";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PretendardText from "../atoms/pretendardText";
import TextBtn from "../atoms/button/textBtn";
import { route } from "../../routes/route";
import { getExtensionOfFilename, videoExtension } from "../../utils/file";

function GeneralChallengeBox({ isProgress, item }) {
  const history = useHistory();

  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        onClick={() => {
          history.push({
            pathname: route.challenge_detail + `/${item.challengeInfo?.id}`,
          });
        }}
        style={{
          marginBottom: "24px",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              position: "relative",
              width: isMobile ? "121px" : "121px",
              height: isMobile ? "121px" : "121px",
              overflow: "hidden",
              borderRadius: "8px",
            }}
          >
            <img
              src={
                videoExtension.includes(
                  getExtensionOfFilename(item.challengeInfo?.fileUrl)
                )
                  ? item.challengeInfo?.thumbFileUrl
                  : item.challengeInfo?.fileUrl
              }
              alt=""
              style={{
                width: "100%",
                height: "100%",
              }}
            />
            <PretendardText
              style={{
                fontSize: 11,
                lineHeight: "14px",
                color: "#FFFFFF",
                fontWeight: 600,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                borderRadius: "6px",
                padding: "5px 8px",
                position: "absolute",
                top: "10px",
                left: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {item.challengeInfo?.challengeTypeTitle}
            </PretendardText>
          </div>
          <div
            style={{
              width: isMobile ? "199px" : "310px",
              marginLeft: "15px",
              paddingTop: "2.5px",
              paddingBottom: "2.5px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 13,
                    lineHeight: "18.75px",
                    color: "rgba(38, 44, 49, 1)",
                    fontWeight: 600,
                  }}
                >
                  {item.retire ? "중도포기" : item.challengeStatusTitle}
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 13,
                    lineHeight: "18.75px",
                    color: "rgba(119, 128, 136, 1)",
                    fontWeight: 600,
                    marginLeft: "7px",
                  }}
                >
                  {item.challengeInfo?.challengeCategoryTitle}
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 13,
                    lineHeight: "18.75px",
                    color: "rgba(119, 128, 136, 1)",
                    fontWeight: 600,
                    marginLeft: "7px",
                  }}
                >
                  {item.challengeInfo?.challengePeriodTitle}
                </PretendardText>
              </div>
              {isProgress ? (
                !item.retire && item.todayRequire ? (
                  <PretendardText
                    PretendardText
                    style={{
                      fontSize: 13,
                      lineHeight: "18.75px",
                      color: "#5ECECE",
                      fontWeight: 700,
                    }}
                  >
                    인증완료!
                  </PretendardText>
                ) : (
                  <PretendardText
                    style={{
                      fontSize: 13,
                      lineHeight: "18.75px",
                      color: "#F24147",
                      fontWeight: 700,
                    }}
                  >
                    인증필요!
                  </PretendardText>
                )
              ) : (
                ""
              )}
            </div>
            <PretendardText
              style={{
                marginTop: "8px",
                fontSize: 15,
                lineHeight: "20.3px",
                color: "#262C31",
                fontWeight: 600,
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                display: "-webkit-box",
              }}
            >
              {item.challengeInfo?.title}
            </PretendardText>
            <div
              style={{
                marginTop: "7px",
                display: "flex",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "16.5px",
                  color: "#6E7385",
                  fontWeight: 500,
                }}
              >
                완수율
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "16.5px",
                  color: "#FF3333",
                  fontWeight: 500,
                  marginLeft: "8px",
                }}
              >
                {item.certificationCount && item.requireCount
                  ? (
                      (item.certificationCount / item.requireCount) *
                      100
                    ).toFixed(2)
                  : 0}
                %
              </PretendardText>
            </div>
            <div
              style={{
                marginTop: "7px",
                display: "flex",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "16.5px",
                  color: "#6E7385",
                  fontWeight: 500,
                }}
              >
                {item.participationStatus === "END_REVIEWED" ||
                item.participationStatus === "RETIRE_REVIEWED"
                  ? "획득 포인트"
                  : "예상 적립 포인트"}
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "16.5px",
                  color: "#6F7485",
                  fontWeight: 500,
                  marginLeft: "8px",
                }}
              >
                {item.participationStatus === "END_REVIEWED" ||
                item.participationStatus === "RETIRE_REVIEWED"
                  ? Number(item.earnPoint).toLocaleString("ko-KR")
                  : Number(item.anticipatePoint).toLocaleString("ko-KR")}
              </PretendardText>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GeneralChallengeBox;
