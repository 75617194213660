import React, { useEffect } from "react";
import moment from "moment";

import useDisplay from "../../hooks/useDisplay";
import TextBtn from "../atoms/button/textBtn";
import PretendardText from "../atoms/pretendardText";
import { useHistory } from "react-router-dom";
import { route } from "../../routes/route";
import { getExtensionOfFilename, videoExtension } from "../../utils/file";

function EntryCertificationBox({ isEnd, item }) {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TextBtn
        style={{
          width: isMobile ? "32.8vw" : "32.9%",
          height: 123,
          marginRight: isEnd ? "0px" : "3px",
          marginBottom: "3px",
        }}
        onClick={() => {
          history.push(route.certification_detail + `/${item.id}`);
        }}
      >
        <div
          style={{
            position: "relative",
            overflow: "hidden",
            flex: 1,
          }}
        >
          {videoExtension.includes(getExtensionOfFilename(item.fileUrl)) ? (
            <img
              src={
                item.thumbFileUrl
                  ? item.thumbFileUrl
                  : "/assets/images/image_default.png"
              }
              alt=""
              style={{
                width: "100%",
                height: "100%",
                minWidth: isMobile ? "" : "160px",
                minHeight: isMobile ? "" : "123px",
              }}
            />
          ) : (
            <img
              src={
                item.fileUrl ? item.fileUrl : "/assets/images/image_default.png"
              }
              alt=""
              style={{
                width: "100%",
                height: "100%",
                minWidth: isMobile ? "" : "160px",
                minHeight: isMobile ? "" : "123px",
              }}
            />
          )}
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              left: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <PretendardText
              style={{
                fontSize: 11,
                lineHeight: "14px",
                color: "#FFFFFF",
                fontWeight: 600,
              }}
            >
              {moment(item.createdAt).format("YY.MM.DD")}
            </PretendardText>
          </div>
        </div>
      </TextBtn>
    </>
  );
}

export default EntryCertificationBox;
