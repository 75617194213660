import { useState } from "react";
import styled from "styled-components";

export const Radio = ({ checked, onChange, size, style }) => {
  return (
    <>
      {checked ? (
        <img
          onClick={onChange}
          src="/assets/icons/radio_a.svg"
          style={{
            height: size ? size : 16,
            width: size ? size : 16,
            ...style,
          }}
        />
      ) : (
        <img
          onClick={onChange}
          src="/assets/icons/radio_d.svg"
          style={{
            height: size ? size : 16,
            width: size ? size : 16,
            ...style,
          }}
        />
      )}
    </>
  );
};
