import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import TitleInput from "../components/molecules/input/titleInput";
import FileForm from "../components/atoms/fileForm/fileForm";
import { route } from "../routes/route";
import UserApi from "../api/userApi";
import { toast } from "react-toastify";

function PaymentComplete(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const history = useHistory();

  return (
    <>
      <AppLayout>
        <AppContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <img
              src="/assets/images/payment.png"
              style={{
                height: "100%",
                width: 303.62,
                marginRight: "16%",
              }}
            />
            <PretendardText
              style={{
                marginTop: 45.34,
                fontSize: 15,
                lineHeight: "22.25px",
                color: "#FF3333",
                fontWeight: 600,
              }}
            >
              결제완료
            </PretendardText>
            <PretendardText
              style={{
                marginTop: 2,
                fontSize: 24,
                lineHeight: "33.6px",
                color: "#262C31",
                fontWeight: 600,
              }}
            >
              결제가 완료되었습니다.
            </PretendardText>
          </div>
          <div
            style={{
              marginRight: 20,
              marginLeft: 20,
              marginBottom: 20,
            }}
          >
            <MainBtn
              onClick={() => {
                history.push({
                  pathname: route.order_list,
                });
              }}
              style={{}}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                주문내역 보러가기
              </PretendardText>
            </MainBtn>

            <MainBtn
              onClick={() => {
                history.push({
                  pathname: route.bottom_home,
                });
              }}
              style={{ marginTop: 16, background: "#ADB4BA" }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                홈으로
              </PretendardText>
            </MainBtn>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default PaymentComplete;
