import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PretendardText from "../../atoms/pretendardText";
import TextBtn from "../../atoms/button/textBtn";
import { route } from "../../../routes/route";

const FollowSection = ({ data, unFollowFunc }) => {
  const history = useHistory();

  return (
    <div
      style={{
        width: "100%",
        padding: "16px 20px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 15,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TextBtn>
            <img
              onClick={() => {
                history.push({
                  pathname:
                    route.influencer_profile + `/${data.followedMember.id}`,
                });
              }}
              src={
                data.followedMember.fileUrl
                  ? data.followedMember.fileUrl
                  : "/assets/images/profile_temp.png"
              }
              style={{
                height: 50,
                width: 50,
                marginRight: 12,
                borderRadius: 100,
                cursor: "pointer",
              }}
            />
          </TextBtn>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextBtn>
              <PretendardText
                onClick={() => {
                  history.push({
                    pathname:
                      route.influencer_profile + `/${data.followedMember.id}`,
                  });
                }}
                style={{
                  fontSize: 15,
                  lineHeight: "22.25px",
                  color: "#262C31",
                  fontWeight: 600,
                  marginBottom: 1.5,
                  cursor: "pointer",
                }}
              >
                {data.followedMember.nickName}
              </PretendardText>
            </TextBtn>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#ADB4BA",
                  fontWeight: 600,
                  marginRight: 4,
                }}
              >
                Followers
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#5A636A",
                  fontWeight: 700,
                  marginRight: 16.5,
                }}
              >
                {data.followedMember.followerCount}
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#ADB4BA",
                  fontWeight: 600,
                  marginRight: 4,
                }}
              >
                Like
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#5A636A",
                  fontWeight: 700,
                }}
              >
                {data.followedMember.likeCount}
              </PretendardText>
            </div>
          </div>
        </div>

        <TextBtn
          onClick={() => {
            unFollowFunc(data.followedMember.id);
          }}
          style={{
            border: "2px solid #DBDDDF",
            borderRadius: 8,
            padding: "8px 7px",
          }}
        >
          <PretendardText
            style={{
              fontSize: 13,
              lineHeight: "18.75px",
              color: "#262C31",
              fontWeight: 700,
            }}
          >
            팔로우 취소
          </PretendardText>
        </TextBtn>
      </div>
    </div>
  );
};

export default FollowSection;
