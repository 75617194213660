import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Rating from "react-rating";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import TitleInput from "../components/molecules/input/titleInput";
import FileForm from "../components/atoms/fileForm/fileForm";
import styled from "styled-components";
import TextArea from "../components/atoms/input/textarea";
import ChallengeApi from "../api/challengeApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import ConfirmModal from "../components/templates/modals/confirmModal";
import { route } from "../routes/route";

function ChallengeReviewWrite(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();

  const [file, setFile] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [content, setContent] = useState("");
  const [ratingValue, setRatingValue] = useState(0);

  const [reviewId, setReviewId] = useState();
  const [partyId, setPartyId] = useState(0);
  const [dataInfo, setDataInfo] = useState();
  const [isModal1, setIsModal1] = useState(false);
  useEffect(() => {
    const id = location?.state ? location.state.id : null;
    const reviewId = location?.state ? location.state.reviewId : null;

    if (id) {
      setPartyId(id);
      reviewPartiDetailFunc(id);
    }

    if (reviewId) {
      reviewDetailFunc(reviewId);
      setReviewId(reviewId);
    }

    window.scrollTo(0, 0);
  }, []);

  const reviewPartiDetailFunc = async (id) => {
    try {
      const response = await ChallengeApi.ReviewPartiInfo(id);

      setDataInfo(response.data.data);
    } catch (e) {
      toast(ko.errorText_500);
    }
  };

  const reviewDetailFunc = async (id) => {
    try {
      const response = await ChallengeApi.ReviewDetail(id);

      setDataInfo(response.data.data);

      setImageUrl(response.data.data.imageUrl);
      setContent(response.data.data.contents);
      setRatingValue(response.data.data.score);
    } catch (e) {
      toast(ko.errorText_500);
    }
  };

  const handleChange = (e) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (data) => {
      setFile(file);
      setImageUrl(data.target.result);
    };
    e.target.value = "";
  };

  const reviewWriteFunc = async () => {
    try {
      let formData = new FormData();

      formData.append("file", file);
      formData.append("contents", content);
      formData.append("score", ratingValue);

      await ChallengeApi.ReviewWrite(partyId, formData);

      history.push({
        pathname: route.challenge_review_list,
        state: {
          challengeId: dataInfo.challenge.id,
        },
      });
      toast("리뷰 작성 완료");
    } catch (e) {
      if (e.response.status === 400) {
        return toast("이미 리뷰를 작성한 챌린지입니다.");
      }
      toast(ko.errorText_500);
    }
  };

  const reviewEditFunc = async () => {
    try {
      let formData = new FormData();

      if (file) {
        formData.append("file", file);
        formData.append("deleteFile", true);
      }

      formData.append("deleteFile", false);
      formData.append("contents", content);
      formData.append("score", ratingValue);

      await ChallengeApi.ReviewEdit(reviewId, formData);

      history.push({
        pathname: route.challenge_review_list,
        state: {
          challengeId: dataInfo.challenge.id,
        },
      });
      toast("리뷰 수정 완료");
    } catch (e) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header title={reviewId ? "리뷰 수정" : "리뷰 작성"} />

          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                marginTop: 60,
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <StyledTextTitle>
                {dataInfo ? (reviewId ? dataInfo.challengeInfo.title : dataInfo.challenge.title) : ""}
              </StyledTextTitle>

              {dataInfo && dataInfo.orderInfo ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 15,
                      lineHeight: "22.25px",
                      fontWeight: 600,
                      color: "#5A636A",
                      textAlign: "center",
                    }}
                  >
                    {dataInfo.orderInfo.merchName}&nbsp;
                  </PretendardText>
                  {dataInfo.orderInfo.orderOptions.map((item, index) => {
                    if (index > 1) {
                      return (
                        <PretendardText
                          key={index}
                          style={{
                            fontSize: 15,
                            lineHeight: "22.25px",
                            fontWeight: 600,
                            color: "#5A636A",
                            textAlign: "center",
                          }}
                        >
                          외 {dataInfo.orderInfo.orderOptions.length - 2}개
                        </PretendardText>
                      );
                    }
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 15,
                            lineHeight: "22.25px",
                            fontWeight: 600,
                            color: "#5A636A",
                            textAlign: "center",
                          }}
                        >
                          {"("}
                        </PretendardText>
                        <PretendardText
                          style={{
                            fontSize: 15,
                            lineHeight: "22.25px",
                            fontWeight: 600,
                            color: "#5A636A",
                            textAlign: "center",
                          }}
                        >
                          {item.optionName}
                        </PretendardText>
                        <PretendardText
                          style={{
                            fontSize: 15,
                            lineHeight: "22.25px",
                            fontWeight: 600,
                            color: "#5A636A",
                            textAlign: "center",
                          }}
                        >
                          /{item.optionValueName}
                        </PretendardText>
                        <PretendardText
                          style={{
                            fontSize: 15,
                            lineHeight: "22.25px",
                            fontWeight: 600,
                            color: "#5A636A",
                            textAlign: "center",
                          }}
                        >
                          {")"}&nbsp;
                        </PretendardText>
                      </div>
                    );
                  })}
                </div>
              ) : null}

              <div
                style={{
                  marginTop: 28,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Rating
                    initialRating={ratingValue}
                    onChange={(value) => {
                      setRatingValue(value);
                    }}
                    emptySymbol={
                      <img
                        src="/assets/icons/rating_d.png"
                        className="icon"
                        style={{
                          width: 48,
                          height: 48,
                          margin: "0px 6px",
                        }}
                      />
                    }
                    fullSymbol={
                      <img
                        src="/assets/icons/rating_a.png"
                        className="icon"
                        style={{
                          width: 48,
                          height: 48,
                          margin: "0px 6px",
                        }}
                      />
                    }
                  />
                </div>

                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "20px",
                    fontWeight: 600,
                    color: "#262C31",
                    textAlign: "center",
                    marginTop: 4,
                  }}
                >
                  {ratingValue}점
                </PretendardText>

                <PretendardText
                  style={{
                    fontSize: 13,
                    lineHeight: "18.75px",
                    color: "#778088",
                    fontWeight: 600,
                    marginTop: 30,
                  }}
                >
                  사진
                </PretendardText>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginTop: 8,
                  }}
                >
                  <FileForm handleChange={handleChange} text={"*최대 1장"} />

                  {imageUrl ? (
                    <div
                      style={{
                        marginLeft: 8,
                        width: 80,
                        height: 80,
                        borderRadius: 12,
                        backgroundImage: `url( ${imageUrl} )`,
                        backgroundSize: "100% 100%",
                        position: "relative",
                      }}
                    >
                      <img
                        onClick={() => {
                          setFile(null);
                          setImageUrl("");
                        }}
                        src="/assets/icons/imageDel.png"
                        style={{
                          height: 20,
                          width: 20,
                          position: "absolute",
                          top: 4,
                          right: 4,
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </div>

              <div
                style={{
                  marginTop: 18,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 13,
                    lineHeight: "18.75px",
                    color: "#778088",
                    fontWeight: 600,
                    marginBottom: 4,
                  }}
                >
                  후기
                </PretendardText>
                <TextArea
                  style={{
                    height: 112,
                  }}
                  value={content}
                  onChange={(e) => {
                    setContent(e.target.value);
                  }}
                  type={"text"}
                  placeholder={"후기를 입력하세요"}
                />
              </div>
            </div>

            <MainBtn
              disabled={imageUrl && content ? false : true}
              style={{
                marginTop: 22,
              }}
              onClick={() => {
                setIsModal1(true);
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                {reviewId ? "수정하기" : "리뷰남기기"}
              </PretendardText>
            </MainBtn>
          </div>

          {isModal1 ? (
            <ConfirmModal
              title={`리뷰를 남기시겠어요?`}
              onClickText={"취소"}
              onCancleText={"리뷰남기기"}
              onClick={() => {
                setIsModal1(false);
              }}
              onCancle={() => {
                reviewId ? reviewEditFunc() : reviewWriteFunc();
              }}
            />
          ) : null}
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default ChallengeReviewWrite;

const StyledTextTitle = styled(PretendardText).attrs((props) => {})`
  font-size: 18px;
  line-height: 25.2px;
  color: rgb(38, 44, 49);
  font-weight: 600;
  margin-bottom: 4px;
  padding: 0px 34px;
  text-align: center;

  display: -webkit-box;
  display: -ms-flexbox;
  display: box;

  overflow: hidden;
  vertical-align: top;
  text-overflow: ellipsis;
  word-break: break-all;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;
