import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PretendardText from "../../atoms/pretendardText";
import TextBtn from "../../atoms/button/textBtn";
import moment from "moment";

const InquirySection = ({ data }) => {
  const history = useHistory();

  const [isDetail, setIsDetail] = useState(false);

  return (
    <div
      onClick={() => {}}
      style={{
        width: "100%",
        padding: "22px 20px 24px 20px",
        borderBottom: "1px solid #DBDDDF",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 15,
        }}
      >
        {data.answered ? (
          <PretendardText
            style={{
              fontSize: 13,
              lineHeight: "18.75px",
              color: "#FFF",
              fontWeight: 700,
              padding: "2px 6px",
              background: "#262C31",
              borderRadius: 6,
            }}
          >
            답변완료
          </PretendardText>
        ) : (
          <PretendardText
            style={{
              fontSize: 13,
              lineHeight: "18.75px",
              color: "#FFF",
              fontWeight: 700,
              padding: "2px 6px",
              background: "#778088",
              borderRadius: 6,
            }}
          >
            답변대기
          </PretendardText>
        )}

        {isDetail ? (
          <img
            onClick={() => {
              setIsDetail(!isDetail);
            }}
            src="/assets/icons/up_arrow.svg"
            style={{
              height: 16,
              width: 16,
              cursor: "pointer",
            }}
          />
        ) : (
          <img
            onClick={() => {
              if (data.answer) setIsDetail(!isDetail);
            }}
            src="/assets/icons/down_arrow.svg"
            style={{
              height: 16,
              width: 16,
              cursor: "pointer",
            }}
          />
        )}
      </div>
      <PretendardText
        style={{
          fontSize: 15,
          lineHeight: "22.3px",
          color: "#262C31",
          fontWeight: 500,
          whiteSpace: "pre-wrap",
        }}
      >
        {data.inquiry}
      </PretendardText>

      <PretendardText
        style={{
          marginTop: 7,
          fontSize: 13,
          lineHeight: "18.75px",
          color: "#ADB4BA",
          fontWeight: 400,
        }}
      >
        {moment(data.createdAt).format("YYYY.MM.DD")}
      </PretendardText>

      {isDetail ? (
        <div
          style={{
            marginTop: 21,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <PretendardText
              style={{
                fontSize: 15,
                lineHeight: "21.5px",
                color: "#DBDDDF",
                fontWeight: 800,
              }}
            >
              ┗
            </PretendardText>
            <PretendardText
              style={{
                marginLeft: 6,
                fontSize: 14,
                lineHeight: "21.5px",
                color: "#5ECECE",
                fontWeight: 500,
              }}
            >
              답변
            </PretendardText>
          </div>

          <PretendardText
            style={{
              marginTop: 6,
              paddingLeft: 3,
              fontSize: 14,
              lineHeight: "21.5px",
              color: "#262C31",
              fontWeight: 500,
              whiteSpace: "pre-wrap",
            }}
          >
            {data.answer}
          </PretendardText>

          <PretendardText
            style={{
              marginTop: 12,
              fontSize: 13,
              lineHeight: "18.75px",
              color: "#ADB4BA",
              fontWeight: 400,
            }}
          >
            {moment(data.updatedAt).format("YYYY.MM.DD")}
          </PretendardText>
        </div>
      ) : null}
    </div>
  );
};

export default InquirySection;
