import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import TitleInput from "../components/molecules/input/titleInput";
import FileForm from "../components/atoms/fileForm/fileForm";
import Empty from "../components/molecules/empty";
import NoticeSection from "../components/molecules/notice/noticeSection";
import AlarmSection from "../components/molecules/alarm/alarmSection";
import { Toggle } from "../components/atoms/toggle/toggle";
import MoreApi from "../api/moreApi";

function AlarmSetting(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  const [isService, setIsService] = useState(true);
  const [isAd, setIsAd] = useState(false);

  useEffect(() => {
    alarmListFunc();

    window.scrollTo(0, 0);
  }, []);

  const alarmListFunc = async () => {
    try {
      let response = await MoreApi.GetAlarmList();

      setIsService(response.data.data.agreeToServiceNotice);
      setIsAd(response.data.data.agreeToAdNotice);
    } catch (error) {}
  };

  const sAlarmUpdateFunc = async (value) => {
    try {
      let formData = new FormData();
      formData.append("agreeNotice", value);

      await MoreApi.SAlarmUpdate(formData);

      alarmListFunc();
    } catch (error) {}
  };

  const aAlarmUpdateFunc = async (value) => {
    try {
      let formData = new FormData();
      formData.append("agreeNotice", value);

      await MoreApi.AAlarmUpdate(formData);

      alarmListFunc();
    } catch (error) {}
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header title={"알림설정"} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "24px 20px",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "22.25px",
                  color: "#262C31",
                  fontWeight: 600,
                }}
              >
                서비스 알림
              </PretendardText>
              <div>
                <Toggle
                  isOn={isService}
                  onClick={() => {
                    setIsService(!isService);
                    sAlarmUpdateFunc(!isService);
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0px 20px",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "22.25px",
                  color: "#262C31",
                  fontWeight: 600,
                }}
              >
                광고성 알림
              </PretendardText>
              <div>
                <Toggle
                  isOn={isAd}
                  onClick={() => {
                    setIsAd(!isAd);
                    aAlarmUpdateFunc(!isAd);
                  }}
                />
              </div>
            </div>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default AlarmSetting;
