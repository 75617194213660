import React, { useState, useEffect } from "react";
import useDisplay from "../../../hooks/useDisplay";
import styled from "styled-components";

import { Radio } from "../../atoms/radio/radio";
import PretendardText from "../../atoms/pretendardText";

function RightImgRadio({
  text,
  size,
  checked,
  inputStyle,
  onClick,
  imgStyle,
  src,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
          ...inputStyle,
        }}
      >
        <Radio checked={checked} onChange={onClick} size={size} />

        <img
          src={src}
          onClick={onClick}
          style={{
            marginLeft: 9,
            ...imgStyle,
          }}
        />
      </div>
    </>
  );
}

export default RightImgRadio;
