import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import TextBtn from "../components/atoms/button/textBtn";
import styled from "styled-components";
import GrayBoldBtn02 from "../components/atoms/button/grayBoldBtn02";
import WelcomeModal from "../components/templates/modals/welcomeModal";
import ProductModal from "../components/templates/modals/productModal";
import ChallengeApi from "../api/challengeApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import moment from "moment";
import { route } from "../routes/route";
import Empty from "../components/molecules/empty";
import UserApi from "../api/userApi";
import { nullCheck } from "../utils/check";
import ConfirmModal from "../components/templates/modals/confirmModal";
import { getExtensionOfFilename, videoExtension } from "../utils/file";
import { shareFunc } from "../utils/share";
import SwiperContain from "../components/molecules/challenge/swiperContain";

function ChallengeDetail(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  let myID = sessionStorage.getItem("id");
  const [isInfluencer, setIsInfluencer] = useState(false);
  const [isMoreBtn, setIsMoreBtn] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.removeItem("pDataTemp");
    const memberType = sessionStorage.getItem("memberType");

    if (memberType === "INFLUENCER") {
      setIsInfluencer(true);
    }
  }, []);

  const [activeIndex, setActiveIndex] = useState(1);

  const [detailImageMore, setDetailImageMore] = useState(false);
  const [detailProductMore, setDetailProductMore] = useState(false);
  const [isOptionModal, setIsOptionModal] = useState(false);

  const [shortFormList, setShortFormList] = useState([]);

  // 챌린지 정보
  const { id } = useParams();

  const [challenge, setChallenge] = useState({});
  const [isModal2, setIsModal2] = useState(false);
  // 중도포기
  const [isModal3, setIsModal3] = useState(false);
  // 삭제하기
  const [isModal4, setIsModal4] = useState(false);

  // init
  const getChallenge = async () => {
    try {
      const response = await ChallengeApi.getChallengeDetail(id);

      setChallenge({
        ...response.data.data,
      });

      getShortFormListAPI(
        response.data.data.influencerInfo.id,
        response.data.data.id
      );
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const getShortFormListAPI = async (ifID, clID) => {
    try {
      const response = await ChallengeApi.getDetailShortFormList(ifID, clID);

      setShortFormList(response.data.data.content);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    getChallenge();
  }, []);

  const dDay = useMemo(() => {
    return moment(challenge?.startDate).diff(
      moment(new Date().toDateString()),
      "days"
    );
  }, [challenge]);

  // 참가자 인증내역
  const [challengeReviewList, setChallengeReviewList] = useState([]);

  // init
  const getChallengeReviewList = async () => {
    try {
      const response = await ChallengeApi.getChallengeReviewList(id);

      setChallengeReviewList(response.data.data.content);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    getChallengeReviewList();
  }, []);

  // 챌린지 저장
  const storeClickFunc = async () => {
    try {
      const formData = new FormData();
      formData.append("isCreate", !challenge.isStored);
      formData.append("objectId", id);
      formData.append("storeType", "CHALLENGE");

      await UserApi.putStore(formData);
      challenge.isStored = !challenge.isStored;
      setChallenge({ ...challenge });
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  // 챌린지 참가
  const [isModal, setIsModal] = useState(false);

  const enterClickFunc = async () => {
    if (btnTitle !== "참가하기") {
      return;
    }

    try {
      await UserApi.enterChallenge(id);

      const memberType = sessionStorage.getItem("memberType");

      if (memberType === "INFLUENCER") {
        history.push({
          pathname: route.bottom_manager_challenge,
        });
      } else {
        history.push({
          pathname: route.bottom_myChallenge,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        toast(`이미 참가한 챌린지입니다.`);
      }
      toast(ko.errorText_500);
    }
  };

  // 상황별 버튼 텍스트
  const btnTitle = useMemo(() => {
    switch (challenge.challengeStatus) {
      // 시작전
      case "BEFORE":
        // 참여한 경우
        if (!nullCheck(challenge.participationInfo)) {
          return "참여 취소하기";
        }

        // 참여정보가 없으면서 내가 관리하는 챌린지가 아닌 경우
        if (
          nullCheck(challenge.participationInfo) &&
          Number(challenge.commonMemberID) !==
            Number(sessionStorage.getItem("id"))
        ) {
          // 무통장 입금 한 경우
          if (challenge.payFlag) {
            return "참여 대기";
          }
          // 환불 요청한 경우
          if (challenge.refundFlag) {
            return "환불 처리 중";
          }
          return nullCheck(challenge.merch) ? "참가하기" : "구매하고 참가하기";
        }
        return "";
      // 진행중
      case "PROGRESS":
        // 일반회원 참여한 경우
        if (
          !nullCheck(challenge.participationInfo) &&
          sessionStorage.getItem("memberType") === "COMMON"
        ) {
          if (challenge.participationInfo?.participationStatus !== "RETIRE") {
            return "중도 포기하기";
          } else if (
            challenge.participationInfo?.participationStatus === "RETIRE" &&
            challenge.participationInfo?.participationStatus !==
              "END_REVIEWED" &&
            challenge.participationInfo?.participationStatus !==
              "RETIRE_REVIEWED"
          ) {
            return "리뷰 작성하기";
          }
        }
        if (
          !nullCheck(challenge.participationInfo) &&
          sessionStorage.getItem("memberType") === "INFLUENCER" &&
          Number(challenge.commonMemberID) !==
            Number(sessionStorage.getItem("id"))
        ) {
          return "인증하기";
        }
        return "";
      // 완료
      case "END":
        // 일반회원 참여한 경우
        if (
          !nullCheck(challenge.participationInfo) &&
          sessionStorage.getItem("memberType") === "COMMON"
        ) {
          if (
            challenge.participationInfo?.participationStatus !==
              "END_REVIEWED" &&
            challenge.participationInfo?.participationStatus !==
              "RETIRE_REVIEWED"
          ) {
            return "리뷰 작성하기";
          }
        }
        // 내가 관리하는 챌린지인 경우
        if (
          Number(challenge.commonMemberID) ===
          Number(sessionStorage.getItem("id"))
        ) {
          return "챌린지 재개하기";
        }
        return "";
      default:
        return "";
    }
  }, [challenge]);

  // 버튼 클릭 시 이벤트
  const btnOnClick = async () => {
    switch (btnTitle) {
      case "참여 취소하기":
        setIsModal2(true);
        break;
      case "참가하기":
        setIsModal(true);
        break;
      case "구매하고 참가하기":
        setIsOptionModal(true);
        break;
      case "중도 포기하기":
        setIsModal3(true);
        break;
      case "리뷰 작성하기":
        history.push({
          pathname: route.challenge_review_write,
          state: {
            id: challenge.participationInfo.id,
          },
        });
        break;
      case "챌린지 재개하기":
        history.push(route.restart_challenge + `/${id}`);
        break;
      case "인증하기":
        history.push(
          route.certification + `/${challenge.participationInfo.id}`
        );
        break;
      default:
        break;
    }
  };

  // 참여취소
  const cancel = async () => {
    try {
      if (nullCheck(challenge.merch)) {
        await UserApi.cancelParticipation(challenge.participationInfo.id);
        toast("챌린지 참여가 취소되었습니다.");
        setIsModal2(false);
        getChallenge();
      } else {
        history.push({
          pathname: route.order_list,
        });
      }
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  // 중도포기
  const giveUp = async () => {
    try {
      await UserApi.giveUpChallenge(challenge.participationInfo.id);
      toast("챌린지 중도포기 요청이 처리되었습니다.");
      setIsModal3(false);
      getChallenge();
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  // 삭제
  const deleteChallengeFunc = async () => {
    try {
      await UserApi.delChallenge(id);
      history.goBack();
      toast(ko.delete);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  // 상황별 포인트
  const point = useMemo(() => {
    if (!nullCheck(challenge.participationInfo)) {
      switch (challenge.participationInfo?.participationStatus) {
        // 시작전
        case "BEFORE":
          return challenge.participationInfo
            ? Number(
                challenge.participationInfo.anticipatePoint
              ).toLocaleString("ko-KR")
            : challenge.rewardUsingPercentage
            ? "구매금액의 " + challenge.rewardPoint + "%"
            : Number(challenge.rewardPoint).toLocaleString("ko-KR");
        // 진행중
        case "PROGRESS":
          return challenge.participationInfo
            ? Number(
                challenge.participationInfo.anticipatePoint
              ).toLocaleString("ko-KR")
            : challenge.rewardUsingPercentage
            ? "구매금액의 " + challenge.rewardPoint + "%"
            : Number(challenge.rewardPoint).toLocaleString("ko-KR");
        // 종료
        case "END":
          return challenge.participationInfo
            ? Number(
                challenge.participationInfo.anticipatePoint
              ).toLocaleString("ko-KR")
            : challenge.rewardUsingPercentage
            ? "구매금액의 " + challenge.rewardPoint + "%"
            : Number(challenge.rewardPoint).toLocaleString("ko-KR");
        // 리뷰 작성 가능
        case "RETIRE":
          return challenge.participationInfo
            ? Number(
                challenge.participationInfo.anticipatePoint
              ).toLocaleString("ko-KR")
            : challenge.rewardUsingPercentage
            ? "구매금액의 " + challenge.rewardPoint + "%"
            : Number(challenge.rewardPoint).toLocaleString("ko-KR");
        // 종료-리뷰작성완료
        case "END_REVIEWED":
          return challenge.participationInfo
            ? Number(challenge.participationInfo.earnPoint).toLocaleString(
                "ko-KR"
              )
            : challenge.rewardUsingPercentage
            ? "구매금액의 " + challenge.rewardPoint + "%"
            : Number(challenge.rewardPoint).toLocaleString("ko-KR");
        // 중도포기-리뷰작성완료
        case "RETIRE_REVIEWED":
          return challenge.participationInfo
            ? Number(challenge.participationInfo.earnPoint).toLocaleString(
                "ko-KR"
              )
            : challenge.rewardUsingPercentage
            ? "구매금액의 " + challenge.rewardPoint + "%"
            : Number(challenge.rewardPoint).toLocaleString("ko-KR");
        default:
          return "";
      }
    } else {
      return challenge.rewardUsingPercentage
        ? "구매금액의 " + challenge.rewardPoint + "%"
        : Number(challenge.rewardPoint).toLocaleString("ko-KR");
    }
  }, [challenge]);

  return (
    <>
      <AppLayout
        style={{
          overflow: isOptionModal ? "hidden" : "",
        }}
      >
        <AppContainer>
          <div
            style={{
              height: "50px",
              paddingLeft: "20px",
              paddingRight: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextBtn
              onClick={() => {
                history.goBack();
              }}
              style={{
                width: 24,
                height: 24,
              }}
            >
              <img
                src="/assets/icons/left_btn.png"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </TextBtn>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {isInfluencer && !challenge.adminCreated ? (
                <TextBtn
                  onClick={() => {
                    setIsMoreBtn(!isMoreBtn);
                  }}
                >
                  <img
                    src="/assets/icons/more.png"
                    style={{
                      height: 24,
                      width: 24,
                    }}
                  />
                </TextBtn>
              ) : (
                <>
                  <TextBtn
                    onClick={() => {
                      storeClickFunc();
                    }}
                    style={{
                      width: 24,
                      height: 24,
                      marginRight: 20,
                    }}
                  >
                    <img
                      src={
                        challenge.isStored
                          ? "/assets/icons/bookmark_black_a.png"
                          : "/assets/icons/bookmark_black_d.png"
                      }
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  </TextBtn>
                  <TextBtn
                    onClick={() => {
                      shareFunc();
                    }}
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  >
                    <img
                      src="/assets/icons/share.png"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  </TextBtn>{" "}
                </>
              )}
              {isMoreBtn ? (
                <div
                  style={{
                    position: "absolute",
                    top: 40,
                    right: 26,
                    minWidth: "120px",
                    borderRadius: "6px",
                    boxShadow: "0px 0px 8px rgba(0,0,0,0.3)",
                    backgroundColor: "#FFFFFF",
                    padding: "10px 14px 10px 14px",
                    zIndex: 9,
                  }}
                >
                  <TextBtn
                    onClick={() => {
                      history.push(route.update_challenge + `/${id}`);
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "600",
                        color: "#262C31",
                        height: "28px",
                        padding: "4px 6px 4px 6px",
                      }}
                    >
                      수정하기
                    </PretendardText>
                  </TextBtn>
                  {/* <TextBtn
                    onClick={() => {
                      setIsModal4(true);
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "600",
                        color: "#262C31",
                        height: "28px",
                        padding: "4px 6px 4px 6px",
                        marginTop: "8px",
                      }}
                    >
                      삭제하기
                    </PretendardText>
                  </TextBtn> */}
                </div>
              ) : null}
            </div>
          </div>

          {/* 배너 */}
          <div
            style={{
              position: "relative",
            }}
          >
            <Swiper
              style={{
                width: isMobile ? "100vw" : "100%",
                height: "100%",
              }}
              slidesPerView={1}
              centeredSlides={true}
              spaceBetween={0}
              onSlideChange={(swiper) => {
                setActiveIndex(swiper.activeIndex + 1);
              }}
            >
              {shortFormList?.length > 0 ? (
                shortFormList?.map((item, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      style={{
                        width: "100%",
                        height: isMobile ? "75vh" : "664px",
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <SwiperContain
                        fileUrl={item.fileUrl}
                        thumbFileUrl={item.thumbFileUrl}
                        index={index}
                        activeIndex={activeIndex}
                      />
                    </SwiperSlide>
                  );
                })
              ) : (
                <SwiperSlide
                  style={{
                    width: "100%",
                    height: "376px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src="/assets/images/image_default.png"
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </SwiperSlide>
              )}
            </Swiper>

            <div
              style={{
                position: "absolute",
                padding: "2px 6px",
                background: "#778088",
                borderRadius: 6,
                zIndex: "1",
                bottom: 20,
                left: 20,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  fontWeight: 700,
                  color: "white",
                }}
              >
                {challenge.challengeStatusTitle}
              </PretendardText>
            </div>

            <div
              style={{
                position: "absolute",
                padding: "5px 8px",
                background: "rgba(0,0,0,0.7)",
                borderRadius: 6,
                zIndex: "1",
                bottom: 20,
                left: 72,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 11,
                  lineHeight: "14px",
                  fontWeight: 600,
                  color: "white",
                }}
              >
                {challenge.challengeTypeTitle}
              </PretendardText>
            </div>

            <PretendardText
              style={{
                borderRadius: "23px",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: 20,
                padding: "4px 14px 4px 14px",

                right: 20,
                zIndex: "1",

                fontSize: 13,
                lineHeight: "18.75px",
                fontWeight: 600,
                textAlign: "center",
                color: "#ffffff",
              }}
            >
              {(shortFormList?.length > 0 ? activeIndex : 0) +
                "/" +
                shortFormList?.length}
            </PretendardText>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "24px 20px 30px 20px",
              width: isMobile ? "100vw" : "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 11,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  fontWeight: 700,
                  color: "#262C31",
                  marginRight: 7,
                }}
              >
                {challenge.challengeCategoryTitle}
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  fontWeight: 700,
                  color: "#262C31",
                  marginRight: 7,
                }}
              >
                {challenge.challengePeriodTitle}
              </PretendardText>
              {challenge.challengeStatus === "BEFORE" && dDay > 0 ? (
                <PretendardText
                  style={{
                    fontSize: 13,
                    lineHeight: "18.75px",
                    fontWeight: 700,
                    color: "#5ECECE",
                  }}
                >
                  {`D-${dDay}`}
                </PretendardText>
              ) : null}
            </div>

            <StyledTextTitle>{challenge.title}</StyledTextTitle>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 11,
                marginBottom: 22,
              }}
            >
              {challenge.tags?.split(",").map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      padding: "2px 7px",
                      background: "rgba(173, 180, 186, 0.2)",
                      borderRadius: 4,
                      marginRight: 4,
                      marginBottom: 4,
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 12,
                        lineHeight: "16.8px",
                        fontWeight: 600,
                        color: "#778088",
                      }}
                    >
                      {item}
                    </PretendardText>
                  </div>
                );
              })}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 8,
              }}
            >
              <PretendardText
                style={{
                  width: 62,
                  fontSize: 14,
                  lineHeight: "20px",
                  fontWeight: 600,
                  color: "#778088",
                }}
              >
                진행일자
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "22.96px",
                  fontWeight: 600,
                  color: "#262C31",
                }}
              >
                {moment(challenge.startDate).format("YYYY.MM.DD")}~
                {moment(challenge.endDate).format("YYYY.MM.DD")}
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 8,
              }}
            >
              <PretendardText
                style={{
                  width: 62,
                  fontSize: 14,
                  lineHeight: "20px",
                  fontWeight: 600,
                  color: "#778088",
                }}
              >
                모집기간
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "22.96px",
                  fontWeight: 600,
                  color: "#262C31",
                }}
              >
                {moment(challenge.createdAt).format("YYYY.MM.DD")}~
                {moment(challenge.startDate)
                  .subtract(1, "days")
                  .format("YYYY.MM.DD")}
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 12.5,
              }}
            >
              <PretendardText
                style={{
                  width: 62,
                  fontSize: 14,
                  lineHeight: "20px",
                  fontWeight: 600,
                  color: "#778088",
                }}
              >
                참가자 수
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "22.96px",
                  fontWeight: 600,
                  color: "#262C31",
                }}
              >
                {challenge.participantCount}
              </PretendardText>
            </div>

            {sessionStorage.getItem("memberType") === "COMMON" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 12.5,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "20px",
                    fontWeight: 600,
                    color: "#778088",
                    marginRight: "10px",
                  }}
                >
                  {challenge.participationInfo?.participationStatus ===
                    "END_REVIEWED" ||
                  challenge.participationInfo?.participationStatus ===
                    "RETIRE_REVIEWED"
                    ? "획득 포인트"
                    : "예상 적립 포인트"}
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "22.96px",
                    fontWeight: 600,
                    color: "#262C31",
                  }}
                >
                  {point}
                </PretendardText>
              </div>
            ) : null}

            <TextBtn
              onClick={() => {
                history.push({
                  pathname: route.challenge_review_list,
                  state: {
                    challengeId: challenge.id,
                  },
                });
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 21,
              }}
            >
              <img
                src="/assets/icons/star.png"
                style={{
                  height: 24,
                  width: 24,
                  marginRight: 1,
                }}
              />
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "20px",
                  fontWeight: 600,
                  color: "#000000",
                }}
              >
                {challenge.reviewAvg ? challenge.reviewAvg : 0}(
                {challenge.reviewCount})
              </PretendardText>
              <img
                src="/assets/icons/right_arrow_gray.png"
                style={{
                  height: 16,
                  width: 16,
                  marginLeft: 1,
                  marginTop: 2,
                }}
              />
            </TextBtn>

            <div
              style={{
                background: "rgba(14, 149, 204, 0.08)",
                borderRadius: 12,
                padding: "19.73px 13px 18.61px 13px",
              }}
            >
              <img
                src="/assets/icons/reward_guide.png"
                style={{
                  height: 35,
                  width: 51.04,
                }}
              />
              <img
                src="/assets/icons/text01.svg"
                style={{
                  height: "32.65px",
                  width: "194.76px",
                  marginLeft: 8.23,
                }}
              />
            </div>
          </div>

          <Division />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "24px 20px",
              width: isMobile ? "100vw" : "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TextBtn
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onClick={() => {
                  history.push({
                    pathname:
                      route.influencer_profile +
                      `/${challenge.influencerInfo?.id}`,
                  });
                }}
              >
                <img
                  src={
                    challenge.influencerInfo?.fileUrl
                      ? challenge.influencerInfo?.fileUrl
                      : "/assets/images/profile_temp.png"
                  }
                  style={{
                    height: "32px",
                    width: "32px",
                    marginRight: 8,
                    borderRadius: "24px",
                  }}
                />
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "22.96px",
                    fontWeight: 600,
                    color: "#262C31",
                  }}
                >
                  {challenge.influencerInfo?.nickName}
                </PretendardText>
              </TextBtn>

              <TextBtn
                onClick={() => {
                  history.push({
                    pathname: route.short_form,
                    state: {
                      id: `${challenge.influencerInfo?.id}`,
                      challengeId: `${id}`,
                    },
                  });
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 15,
                    lineHeight: "22.25px",
                    fontWeight: 600,
                    color: "#262C31",
                    marginRight: 6,
                  }}
                >
                  숏폼 영상보기
                </PretendardText>
                <img
                  src="/assets/icons/sf_play.png"
                  style={{
                    height: "16px",
                    width: "16px",
                  }}
                />
              </TextBtn>
            </div>

            {shortFormList.length > 0 ? (
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "22.25px",
                  fontWeight: 600,
                  color: "#5A636A",
                  marginBottom: 8,
                  marginTop: 14,
                  whiteSpace: "pre-wrap",
                }}
              >
                {shortFormList.length > 0
                  ? shortFormList[activeIndex - 1].contents
                  : ""}
              </PretendardText>
            ) : null}
          </div>

          <Division />

          <div
            style={{
              padding: "36px 0px",
              display: "flex",
              flexDirection: "column",
              width: isMobile ? "100vw" : "100%",
            }}
          >
            <div
              style={{
                padding: "0px 20px",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  lineHeight: "22.96px",
                  color: "#262C31",
                  marginBottom: 16,
                }}
              >
                참가자 인증내역
              </PretendardText>

              {challengeReviewList.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 16,
                  }}
                >
                  <img
                    src="/assets/icons/fire.png"
                    style={{
                      height: "16px",
                      width: "16px",
                      marginRight: 8,
                    }}
                  />
                  <PretendardText
                    style={{
                      fontSize: 15,
                      fontWeight: 700,
                      lineHeight: "20.3px",
                      color: "#778088",
                    }}
                  >
                    지금까지
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: 15,
                      fontWeight: 700,
                      lineHeight: "20.3px",
                      color: "#FF3333",
                    }}
                  >
                    &nbsp;
                    {challenge.totalParticipantCount
                      ? challenge.totalParticipantCount
                      : 0}
                    명
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: 15,
                      fontWeight: 700,
                      lineHeight: "20.3px",
                      color: "#778088",
                    }}
                  >
                    이 참가했어요!
                  </PretendardText>
                </div>
              ) : null}
            </div>

            {challengeReviewList.length > 0 ? (
              <ScrollContainer className="scroll-container">
                <div
                  style={{
                    marginBottom: "14px",
                    display: "flex",
                    width: isMobile ? "100vw" : "100%",
                  }}
                >
                  {challengeReviewList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          marginLeft: index === 0 ? "20px" : "12px",
                          paddingRight:
                            challengeReviewList.length === index + 1
                              ? "20px"
                              : "0px",
                        }}
                      >
                        <div
                          style={{
                            width: "75px",
                            height: "75px",
                            borderRadius: "8px",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={
                              videoExtension.includes(
                                getExtensionOfFilename(item.fileUrl)
                              )
                                ? item.thumbFileUrl
                                : item.fileUrl
                            }
                            alt=""
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </ScrollContainer>
            ) : (
              <Empty
                text={"아직 인증내역이 없어요"}
                style={{ margin: "61px 102px" }}
              />
            )}

            {challengeReviewList.length > 0 ? (
              <div
                style={{
                  padding: "0px 20px",
                }}
              >
                <GrayBoldBtn02
                  onClick={() => {
                    history.push({
                      pathname: route.entry_certification + `/${id}`,
                    });
                  }}
                  style={{
                    height: 45,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 15,
                      fontWeight: 700,
                      lineHeight: "20.3px",
                      color: "#262C31",
                    }}
                  >
                    참가자 인증내역 보기
                  </PretendardText>
                </GrayBoldBtn02>
              </div>
            ) : null}
          </div>

          <Division />

          <div
            style={{
              padding: detailImageMore
                ? "36px 20px 36px 20px"
                : "36px 20px 76px 20px",
              width: isMobile ? "100vw" : "100%",
            }}
          >
            <PretendardText
              style={{
                fontSize: 16,
                fontWeight: 600,
                lineHeight: "22.96px",
                color: "#262C31",
                marginBottom: 14,
              }}
            >
              챌린지 상세
            </PretendardText>

            <div
              style={{
                position: "relative",
                width: "100%",
                height: detailImageMore ? "100%" : 200,
              }}
            >
              {/* <img
                src={challenge.fileUrl}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              /> */}
              <div
                className="ql-editor"
                style={{
                  width: "100%",
                  height: detailImageMore ? "100%" : 200,
                  overflow: "hidden",
                }}
                dangerouslySetInnerHTML={{
                  __html: challenge.description,
                }}
              />
              {detailImageMore ? null : (
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    zIndex: 2,
                    width: "100%",
                    height: 140,
                    background:
                      "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.531547619047619) 37%, rgba(255,255,255,0.833211) 64%, rgba(255,255,255,1) 100%)",
                  }}
                />
              )}

              {detailImageMore ? null : (
                <GrayBoldBtn02
                  onClick={() => {
                    setDetailImageMore(!detailImageMore);
                  }}
                  style={{
                    position: "absolute",
                    bottom: -40,
                    height: 45,
                    zIndex: 3,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 15,
                      fontWeight: 700,
                      lineHeight: "20.3px",
                      color: "#262C31",
                    }}
                  >
                    더보기
                  </PretendardText>
                </GrayBoldBtn02>
              )}
            </div>
          </div>

          <Division />

          {challenge && challenge.merch ? (
            <div
              style={{
                padding: detailProductMore
                  ? "36px 20px 36px 20px"
                  : "36px 20px 66px 20px",
                width: isMobile ? "100vw" : "100%",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  lineHeight: "22.96px",
                  color: "#262C31",
                  marginBottom: 14,
                }}
              >
                상품 상세
              </PretendardText>

              <div
                style={{
                  position: "relative",
                  width: "100%",
                  overflow: "hidden",
                  height: detailProductMore ? "100%" : 200,
                }}
              >
                <div
                  className="ql-editor"
                  style={{
                    width: "100%",
                    height: detailProductMore ? "100%" : 200,
                    overflow: "hidden",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: challenge.merch?.description,
                  }}
                />

                {detailProductMore ? null : (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      zIndex: 2,
                      width: "100%",
                      height: 140,
                      background:
                        "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.531547619047619) 37%, rgba(255,255,255,0.833211) 64%, rgba(255,255,255,1) 100%)",
                    }}
                  />
                )}

                {detailProductMore ? null : (
                  <GrayBoldBtn02
                    onClick={() => {
                      setDetailProductMore(!detailProductMore);
                    }}
                    style={{
                      position: "absolute",
                      bottom: 0,
                      height: 45,
                      zIndex: 3,
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 15,
                        fontWeight: 700,
                        lineHeight: "20.3px",
                        color: "#262C31",
                      }}
                    >
                      더보기
                    </PretendardText>
                  </GrayBoldBtn02>
                )}
              </div>
            </div>
          ) : null}

          <div
            style={{
              position: "sticky",
              padding: "10px 20px",
              width: "100%",
              background: "#fff",
              bottom: 0,
              left: 0,
              zIndex: 99,
            }}
          >
            {btnTitle.length > 0 ? (
              <MainBtn
                disabled={challenge.payFlag || challenge.refundFlag}
                onClick={() => {
                  btnOnClick();
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 15,
                    lineHeight: "20.3px",
                    color: "white",
                    fontWeight: 700,
                  }}
                >
                  {btnTitle}
                </PretendardText>
              </MainBtn>
            ) : null}
            {/* {(challenge.challengeStatus === "END" ||
              challenge.challengeStatus === "PROGRESS" ||
              myID == challenge?.influencerInfo?.id) &&
              (nullCheck(challenge.participationInfo) ||
                challenge.participationInfo.reviewed === true) ? null : (
              <MainBtn
                disabled={challenge.payFlag}
                onClick={() => {
                  btnOnClick();
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 15,
                    lineHeight: "20.3px",
                    color: "white",
                    fontWeight: 700,
                  }}
                >
                  {btnTitle}
                </PretendardText>
              </MainBtn>
            )} */}
          </div>

          <ProductModal
            isOpen={isOptionModal}
            setIsOptionModal={setIsOptionModal}
            merch={challenge.merch}
            challengeId={id}
          ></ProductModal>

          {isModal ? (
            <ConfirmModal
              title={"챌린지에 참여하시겠습니까?"}
              content={""}
              onClickText={"취소"}
              onCancleText={"참가하기"}
              onClick={() => {
                setIsModal(false);
              }}
              onCancle={() => {
                enterClickFunc();
              }}
            />
          ) : null}

          {isModal2 ? (
            <ConfirmModal
              title={`챌린지를 참여취소하시겠어요?`}
              content={""}
              onClickText={"취소"}
              onCancleText={"참여 취소하기"}
              onClick={() => {
                setIsModal2(false);
              }}
              onCancle={() => {
                cancel();
              }}
            />
          ) : null}

          {isModal3 ? (
            <ConfirmModal
              title={`챌린지를 중도 포기하시겠어요?`}
              content={
                challenge.challengeType === "GROUPBUYING"
                  ? "챌린지를 중도 포기 시 일부 포인트만 지급할 수 있으며, 복구가 불가합니다. 또한, 변심에 의한 중도포기는 상품환불이 불가합니다. 그래도 중도포기하시겠습니까?"
                  : "챌린지 중도 포기 시 일부 포인트만 지급할 수 있으며, 복구가 불가합니다. 그래도 중도 포기하시겠습니까?"
              }
              onClickText={"취소"}
              onCancleText={"중도 포기하기"}
              onClick={() => {
                setIsModal3(false);
              }}
              onCancle={() => {
                giveUp();
              }}
            />
          ) : null}

          {isModal4 ? (
            <ConfirmModal
              title={`챌린지를 삭제하시겠어요?`}
              onClickText={"취소"}
              onCancleText={"삭제하기"}
              onClick={() => {
                setIsModal4(false);
              }}
              onCancle={() => {
                deleteChallengeFunc();
              }}
            />
          ) : null}
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default ChallengeDetail;

const StyledTextTitle = styled(PretendardText).attrs((props) => {})`
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #262c31;

  display: -webkit-box;
  display: -ms-flexbox;
  display: box;

  overflow: hidden;
  vertical-align: top;
  text-overflow: ellipsis;
  word-break: break-all;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const Division = styled.div.attrs((props) => {})`
  width: 100%;
  border: 8px solid #f5f5f5;
`;
