import React, { useState, useEffect } from "react";

import PretendardText from "../../atoms/pretendardText";
import TextBtn from "../../atoms/button/textBtn";

const FilterSection = ({ onClick, data }) => {
  return (
    <TextBtn
      onClick={onClick}
      style={{
        padding: "10px 16px",
        width: "max-content",
        border: data.value ? "1.5px solid #FF3333" : "1.5px solid #ECEDF0",
        borderRadius: 21,
      }}
    >
      <PretendardText
        style={{
          fontSize: 14,
          lineHeight: "20px",
          color: data.value ? "#FF3333" : "#262C31",
          fontWeight: 600,
        }}
      >
        {data.label}
      </PretendardText>
    </TextBtn>
  );
};

export default FilterSection;
