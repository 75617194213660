import React, { useEffect, useState } from "react";
import AppLayout from "../components/atoms/layouts/appLayout";
import AppContainer from "../components/atoms/layouts/appContainer";
import { useHistory, useParams } from "react-router-dom";
import useDisplay from "../hooks/useDisplay";
import PretendardText from "../components/atoms/pretendardText";
import TextBtn from "../components/atoms/button/textBtn";
import CertificationBox from "../components/organisms/certificationBox";
import MainBtn from "../components/atoms/button/mainBtn";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import UserApi from "../api/userApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import { route } from "../routes/route";
import moment from "moment";
import { useInView } from "react-intersection-observer";
import ConfirmModal from "../components/templates/modals/confirmModal";
import { nullCheck } from "../utils/check";
// 무한스크롤 테스트
function MypageCertificationList() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  const [isMoreBtn, setIsMoreBtn] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isModal2, setIsModal2] = useState(false);
  const [isModal3, setIsModal3] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [completeMore, setCompleteMore] = useState(false);

  // 채린지 정보
  const { id } = useParams();
  const [myChallengeInfo, setMyChallengeInfo] = useState({});
  const [challengeInfo, setChallengeInfo] = useState({});
  const [challengeParticipationInfo, setChallengeParticipationInfo] = useState(
    {}
  );

  // init
  const getChallengeInfo = async () => {
    try {
      const response = await UserApi.ChallengeInfo(id);

      const data = response.data.data;
      setChallengeInfo(data.challengeInfo);
      setChallengeParticipationInfo(data.challengeParticipationInfo);
      setMyChallengeInfo({
        anticipatePoint: data.anticipatePoint,
        participantCount: data.participantCount,
        participationStatus: data.participationStatus,
        participationStatusTitle: data.participationStatusTitle,
        requireCount: data.requireCount,
        retireCount: data.retireCount,
        reviewed: data.reviewed,
        todayRequire: data.todayRequire,
      });
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    getChallengeInfo();
  }, []);

  // 인증내역
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [ref, inView] = useInView();
  const [challengeList, setChallengeList] = useState([]);

  // init
  const getChallengeList = async () => {
    try {
      setLoading(true);
      const response = await UserApi.ChallengeReviewList(id, {
        page: currentPage,
        size: 10,
      });
      const data = response.data.data;
      setChallengeList(challengeList.concat(data.content));
      setIsLast(data.last);
      setLoading(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    getChallengeList();
  }, [currentPage]);

  useEffect(() => {
    if (inView && !isLast && !loading) {
      setCurrentPage(currentPage + 1);
      getChallengeList();
    }
  }, [inView, loading]);

  // 중도포기
  const giveUp = async () => {
    try {
      await UserApi.giveUpChallenge(id);

      history.push(route.bottom_myChallenge);
      toast("챌린지 중도포기 요청이 처리되었습니다.");
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  // 참여취소
  const cancel = async () => {
    try {
      if (nullCheck(challengeInfo.merch)) {
        await UserApi.cancelParticipation(id);
        toast("챌린지 참여가 취소되었습니다.");
      } else {
        history.push({
          pathname: route.order_list,
        });
      }
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  // 인증
  const challengeProgressFunc = async () => {
    try {
      if (challengeInfo.challengeStatus === "PROGRESS") {
        if (!myChallengeInfo.todayRequire) {
          toast("오늘 이미 인증을 완료하였습니다");
        } else {
          history.push(route.challenge_certification + `/${id}`);
        }
      } else {
        toast("챌린지 시작 전입니다");
      }
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  return (
    <AppLayout>
      <AppContainer style={{}}>
        {/* 헤더 */}
        <div
          style={{
            height: "50px",
            paddingLeft: "20px",
            paddingRight: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <TextBtn>
            <img
              src="/assets/icons/left_btn.png"
              alt=""
              style={{
                width: "24px",
                height: "24px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.goBack();
              }}
            />
          </TextBtn>
          {challengeInfo.challengeStatus !== "END" ? (
            <TextBtn
              onClick={() => {
                setIsMoreBtn(!isMoreBtn);
              }}
            >
              <img
                src="/assets/icons/more.png"
                style={{
                  height: 24,
                  width: 24,
                }}
              />
            </TextBtn>
          ) : null}
          {isMoreBtn ? (
            <div
              style={{
                position: "absolute",
                bottom: -34,
                right: 26,
                minWidth: "120px",
                borderRadius: "6px",
                boxShadow: "0px 0px 8px rgba(0,0,0,0.3)",
                backgroundColor: "#FFFFFF",
                padding: "10px 14px 10px 14px",
              }}
            >
              {challengeInfo.challengeStatus === "PROGRESS" &&
              !challengeParticipationInfo.retire ? (
                <TextBtn
                  onClick={() => {
                    setIsModal2(true);
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "600",
                      color: "#262C31",
                      height: "28px",
                      padding: "4px 6px 4px 6px",
                    }}
                  >
                    중도포기하기
                  </PretendardText>
                </TextBtn>
              ) : null}

              {challengeInfo.challengeStatus === "BEFORE" ? (
                <TextBtn
                  onClick={() => {
                    setIsModal3(true);
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "600",
                      color: "#262C31",
                      height: "28px",
                      padding: "4px 6px 4px 6px",
                    }}
                  >
                    참여취소
                  </PretendardText>
                </TextBtn>
              ) : null}
            </div>
          ) : (
            ""
          )}
        </div>

        {/* intro */}
        <div
          style={{
            padding: "0px 20px",
          }}
        >
          <div
            style={{
              marginTop: "3px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <PretendardText
                style={{
                  fontSize: "13px",
                  lineHeight: "18.75px",
                  fontWeight: "700",
                  color: "#262C31",
                }}
              >
                {challengeParticipationInfo.retire
                  ? "중도포기"
                  : challengeInfo.challengeStatusTitle}
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: "13px",
                  lineHeight: "18.75px",
                  fontWeight: "700",
                  color: "#5A636A",
                  marginLeft: "7px",
                }}
              >
                {challengeInfo.challengeCategoryTitle}
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: "13px",
                  lineHeight: "18.75px",
                  fontWeight: "700",
                  color: "#5A636A",
                  marginLeft: "7px",
                }}
              >
                {challengeInfo.challengePeriodTitle}
              </PretendardText>
            </div>
            <PretendardText
              style={{
                fontSize: "11px",
                lineHeight: "14px",
                fontWeight: "600",
                color: "#FFFFFF",
                height: "24px",
                minWidth: "86px",
                borderRadius: "6px",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {challengeInfo.challengeTypeTitle + " 챌린지"}
            </PretendardText>
          </div>

          <div
            style={{
              marginTop: "5px",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <TextBtn
              onClick={() => {
                history.push({
                  pathname: route.challenge_detail + `/${challengeInfo.id}`,
                });
              }}
              style={{
                display: "flex",
                alignItems: "end",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "22.25px",
                  color: "#262C31",
                  fontWeight: 600,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  display: "-webkit-box",
                  maxHeight: "44px",
                }}
              >
                {challengeInfo.title}
              </PretendardText>

              <img
                src="/assets/icons/ic_16_arrow_2.png"
                alt=""
                style={{
                  width: "16px",
                  height: "16px",
                  marginBottom: "3px",
                }}
              />
            </TextBtn>
            {sessionStorage.getItem("memberType") === "COMMON" ? (
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "22.25px",
                  color: "#262C31",
                  fontWeight: 600,
                  marginLeft: "35px",
                  minWidth: "98px",
                  textAlign: "right",
                }}
              >
                {myChallengeInfo.participationStatus === "END_REVIEWED" ||
                myChallengeInfo.participationStatus === "RETIRE_REVIEWED"
                  ? "획득 포인트"
                  : "예상 적립 포인트"}
              </PretendardText>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <PretendardText
              style={{
                marginTop: "8px",
                fontSize: 13,
                lineHeight: "18.75px",
                color: "#ADB4BA",
                fontWeight: 600,
              }}
            >
              {moment(challengeInfo.startDate).format("YYYY.MM.DD")}~
              {moment(challengeInfo.endDate).format("YYYY.MM.DD")}
            </PretendardText>
            {sessionStorage.getItem("memberType") === "COMMON" ? (
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "#FF3333",
                  fontWeight: 700,
                  marginLeft: "35px",
                }}
              >
                {myChallengeInfo.participationStatus === "END_REVIEWED" ||
                myChallengeInfo.participationStatus === "RETIRE_REVIEWED"
                  ? Number(challengeParticipationInfo.earnPoint).toLocaleString(
                      "ko-KR"
                    )
                  : Number(myChallengeInfo.anticipatePoint).toLocaleString(
                      "ko-KR"
                    )}
              </PretendardText>
            ) : null}
          </div>
        </div>

        {/* 내 완수율 및 참가 현황 */}
        <div
          style={{
            marginTop: "22px",
            border: "1px solid #DBDDDF",
            paddingTop: "18px",
            paddingLeft: "19px",
            paddingRight: "20px",
            paddingBottom: "18px",
          }}
        >
          <TextBtn
            onClick={() => {
              setCompleteMore(!completeMore);
            }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <PretendardText
              style={{
                fontSize: 15,
                lineHeight: "22.25px",
                color: "#262C31",
                fontWeight: 600,
              }}
            >
              내 완수율 및 참가 현황
            </PretendardText>
            <img
              src="/assets/icons/ic_16_under_arrow_grey.png"
              alt=""
              style={{
                width: "16px",
                height: "16px",
                transform: completeMore ? "rotate(180deg)" : "",
              }}
            />
          </TextBtn>

          {completeMore ? (
            <>
              <div
                style={{
                  display: "flex",
                  marginTop: "21px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 13,
                    lineHeight: "18.75px",
                    color: "#778088",
                    fontWeight: 700,
                  }}
                >
                  내 완수율
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 13,
                    lineHeight: "18.75px",
                    color: "#778088",
                    fontWeight: 700,
                    marginLeft: "99px",
                  }}
                >
                  참가인원
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 12,
                    lineHeight: "16.5px",
                    color: "#778088",
                    fontWeight: 500,
                    marginLeft: "6px",
                  }}
                >
                  {myChallengeInfo.participantCount -
                    myChallengeInfo.retireCount}
                  /{myChallengeInfo.participantCount}
                  (전체)
                </PretendardText>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "17px",
                }}
              >
                <div
                  style={{
                    marginTop: "14px",
                    width: "93px",
                    height: "93px",
                  }}
                >
                  <CircularProgressbarWithChildren
                    value={
                      challengeParticipationInfo.certificationCount &&
                      myChallengeInfo.requireCount
                        ? (
                            (challengeParticipationInfo.certificationCount /
                              myChallengeInfo.requireCount) *
                            100
                          ).toFixed(0)
                        : 0
                    }
                    strokeWidth={10}
                    styles={{
                      path: {
                        stroke: `#5ECECE`,
                      },
                      trail: {
                        stroke: `#F2F4F5`,
                      },
                    }}
                  >
                    <PretendardText
                      style={{
                        border: "1px solid #E6E8EB",
                        borderRadius: "8px",
                        padding: "0px 10px",
                        fontSize: 15,
                        lineHeight: "24.5px",
                        color: "#5ECECE",
                        fontWeight: 600,
                        boxShadow: "0px 4px 13px rgba(0,0,0,0.25)",
                      }}
                    >
                      {challengeParticipationInfo.certificationCount &&
                      myChallengeInfo.requireCount
                        ? (
                            (challengeParticipationInfo.certificationCount /
                              myChallengeInfo.requireCount) *
                            100
                          ).toFixed(0)
                        : 0}
                      %
                    </PretendardText>
                  </CircularProgressbarWithChildren>
                </div>
                <div
                  style={{
                    marginTop: "19px",
                    width: "130px",
                    height: "102px",
                    marginLeft: "44px",
                    display: "flex",
                  }}
                >
                  <PretendardText
                    style={{
                      height: "25px",
                      marginTop: "9px",
                      border: "1px solid #E6E8EB",
                      borderRadius: "8px",
                      padding: "0px 10px",
                      fontSize: 15,
                      lineHeight: "24.5px",
                      color: "#5ECECE",
                      fontWeight: 600,
                      boxShadow: "0px 4px 13px rgba(0,0,0,0.25)",
                    }}
                  >
                    {isNaN(
                      (
                        ((myChallengeInfo.participantCount -
                          myChallengeInfo.retireCount) /
                          myChallengeInfo.participantCount) *
                        100
                      ).toFixed(0)
                    )
                      ? 0
                      : (
                          ((myChallengeInfo.participantCount -
                            myChallengeInfo.retireCount) /
                            myChallengeInfo.participantCount) *
                          100
                        ).toFixed(0)}
                    %
                  </PretendardText>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        height: "77px",
                        width: "11px",
                        borderRadius: "17px",
                        backgroundColor: "#ECEDF0",
                        position: "relative",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0",
                          left: "0",
                          backgroundColor: "#5ECECE",
                          height: `${
                            77 *
                            (
                              (myChallengeInfo.participantCount -
                                myChallengeInfo.retireCount) /
                              myChallengeInfo.participantCount
                            ).toFixed(0)
                          }px`,
                          width: "11px",
                        }}
                      ></div>
                    </div>
                    <PretendardText
                      style={{
                        marginTop: "6px",
                        fontSize: 13,
                        lineHeight: "18.75px",
                        color: "#ADB4BA",
                        fontWeight: 600,
                      }}
                    >
                      진행
                    </PretendardText>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "16px",
                    }}
                  >
                    <div
                      style={{
                        height: "77px",
                        width: "11px",
                        borderRadius: "17px",
                        backgroundColor: "#ECEDF0",
                        position: "relative",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0",
                          left: "0",
                          backgroundColor: "#F4CF00",
                          height: `${
                            77 *
                            (
                              myChallengeInfo.retireCount /
                              myChallengeInfo.participantCount
                            ).toFixed(0)
                          }px`,
                          width: "11px",
                        }}
                      ></div>
                    </div>
                    <PretendardText
                      style={{
                        marginTop: "6px",
                        fontSize: 13,
                        lineHeight: "18.75px",
                        color: "#ADB4BA",
                        fontWeight: 600,
                      }}
                    >
                      포기
                    </PretendardText>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>

        {/* 인증내역 */}
        <div
          style={{
            marginTop: "22px",
          }}
        >
          <PretendardText
            style={{
              fontSize: 15,
              lineHeight: "22.25px",
              color: "#262C31",
              fontWeight: 600,
              marginLeft: "20px",
            }}
          >
            인증내역
          </PretendardText>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: "14px",
            }}
          >
            {challengeInfo.challengeStatus === "END" ? (
              ""
            ) : (
              <TextBtn
                style={{
                  width: isMobile ? "32.7%" : "119px",
                }}
                onClick={() => {
                  challengeProgressFunc();
                }}
              >
                <div
                  style={{
                    marginTop: "3px",
                    position: "relative",
                    width: "100%",
                    height: isMobile ? "119px" : "119px",
                    overflow: "hidden",
                    marginRight: "3px",
                  }}
                >
                  <img
                    src="/assets/icons/input_file_certification.png"
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              </TextBtn>
            )}
            {challengeList.map((item, index) => {
              return (
                <CertificationBox
                  item={item}
                  key={index}
                  isEnd={
                    isMobile
                      ? challengeInfo.challengeStatus === "END"
                        ? index % 3 === 2
                        : index % 3 === 1
                      : challengeInfo.challengeStatus === "END"
                      ? index % 4 === 3
                      : index % 4 === 2
                  }
                ></CertificationBox>
              );
            })}
            <div ref={ref}>{/* loading */}</div>
          </div>
        </div>

        {!myChallengeInfo.reviewed &&
        (challengeInfo.challengeStatus === "END" ||
          challengeParticipationInfo.retire) ? (
          <div
            style={{
              position: "absolute",
              bottom: "20px",
              padding: "0px 20px",
              width: "100%",
            }}
          >
            <MainBtn
              onClick={() => {
                setIsModal(true);
              }}
              style={{}}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                포인트 받기
              </PretendardText>
            </MainBtn>
          </div>
        ) : (
          ""
        )}
        {isModal ? (
          <ConfirmModal
            title={`포인트는 챌린지 리뷰 작성 시 지급이 완료됩니다.`}
            content={""}
            onClickText={"취소"}
            onCancleText={"확인"}
            onClick={() => {
              setIsModal(false);
            }}
            onCancle={() => {
              history.push({
                pathname: route.challenge_review_write,
                state: {
                  id: id,
                },
              });
            }}
          />
        ) : null}

        {isModal2 ? (
          <ConfirmModal
            title={`챌린지를 중도 포기하시겠어요?`}
            content={
              !nullCheck(challengeInfo.merch)
                ? "챌린지를 중도 포기 시 일부 포인트만 지급할 수 있으며, 복구가 불가합니다. 또한, 변심에 의한 중도포기는 상품환불이 불가합니다. 그래도 중도포기하시겠습니까?"
                : "챌린지 중도 포기 시 일부 포인트만 지급할 수 있으며, 복구가 불가합니다. 그래도 중도 포기하시겠습니까?"
            }
            onClickText={"취소"}
            onCancleText={"중도 포기하기"}
            onClick={() => {
              setIsModal2(false);
            }}
            onCancle={async () => {
              await giveUp();
            }}
          />
        ) : null}

        {isModal3 ? (
          <ConfirmModal
            title={`챌린지를 참여취소하시겠어요?`}
            content={""}
            onClickText={"취소"}
            onCancleText={"참여 취소하기"}
            onClick={() => {
              setIsModal3(false);
            }}
            onCancle={async () => {
              await cancel();
            }}
          />
        ) : null}
      </AppContainer>
    </AppLayout>
  );
}

export default MypageCertificationList;
