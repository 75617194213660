import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import PretendardText from "../../atoms/pretendardText";

const Header = (props) => {
  const history = useHistory();

  return (
    <div
      style={{
        width: "100%",
        height: 50,
        paddingLeft: 20,
        paddingRight: 20,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
        ...props.style,
      }}
    >
      <div
        onClick={() => {
          history.goBack();
        }}
        style={{
          position: "absolute",
          left: 20,
          cursor: "pointer",
          width: 24,
          height: 24,
        }}
      >
        <img
          src="/assets/icons/left_btn.png"
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      </div>
      {props.title ? (
        <PretendardText
          style={{
            fontWeight: 600,
            fontSize: 15,
            lineHeight: "22.25px",
            color: "#262C31",
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {props.title}
        </PretendardText>
      ) : null}
    </div>
  );
};

export default Header;
