import React, { useState, useEffect } from "react";

import TextBtn from "../atoms/button/textBtn";

function Tab(props) {
  return (
    <>
      <TextBtn
        onClick={props.onClick}
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: 50,
          borderBottom: props.value ? "2px solid #262C31" : "none",
        }}
      >
        {props.children}
      </TextBtn>
    </>
  );
}

export default Tab;
