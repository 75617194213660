import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import useDisplay from "../../../hooks/useDisplay";
import PretendardText from "../../atoms/pretendardText";
import MainBtn from "../../atoms/button/mainBtn";
import GrayBtn from "../../atoms/button/grayBtn";
import RightLabelRadio from "../../molecules/radio/rightLabelRadio";
import DeliverySRadio from "../../organisms/radio/deliverySRadio";

function DeliveryModal({
  isOpen,
  setIsDeliveryModal,
  deliveryInfo,
  selectedDeliveryInfo,
  setSelectedDeliveryInfo,
  setDeleteModal,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  const [dataList, setDataList] = useState([
    { value: false, label: "원하는 서비스가 없어요." },
    { value: false, label: "헬랄라를 사용하기가 어려워요." },
    { value: false, label: "헬랄라에서 불쾌한 경험을 했어요." },
    { value: false, label: "새로운 계정을 만들고 싶어요." },
    { value: false, label: "기타" },
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const radioClickFunc = (text) => {
    const temp = dataList.map((item) => {
      return item.label === text
        ? {
            value: true,
            label: item.label,
          }
        : {
            value: false,
            label: item.label,
          };
    });

    setDataList(temp);
  };

  return (
    <>
      {isOpen ? (
        <div
          style={{
            position: "absolute",
            left: "0",
            top: "0",
            width: "100%",
            height: "100%",
            zIndex: "9999",
            display: "block",
          }}
        >
          <div
            className="dim"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              background: "rgba(0, 0, 0, 0.7)",
            }}
          ></div>
          <ModalLayoutWrapper isMobile={isMobile}>
            <PretendardText
              style={{
                fontWeight: "600",
                fontSize: "24px",
                lineHeight: "33.6px",
                color: "#262C31",
                marginTop: "8%",
                marginBottom: 3,
              }}
            >
              배송지선택
            </PretendardText>

            <ModalScrollLayoutWrapper isMobile={isMobile}>
              <div
                style={{
                  marginTop: 37,
                }}
              >
                {deliveryInfo.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        marginBottom: 24,
                      }}
                    >
                      <DeliverySRadio
                        item={item}
                        setDeleteModal={setDeleteModal}
                        checked={selectedDeliveryInfo.id === item.id}
                        onClick={() => {
                          setSelectedDeliveryInfo(item);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </ModalScrollLayoutWrapper>
            <div
              style={{
                position: "sticky",
                padding: "10px 0px",
                width: "100%",
                background: "#fff",
                bottom: 0,
                left: 0,
                zIndex: 99,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <GrayBtn
                  onClick={() => {
                    setIsDeliveryModal(false);
                  }}
                  style={{
                    marginRight: 15,
                    background: "#ADB4BA",
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 15,
                      lineHeight: "20.3px",
                      color: "white",
                      fontWeight: 700,
                    }}
                  >
                    취소
                  </PretendardText>
                </GrayBtn>

                <MainBtn
                  onClick={() => {
                    setIsDeliveryModal(false);
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 15,
                      lineHeight: "20.3px",
                      color: "white",
                      fontWeight: 700,
                    }}
                  >
                    등록
                  </PretendardText>
                </MainBtn>
              </div>
            </div>
          </ModalLayoutWrapper>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default DeliveryModal;

const ModalLayoutWrapper = styled.div.attrs((props) => {})`
  position: fixed;
  bottom: 0px;
  background: rgb(255, 255, 255);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: ${(props) => (props.isMobile ? "100vw" : "486px")};
  height: ${(props) => (props.isMobile ? "70%" : "563px")};
  padding-left: 20px;
  padding-right: 20px;
`;

const ModalScrollLayoutWrapper = styled.div.attrs((props) => {})`
  overflow-y: auto;
  height: ${(props) => (props.isMobile ? "100%" : "415px")};
  padding-bottom: ${(props) => (props.isMobile ? "259px" : "20px")};

  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-thumb {
    // background: rgba(220, 20, 60); /* 스크롤바 색상 */
    border-radius: 0px; /* 스크롤바 둥근 테두리 */
  }

  ::-webkit-scrollbar-track {
    // background: rgba(220, 20, 60, 0.1); /*스크롤바 뒷 배경 색상*/
  }
`;
