import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import useDisplay from "../../../hooks/useDisplay";

import PretendardText from "../../atoms/pretendardText";
import TextBtn from "../../atoms/button/textBtn";
import GrayBoldBtn02 from "../../atoms/button/grayBoldBtn02";
import { route } from "../../../routes/route";

const OrderSection = ({
  data,
  style,
  isDetail,
  refundDetail,
  depositRefundConfirm,
}) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [orderStatus, setOrderStatus] = useState("");

  const history = useHistory();

  useEffect(() => {
    switch (data.orderStatus) {
      case "WAITING_PAYMENT":
        setOrderStatus("결제대기");
        break;
      case "COMPLETE_PAYMENT":
        setOrderStatus("결제완료");
        break;
      case "READY_TO_SHIP":
        setOrderStatus("배송준비중");
        break;
      case "SHIPPING_IN_PROGRESS":
        setOrderStatus("배송중");
        break;
      case "DELIVERY_COMPLETED":
        setOrderStatus("배송완료");
        break;
      case "BUYER_REFUND":
        setOrderStatus("구매자 취소/환불 완료");
        break;
      case "BUYER_REFUND_REQUEST":
        setOrderStatus("구매자 취소/환불 요청");
        break;
      case "BUYER_REFUND_REJECTED":
        setOrderStatus("구매자 취소/환불 요청 거부");
        break;
      case "SELLER_CANCEL":
        setOrderStatus("판매자 취소");
        break;
    }
  }, [data]);

  const stateColorFunc = (value) => {
    if (
      value === "구매자 취소/환불 완료" ||
      value === "구매자 취소/환불 요청" ||
      value === "구매자 취소/환불 요청 거부" ||
      value === "판매자 취소"
    ) {
      return "#F24147";
    } else {
      return "#66CCCC";
    }
  };

  const stateBtnFunc = (value) => {
    if (value === "결제대기") {
      return (
        <GrayBoldBtn02
          onClick={() => {
            depositRefundConfirm(data.challengeId);
          }}
          style={{
            marginRight: 15,
          }}
        >
          <StyledBtnText>취소요청</StyledBtnText>
        </GrayBoldBtn02>
      );
    } else if (
      value === "결제완료" ||
      value === "배송준비중" ||
      value === "배송중" ||
      value === "배송완료"
    ) {
      return (
        <GrayBoldBtn02
          onClick={() => {
            history.push({
              pathname: route.refund,
              state: {
                challengeParticipationId: data.challengeParticipationId,
                orderId: data.id,
                orderInfo: data,
              },
            });
          }}
          style={{
            marginRight: 15,
          }}
        >
          <StyledBtnText>취소/환불 요청</StyledBtnText>
        </GrayBoldBtn02>
      );
    } else if (value === "구매자 취소/환불 요청") {
      return (
        <GrayBoldBtn02
          style={{
            marginRight: 15,
            background: "#ECEDF0",
            borderColor: "#ECEDF0",
          }}
          disabled={true}
        >
          <PretendardText
            style={{
              fontSize: 15,
              lineHeight: "20.3px",
              color: "#ADB4BA",
              fontWeight: 700,
            }}
          >
            취소/환불 요청 확인중
          </PretendardText>
        </GrayBoldBtn02>
      );
    }
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "0px 20px 36px 20px",
        display: "flex",
        flexDirection: "column",
        ...style,
      }}
    >
      <PretendardText
        style={{
          fontSize: 16,
          lineHeight: "22.96px",
          color: "#262C31",
          fontWeight: 600,
          marginBottom: 12,
        }}
      >
        {data.merchName}
      </PretendardText>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 19,
        }}
      >
        <img
          src={data.merchImageUrl}
          style={{
            height: 70,
            width: 70,
            borderRadius: 8,
            marginRight: 10,
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 7,
            }}
          >
            <StyledTextLeft>주문번호</StyledTextLeft>
            <StyledTextRight isMobile={isMobile}>
              {data.orderNum}
            </StyledTextRight>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 7,
            }}
          >
            <StyledTextLeft>챌린지명</StyledTextLeft>
            <StyledTextRight isMobile={isMobile}>
              {data.challengeTitle}
            </StyledTextRight>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 7,
            }}
          >
            <StyledTextLeft>주문금액</StyledTextLeft>
            <StyledTextRight isMobile={isMobile}>
              {Number(
                data.totalPrice +
                  data.shippingFee -
                  data.redeemPoints -
                  data.discountAmount
              ).toLocaleString("ko-KR")}
              원
            </StyledTextRight>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: data.deliveryCompany ? 7 : 0,
            }}
          >
            <StyledTextLeft>주문상태</StyledTextLeft>
            <div>
              <StyledTextRight
                style={{
                  color: stateColorFunc(orderStatus),
                }}
                isMobile={isMobile}
              >
                {orderStatus}
              </StyledTextRight>
              {data.buyerRefundRejectReason ? (
                refundDetail ? (
                  <PretendardText
                    style={{
                      fontSize: 14,
                      lineHeight: "21.5px",
                      color: "rgb(38, 44, 49)",
                      fontWeight: 500,
                    }}
                  >
                    사유 : {data.buyerRefundRejectReason}
                  </PretendardText>
                ) : (
                  <StyledTextRight
                    style={{
                      color: "#262C31",
                    }}
                    isMobile={isMobile}
                  >
                    사유 : {data.buyerRefundRejectReason}
                  </StyledTextRight>
                )
              ) : null}

              {data.sellerCancelReason ? (
                refundDetail ? (
                  <PretendardText
                    style={{
                      fontSize: 14,
                      lineHeight: "21.5px",
                      color: "rgb(38, 44, 49)",
                      fontWeight: 500,
                    }}
                  >
                    사유 : {data.sellerCancelReason}
                  </PretendardText>
                ) : (
                  <StyledTextRight
                    style={{
                      color: "#262C31",
                    }}
                    isMobile={isMobile}
                  >
                    사유 : {data.sellerCancelReason}
                  </StyledTextRight>
                )
              ) : null}
            </div>
          </div>
          {data.deliveryCompany ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: data.shippingNumber ? 7 : 0,
              }}
            >
              <StyledTextLeft>택배사</StyledTextLeft>
              <StyledTextRight isMobile={isMobile}>
                {data.deliveryCompany}
              </StyledTextRight>
            </div>
          ) : null}

          {data.shippingNumber ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <StyledTextLeft>송장번호</StyledTextLeft>
              <StyledTextRight isMobile={isMobile}>
                {data.shippingNumber}
              </StyledTextRight>
            </div>
          ) : null}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {stateBtnFunc(orderStatus)}

        {isDetail ? null : (
          <GrayBoldBtn02
            onClick={() => {
              history.push({
                pathname: route.order_detail,
                state: {
                  id: data.id,
                },
              });
            }}
          >
            <StyledBtnText>주문상세보기</StyledBtnText>
          </GrayBoldBtn02>
        )}
      </div>
    </div>
  );
};

export default OrderSection;

const StyledTextLeft = styled(PretendardText).attrs((props) => {})`
  font-size: 14px;
  line-height: 21.5px;
  color: #778088;
  font-weight: 500;
  min-width: 49px;
  margin-right: 9px;
`;

const StyledTextRight = styled(PretendardText).attrs((props) => {})`
  font-size: 14px;
  line-height: 21.5px;
  color: #262c31;
  font-weight: 500;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;

  width: ${(props) => (props.isMobile ? "184px" : "280px")};
`;

const StyledBtnText = styled(PretendardText).attrs((props) => {})`
  font-size: 15px;
  line-height: 22.25px;
  color: #262c31;
  font-weight: 600;
`;
