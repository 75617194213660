import React, { useEffect, useState, useRef } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import useDisplay from "../hooks/useDisplay";
import AppLayout from "../components/atoms/layouts/appLayout";
import AppContainer from "../components/atoms/layouts/appContainer";
import PretendardText from "../components/atoms/pretendardText";
import TextArea from "antd/lib/input/TextArea";
import TextBtn from "../components/atoms/button/textBtn";
import Input from "../components/atoms/input/input";
import InputReview from "../components/atoms/input/inputReview";
import CommentBox from "../components/organisms/commentBox";
import ReplyBox from "../components/organisms/replyBox";
import ChallengeApi from "../api/challengeApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import { route } from "../routes/route";
import moment from "moment";
import { useInView } from "react-intersection-observer";
import ConfirmModal from "../components/templates/modals/confirmModal";
import UserApi from "../api/userApi";
import { getExtensionOfFilename, videoExtension } from "../utils/file";
import { shareFunc } from "../utils/share";
import { nullCheck } from "../utils/check";

function MyPageCertificationDetail() {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [hover, setHover] = useState(false);
  const [playClick, setPlayClick] = useState(false);
  const [playTime, setPlayTime] = useState("00:00");
  const [myContent, setMyContent] = useState(false);
  const [isMoreBtn, setIsMoreBtn] = useState(false);
  const [isModal4, setIsModal4] = useState(false);

  const numbers = [1, 2];
  const writeCommentFunc = (commentId) => {
    const inputReview = document.getElementById("inputReview");
    inputReview.focus();
    setSelectedCommentId(commentId);
  };

  // 챌린지 인증 상세
  const { id } = useParams();
  const [certificationInfo, setCertificationInfo] = useState({});

  // init
  const getCertificationInfo = async () => {
    try {
      const response = await ChallengeApi.CertificationInfo(id);
      const data = response.data.data;

      setCertificationInfo(data);
      setMyContent(
        data.memberInfo?.id === Number(sessionStorage.getItem("id"))
      );
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    getCertificationInfo();
  }, []);

  // 댓글
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [ref, inView] = useInView();
  const [commentList, setCommentList] = useState([]);
  // init
  const getCommentList = async () => {
    try {
      const response = await ChallengeApi.CommentList({
        commentType: "CERTIFICATION",
        objectId: id,
        page: currentPage,
        size: 1000,
      });
      const data = response.data.data;
      setCommentList(data.content);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    getCommentList();
  }, []);

  // 댓글등록
  const [comment, setComment] = useState("");
  const commentCreate = async () => {
    try {
      const formData = new FormData();
      formData.append("commentType", "CERTIFICATION");
      formData.append("objectId", id);
      formData.append("contents", comment);
      await ChallengeApi.CommentCreate(formData);
      getCommentList();
      getCertificationInfo();
      setComment("");
      toast("등록되었습니다");
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  // 대댓글등록
  const [selectedCommentId, setSelectedCommentId] = useState(0);
  const [reply, setReply] = useState("");
  const replyCreate = async () => {
    try {
      const formData = new FormData();
      formData.append("commentId", selectedCommentId);
      formData.append("contents", reply);
      await ChallengeApi.ReplyCreate(formData);
      getCommentList();
      getCertificationInfo();
      setSelectedCommentId(0);
      setReply("");
      toast("등록되었습니다");
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  // 댓글 수정 및 삭제
  const [updateCommentId, setUpdateCommentId] = useState(0);
  const [updateComment, setUpdateComment] = useState("");

  const commentUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("contents ", updateComment);
      await ChallengeApi.CommentUpdate(updateCommentId, formData);
      getCommentList();
      getCertificationInfo();
      setUpdateCommentId(0);
      setUpdateComment("");

      setOpenFlag({
        type: "",
        id: 0,
      });
      toast("수정되었습니다");
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const [deleteCommentId, setDeleteCommentId] = useState(0);
  const commentDelete = async () => {
    try {
      await ChallengeApi.CommentDelete(deleteCommentId);
      getCommentList();
      getCertificationInfo();
      setUpdateCommentId(0);

      setOpenFlag({
        type: "",
        id: 0,
      });
      toast("삭제되었습니다");
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  // 대댓글 수정 및 삭제
  const [updateReplyId, setUpdateReplyId] = useState(0);
  const [updateReply, setUpdateReply] = useState("");
  const [replyListP, setReplyListP] = useState([]);

  const replyUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("contents ", updateReply);
      await ChallengeApi.ReplyUpdate(updateReplyId, formData);
      getCommentList();
      getCertificationInfo();
      setUpdateReplyId(0);
      setUpdateReply("");

      setOpenFlag({
        type: "",
        id: 0,
      });
      toast("수정되었습니다");
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const [deleteReplyId, setDeleteReplyId] = useState(0);
  const replyDelete = async () => {
    try {
      await ChallengeApi.ReplyDelete(deleteReplyId);
      getCommentList();
      getCertificationInfo();
      setUpdateReplyId(0);

      setOpenFlag({
        type: "",
        id: 0,
      });
      toast("삭제되었습니다");
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  // 수정, 삭제 태그
  const [openFlag, setOpenFlag] = useState({
    type: "",
    id: 0,
  });

  // 인증 저장
  const storeClickFunc = async () => {
    try {
      const formData = new FormData();
      formData.append("isCreate", !certificationInfo.isStored);
      formData.append("objectId", id);
      formData.append("storeType", "CERTIFICATION");

      await UserApi.putStore(formData);
      certificationInfo.isStored = !certificationInfo.isStored;
      setCertificationInfo({ ...certificationInfo });
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  // 인증 좋아요
  const likeClickFunc = async () => {
    try {
      const formData = new FormData();
      formData.append("isCreate", !certificationInfo.isLiked);
      formData.append("objectId", id);
      formData.append("likeType", "CERTIFICATION");

      await UserApi.putLike(formData);
      certificationInfo.isLiked = !certificationInfo.isLiked;
      if (!certificationInfo.isLiked) {
        certificationInfo.likeAmount -= 1;
      } else {
        certificationInfo.likeAmount += 1;
      }
      setCertificationInfo({ ...certificationInfo });
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      let timeTemp = setTotalTime();

      setPlayTime(timeTemp);
    }, 500);
  }, [certificationInfo]);

  // 초를 시,분,초로 변환
  const formatDate = (seconds) => {
    const secondsNumber = parseInt(seconds, 10);
    let hours = Math.floor(secondsNumber / 3600);
    let minutes = Math.floor((secondsNumber - hours * 3600) / 60);
    let totalSeconds = secondsNumber - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (totalSeconds < 10) {
      totalSeconds = `0${totalSeconds}`;
    }

    if (hours == "00") {
      return `${isNaN(minutes) ? "00" : minutes}:${
        isNaN(totalSeconds) ? "00" : totalSeconds
      }`;
    } else {
      return `${isNaN(hours) ? "00" : hours}:${
        isNaN(minutes) ? "00" : minutes
      }:${isNaN(totalSeconds) ? "00" : totalSeconds}`;
    }
  };

  // 비디오 전체 길이를 가져오는 함수
  const setTotalTime = () => {
    // 비디오 링크를 클릭하여 해당 비디오 페이지에 들어왔을때
    const videoContainer = document.getElementById("video");

    if (videoContainer) {
      let durationTemp = videoContainer.duration;
      return formatDate(durationTemp);
    } else {
      return "00:00";
    }
  };

  // 비디오 전체 길이를 가져오는 함수

  useEffect(() => {
    if (updateCommentId === 0 && updateReplyId === 0) {
    } else {
      if (updateCommentId === 0) {
        // 대댓글수정 시
        setUpdateReply(
          replyListP.find((item) => item.id === updateReplyId).contents
        );
      } else {
        // 댓글수정 시
        setUpdateComment(
          commentList.find((item) => item.id === updateCommentId).contents
        );
      }
    }
  }, [
    updateCommentId,
    updateReplyId,
    selectedCommentId,
    selectedCommentId,
    updateCommentId,
  ]);

  // 삭제
  const deleteFunc = async () => {
    try {
      await UserApi.delCertification(id);
      history.goBack();
      toast(ko.delete);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const layout_scroll = useRef();
  const [isReviewShow, setIsReviewShow] = useState(false);
  return (
    <>
      <AppLayout
        style={{}}
        ref={layout_scroll}
        onScroll={() => {
          if (layout_scroll.current.scrollTop > 100) {
            setIsReviewShow(true);
          } else {
            setIsReviewShow(false);
          }
        }}
      >
        <AppContainer
          style={{
            paddingBottom: "24px",
          }}
        >
          {certificationInfo?.fileUrl ? (
            <video
              controls={false}
              autoPlay={false}
              muted
              playsInline
              style={{
                width: "0%",
                height: "0%",
                display: "none",
                objectFit: "cover",
              }}
              id="video"
            >
              <source src={certificationInfo?.fileUrl}></source>
            </video>
          ) : null}

          {/* 영상 */}
          <div
            style={{
              height: isMobile ? "75vh" : "664px",
              backgroundColor: "grey",
              position: "relative",
            }}
          >
            {playClick ? null : !videoExtension.includes(
                getExtensionOfFilename(certificationInfo.fileUrl)
              ) ? null : (
              <img
                src={"/assets/images/dim.png"}
                alt=""
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
              />
            )}

            {playClick ? null : !videoExtension.includes(
                getExtensionOfFilename(certificationInfo.fileUrl)
              ) ? null : (
              <div
                style={{
                  position: "absolute",
                  borderRadius: 23,
                  padding: "5px 8px 5px 8px",
                  bottom: 20,
                  right: 20,
                  background: "rgba(0,0,0,0.5)",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "11px",
                    lineHeight: "14px",
                    fontWeight: "600",
                  }}
                >
                  {playTime}
                </PretendardText>
              </div>
            )}

            {/* <Video src={'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'}></Video> */}
            {!videoExtension.includes(
              getExtensionOfFilename(certificationInfo.fileUrl)
            ) ? (
              <img
                src={certificationInfo?.fileUrl}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : playClick ? (
              <video
                controls={true}
                autoPlay={true}
                muted
                playsInline
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                id="video"
              >
                <source src={certificationInfo?.fileUrl}></source>
              </video>
            ) : (
              <img
                src={certificationInfo?.thumbFileUrl}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            )}

            {playClick ? null : !videoExtension.includes(
                getExtensionOfFilename(certificationInfo.fileUrl)
              ) ? null : (
              <img
                onClick={() => {
                  setPlayClick(true);
                }}
                src={"/assets/images/play.png"}
                alt="play"
                style={{
                  width: 64,
                  height: 64,
                  position: "absolute",
                  zIndex: 99,
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  cursor: "pointer",
                }}
              />
            )}

            <div
              style={{
                width: isMobile ? "96%" : "100%",
                height: "50px",
                paddingLeft: "20px",
                paddingRight: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                position: "absolute",
                top: isMobile ? "20px" : "0",
              }}
            >
              <TextBtn>
                <img
                  src="/assets/icons/ic_24_left_arrow_white.svg"
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </TextBtn>
              {myContent ? (
                <TextBtn
                  onClick={() => {
                    setIsMoreBtn(!isMoreBtn);
                  }}
                >
                  <img
                    src="/assets/icons/ic_24_more_white.png"
                    style={{
                      height: 24,
                      width: 24,
                    }}
                  />
                </TextBtn>
              ) : (
                <TextBtn
                  onClick={() => {
                    storeClickFunc();
                  }}
                >
                  <img
                    src={
                      certificationInfo.isStored
                        ? "/assets/icons/bookmark_white.png"
                        : "/assets/icons/bookmark_black_d.png"
                    }
                    alt=""
                    style={{
                      cursor: "pointer",
                      width: "24px",
                      height: "24px",
                      filter: certificationInfo.isStored
                        ? ""
                        : "invert(100%) sepia(0%) saturate(7500%) hue-rotate(167deg) brightness(108%) contrast(102%)",
                    }}
                  />
                </TextBtn>
              )}
              {isMoreBtn ? (
                <div
                  style={{
                    position: "absolute",
                    top: 40,
                    right: 26,
                    minWidth: "120px",
                    borderRadius: "6px",
                    boxShadow: "0px 0px 8px rgba(0,0,0,0.3)",
                    backgroundColor: "#FFFFFF",
                    padding: "10px 14px 10px 14px",
                    zIndex: 9,
                  }}
                >
                  <TextBtn
                    onClick={() => {
                      history.push({
                        pathname:
                          route.challenge_certification +
                          `/${certificationInfo.challengeParticipationId}`,
                        state: {
                          certificationId: certificationInfo.id,
                        },
                      });
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "600",
                        color: "#262C31",
                        height: "28px",
                        padding: "4px 6px 4px 6px",
                      }}
                    >
                      수정하기
                    </PretendardText>
                  </TextBtn>
                  <TextBtn
                    onClick={() => {
                      setIsModal4(true);
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "600",
                        color: "#262C31",
                        height: "28px",
                        padding: "4px 6px 4px 6px",
                        marginTop: "8px",
                      }}
                    >
                      삭제하기
                    </PretendardText>
                  </TextBtn>
                </div>
              ) : null}
            </div>
            {/* {hover ? <div style={{
                            position: "absolute",
                            left: '42.6%',
                            top: '44.2%',
                        }}
                            onMouseEnter={() => setHover(true)} // 마우스엔터(호버)시 키값이 저장된다
                            onMouseLeave={() => setHover(false)} // 마우스리브 시에는 키값이 지워진다
                            onClick={() => {
                                // history.push({
                                //     pathname: "/short_form_detail",
                                // });
                            }}
                        >
                            <img src={false ? "/assets/icons/ic_64_play.png" : '/assets/icons/ic_64_pause.png'}
                                alt=""
                                style={{
                                    width: "64px",
                                    height: "64px",
                                    cursor: 'pointer',
                                }}
                            />
                        </div> : ''} */}
          </div>

          {/* 내용 */}
          <div
            style={{
              marginTop: "25px",
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingBottom: "32px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    border: "2px solid rgba(255, 255, 255, 1)",
                    overflow: "hidden",
                    marginRight: "10px",
                  }}
                >
                  <TextBtn
                    onClick={() => {
                      if (
                        certificationInfo.memberInfo?.userType === "INFLUENCER"
                      ) {
                        history.push({
                          pathname:
                            route.influencer_profile +
                            `/${certificationInfo.memberInfo?.id}`,
                        });
                      } else {
                        history.push(
                          route.general_user_profile +
                            `/${certificationInfo.memberInfo?.id}`
                        );
                      }
                    }}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={
                        certificationInfo.memberInfo?.fileUrl
                          ? certificationInfo.memberInfo?.fileUrl
                          : "/assets/images/profile_temp.png"
                      }
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </TextBtn>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <TextBtn
                      onClick={() => {
                        if (
                          certificationInfo.memberInfo?.userType ===
                          "INFLUENCER"
                        ) {
                          history.push({
                            pathname:
                              route.influencer_profile +
                              `/${certificationInfo.memberInfo?.id}`,
                          });
                        } else {
                          history.push(
                            route.general_user_profile +
                              `/${certificationInfo.memberInfo?.id}`
                          );
                        }
                      }}
                    >
                      <PretendardText
                        style={{
                          color: "rgba(38, 44, 49, 1)",
                          fontSize: "14px",
                          lineHeight: "20px",
                          fontWeight: "600",
                        }}
                      >
                        {certificationInfo.memberInfo?.nickName}
                      </PretendardText>
                      {certificationInfo.memberInfo?.nickName ===
                      "INFLUENCER" ? (
                        <img
                          src="/assets/icons/card_checkstar.svg"
                          alt=""
                          style={{
                            width: "17px",
                            height: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      ) : null}
                    </TextBtn>
                  </div>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <PretendardText
                      style={{
                        color: "#9D56FB",
                        fontSize: "12px",
                        lineHeight: "16.5px",
                        fontWeight: "500",
                      }}
                    >
                      인증
                    </PretendardText>
                    <PretendardText
                      style={{
                        color: "#262C31",
                        fontSize: "12px",
                        lineHeight: "16.5px",
                        fontWeight: "500",
                        marginLeft: "5px",
                      }}
                    >
                      {moment(certificationInfo.createdAt).format(
                        "YYYY.MM.DD A hh:mm"
                      )}
                    </PretendardText>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <TextBtn
                  onClick={() => {
                    history.push({
                      pathname: route.report_write,
                      state: {
                        id: certificationInfo.id,
                        reportType: "CERTIFICATION",
                      },
                    });
                  }}
                >
                  <img
                    src="/assets/icons/ic_24_warning.png"
                    alt=""
                    style={{
                      width: "24px",
                      height: "24px",
                      cursor: "pointer",
                      marginLeft: "20px",
                    }}
                  />
                </TextBtn>
                <TextBtn
                  onClick={() => {
                    shareFunc();
                  }}
                >
                  <img
                    src="/assets/icons/ic_24_share.png"
                    alt=""
                    style={{
                      width: "24px",
                      height: "24px",
                      cursor: "pointer",
                      marginLeft: "19px",
                    }}
                  />
                </TextBtn>
              </div>
            </div>
            <PretendardText
              style={{
                fontSize: 13,
                lineHeight: "18.75px",
                fontWeight: 700,
                color: "#ffffff",
                textAlign: "center",
                marginTop: "27px",
                borderRadius: "6px",
                width: "46px",
                height: "23px",
                backgroundColor:
                  certificationInfo.challengeInfo?.challengeStatus !== "END"
                    ? "rgba(255, 51, 51, 1)"
                    : "rgba(38, 44, 49, 1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {certificationInfo.challengeInfo?.challengeStatusTitle}
            </PretendardText>
            <TextBtn
              onClick={() => {
                history.push({
                  pathname:
                    route.challenge_detail +
                    `/${certificationInfo.challengeInfo?.id}`,
                });
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "9px",
                  maxWidth: isMobile ? "85vw" : "100%",
                }}
              >
                <PretendardText
                  style={{
                    color: "rgba(38, 44, 49, 1)",
                    fontSize: "18px",
                    lineHeight: "25.2px",
                    fontWeight: "600",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {/* 챌린지명 챌린지명 챌린지명 챌린지명 챌casasascascascscascascs */}
                  {certificationInfo.challengeInfo?.title}
                </PretendardText>
                <img
                  src="/assets/icons/right_arrow.png"
                  alt=""
                  style={{
                    width: "16px",
                    height: "16px",
                  }}
                />
              </div>
            </TextBtn>
            <PretendardText
              style={{
                color: "rgba(38, 44, 49, 1)",
                fontSize: "14px",
                lineHeight: "21.5px",
                fontWeight: "500",
                marginTop: "10px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: "8",
                WebkitBoxOrient: "vertical",
                display: "-webkit-box",
                whiteSpace: "pre",
              }}
            >
              {certificationInfo?.contents}
            </PretendardText>

            {certificationInfo.challengeInfo?.merch ? (
              <div>
                <div
                  style={{
                    border: "1px solid rgba(236, 237, 240, 1)",
                    borderRadius: "8px",
                    padding: "9px",
                    display: "flex",
                    marginTop: "24px",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <img
                    src={
                      certificationInfo.challengeInfo?.merch?.merchImages
                        .length > 0
                        ? certificationInfo.challengeInfo?.merch.merchImages[0]
                            .imageUrl
                        : "/assets/images/image_default.png"
                    }
                    alt=""
                    style={{
                      width: "42px",
                      height: "42px",
                      borderRadius: "4px",
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "9px",
                    }}
                  >
                    <PretendardText
                      style={{
                        color: "rgba(38, 44, 49, 1)",
                        fontSize: "13px",
                        lineHeight: "18.75px",
                        fontWeight: "600",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "211px",
                      }}
                    >
                      {certificationInfo.challengeInfo?.merch?.name}
                    </PretendardText>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PretendardText
                        style={{
                          color: "rgba(38, 44, 49, 1)",
                          fontSize: "12px",
                          lineHeight: "16.5px",
                          fontWeight: "500",
                        }}
                      >
                        {Number(
                          certificationInfo.challengeInfo?.merch?.price
                        ).toLocaleString("ko-KR")}
                        원
                      </PretendardText>
                      {certificationInfo.challengeInfo?.merch?.originPrice ===
                      certificationInfo.challengeInfo?.merch?.price ? null : (
                        <PretendardText
                          style={{
                            color: "rgba(255, 89, 89, 1)",
                            fontSize: "13px",
                            lineHeight: "18.75px",
                            fontWeight: "600",
                            marginLeft: "4px",
                          }}
                        >
                          {100 -
                            Number(
                              (certificationInfo.challengeInfo?.merch?.price /
                                certificationInfo.challengeInfo?.merch
                                  ?.originPrice) *
                                100
                            ).toFixed(0)}
                          %
                        </PretendardText>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div
              style={{
                marginTop: false ? "24px" : "28px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextBtn
                onClick={() => {
                  likeClickFunc();
                }}
              >
                {!certificationInfo.isLiked ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "rgba(244, 246, 246, 0.6)",
                      minWidth: "62px",
                      height: "31px",
                      borderRadius: "25px",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/assets/icons/ic_16_heart_grey.png"
                      alt=""
                      style={{
                        width: "16px",
                        height: "16px",
                      }}
                    />
                    <PretendardText
                      style={{
                        color: "rgba(119, 128, 136, 1)",
                        fontSize: "11px",
                        lineHeight: "14px",
                        fontWeight: "600",
                        marginLeft: "5px",
                      }}
                    >
                      {certificationInfo.likeAmount}
                    </PretendardText>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "rgba(242, 65, 71, 0.1)",
                      minWidth: "62px",
                      height: "31px",
                      borderRadius: "25px",
                      justifyContent: "center",
                      border: "1px solid rgba(242, 65, 71, 1)",
                    }}
                  >
                    <img
                      src="/assets/icons/ic_16_heart_red.png"
                      alt=""
                      style={{
                        width: "16px",
                        height: "16px",
                      }}
                    />
                    <PretendardText
                      style={{
                        color: "rgba(242, 65, 71, 1)",
                        fontSize: "11px",
                        lineHeight: "14px",
                        fontWeight: "600",
                        marginLeft: "5px",
                      }}
                    >
                      {certificationInfo.likeAmount}
                    </PretendardText>
                  </div>
                )}
              </TextBtn>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(244, 246, 246, 0.6)",
                  minWidth: "62px",
                  height: "31px",
                  borderRadius: "25px",
                  justifyContent: "center",
                  marginLeft: "5px",
                }}
              >
                <img
                  src="/assets/icons/ic_16_comment_grey.png"
                  alt=""
                  style={{
                    width: "16px",
                    height: "16px",
                  }}
                />
                <PretendardText
                  style={{
                    color: "rgba(119, 128, 136, 1)",
                    fontSize: "11px",
                    lineHeight: "14px",
                    fontWeight: "600",
                    marginLeft: "5px",
                  }}
                >
                  {certificationInfo.commentAmount}
                </PretendardText>
              </div>
              <TextBtn
                onClick={() => {
                  history.push(
                    route.entry_certification +
                      `/${certificationInfo.challengeInfo?.id}`
                  );
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(244, 246, 246, 0.6)",
                    minWidth: "87px",
                    height: "31px",
                    borderRadius: "25px",
                    justifyContent: "center",
                    marginLeft: "5px",
                  }}
                >
                  <img
                    src="/assets/icons/ic_16_victory_grey.png"
                    alt=""
                    style={{
                      width: "16px",
                      height: "16px",
                    }}
                  />
                  <PretendardText
                    style={{
                      color: "rgba(119, 128, 136, 1)",
                      fontSize: "11px",
                      lineHeight: "14px",
                      fontWeight: "600",
                      marginLeft: "5px",
                    }}
                  >
                    인증보기
                  </PretendardText>
                </div>
              </TextBtn>
            </div>
          </div>

          <img
            src="/assets/icons/divider.png"
            alt=""
            style={{
              width: "100%",
              height: "100%",
            }}
          />

          {/* 댓글 */}
          <div
            style={{
              marginTop: "24px",
              paddingLeft: "20px",
              paddingRight: "20px",
              marginBottom: "30px",
            }}
          >
            {commentList.length > 0 ? (
              commentList.map((item, index) => {
                return (
                  <CommentBox
                    item={item}
                    key={index}
                    isFirst={index === 0}
                    writeCommentFunc={writeCommentFunc}
                    // 댓글수정
                    setUpdateCommentId={setUpdateCommentId}
                    // 대댓글수정
                    setUpdateReplyId={setUpdateReplyId}
                    // 댓글 삭제
                    setDeleteCommentId={setDeleteCommentId}
                    // 대댓글 삭제
                    setDeleteReplyId={setDeleteReplyId}
                    // 태그 오픈
                    openFlag={openFlag}
                    setOpenFlag={setOpenFlag}
                    // 대댓글 리스트
                    setReplyListP={setReplyListP}
                  ></CommentBox>
                );
              })
            ) : (
              <div
                style={{
                  height: 160,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src="/assets/icons/empty.png"
                  style={{
                    height: 32,
                    width: 32,
                  }}
                />
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "20px",
                    color: "#DBDDDF",
                    fontWeight: 600,
                    marginTop: 8,
                  }}
                >
                  댓글이 아직 없어요.
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "20px",
                    color: "#DBDDDF",
                    fontWeight: 600,
                  }}
                >
                  첫 댓글을 달아주세요!
                </PretendardText>
              </div>
            )}
          </div>

          {/* 댓글 달기 */}
          {updateCommentId === 0 && updateReplyId === 0 ? (
            selectedCommentId === 0 ? (
              isReviewShow ? (
                <div
                  style={{
                    marginTop: commentList.length > 0 ? 24 : 0,
                    padding: "10px 20px",
                  }}
                >
                  <InputReview
                    placeholder={"댓글을 입력하세요"}
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (comment.length > 0) {
                          commentCreate();
                        }
                      }
                    }}
                    onClick={() => {
                      commentCreate();
                    }}
                  ></InputReview>
                </div>
              ) : null
            ) : isReviewShow ? (
              <div
                style={{
                  marginTop: "31px",
                  padding: "10px 20px",
                }}
              >
                <InputReview
                  placeholder={"대댓글을 입력하세요"}
                  value={reply}
                  onChange={(e) => {
                    setReply(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (reply.length > 0) {
                        replyCreate();
                      }
                    }
                  }}
                  onClick={() => {
                    replyCreate();
                  }}
                ></InputReview>
              </div>
            ) : null
          ) : updateCommentId === 0 ? (
            isReviewShow ? (
              <div
                style={{
                  marginTop: "31px",
                  padding: "10px 20px",
                }}
              >
                <InputReview
                  placeholder={"수정대댓글을 입력하세요"}
                  value={updateReply}
                  onChange={(e) => {
                    setUpdateReply(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (updateReply.length > 0) {
                        replyUpdate();
                      }
                    }
                  }}
                  onClick={() => {
                    replyUpdate();
                  }}
                ></InputReview>
              </div>
            ) : null
          ) : isReviewShow ? (
            <div
              style={{
                marginTop: "31px",
                padding: "10px 20px",
              }}
            >
              <InputReview
                placeholder={"수정댓글을 입력하세요"}
                value={updateComment}
                onChange={(e) => {
                  setUpdateComment(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (updateComment.length > 0) {
                      commentUpdate();
                    }
                  }
                }}
                onClick={() => {
                  commentUpdate();
                }}
              ></InputReview>
            </div>
          ) : null}

          {deleteCommentId !== 0 ? (
            <ConfirmModal
              title={"댓글을 삭제하시겠습니까?"}
              onClickText={"닫기"}
              onCancleText={"삭제"}
              onClick={() => {
                setDeleteCommentId(0);
              }}
              onCancle={() => {
                commentDelete();
                setDeleteCommentId(0);
              }}
            />
          ) : null}
          {deleteReplyId !== 0 ? (
            <ConfirmModal
              title={"대댓글을 삭제하시겠습니까?"}
              onClickText={"닫기"}
              onCancleText={"삭제"}
              onClick={() => {
                setDeleteReplyId(0);
              }}
              onCancle={() => {
                replyDelete();
                setDeleteReplyId(0);
              }}
            />
          ) : null}

          {isModal4 ? (
            <ConfirmModal
              title={`게시글을 삭제하시겠어요?`}
              onClickText={"취소"}
              onCancleText={"삭제하기"}
              onClick={() => {
                setIsModal4(false);
              }}
              onCancle={() => {
                deleteFunc();
              }}
            />
          ) : null}
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default MyPageCertificationDetail;
