import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import useDisplay from "../../hooks/useDisplay";
import { useInView } from "react-intersection-observer";

import AppLayout from "../../components/atoms/layouts/appLayout";
import AppContainer from "../../components/atoms/layouts/appContainer";
import BottomTab from "../../components/organisms/bottomTab/bottomTab";
import PretendardText from "../../components/atoms/pretendardText";
import TextBtn from "../../components/atoms/button/textBtn";
import Tab from "../../components/molecules/tab";
import ProfileBoardBox from "../../components/organisms/profileBoardBox";
import ProfileChallengeBox from "../../components/organisms/profileChallengeBox";
import { route } from "../../routes/route";

import Empty from "../../components/molecules/empty";
import ProfileApi from "../../api/profileApi";
import { toast } from "react-toastify";
import { ko } from "../../utils/ko";
import SearchChallengeBox from "../../components/organisms/searchChallengeBox";

function MyProfile(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();

  const [isMoreBtn, setIsMoreBtn] = useState(false);
  const [moreIntro, setMoreIntro] = useState(false);
  const [profileInfo, setProfileInfo] = useState();

  const [tabList, setTabList] = useState([
    { label: "전체", value: true },
    { label: "챌린지", value: false },
  ]);

  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [ref, inView] = useInView();
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    const tabListTemp = location?.state ? location.state.tabListTemp : null;

    if (tabListTemp) {
      setTabList(tabListTemp);

      if (tabListTemp[0].value) {
        setPRData();
      } else {
        setUnPRData();
      }
    }

    profileDetailFunc();
    window.scrollTo(0, 0);
  }, []);

  const moveLoaction = (data) => {
    history.replace({
      pathname: route.bottom_my_profile,
      state: {
        tabListTemp: data,
      },
    });
  };

  const tabClickFunc = (label) => {
    let temp = tabList.map((item) =>
      item.label === label
        ? { label: item.label, value: true }
        : { label: item.label, value: false }
    );

    setTabList(temp);
    moveLoaction(temp);
  };

  const profileDetailFunc = async () => {
    try {
      const response = await ProfileApi.Info();

      setProfileInfo(response.data.data);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const handleOpenNewTab = (url) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  const setPRData = async () => {
    try {
      setLoading(true);
      const response = await ProfileApi.BulletinList({
        page: currentPage,
        size: 10,
      });

      const data = response.data.data;
      setDataList(dataList.concat(data.content));
      setIsLast(data.last);
      setLoading(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const setUnPRData = async () => {
    try {
      setLoading(true);
      const response = await ProfileApi.ChallengeAllList({
        page: currentPage,
        size: 10,
      });

      const data = response.data.data;
      setDataList(dataList.concat(data.content));
      setIsLast(data.last);
      setLoading(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    if (tabList[0].value) {
      setPRData();
    } else {
      setUnPRData();
    }
  }, [currentPage, tabList]);

  useEffect(() => {
    if (inView && !isLast && !loading) {
      setCurrentPage(currentPage + 1);
      if (tabList[0].value) {
        setPRData();
      } else {
        setUnPRData();
      }
    }
  }, [inView, loading]);

  return (
    <>
      <AppLayout>
        <AppContainer style={{}}>
          {/* 헤더 */}
          <div
            style={{
              height: "50px",
              paddingLeft: "20px",
              paddingRight: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <div />
            <TextBtn
              onClick={() => {
                history.push({
                  pathname: route.influencer_more,
                });
              }}
            >
              <img
                src="/assets/icons/more02.png"
                style={{
                  height: 24,
                  width: 24,
                }}
              />
            </TextBtn>
          </div>

          {/* 프로필 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 31,
              marginBottom: 13,
            }}
          >
            <img
              src={
                profileInfo
                  ? profileInfo.fileUrl
                    ? profileInfo.fileUrl
                    : "/assets/images/profile_temp.png"
                  : "/assets/images/profile_temp.png"
              }
              style={{
                height: 80,
                width: 80,
                borderRadius: 100,
                marginBottom: 10,
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 20,
                  lineHeight: "28px",
                  color: "#262C31",
                  fontWeight: 600,
                }}
              >
                {profileInfo ? profileInfo.nickName : ""}
              </PretendardText>
              <img
                src="/assets/icons/card_checkstar.svg"
                alt=""
                style={{
                  width: "17px",
                  height: "17px",
                  marginLeft: "3px",
                }}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "0px 55px",
              width: "100%",
            }}
          >
            <PretendardText
              style={{
                fontSize: 13,
                lineHeight: "18.75px",
                color: "#778088",
                fontWeight: 600,
                overflow: "hidden",
                textOverflow: moreIntro ? "" : "ellipsis",
                WebkitLineClamp: moreIntro ? "" : "2",
                WebkitBoxOrient: moreIntro ? "" : "vertical",
                display: moreIntro ? "" : "-webkit-box",
                textAlign: "center",
                whiteSpace: "pre",
              }}
            >
              {profileInfo ? profileInfo.introduce : ""}
            </PretendardText>
            <TextBtn
              onClick={() => {
                setMoreIntro(!moreIntro);
              }}
            >
              <img
                src="/assets/icons/ic_16_under_arrow_grey.png"
                alt=""
                style={{
                  width: "16px",
                  height: "16px",
                  transform: moreIntro ? "rotate(180deg)" : "",
                }}
              />
            </TextBtn>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "13px",
            }}
          >
            <div
              style={{
                width: "111px",
                height: "59px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#ADB4BA",
                  fontWeight: 700,
                }}
              >
                Followers
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "22.96px",
                  color: "#262C31",
                  fontWeight: 600,
                }}
              >
                {profileInfo ? profileInfo.followerCount : "0"}
              </PretendardText>
            </div>
            <div
              style={{
                width: "111px",
                height: "59px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#ADB4BA",
                  fontWeight: 700,
                }}
              >
                Like
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "22.96px",
                  color: "#262C31",
                  fontWeight: 600,
                }}
              >
                {profileInfo ? profileInfo.likeCount : "0"}
              </PretendardText>
            </div>
            <div
              style={{
                width: "111px",
                height: "59px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#ADB4BA",
                  fontWeight: 700,
                }}
              >
                Challenge
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "22.96px",
                  color: "#262C31",
                  fontWeight: 600,
                }}
              >
                {profileInfo ? profileInfo.onGoingChallengesCount : "0"}
              </PretendardText>
            </div>
          </div>
          <div
            style={{
              marginTop: "14px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {profileInfo && profileInfo.links
              ? profileInfo.links.length > 0
                ? profileInfo.links.map((item, index) => {
                    return (
                      <TextBtn
                        key={index}
                        onClick={() => {
                          if (item.includes("http") || item.includes("https")) {
                            handleOpenNewTab(item);
                          } else {
                            handleOpenNewTab(`https://${item}`);
                          }
                        }}
                      >
                        <img
                          src="/assets/icons/btn_circle.png"
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            marginLeft: "10px",
                          }}
                        />
                      </TextBtn>
                    );
                  })
                : null
              : null}
          </div>

          {/* 탭 */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            {tabList.map((item, index) => {
              return (
                <Tab
                  onClick={() => {
                    tabClickFunc(item.label);
                    setDataList([]);
                    setIsLast(false);
                    setCurrentPage(0);
                  }}
                  key={index}
                  value={item.value}
                >
                  <PretendardText
                    style={{
                      fontSize: 14,
                      lineHeight: "20px",
                      color: item.value ? "#262C31" : "#ADB4BA",
                      fontWeight: 600,
                    }}
                  >
                    {item.label}
                  </PretendardText>
                </Tab>
              );
            })}
          </div>

          {/* 게시글 */}
          {tabList[0].value ? (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {dataList && dataList.length > 0 ? (
                dataList.map((item, index) => {
                  return (
                    <ProfileBoardBox
                      data={item}
                      key={index}
                      isEnd={
                        isMobile ? (index + 1) % 3 === 0 : (index + 1) % 4 === 0
                      }
                    />
                  );
                })
              ) : (
                <Empty
                  style={{
                    height: "30vh",
                  }}
                  text={"게시글이 없습니다."}
                />
              )}
            </div>
          ) : tabList[1].value ? (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                padding: "0px 20px",
                marginTop: 18,
              }}
            >
              {dataList && dataList.length > 0 ? (
                dataList.map((item, index) => {
                  if (item.challengeStatusTitle) {
                    return (
                      <SearchChallengeBox
                        key={index}
                        isEnd={(index + 1) % 2 === 0}
                        marginBottom={"24px"}
                        data={item}
                      />
                    );
                  }
                })
              ) : (
                <Empty
                  style={{
                    height: "30vh",
                  }}
                  text={"챌린지가 없습니다."}
                />
              )}
            </div>
          ) : null}

          <div ref={ref}>{/* loading */}</div>

          <BottomTab />
        </AppContainer>

        <TextBtn
          onClick={() => {
            history.push(route.board_upload);
          }}
          style={{
            position: "sticky",
            width: 70,
            height: 70,
            left: isMobile ? "80%" : "82%",
            bottom: 60,
          }}
        >
          <img
            src="/assets/icons/plus_btn.png"
            alt=""
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </TextBtn>
      </AppLayout>
    </>
  );
}

export default MyProfile;
