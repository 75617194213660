import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";

import styled from "styled-components";
import ClPaymentSection from "../components/molecules/challenge/clPaymentSection";
import ProductSection from "../components/molecules/product/productSection";
import Select from "../components/atoms/select/select";
import TextArea from "../components/atoms/input/textarea";
import ConfirmModal from "../components/templates/modals/confirmModal";
import OrderApi from "../api/orderApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";

function Refund(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();

  const [isModal, setIsModal] = useState(false);
  const [challengeParticipationId, setChallengeParticipationId] = useState();
  const [orderData, setOrderData] = useState();
  const [challengeData, setChallengeData] = useState();
  const [orderId, setOrderId] = useState();
  const [orderInfo, setOrderInfo] = useState();

  const [selectValue01, setSelectValue01] = useState("");
  const [selectList01, setSelectList01] = useState([
    {
      value: "챌린지 진행이 어려워요",
      label: "챌린지 진행이 어려워요",
      type: "DIFFICULTY",
    },
    {
      value: "단순 변심",
      label: "단순 변심",
      type: "CHANGE_OF_MIND",
    },
    {
      value: "불량/ 하자 발견",
      label: "불량/ 하자 발견",
      type: "BAD_PRODUCT",
    },
    {
      value: "기타 이유",
      label: "기타 이유",
      type: "ETC",
    },
  ]);
  const [reason, setReason] = useState("");

  useEffect(() => {
    const challengeParticipationId = location?.state
      ? location.state.challengeParticipationId
      : null;

    const orderId = location?.state ? location.state.orderId : null;
    const orderInfo = location?.state ? location.state.orderInfo : null;

    setOrderId(orderId);
    setOrderInfo(orderInfo);

    if (challengeParticipationId) {
      setChallengeParticipationId(challengeParticipationId);
      getOrderInfoFunc(challengeParticipationId);
      getChallengInfoFunc(challengeParticipationId);
    }

    window.scrollTo(0, 0);
  }, []);

  const getOrderInfoFunc = async (id) => {
    try {
      let response = await OrderApi.GetOrderInfo(id);
      setOrderData(response.data.data);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const getChallengInfoFunc = async (id) => {
    try {
      let response = await OrderApi.GetChallengInfo(id);
      setChallengeData(response.data.data);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const refundFunc = async () => {
    try {
      let reasonType = selectList01.find(
        (item) => item.value === selectValue01
      );

      let formData = new FormData();

      formData.append("refundReason", reasonType.type);
      formData.append("refundReasonDtl", reason);

      if (challengeParticipationId) {
        await OrderApi.Refund(challengeParticipationId, formData);
      } else {
        await OrderApi.OrderInfoIdRefund(orderId, formData);
      }

      history.goBack();
      toast("환불 신청되었습니다.");
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header title={"취소/환불 요청하기"} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              paddingTop: 24,
            }}
          >
            {challengeParticipationId && challengeData ? (
              <ClPaymentSection
                style={{
                  padding: "0px 20px 24px 20px",
                }}
                data={challengeData}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 100,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 15,
                    lineHeight: "20.3px",
                    color: "#ADB4BA",
                    fontWeight: 500,
                  }}
                >
                  삭제된 챌린지입니다.
                </PretendardText>
              </div>
            )}

            <Division />

            {orderData || orderInfo ? (
              <ProductSection
                style={{
                  padding: "24px 20px",
                }}
                data={challengeParticipationId ? orderData : orderInfo}
              />
            ) : null}

            <Division />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "25px 20px 0px 20px",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "#262C31",
                  fontWeight: 700,
                  marginBottom: 12,
                }}
              >
                환불 사유를 선택해주세요
              </PretendardText>
              <Select
                setValue={setSelectValue01}
                value={selectValue01}
                dataList={selectList01}
                placeholder={"환불 사유를 선택해주세요"}
              />
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "#262C31",
                  fontWeight: 700,
                  marginBottom: 12,
                  marginTop: 25,
                }}
              >
                상세 사유를 말씀주세요 (선택)
              </PretendardText>
              <TextArea
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                type={"text"}
                placeholder={
                  "요상세 사유를 입력해주시면 CS 인력이 연락 드리도록 하겠습니다."
                }
                style={{
                  height: 73,
                }}
              />

              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#262C31",
                  fontWeight: 700,
                  marginBottom: 9,
                  marginTop: 57,
                }}
              >
                환불 유의사항
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "16.5px",
                  color: "#ADB4BA",
                  fontWeight: 500,
                  marginBottom: 30,
                }}
              >
                환불시 반품 확인여부를 확인한 후 3~5영업일 이내에 결제 금액을
                환불해 드립니다.
                <br /> 신용카드로 결제하신 경우는 신용카드 승인을 취소하여 결제
                대금이 청구되지 않게 합니다.
                <br /> (단, 신용카드 결제일자에 맞추어 대금이 청구 될수 있으면
                이경우 익월 신용카드 대금청구시 카드사에서 환급처리 됩니다.)
              </PretendardText>
            </div>
          </div>

          <div
            style={{
              position: "sticky",
              padding: "10px 20px",
              width: "100%",
              background: "#fff",
              bottom: 0,
              left: 0,
              zIndex: 99,
            }}
          >
            <MainBtn
              disabled={selectValue01 ? false : true}
              onClick={() => {
                setIsModal(true);
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                환불 요청하기
              </PretendardText>
            </MainBtn>
          </div>

          {isModal ? (
            <ConfirmModal
              setVisible={setIsModal}
              title={"정말 환불을 요청하시겠어요?"}
              content={
                "담당자가 확인 후 3~5일 이내에 환불 처리를 도와드리겠습니다."
              }
              onClickText={"취소"}
              onCancleText={"환불 요청하기"}
              onClick={() => {
                setIsModal(false);
              }}
              onCancle={() => {
                refundFunc();
              }}
            />
          ) : null}
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default Refund;

const Division = styled.div.attrs((props) => {})`
  width: 100%;
  border: 8px solid #f5f5f5;
`;
