import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useDisplay from "../../hooks/useDisplay";
import AppLayout from "../../components/atoms/layouts/appLayout";
import AppContainer from "../../components/atoms/layouts/appContainer";
import BottomTab from "../../components/organisms/bottomTab/bottomTab";
import TextBtn from "../../components/atoms/button/textBtn";
import PretendardText from "../../components/atoms/pretendardText";
import styled from "styled-components";
import Tab from "../../components/molecules/tab";
import MypageChallengeBox from "../../components/organisms/mypageChallengeBox";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import MoreApi from "../../api/moreApi";
import { toast } from "react-toastify";
import { ko } from "../../utils/ko";
import UserApi from "../../api/userApi";

import { route } from "../../routes/route";

function MyChallenge(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();

  const [isMoreBtn, setIsMoreBtn] = useState(false);
  const [tabList, setTabList] = useState([
    { label: "진행중", value: true },
    { label: "진행완료", value: false },
    { label: "전체", value: false },
  ]);
  const [count01, setCount01] = useState(0);
  const [count02, setCount02] = useState(0);
  const [count03, setCount03] = useState(0);

  useEffect(() => {
    if (sessionStorage.getItem("id")) {
      getAccountInfo();
      getPointInfo();
    }

    const tabListTemp = location?.state ? location.state.tabListTemp : null;

    if (tabListTemp) {
      setTabList(tabListTemp);
    }

    window.scrollTo(0, 0);
  }, []);

  const moveLoaction = (data) => {
    history.replace({
      pathname: route.bottom_myChallenge,
      state: {
        tabListTemp: data,
      },
    });
  };

  const tabClickFunc = (label) => {
    let temp = tabList.map((item) => {
      if (item.label === label) {
        return {
          ...item,
          value: true,
        };
      } else {
        return {
          ...item,
          value: false,
        };
      }
    });

    setTabList(temp);
    moveLoaction(temp);
  };

  // 채린지 정보
  const [AccountInfo, setAccountInfo] = useState({});
  const [pointInfo, setPointInfo] = useState({});
  const [challengeList, setChallengeList] = useState([]);

  // init
  const getAccountInfo = async () => {
    try {
      const response = await MoreApi.AccountInfo();
      setAccountInfo(response.data.data);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const getPointInfo = async () => {
    try {
      const response = await UserApi.PointInfo();
      const data = response.data.data;
      setPointInfo(data);
      setCount01(data.ongiongCount);
      setCount02(data.endCount);
      setCount03(data.totalCount);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const getChallengeList = async () => {
    try {
      const tab = tabList.find((item) => item.value);
      let response = null;
      switch (tab.label) {
        case "진행중":
          response = await UserApi.ChallengeListProgress({
            size: 1000,
          });
          break;
        case "진행완료":
          response = await UserApi.ChallengeListEnd({
            size: 1000,
          });
          break;
        case "전체":
          response = await UserApi.ChallengeListAll({
            size: 1000,
          });
          break;

        default:
          break;
      }
      const data = response.data.data;
      setChallengeList(data.content);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("id")) {
      getChallengeList();
    }
  }, [tabList]);

  return (
    <>
      <AppLayout>
        <AppContainer style={{}}>
          <div
            style={{
              paddingBottom: 60,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                padding: "0px 20px",
                width: "100%",
                height: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                position: "relative",
              }}
            >
              <TextBtn
                onClick={() => {
                  setIsMoreBtn(!isMoreBtn);
                }}
              >
                <img
                  src="/assets/icons/more.png"
                  style={{
                    height: 24,
                    width: 24,
                    cursor: "pointer",
                  }}
                />
              </TextBtn>
              {isMoreBtn ? (
                <TextBtn
                  onClick={() => {
                    history.push({
                      pathname: route.profile_edit,
                    });
                  }}
                  style={{
                    position: "absolute",
                    bottom: -34,
                    right: 26,
                    padding: "10px 14px",
                    borderRadius: 6,
                    boxShadow: "0px 0px 5px rgba(0,0,0,0.3)",
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 14,
                      lineHeight: "20px",
                      color: "#262C31",
                      fontWeight: 600,
                    }}
                  >
                    프로필 수정하기
                  </PretendardText>
                </TextBtn>
              ) : null}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 31,
                marginBottom: 13,
              }}
            >
              <img
                src={
                  AccountInfo.fileUrl
                    ? AccountInfo.fileUrl
                    : "/assets/images/profile_temp.png"
                }
                style={{
                  height: 80,
                  width: 80,
                  borderRadius: 100,
                  marginBottom: 10,
                }}
              />
              <PretendardText
                style={{
                  fontSize: 20,
                  lineHeight: "28px",
                  color: "#262C31",
                  fontWeight: 600,
                }}
              >
                {AccountInfo.nickName}
              </PretendardText>
            </div>

            <div
              style={{
                padding: "0px 20px 29px 20px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <NumberWrapper>
                <NumberWrapperTitle>사용 포인트</NumberWrapperTitle>
                <NumberWrapperValue>
                  {pointInfo.usePoint
                    ? Number(pointInfo.usePoint).toLocaleString("ko-KR")
                    : 0}
                </NumberWrapperValue>
              </NumberWrapper>
              <NumberWrapper>
                <NumberWrapperTitle>누적 포인트</NumberWrapperTitle>
                <NumberWrapperValue>
                  {pointInfo.accuPoint
                    ? Number(pointInfo.accuPoint).toLocaleString("ko-KR")
                    : 0}
                </NumberWrapperValue>
              </NumberWrapper>
              <NumberWrapper>
                <NumberWrapperTitle>남은 포인트</NumberWrapperTitle>
                <NumberWrapperValue>
                  {pointInfo.commonMemberDto?.point
                    ? Number(pointInfo.commonMemberDto?.point).toLocaleString(
                        "ko-KR"
                      )
                    : 0}
                </NumberWrapperValue>
              </NumberWrapper>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "29px",
              }}
            >
              {tabList.map((item, index) => {
                return (
                  <Tab
                    onClick={() => {
                      tabClickFunc(item.label);
                    }}
                    key={index}
                    value={item.value}
                  >
                    <PretendardText
                      style={{
                        fontSize: 14,
                        lineHeight: "20px",
                        color: item.value ? "#262C31" : "#ADB4BA",
                        fontWeight: 600,
                      }}
                    >
                      {item.label}
                    </PretendardText>
                    <PretendardText
                      style={{
                        fontSize: 14,
                        lineHeight: "20px",
                        color: item.value ? "#FF3333" : "#ADB4BA",
                        fontWeight: 600,
                        marginLeft: 5,
                      }}
                    >
                      {item.label === "진행중"
                        ? count01
                        : item.label === "진행완료"
                        ? count02
                        : count03}
                    </PretendardText>
                  </Tab>
                );
              })}
            </div>

            <div
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              {/* {tabList[0].value
                ? challengeList.map((item, index) => {
                  return (
                    <MypageChallengeBox
                      key={index}
                      isProgress={item.challengeStatus === 'PROGRESS' && !item.retire}
                      item={item}
                    ></MypageChallengeBox>
                  );
                })
                : challengeList.map((item, index) => {
                  return (
                    <MypageChallengeBox
                      key={index}
                      isProgress={false}
                      item={item}
                    ></MypageChallengeBox>
                  );
                })} */}
              {challengeList.map((item, index) => {
                return (
                  <MypageChallengeBox
                    key={index}
                    isProgress={
                      item.participationStatus === "PROGRESS" && !item.retire
                    }
                    item={item}
                  ></MypageChallengeBox>
                );
              })}
            </div>
          </div>
          <BottomTab />
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default MyChallenge;

const NumberWrapper = styled.div.attrs((props) => {})`
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 59px;
  justify-content: center;
`;

const NumberWrapperTitle = styled(PretendardText).attrs((props) => {})`
  font-size: 13px;
  line-height: 18.75px;
  color: rgb(173, 180, 186);
  font-weight: 700;
`;

const NumberWrapperValue = styled(PretendardText).attrs((props) => {})`
  font-size: 16px;
  line-height: 22.96px;
  color: rgb(38, 44, 49);
  font-weight: 600;
`;
