import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import TitleInput from "../components/molecules/input/titleInput";
import FileForm from "../components/atoms/fileForm/fileForm";
import UserApi from "../api/userApi";
import { ko } from "../utils/ko";
import { toast } from "react-toastify";
import TextArea from "../components/atoms/input/textarea";
import { nullCheck } from "../utils/check";

function InfluencerProfileEdit(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  const [nickName, setNickName] = useState("");
  const [oNickName, setONickName] = useState("");
  const [nickNameErr, setNickNameErr] = useState("");
  const [nickChk, setNickChk] = useState(false);

  const [file, setFile] = useState();
  const [imageUrl, setImageUrl] = useState();

  const [intro, setIntro] = useState("");
  const [link01, setLink01] = useState("");
  const [link02, setLink02] = useState("");
  const [link03, setLink03] = useState("");

  useEffect(() => {
    getMyInfoFunc();

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (nickName.length > 0) {
      setNickNameErr("");

      setNickChk(false);
    }

    if (oNickName === nickName) {
      setNickChk(true);
    }
  }, [nickName, oNickName]);

  const handleChange = (e) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (data) => {
      setFile(file);
      setImageUrl(data.target.result);
    };
    e.target.value = "";
  };

  const getMyInfoFunc = async () => {
    try {
      let response = await UserApi.GetProfile();

      setNickName(response.data.data.nickName);
      setONickName(response.data.data.nickName);
      setImageUrl(response.data.data.fileUrl);
      setIntro(response.data.data.introduce);

      if (!nullCheck(response.data.data.links)) {
        if (response.data.data.links[0]) {
          setLink01(response.data.data.links[0]);
        }
        if (response.data.data.links[1]) {
          setLink02(response.data.data.links[1]);
        }
        if (response.data.data.links[2]) {
          setLink03(response.data.data.links[2]);
        }
      }
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const nickCheckApi = async () => {
    try {
      if (nickNameErr != "" || nickName == "") {
        return;
      }

      const data = {
        nickName: nickName,
      };

      const response = await UserApi.RNickNameCheck(data);

      if (response.data.data.isDuplicated) {
        setNickNameErr("닉네임이 중복되었습니다.");
        setNickChk(false);
      } else {
        setNickNameErr("");
        setNickChk(true);
      }
    } catch (err) {
      setNickChk(false);
    }
  };

  const profileEditApi = async () => {
    try {
      let formData = new FormData();

      if (file) {
        formData.append("file", file);
      }

      if (nickName) {
        formData.append("nickName", nickName);
      } else {
        formData.append("nickName", oNickName);
      }
      formData.append("introduce", intro);

      let linkTemp = "";

      if (link01) {
        linkTemp += link01;
      }

      if (link02) {
        link01 ? (linkTemp += `, ${link02}`) : (linkTemp += `${link02}`);
      }

      if (link03) {
        link01 || link02 ? (linkTemp += `, ${link03}`) : (linkTemp += `${link03}`);
      }

      formData.append("links", linkTemp);

      await UserApi.EditProfile(formData);
      toast("프로필 수정완료");
      history.goBack();
    } catch (err) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header title={"프로필 수정"} />

          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                marginTop: 40,
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 32,
                  lineHeight: "44.16px",
                  color: "#262C31",
                  fontWeight: 600,
                  marginBottom: 6,
                }}
              >
                프로필 수정
              </PretendardText>

              <div
                style={{
                  marginTop: 69,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 13,
                    lineHeight: "18.75px",
                    color: "#778088",
                    fontWeight: 600,
                  }}
                >
                  프로필 이미지 선택
                </PretendardText>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginTop: 8,
                  }}
                >
                  <FileForm handleChange={handleChange} />

                  {imageUrl ? (
                    <div
                      style={{
                        marginLeft: 8,
                        width: 80,
                        height: 80,
                        borderRadius: 12,
                        backgroundImage: `url( ${imageUrl} )`,
                        backgroundSize: "100% 100%",
                        position: "relative",
                      }}
                    >
                      <img
                        onClick={() => {
                          setFile(null);
                          setImageUrl("");
                        }}
                        src="/assets/icons/imageDel.png"
                        style={{
                          height: 20,
                          width: 20,
                          position: "absolute",
                          top: 4,
                          right: 4,
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </div>

              <div
                style={{
                  marginTop: 20,
                }}
              >
                <TitleInput
                  title={"닉네임"}
                  placeholder="10자이하로 입력하세요"
                  type={"text"}
                  onChange={(e) => {
                    setNickName(e.target.value);
                  }}
                  value={nickName}
                  btn={true}
                  btnText={"중복확인"}
                  onClick={() => {
                    nickCheckApi();
                  }}
                  maxLength={"10"}
                  warningMessage={nickNameErr}
                  btnStyle={{
                    marginTop: nickNameErr || nickChk ? -23 : 0,
                  }}
                  btnTextStyle={{
                    lineHeight: (nickNameErr || nickChk) && isMobile ? "17.75px" : "18.75px",
                  }}
                  checkMessage={nickChk ? "사용가능한 닉네임입니다." : ""}
                />
              </div>

              <div>
                <div
                  style={{
                    marginTop: 17,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 13,
                      lineHeight: "18.75px",
                      color: "#778088",
                      fontWeight: 600,
                      marginBottom: 4,
                    }}
                  >
                    소개글
                  </PretendardText>
                  <TextArea
                    style={{
                      height: 112,
                    }}
                    value={intro}
                    onChange={(e) => {
                      setIntro(e.target.value);
                    }}
                    type={"text"}
                    placeholder={"소개글을 입력하세요"}
                  />
                </div>

                <div
                  style={{
                    marginTop: 17,
                  }}
                >
                  <TitleInput
                    title={"링크"}
                    placeholder="관련 링크를 입력하세요"
                    type={"text"}
                    onChange={(e) => {
                      setLink01(e.target.value);
                    }}
                    value={link01}
                  />
                </div>
                <div
                  style={{
                    marginTop: 17,
                  }}
                >
                  <TitleInput
                    title={"링크"}
                    placeholder="관련 링크를 입력하세요"
                    type={"text"}
                    onChange={(e) => {
                      setLink02(e.target.value);
                    }}
                    value={link02}
                  />
                </div>
                <div
                  style={{
                    marginTop: 17,
                  }}
                >
                  <TitleInput
                    title={"링크"}
                    placeholder="관련 링크를 입력하세요"
                    type={"text"}
                    onChange={(e) => {
                      setLink03(e.target.value);
                    }}
                    value={link03}
                  />
                </div>
              </div>
            </div>

            <MainBtn
              onClick={() => {
                profileEditApi();
              }}
              disabled={nickNameErr === "" && nickName && nickChk ? false : true}
              style={{
                marginTop: 22,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                저장하기
              </PretendardText>
            </MainBtn>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default InfluencerProfileEdit;
