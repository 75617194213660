import React, { useState, useEffect } from "react";
import styled from "styled-components";

import PretendardText from "../pretendardText";

const PCLeftLayout = (props) => {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "center",
        marginRight: "6%",
      }}
    >
      <div
        style={{
          paddingLeft: 40,
          position: "fixed",
        }}
      >
        <img
          src="/assets/images/logo.png"
          style={{
            height: 104,
            width: 340,
            marginBottom: 36,
          }}
        />
        <PretendardText
          style={{
            fontSize: 20,
            lineHeight: "28px",
            color: "white",
            fontWeight: 600,
            marginBottom: 15,
            paddingLeft: 20,
          }}
        >
          헬스라이프 스타일, 즐겁고 신나게!
        </PretendardText>
        <PretendardText
          style={{
            fontSize: 16,
            lineHeight: "32.5px",
            color: "rgba(255,255,255,0.7)",
            fontWeight: 400,
            paddingLeft: 20,
          }}
        >
          “함께 쉽고 즐겁게” 우리는 모두와 함께
          <br /> 헬스 라이프 스타일 문화를 만듭니다.
        </PretendardText>
      </div>
    </div>
  );
};

export default PCLeftLayout;
