import api from "./api.js";

export default class UserApi {
  static async RPhoneCertify(data) {
    return await api.get(`usr-002_F0`, data);
  }

  static async REmailCheck(data) {
    return await api.get(`usr-002_F1`, data);
  }

  static async RNickNameCheck(data) {
    return await api.get(`usr-002_F2`, data);
  }

  static async Register(data) {
    return await api.post(`usr-002_I0`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async IFRegister(data) {
    return await api.post(`usr-002_I1`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async Edit(id, data) {
    return await api.put(`admin/merch/update/${id}`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async FPhoneCertify(data) {
    return await api.get(`usr-003_F0`, data);
  }

  static async EmailFind(data) {
    return await api.get(`usr-003_F1`, data);
  }

  static async FPWPhoneCertify(data) {
    return await api.get(`usr-004_F0`, data);
  }

  static async FindPWChange(data) {
    return await api.post(`usr-004_U0`, data);
  }

  static async Login(data) {
    return await api.post(`usr-011_F0`, data);
  }

  static async SocialLogin(data) {
    return await api.post(`usr-011_F1`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async putStore(data) {
    return await api.put(`user/store`, data);
  }

  static async putLike(data) {
    return await api.put(`user/like`, data);
  }

  static async Withdraw(data) {
    return await api.put(`user/inf-001_U0`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async getDeliveryList(data) {
    return await api.get(`user/chn-010_F0`, data);
  }

  static async getPointInfo(data) {
    return await api.get(`user/chn-010_F1`, data);
  }

  static async enterChallenge(id) {
    return await api.post(`user/chn-002_I0/${id}`);
  }

  static async enterPayChallenge(id, data) {
    return await api.post(`user/chn-002_I1/${id}`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async PointInfo(data) {
    return await api.get(`user/myc-101_F0`, data);
  }

  static async generalPointInfo(id, data) {
    return await api.get(`user/myc-101_F0/${id}`, data);
  }

  static async ChallengeListProgress(data) {
    return await api.get(`user/myc-101_F1`, data);
  }
  static async ChallengeListEnd(data) {
    return await api.get(`user/myc-101_F2`, data);
  }
  static async ChallengeListAll(data) {
    return await api.get(`user/myc-101_F3`, data);
  }
  static async ChallengeInfo(id, data) {
    return await api.get(`user/myc-102_F0/${id}`, data);
  }
  static async ChallengeReviewList(id, data) {
    return await api.get(`user/myc-102_F1/${id}`, data);
  }
  static async GetProfile(data) {
    return await api.get(`user/i-PRO-001_F0`);
  }

  static async getMyChallengeList(data) {
    return await api.get(`user/i-PRO-001_F2`, data);
  }

  static async getSFChallengeList() {
    return await api.get(`user/i-PRO-002_F0`);
  }

  static async postBoard(data) {
    return await api.post(`user/i-PRO-002_I0`, data);
  }
  static async putBoard(id, data) {
    return await api.put(`user/i-PRO-002_U0/${id}`, data);
  }

  static async reStart(id, data) {
    return await api.put(`/user/i-MYC-022_U1/${id}`, data);
  }
  static async delChallenge(id) {
    return await api.del(`user/chn-002_U2/${id}`);
  }
  static async giveUpChallenge(id) {
    return await api.put(`user/chn-002_U1/${id}`);
  }
  static async cancelParticipation(id) {
    return await api.put(`user/chn-002_U0/${id}`);
  }
  static async EditProfile(data) {
    return await api.put(`user/myc-002_U0`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async generalChallengeListProgress(id, data) {
    return await api.get(`user/myc-101_F1/${id}`, data);
  }
  static async generalChallengeListEnd(id, data) {
    return await api.get(`user/myc-101_F2/${id}`, data);
  }
  static async generalChallengeListAll(id, data) {
    return await api.get(`user/myc-101_F3/${id}`, data);
  }

  static async createVisit() {
    return await api.post(`visitor`);
  }

  static async delCertification(id) {
    return await api.del(`user/myc-001_D0/${id}`);
  }
  static async delShortForm(id) {
    return await api.del(`user/i-PRO-002_D0/${id}`);
  }
}
