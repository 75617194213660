import React, { useState, useEffect, useRef, useMemo } from "react";
import styled from "styled-components";
import ReactQuill, { Quill } from "react-quill";
import { useQuill } from "react-quilljs";

import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";

import PretendardText from "../../atoms/pretendardText";
import CommonApi from "../../../api/commonApi";
import { ko } from "../../../utils/ko";

import ImageResize from "quill-image-resize-module-react";
import BlotFormatter from "quill-blot-formatter";
import LoadingFile from "../../templates/loadingFile";

Quill.register("modules/blotFormatter", BlotFormatter);
Quill.register("modules/imageResize", ImageResize);

function TextFormEditor({
  type,
  title,
  placeholder,
  value,
  onChange,
  style,
  editorStyle,
  editorHeight,
  disabled,
  position,
  isBlur,
  isLoading,
  setIsLoading,
}) {
  const quillRef = useRef();

  const handleImage = () => {
    // 1. 이미지를 저장할 input type=file DOM을 만든다.
    const input = document.createElement("input");
    // 속성 써주기
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.setAttribute("multiple", "multiple");
    input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
    // input이 클릭되면 파일 선택창이 나타난다.

    // input에 변화가 생긴다면 = 이미지를 선택
    input.addEventListener("change", async () => {
      if (input.files != null && input.files[0] != null) {
        setIsLoading(true);
        for (let i = 0; i < input.files.length; i++) {
          const file = input.files[i];
          // multer에 맞는 형식으로 데이터 만들어준다.

          // 백엔드 multer라우터에 이미지를 보낸다.
          try {
            const result = await fileAddFunc(file);
            const IMG_URL = result;
            // 이 URL을 img 태그의 src에 넣은 요소를 현재 에디터의 커서에 넣어주면 에디터 내에서 이미지가 나타난다
            // src가 base64가 아닌 짧은 URL이기 때문에 데이터베이스에 에디터의 전체 글 내용을 저장할 수있게된다
            // 이미지는 꼭 로컬 백엔드 uploads 폴더가 아닌 다른 곳에 저장해 URL로 사용하면된다.

            // 이미지 태그를 에디터에 써주기 - 여러 방법이 있다.
            const editor = quillRef.current.getEditor(); // 에디터 객체 가져오기
            // 1. 에디터 root의 innerHTML을 수정해주기
            // editor의 root는 에디터 컨텐츠들이 담겨있다. 거기에 img태그를 추가해준다.
            // 이미지를 업로드하면 -> 멀터에서 이미지 경로 URL을 받아와 -> 이미지 요소로 만들어 에디터 안에 넣어준다.
            // editor.root.innerHTML =
            //   editor.root.innerHTML + `<img src=${IMG_URL} /><br/>`; // 현재 있는 내용들 뒤에 써줘야한다.

            // 2. 현재 에디터 커서 위치값을 가져온다
            const range = editor.getSelection();
            // 가져온 위치에 이미지를 삽입한다
            editor.insertEmbed(range.index, "image", IMG_URL);
          } catch (error) {
            console.log(error);
          }
        }
        setIsLoading(false);
      }
    });
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ size: ["small", false, "large", "huge"] }], // custom dropdown
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ list: "ordered" }, { list: "bullet" }],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          ["link", "image", "video"],
          ["clean"],
        ],

        imageResize: {
          parchment: Quill.import("parchment"),
          modules: ["Resize", "DisplaySize"],
        },
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        },
        handlers: {
          // 이미지 처리는 우리가 직접 imageHandler라는 함수로 처리할 것이다.
          image: handleImage,
          // video: videoHandler,
        },
      },
      blotFormatter: {},
    };
  }, []);

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "size",
    "color",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "align",
  ];

  const fileAddFunc = async (file) => {
    try {
      const response = await CommonApi.FileAdd(file);

      return response.data.data.fileUrl;
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    if (isBlur) {
      quillRef.current.blur();
    }
  }, [position]);

  return (
    <>
      <TextFormWrapper
        style={{
          height: editorHeight,
          ...style,
        }}
      >
        {/* <TextFormTitle type={type}>{title}</TextFormTitle> */}
        <div
          className="text-editor"
          style={{
            width: "100%",
            height: editorHeight,
            ...editorStyle,
          }}
        >
          <ReactQuill
            theme="snow"
            ref={quillRef}
            modules={modules}
            formats={formats}
            value={value || ""}
            onChange={onChange}
            style={{
              height: editorHeight - 40,
            }}
            placeholder={placeholder}
          />
          {/* {isLoading ? <LoadingFile /> : null} */}
        </div>
      </TextFormWrapper>
    </>
  );
}

export default TextFormEditor;

const TextFormWrapper = styled.div.attrs((props) => {})`
  display: flex;
  gap: 20px;
  height: 48px;
  margin-bottom: 24px;
`;

const TextFormTitle = styled(PretendardText).attrs((props) => {})`
  display: block;
  min-width: ${(props) => (props.type === "edit" ? "132px" : "200px")};
  color: var(--gray-4, #adb4ba);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.5px;
`;
