import api from "./api.js";

export default class ProfileApi {
  static async Info() {
    return await api.get(`user/i-PRO-001_F0`);
  }

  static async BulletinList(data) {
    return await api.get(`user/i-PRO-001_F1`, data);
  }

  static async ChallengeList(data) {
    return await api.get(`user/i-PRO-001_F2`, data);
  }

  static async ChallengeAllList(data) {
    return await api.get(`user/i-MYC-002_F0`, data);
  }
}
