import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PretendardText from "../../atoms/pretendardText";
import styled from "styled-components";

const ReviewBar = ({ text, style, index, value }) => {
  const history = useHistory();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...style,
      }}
    >
      <div
        style={{
          height: "61px",
          width: "9px",
          borderRadius: "17px",
          backgroundColor: "#ECEDF0",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "0",
            left: "0",
            backgroundColor: "#5ECECE",
            height: `${value}%`,

            width: "11px",
          }}
        />
      </div>
      <PretendardText
        style={{
          marginTop: "5px",
          fontSize: 12,
          lineHeight: "16.5px",
          color: "#ADB4BA",
          fontWeight: 500,
        }}
      >
        {text}
      </PretendardText>
    </div>
  );
};

export default ReviewBar;
