import React, { useState, useEffect } from "react";
import useDisplay from "../../../hooks/useDisplay";
import styled from "styled-components";

import { Radio } from "../../atoms/radio/radio";
import PretendardText from "../../atoms/pretendardText";

function RightLabelRadio({
  text,
  size,
  checked,
  inputStyle,
  onClick,
  textStyle,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
          ...inputStyle,
        }}
      >
        <Radio checked={checked} onChange={onClick} size={size} />

        <StyledP
          onClick={onClick}
          style={{
            ...textStyle,
          }}
        >
          {text}
        </StyledP>
      </div>
    </>
  );
}

export default RightLabelRadio;

const StyledP = styled(PretendardText)`
  color: #262c31;
  font-weight: 500;
  font-size: 15px;
  line-height: 22.3px;
  margin-left: 8px;
  flex: 1;
`;
