import React, { useEffect } from "react";
import useDisplay from "../../hooks/useDisplay";
import PretendardText from "../atoms/pretendardText";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TextBtn from "../atoms/button/textBtn";
import { route } from "../../routes/route";
import { toast } from "react-toastify";
import { ko } from "date-fns/locale";
import UserApi from "../../api/userApi";
import Video from "../atoms/video/video";
import { getExtensionOfFilename, videoExtension } from "../../utils/file";
import { shareFunc } from "../../utils/share";

function ShortFormCard({ item, storeClickFunc, likeClickFunc }) {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "66%",
          borderRadius: "16px",
          overflow: "hidden",
          marginBottom: "16px",
          position: "relative",
        }}
      >
        <img
          src={"/assets/images/dim.png"}
          alt=""
          style={{
            // minWidth: isMobile ? '335px' : '446px',
            // minHeight: isMobile ? '509px' : '678px',
            position: "absolute",
            width: isMobile ? "100%" : "446px",
            height: isMobile ? "509px" : "678px",
          }}
        />

        {videoExtension.includes(getExtensionOfFilename(item.fileUrl)) ? (
          <img
            src={
              item.thumbFileUrl
                ? item.thumbFileUrl
                : "/assets/images/image_default.png"
            }
            alt=""
            style={{
              // minWidth: isMobile ? '335px' : '446px',
              // minHeight: isMobile ? '509px' : '678px',
              width: isMobile ? "100%" : "446px",
              height: isMobile ? "509px" : "678px",
            }}
          />
        ) : (
          <img
            src={
              item.fileUrl ? item.fileUrl : "/assets/images/image_default.png"
            }
            alt=""
            style={{
              // minWidth: isMobile ? '335px' : '446px',
              // minHeight: isMobile ? '509px' : '678px',
              width: isMobile ? "100%" : "446px",
              height: isMobile ? "509px" : "678px",
            }}
          />
        )}
        <div style={{}}>
          {/* 상단 */}
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              paddingTop: "6%",
              paddingLeft: "7%",
              paddingRight: "7%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "50%",
                  border: "2px solid rgba(255, 255, 255, 1)",
                  overflow: "hidden",
                  marginRight: "10px",
                }}
              >
                <TextBtn
                  onClick={() => {
                    history.push({
                      pathname:
                        route.influencer_profile +
                        `/${item?.influencerInfo.id}`,
                    });
                  }}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img
                    src={
                      item?.influencerInfo?.fileUrl
                        ? item?.influencerInfo?.fileUrl
                        : "/assets/images/profile_temp.png"
                    }
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </TextBtn>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <TextBtn
                    onClick={() => {
                      history.push({
                        pathname:
                          route.influencer_profile +
                          `/${item?.influencerInfo.id}`,
                      });
                    }}
                  >
                    <PretendardText
                      style={{
                        color: "rgba(255, 255, 255, 1)",
                        fontSize: "13px",
                        lineHeight: "18.75px",
                        fontWeight: "600",
                        width: "60px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item?.influencerInfo.nickName}
                    </PretendardText>
                  </TextBtn>
                  <img
                    src="/assets/icons/card_checkstar.svg"
                    alt=""
                    style={{
                      width: "17px",
                      height: "17px",
                      marginLeft: "1px",
                    }}
                  />
                </div>
                <PretendardText
                  style={{
                    color: "rgba(255, 255, 255, 0.6)",
                    fontSize: "11px",
                    lineHeight: "14px",
                    fontWeight: "600",
                  }}
                >
                  {item?.dateTitle}
                </PretendardText>
              </div>
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <TextBtn
                onClick={() => {
                  storeClickFunc(item?.isStored, item?.id);
                }}
              >
                <img
                  src={
                    item?.isStored
                      ? "/assets/icons/bookmark_white.png"
                      : "/assets/icons/bookmark_black_d.png"
                  }
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                    filter: item?.isStored
                      ? ""
                      : "invert(100%) sepia(0%) saturate(7500%) hue-rotate(167deg) brightness(108%) contrast(102%)",
                  }}
                />
              </TextBtn>
              <TextBtn
                onClick={() => {
                  history.push({
                    pathname: route.report_write,
                    state: {
                      id: item?.id,
                      reportType: "SHORT_FORM",
                    },
                  });
                }}
              >
                <img
                  src="/assets/icons/ic_24_warning_white.png"
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                    marginLeft: "20px",
                    filter:
                      "invert(100%) sepia(0%) saturate(7500%) hue-rotate(167deg) brightness(108%) contrast(102%)",
                  }}
                />
              </TextBtn>
            </div>
          </div>

          {videoExtension.includes(getExtensionOfFilename(item.fileUrl)) ? (
            <TextBtn>
              <div
                style={{
                  position: "absolute",
                  left: "42.6%",
                  top: "44.2%",
                }}
                onClick={() => {
                  history.push({
                    pathname: route.short_form_detail + `/${item?.id}`,
                  });
                }}
              >
                <img
                  src="/assets/icons/ic_64_play.png"
                  alt=""
                  style={{
                    width: "64px",
                    height: "64px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </TextBtn>
          ) : (
            <TextBtn>
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "80%",
                  top: "10%",
                }}
                onClick={() => {
                  history.push({
                    pathname: route.short_form_detail + `/${item?.id}`,
                  });
                }}
              ></div>
            </TextBtn>
          )}

          {/* 중단 - 메뉴 */}
          <div
            style={{
              position: "absolute",
              top: item.challengeInfo?.merch ? "19%" : "29.7%",
              right: "5.1%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              height: "50%",
            }}
          >
            <TextBtn
              onClick={() => {
                likeClickFunc(item?.isLiked, item?.id);
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "43px",
                }}
              >
                <img
                  src={
                    item?.isLiked
                      ? "/assets/icons/ic_24_heart.png"
                      : "/assets/icons/ic_24_heart_empty.png"
                  }
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                  }}
                />
                <PretendardText
                  style={{
                    color: "rgba(255, 255, 255, 1)",
                    fontSize: "11px",
                    lineHeight: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  {item?.likeAmount}
                </PretendardText>
              </div>
            </TextBtn>
            <TextBtn
              onClick={() => {
                history.push({
                  pathname: route.short_form_detail + `/${item?.id}`,
                });
              }}
            >
              <div
                style={{
                  height: "43px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src="/assets/icons/ic_24_comment.png"
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                  }}
                />
                <PretendardText
                  style={{
                    color: "rgba(255, 255, 255, 1)",
                    fontSize: "11px",
                    lineHeight: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  {item?.commentAmount}
                </PretendardText>
              </div>
            </TextBtn>
            <TextBtn
              onClick={() => {
                shareFunc();
              }}
            >
              <div
                style={{
                  height: "43px",
                }}
              >
                <img
                  src="/assets/icons/ic_24_share.svg"
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </TextBtn>
            <TextBtn
              onClick={() => {
                history.push(
                  route.entry_certification + `/${item?.challengeInfo?.id}`
                );
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "43px",
                }}
              >
                <img
                  src="/assets/icons/ic_24_victory.png"
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                  }}
                />
                <PretendardText
                  style={{
                    color: "rgba(255, 255, 255, 1)",
                    fontSize: "11px",
                    lineHeight: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  인증보기
                </PretendardText>
              </div>
            </TextBtn>
          </div>
          {/* 하단 */}
          <div
            style={{
              position: "absolute",
              bottom: "6%",
              left: "7.2%",
            }}
          >
            <div
              style={{
                borderRadius: "6px",
                width: "46px",
                height: "23px",
                backgroundColor:
                  item?.challengeInfo.challengeStatus === "END"
                    ? "rgba(38, 44, 49, 1)"
                    : "rgba(119, 128, 136, 1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  fontWeight: 700,
                  color: "rgba(255, 255, 255, 1)",
                  textAlign: "center",
                }}
              >
                {item?.challengeInfo.challengeStatusTitle}
              </PretendardText>
            </div>
            <TextBtn
              onClick={() => {
                history.push({
                  pathname:
                    route.challenge_detail + `/${item.challengeInfo.id}`,
                });
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1.9%",
                }}
              >
                <PretendardText
                  style={{
                    color: "rgba(255, 255, 255, 1)",
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: "600",
                    maxWidth: isMobile ? "73vw" : "370px",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item?.challengeInfo.title}
                </PretendardText>
                <img
                  src="/assets/icons/ic_16_arrow_white.svg"
                  alt=""
                  style={{
                    width: "16px",
                    height: "16px",
                  }}
                />
              </div>
            </TextBtn>
            <PretendardText
              onClick={() => {
                history.push(route.short_form_detail + `/${item?.id}`);
              }}
              style={{
                color: "rgba(255, 255, 255, 1)",
                fontSize: "13px",
                lineHeight: "18.75px",
                fontWeight: "400",
                width: isMobile ? "76vw" : "384px",
                marginTop: "1.1%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                display: "-webkit-box",
              }}
            >
              {item?.contents}
            </PretendardText>
            {item.challengeInfo?.merch ? (
              <div>
                <div
                  style={{
                    background: "rgba(0,0,0,0.8)",
                    borderRadius: "8px",
                    padding: "9px",
                    display: "flex",
                    marginTop: "12px",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <img
                    src={
                      item.challengeInfo?.merch?.merchImages.length > 0
                        ? item.challengeInfo?.merch.merchImages[0].imageUrl
                        : "/assets/images/image_default.png"
                    }
                    alt=""
                    style={{
                      width: "42px",
                      height: "42px",
                      borderRadius: "4px",
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "9px",
                    }}
                  >
                    <PretendardText
                      style={{
                        color: "rgba(255, 255, 255, 1)",
                        fontSize: "13px",
                        lineHeight: "18.75px",
                        fontWeight: "600",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "211px",
                      }}
                    >
                      {item.challengeInfo?.merch?.name}
                    </PretendardText>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PretendardText
                        style={{
                          color: "rgba(255, 255, 255, 1)",
                          fontSize: "12px",
                          lineHeight: "16.5px",
                          fontWeight: "500",
                        }}
                      >
                        {Number(
                          item.challengeInfo?.merch?.price
                        ).toLocaleString("ko-KR")}
                        원
                      </PretendardText>
                      {item.challengeInfo?.merch?.originPrice ===
                      item.challengeInfo?.merch?.price ? null : (
                        <PretendardText
                          style={{
                            color: "rgba(255, 89, 89, 1)",
                            fontSize: "13px",
                            lineHeight: "18.75px",
                            fontWeight: "600",
                            marginLeft: "4px",
                          }}
                        >
                          {100 -
                            Number(
                              (item.challengeInfo?.merch?.price /
                                item.challengeInfo?.merch?.originPrice) *
                                100
                            ).toFixed(0)}
                          %
                        </PretendardText>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default ShortFormCard;
