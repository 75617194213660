import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import Empty from "../components/molecules/empty";
import InquirySection from "../components/molecules/inquiry/inquirySection";
import InqueryApi from "../api/inqueryApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import { route } from "../routes/route";

function InquiryList(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [ref, inView] = useInView();
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setData = async () => {
    try {
      setLoading(true);
      const response = await InqueryApi.List({
        page: currentPage,
        size: 10,
      });

      const data = response.data.data;
      setDataList(dataList.concat(data.content));
      setIsLast(data.last);
      setLoading(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    setData();
  }, [currentPage]);

  useEffect(() => {
    if (inView && !isLast && !loading) {
      setCurrentPage(currentPage + 1);
      setData();
    }
  }, [inView, loading]);

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header title={"1:1 문의하기"} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                marginTop: 12,
                marginBottom: 19,
                padding: "0px 20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "#262C31",
                  fontWeight: 700,
                }}
              >
                내가 쓴 문의
              </PretendardText>
              <MainBtn
                onClick={() => {
                  history.push({
                    pathname: route.inquiry_write,
                  });
                }}
                style={{
                  borderRadius: 8,
                  height: 35,
                  width: 85,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 13,
                    lineHeight: "18.75px",
                    color: "white",
                    fontWeight: 700,
                  }}
                >
                  문의하기
                </PretendardText>
              </MainBtn>
            </div>

            {dataList.length > 0 ? (
              dataList.map((item, index) => {
                return <InquirySection key={index} data={item} />;
              })
            ) : (
              <Empty text={"문의내역이 없어요"} />
            )}

            <div ref={ref}>{/* loading */}</div>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default InquiryList;
