import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Banner from "../components/molecules/banner/banner";
import Header from "../components/molecules/header/header";
import TitleInput from "../components/molecules/input/titleInput";
import LeftLabelCheckbox from "../components/atoms/checkbox/leftLabelCheckbox";
import Input from "../components/atoms/input/input";
import styled from "styled-components";
import { route } from "../routes/route";
import BannerApi from "../api/bannerApi";
import UserApi from "../api/userApi";
import { toast } from "react-toastify";

let timer;

function RegisterSocial(props) {
  const [socialToken, setSocialToken] = useState("");
  useEffect(() => {
    if (sessionStorage.getItem("joinType") === "NAVER") {
      setName(sessionStorage.getItem("name"));
      setEmail(sessionStorage.getItem("email"));
      if (sessionStorage.getItem("gender")) {
        setGender(sessionStorage.getItem("gender") === "M" ? 1 : 2);
      }
      setSocialFlag(1);
    } else if (sessionStorage.getItem("joinType") === "KAKAO") {
      if (sessionStorage.getItem("email")) {
        setEmail(sessionStorage.getItem("email"));
        setEmailFlag(true);
      }
      if (sessionStorage.getItem("gender")) {
        setGender(sessionStorage.getItem("gender") === "male" ? 1 : 2);
        setGenderFlag(true);
      }
      setSocialFlag(2);
    }

    setSocialToken(sessionStorage.getItem("socialId"));
  }, []);

  let min = 3;
  let sec = 0;

  const [socialFlag, setSocialFlag] = useState(); // 1: NAVER, 2: KAKAO
  const [emailFlag, setEmailFlag] = useState(false);
  const [genderFlag, setGenderFlag] = useState(false);

  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  const [genderFocus, setGenderFocus] = useState(false);

  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [birth, setBirth] = useState("");
  const [birthErr, setBirthErr] = useState("");
  const [gender, setGender] = useState("");
  const [genderErr, setGenderErr] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [isPNC, setIsPNC] = useState(false);
  const [phoneNumberChk, setPhoneNumberChk] = useState(false);

  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [phoneNumberValueErr, setPhoneNumberValueErr] = useState("");
  const [certifyValue, setCertifyValue] = useState("");

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [emailChk, setEmailChk] = useState(false);

  const [pw, setPw] = useState("");
  const [pwErr, setPwErr] = useState("");
  const [pwConfirm, setPwConfirm] = useState("");
  const [pwConfirmErr, setPwConfirmErr] = useState("");

  const [allCheck, setAllCheck] = useState(false);
  const [terms, setTerms] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [policy02, setPolicy02] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [service, setService] = useState(false);
  const [ad, setAd] = useState(false);

  const [bannerInfo, setBannerInfo] = useState();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [isSNS, setIsSNS] = useState(true);

  const [minState, setMinState] = useState(3);
  const [secState, setSecState] = useState(0);

  const [isDataTemp, setIsDataTemp] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const rDataTemp = localStorage.getItem("rDataTemp");

    if (rDataTemp) {
      setIsDataTemp(true);
      dataMatchFunc(JSON.parse(rDataTemp));
    }

    bannerGetFunc();

    return () => {
      clearTimer("타이머 종료");
    };
  }, []);

  useEffect(() => {
    if (birth?.length > 0) {
      var regType1 = /([0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1,2][0-9]|3[0,1]))/;
      if (!regType1.test(birth)) {
        return setBirthErr("생년월일을 올바르게 입력해주세요.");
      }

      setBirthErr("");
    }
  }, [birth]);

  useEffect(() => {
    if (gender?.length > 0) {
      if (gender != "1" && gender != "2" && gender != "3" && gender != "4") {
        return setGenderErr("생년월일을 올바르게 입력해주세요.");
      }

      setGenderErr("");
    }
  }, [gender]);

  useEffect(() => {
    if (phone?.length > 0) {
      var regType1 = /^[0-9]+$/;
      if (!regType1.test(phone)) {
        return setPhoneErr("-없이 숫자만 입력해주세요.");
      }

      setPhoneErr("");

      if (isDataTemp === false) {
        setPhoneNumberChk(false);
      }
      setIsPNC(false);
    }
  }, [phone]);

  useEffect(() => {
    if (email?.length > 0) {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
      if (!regex.test(email)) {
        return setEmailErr("이메일 형식이 올바르지 않습니다.");
      }

      setEmailErr("");

      if (isDataTemp === false) {
        setEmailChk(false);
      }
    }
  }, [email]);

  // useEffect(() => {
  //   if (pw?.length > 0) {
  //     var regType1 = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/;

  //     if (!regType1.test(pw)) {
  //       return setPwErr("8-16자 영문, 숫자, 특수문자 조합으로 입력해주세요.");
  //     }

  //     if (pw.length < 8) {
  //       return setPwErr("비밀번호 8자리 이상을 입력해주세요.");
  //     }

  //     setPwErr("");
  //   }
  // }, [pw]);

  useEffect(() => {
    if (pwConfirm?.length > 0) {
      if (pwConfirm !== pw) {
        return setPwConfirmErr("비밀번호가 일치하지 않습니다.");
      }

      setPwConfirmErr("");
    }
  }, [pwConfirm]);

  const bannerGetFunc = async () => {
    try {
      let response = await BannerApi.List();

      if (response.data.data.content.length > 0) {
        let bannerTemp = response.data.data.content.find((item) => item.title === "회원가입 배너");

        setBannerInfo(bannerTemp);
      }
    } catch (error) {}
  };

  const countTimerStart = () => {
    timer = setInterval(countTimer, 1000);
  };

  const countTimer = () => {
    if (sec != 0) {
      sec = sec - 1;
      setSecState(sec);
    } else {
      if (min != 0) {
        min = min - 1;
        sec = 59;

        setSecState(sec);
        setMinState(min);
      } else {
        clearTimer("타이머 종료");
        setPhoneErr("인증에 실패하였습니다. 인증을 다시 시도하세요.");
      }
    }
  };

  function clearTimer(text) {
    clearInterval(timer);

    if (text === "타이머 종료") {
      min = 3;
      setMinState(3);
      sec = 0;
      setSecState(0);
    }
  }

  useEffect(() => {
    if (
      (name &&
        nameErr == "" &&
        birth &&
        birthErr == "" &&
        gender &&
        genderErr == "" &&
        phone &&
        phoneErr == "" &&
        phoneNumberChk &&
        email &&
        emailErr == "" &&
        emailChk &&
        pwConfirmErr == "" &&
        pwConfirm &&
        pw &&
        pwErr == "" &&
        terms &&
        policy &&
        policy02) ||
      (isSNS &&
        nameErr == "" &&
        birth &&
        birthErr == "" &&
        gender &&
        genderErr == "" &&
        phone &&
        phoneErr == "" &&
        phoneNumberChk &&
        email &&
        emailErr == "" &&
        emailChk &&
        terms &&
        policy &&
        policy02)
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [
    name,
    nameErr,
    birth,
    birthErr,
    gender,
    genderErr,
    phone,
    phoneErr,
    phoneNumberChk,
    email,
    emailErr,
    emailChk,
    pw,
    pwErr,
    pwConfirm,
    pwConfirmErr,
    isSNS,
    terms,
    policy,
    policy02,
  ]);

  const allCheckFunc = () => {
    if (!allCheck) {
      setAllCheck(true);
      setTerms(true);
      setPolicy(true);
      setPolicy02(true);
      setMarketing(true);
      setService(true);
      setAd(true);
    } else {
      setAllCheck(false);
      setTerms(false);
      setPolicy(false);
      setPolicy02(false);
      setMarketing(false);
      setService(false);
      setAd(false);
    }
  };

  useEffect(() => {
    if (terms && policy && policy02 && ad && marketing && service) {
      setAllCheck(true);
    } else {
      setAllCheck(false);
    }
  }, [terms, policy, ad, marketing, service, policy02]);

  const emailCheckApi = async () => {
    try {
      if (emailErr != "" || email == "") {
        return;
      }

      const data = {
        email: email,
      };

      const response = await UserApi.REmailCheck(data);

      if (response.data.data.isDuplicated) {
        setEmailErr("이메일이 중복되었습니다.");
        setEmailChk(false);
      } else {
        setEmailErr("");
        setEmailChk(true);
      }
    } catch (err) {
      setEmailChk(false);
    }
  };

  const sendSMSAPI = async () => {
    try {
      if (phone.length != 11) {
        setIsPNC(false);
        setPhoneErr("휴대폰 번호가 올바르지 않습니다.");

        return;
      }

      clearTimer("타이머 종료");

      const data = {
        phoneNumber: phone,
      };
      const response = await UserApi.RPhoneCertify(data);

      if (response.data.data.randomNumber) {
        setPhoneNumberChk(false);
        setCertifyValue(response.data.data.randomNumber);
        setPhoneErr("");
        setPhoneNumberValue("");
        setPhoneNumberValueErr("");

        setIsPNC(true);
        countTimerStart();
      }
    } catch (err) {
      setIsPNC(false);
      setPhoneErr("이미 등록된 휴대폰 번호 입니다.");
    }
  };

  const phoneNumberChkFunc = () => {
    if (certifyValue === phoneNumberValue) {
      setPhoneNumberChk(true);
      setPhoneNumberValueErr("");

      clearTimer("완");
    } else {
      setPhoneNumberValueErr("인증번호가 올바르지 않습니다.");
      setPhoneNumberChk(false);
    }
  };

  const localSaveFunc = () => {
    const dataTemp = {
      name: name,
      nameErr: nameErr,
      birth: birth,
      birthErr: birthErr,
      gender: gender,
      genderErr: genderErr,
      phone: phone,
      phoneErr: phoneErr,
      isPNC: isPNC,
      phoneNumberChk: phoneNumberChk,
      phoneNumberValue: phoneNumberValue,
      phoneNumberValueErr: phoneNumberValueErr,
      certifyValue: certifyValue,
      email: email,
      emailErr: emailErr,
      emailChk: emailChk,
      pw: pw,
      pwErr: pwErr,
      pwConfirm: pwConfirm,
      allCheck: allCheck,
      terms: terms,
      policy: policy,
      policy02: policy02,
      marketing: marketing,
      service: service,
      ad: ad,
      socialToken: socialToken,
    };

    localStorage.setItem("rDataTemp", JSON.stringify(dataTemp));
  };

  const dataMatchFunc = (data) => {
    setName(data.name);
    setNameErr(data.nameErr);
    setBirth(data.birth);
    setBirthErr(data.birthErr);
    setGender(data.gender);
    setGenderErr(data.genderErr);
    setPhone(data.phone);
    setPhoneErr(data.phoneErr);
    setIsPNC(data.isPNC);
    setPhoneNumberChk(data.phoneNumberChk);
    setPhoneNumberValue(data.phoneNumberValue);
    setPhoneNumberValueErr(data.phoneNumberValueErr);
    setCertifyValue(data.certifyValue);
    setEmail(data.email);
    setEmailErr(data.emailErr);
    setEmailChk(data.emailChk);
    setPw(data.pw);
    setPwErr(data.pwErr);
    setPwConfirm(data.pwConfirm);
    setPwConfirmErr(data.pwConfirmErr);
    setAllCheck(data.allCheck);
    setTerms(data.terms);
    setPolicy(data.policy);
    setPolicy02(data.policy02);
    setMarketing(data.marketing);
    setService(data.service);
    setAd(data.ad);

    setTimeout(() => {
      setIsDataTemp(false);
    }, 100);
  };

  const moveTerms = (title) => {
    localSaveFunc();

    history.push({
      pathname: route.term_detail,
      state: {
        title: title,
      },
    });
  };

  const moveNext = () => {
    localSaveFunc();

    const today = new Date();

    let birthYY = birth.slice(0, 4);
    let birthMM = Number(birth.slice(4, 6)) - 1;
    let birthDD = birth.slice(6, 8);

    const birthDate = new Date(Number(birthYY), Number(birthMM), Number(birthDD)); // 2000년 8월 10일

    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    if (age > 14) {
      history.push({
        pathname: route.profile_register_social,
      });
    } else {
      toast("만 14세는 가입이 어렵습니다.");
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <div
            style={{
              width: "100%",
              height: 50,
              paddingLeft: 20,
              paddingRight: 20,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              position: "relative",
              ...props.style,
            }}
          >
            <div
              onClick={() => {
                history.push(route.main);
              }}
              style={{
                position: "absolute",
                left: 20,
                cursor: "pointer",
                width: 24,
                height: 24,
              }}
            >
              <img
                src="/assets/icons/left_btn.png"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </div>
          </div>

          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Banner
              src={bannerInfo && bannerInfo.imageUrl ? bannerInfo.imageUrl : ""}
              style={{
                marginTop: 17,
              }}
              link={bannerInfo && bannerInfo.link ? bannerInfo.link : ""}
            />

            <div
              style={{
                marginTop: 25,
                paddingBottom: 20,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 32,
                  lineHeight: "44.16px",
                  color: "#262C31",
                  fontWeight: 600,
                }}
              >
                회원가입
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "21.5px",
                  color: "#5A636A",
                  fontWeight: 500,
                }}
              >
                헬스라이프스타일을 즐겁고 신나게!
                <br />
                헬랄라에 오신 것을 환영합니다.
              </PretendardText>

              <div
                style={{
                  marginTop: 47,
                }}
              >
                <TitleInput
                  title={"이름"}
                  placeholder="이름을 입력해주세요"
                  type={"text"}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                  disabled={socialFlag === 1}
                />

                <PretendardText
                  style={{
                    fontSize: 13,
                    lineHeight: "18.75px",
                    color: "#778088",
                    fontWeight: 600,
                    marginBottom: 4,
                    marginTop: 17,
                  }}
                >
                  생년월일
                </PretendardText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Input
                      maxLength={"8"}
                      value={birth}
                      onChange={(e) => {
                        setBirth(e.target.value);
                      }}
                      type={"text"}
                      placeholder={"YYYYMMDD"}
                    />
                    {birthErr ? (
                      <PretendardText
                        style={{
                          fontWeight: 500,
                          fontSize: 13,
                          lineHeight: "18.2px",
                          color: "#F24147",
                          marginTop: 4,
                        }}
                      >
                        {birthErr}
                      </PretendardText>
                    ) : null}
                  </div>

                  <div
                    style={{
                      height: 2,
                      borderLeft: "8px solid #ADB4BA",
                      marginLeft: 15,
                      marginRight: 15,
                      marginTop: 25,
                    }}
                  />

                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "100%",
                        height: 51,
                        background: "rgb(244, 246, 246)",
                        border: genderFocus ? "1px solid #ff3333" : "1px solid rgb(244, 246, 246)",
                        borderRadius: 12,
                        padding: "14px 16px",
                      }}
                    >
                      <InputWrapper
                        maxLength={"1"}
                        onFocus={() => {
                          setGenderFocus(true);
                        }}
                        onBlur={() => {
                          setGenderFocus(false);
                        }}
                        value={gender}
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                        type={"text"}
                        placeholder={"N"}
                        disabled={socialFlag === 1 || genderFlag}
                      />

                      <PretendardText
                        style={{
                          fontSize: 15,
                          lineHeight: "22.3px",
                          color: "#adb4ba",
                          fontWeight: 500,
                        }}
                      >
                        ●●●●●●
                      </PretendardText>
                    </div>
                    {genderErr ? (
                      <PretendardText
                        style={{
                          fontWeight: 500,
                          fontSize: 13,
                          lineHeight: "18.2px",
                          color: "#F24147",
                          marginTop: 4,
                        }}
                      >
                        {genderErr}
                      </PretendardText>
                    ) : null}
                  </div>
                </div>

                <TitleInput
                  style={{
                    marginTop: 17,
                  }}
                  title={"휴대폰번호"}
                  placeholder="번호만 입력가능합니다"
                  type={"text"}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  value={phone}
                  btn={true}
                  btnText={isPNC ? "재전송" : "인증요청"}
                  onClick={() => {
                    sendSMSAPI();
                  }}
                  warningMessage={phoneErr}
                  maxLength={11}
                  btnStyle={{
                    marginTop: phoneErr ? -23 : 0,
                    background: isPNC ? "transparent" : "#262C31",
                    border: isPNC ? "2px solid #262C31" : "none",
                  }}
                  btnTextStyle={{
                    lineHeight: phoneErr && isMobile ? "17.75px" : "18.75px",
                    color: isPNC ? "#262C31" : "#ffffff",
                  }}
                  inputStyle={{
                    letterSpacing: "0.6px",
                  }}
                />

                {isPNC ? (
                  <TitleInput
                    style={{
                      marginTop: 17,
                    }}
                    title={"인증번호 입력"}
                    placeholder="인증번호 6자리를 입력하세요"
                    type={"text"}
                    disabled={phoneNumberChk ? true : false}
                    onChange={(e) => {
                      setPhoneNumberValue(e.target.value);
                    }}
                    warningMessage={phoneNumberValueErr}
                    maxLength={6}
                    value={phoneNumberValue}
                    timer={phoneNumberChk ? false : true}
                    min={minState}
                    sec={secState}
                    timerColor={"#9D56FB"}
                    btn={true}
                    btnText={phoneNumberChk ? "인증완료" : "인증하기"}
                    onClick={() => {
                      phoneNumberChkFunc();
                    }}
                    btnStyle={{
                      marginTop: phoneNumberValueErr || phoneNumberChk ? -23 : 0,
                      background: phoneNumberChk ? "#ADB4BA" : "#262C31",
                    }}
                    btnTextStyle={{
                      lineHeight: phoneNumberValueErr || (phoneNumberChk && isMobile) ? "17.75px" : "18.75px",
                    }}
                    checkMessage={phoneNumberChk ? "인증되었습니다." : ""}
                  />
                ) : null}

                <TitleInput
                  style={{
                    marginTop: 17,
                  }}
                  title={"이메일"}
                  placeholder="이메일을 입력하세요"
                  type={"text"}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  btn={true}
                  btnText={"중복확인"}
                  onClick={() => {
                    emailCheckApi();
                  }}
                  warningMessage={emailErr}
                  btnStyle={{
                    marginTop: emailErr || emailChk ? -23 : 0,
                  }}
                  btnTextStyle={{
                    lineHeight: (emailErr || emailChk) && isMobile ? "17.75px" : "18.75px",
                  }}
                  checkMessage={emailChk ? "사용가능한 이메일입니다." : ""}
                  disabled={socialFlag === 1 || emailFlag}
                />

                {/* <TitleInput
                  style={{
                    marginTop: 17,
                  }}
                  title={"비밀번호"}
                  placeholder="8-16자리 영문, 숫자, 특수문자 조합"
                  type={"password"}
                  onChange={(e) => {
                    setPw(e.target.value);
                  }}
                  value={pw}
                  warningMessage={pwErr}
                  maxLength={"16"}
                />

                <TitleInput
                  style={{
                    marginTop: 17,
                  }}
                  title={"비밀번호 확인"}
                  placeholder="비밀번호를 다시 입력하세요"
                  type={"password"}
                  onChange={(e) => {
                    setPwConfirm(e.target.value);
                  }}
                  value={pwConfirm}
                  warningMessage={pwConfirmErr}
                  maxLength={"16"}
                /> */}

                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "25.2px",
                    color: "#262C31",
                    fontWeight: 700,
                    marginTop: 19,
                  }}
                >
                  약관동의
                </PretendardText>

                <LeftLabelCheckbox
                  textStyle={{
                    fontWeight: 600,
                  }}
                  inputStyle={{
                    marginTop: 11,
                  }}
                  checked={allCheck}
                  onClick={() => {
                    allCheckFunc();
                  }}
                  onChange={(e) => {
                    allCheckFunc();
                  }}
                  text={"전체 동의"}
                />

                <LeftLabelCheckbox
                  textStyle={{
                    textDecoration: "underline",
                    textUnderlinePosition: "under",
                  }}
                  inputStyle={{
                    marginTop: 24,
                  }}
                  checked={terms}
                  onClick={() => {
                    moveTerms("헬랄라 회원이용약관 (필수)");
                  }}
                  onChange={() => {
                    setTerms(!terms);
                  }}
                  text={"헬랄라 회원이용약관 (필수)"}
                />

                <LeftLabelCheckbox
                  textStyle={{
                    textDecoration: "underline",
                    textUnderlinePosition: "under",
                  }}
                  inputStyle={{
                    marginTop: 24,
                  }}
                  checked={policy}
                  onClick={() => {
                    moveTerms("개인정보수집 및 이용동의 (필수)");
                  }}
                  onChange={() => {
                    setPolicy(!policy);
                  }}
                  text={"개인정보수집 및 이용동의 (필수)"}
                />

                <LeftLabelCheckbox
                  textStyle={{
                    textDecoration: "underline",
                    textUnderlinePosition: "under",
                  }}
                  inputStyle={{
                    marginTop: 24,
                  }}
                  checked={policy02}
                  onClick={() => {
                    moveTerms("개인정보 제 3자 제공 동의 (필수)");
                  }}
                  onChange={() => {
                    setPolicy02(!policy02);
                  }}
                  text={"개인정보 제 3자 제공 동의 (필수)"}
                />

                <LeftLabelCheckbox
                  textStyle={{
                    textDecoration: "underline",
                    textUnderlinePosition: "under",
                  }}
                  inputStyle={{
                    marginTop: 24,
                  }}
                  checked={marketing}
                  onClick={() => {
                    moveTerms("이메일 및 SMS 마케팅정보 수신 동의 (선택)");
                  }}
                  onChange={() => {
                    setMarketing(!marketing);
                  }}
                  text={"이메일 및 SMS 마케팅정보 수신 동의 (선택)"}
                />

                <LeftLabelCheckbox
                  textStyle={{
                    textDecoration: "underline",
                    textUnderlinePosition: "under",
                  }}
                  inputStyle={{
                    marginTop: 24,
                  }}
                  checked={service}
                  onClick={() => {
                    moveTerms("서비스 알림 수신 동의 (선택)");
                  }}
                  onChange={() => {
                    setService(!service);
                  }}
                  text={"서비스 알림 수신 동의 (선택)"}
                />

                <LeftLabelCheckbox
                  textStyle={{
                    textDecoration: "underline",
                    textUnderlinePosition: "under",
                  }}
                  inputStyle={{
                    marginTop: 24,
                  }}
                  checked={ad}
                  onClick={() => {
                    moveTerms("광고성 알림 수신 동의 (선택)");
                  }}
                  onChange={() => {
                    setAd(!ad);
                  }}
                  text={"광고성 알림 수신 동의 (선택)"}
                />
              </div>

              <MainBtn
                disabled={btnDisabled}
                onClick={() => {
                  moveNext();
                }}
                style={{
                  marginTop: 44,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 15,
                    lineHeight: "20.3px",
                    color: "white",
                    fontWeight: 700,
                  }}
                >
                  회원가입 하기
                </PretendardText>
              </MainBtn>
            </div>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default RegisterSocial;

const InputWrapper = styled.input.attrs((props) => {})`
  width: 20px;
  height: 48px;
  background: rgb(244, 246, 246);
  border: 1px solid rgb(244, 246, 246);
  padding: 0px;

  font-family: "Pretendard";
  color: #262c31;
  font-weight: 500;
  font-size: 15px;
  line-height: 22.3px;

  :focus {
    outline: none !important;
    border-color: rgb(244, 246, 246);
  }

  ::placeholder {
    color: #adb4ba;
    font-weight: 500;
    font-size: 15px;
    line-height: 22.3px;
  }
`;
