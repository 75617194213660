import api from "./api.js";

export default class InqueryApi {
  static async List(data) {
    return await api.get(`user/inq-001_F0`, data);
  }

  static async Add(data) {
    return await api.post(`user/inq-002_I0`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }
}
