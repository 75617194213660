import React, { useEffect, useState } from "react";
import useDisplay from "../../../hooks/useDisplay";
import PretendardText from "../../atoms/pretendardText";
import AppLayout from "../../atoms/layouts/appLayout";
import AppContainer from "../../atoms/layouts/appContainer";
import MainBtn from "../../atoms/button/mainBtn";
import TextBtn from "../../atoms/button/textBtn";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { route } from "../../../routes/route";
import BannerApi from "../../../api/bannerApi";

function WelcomeModal() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();

  const [bannerInfo, setBannerInfo] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    bannerGetFunc();
  }, []);

  const exceptionRouteList = [
    route.main,
    route.bottom_home,
    route.login,
    route.id_find,
    route.id_find_complete,
    route.pw_find,
    route.pw_change,
    route.pw_reset,
    route.register,
    route.register_social,
    route.profile_register,
    route.profile_register_social,
    route.register_influencer,
    route.profile_register_influencer,
    route.influencer_main,
    route.term_detail,
  ];

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (
      sessionStorage.getItem("token") ||
      exceptionRouteList.includes(window.location.pathname)
    ) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [location]);

  const bannerGetFunc = async () => {
    try {
      let response = await BannerApi.List();

      if (response.data.data.content.length > 0) {
        let bannerTemp = response.data.data.content.find(
          (item) => item.title === "로그인 유도 배너"
        );

        setBannerInfo(bannerTemp);
      }
    } catch (error) {}
  };

  const handleOpenNewTab = (url) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  const loginMoveFunc = () => {
    if (history.length > 2) {
      history.push(route.main);
    } else {
      sessionStorage.setItem("urlState", location.pathname);
      history.push({
        pathname: route.main,
      });
    }
  };

  return (
    <>
      {isOpen ? (
        <AppLayout
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: "9999",
            display: "block",
            background: "rgba(0, 0, 0, 0.7)",
          }}
        >
          <AppContainer>
            <div
              style={{
                position: "fixed",
                bottom: "0",
                background: "#ffffff",
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
                width: isMobile ? "100vw" : "486px",
                height: isMobile ? "80%" : "664px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <PretendardText
                style={{
                  fontWeight: "700",
                  fontSize: "13px",
                  lineHeight: "18.75px",
                  color: "rgba(255, 51, 51, 1)",
                  marginTop: "13%",
                  textAlign: "center",
                  letterSpacing: "5.6px",
                }}
              >
                WELCOME
              </PretendardText>
              <PretendardText
                style={{
                  fontWeight: "600",
                  fontSize: "24px",
                  lineHeight: "33.6px",
                  color: "rgba(0, 0, 0, 1)",
                  marginTop: "1.6%",
                  textAlign: "center",
                }}
              >
                로그인 후 서비스를<br></br> 이용해주세요!
              </PretendardText>
              <div
                style={{
                  width: "100%",
                  marginTop: "3.9%",
                  borderRadius: "8px",
                  overflow: "hidden",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (bannerInfo && bannerInfo.link) {
                    if (
                      bannerInfo.link.includes("http") ||
                      bannerInfo.link.includes("https")
                    ) {
                      handleOpenNewTab(bannerInfo.link);
                    } else {
                      handleOpenNewTab(`https://${bannerInfo.link}`);
                    }
                  }
                }}
              >
                <img
                  src={
                    bannerInfo && bannerInfo.imageUrl ? bannerInfo.imageUrl : ""
                  }
                  alt=""
                  style={{
                    width: "100%",
                    height: isMobile ? "250px" : 335,
                    borderRadius: "8px",
                  }}
                />
              </div>
              <MainBtn
                onClick={() => {
                  loginMoveFunc();
                }}
                style={{
                  marginTop: "3.6%",
                  borderRadius: "8px",
                  height: "48px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "rgba(255, 255, 255, 1)",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: "600",
                }}
              >
                로그인하러 가기
              </MainBtn>
              <div
                style={{
                  marginTop: "1.5%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <PretendardText
                  style={{
                    color: "rgba(90, 99, 106, 1)",
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: "600",
                  }}
                >
                  회원이 아니신가요?
                </PretendardText>
                <TextBtn
                  onClick={() => {
                    history.push(route.register);
                  }}
                >
                  <PretendardText
                    style={{
                      color: "rgba(255, 51, 51, 1)",
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "600",
                      marginLeft: "7px",
                    }}
                  >
                    회원가입
                  </PretendardText>
                </TextBtn>
              </div>
            </div>
          </AppContainer>
        </AppLayout>
      ) : null}
    </>
  );
}

export default WelcomeModal;
