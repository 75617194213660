import api from "./api.js";

export default class InfluencerApi {
  static async SearchList(data) {
    return await api.get(`/ser-001_F0`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async Follow(id) {
    return await api.put(`user/follow/${id}`);
  }

  static async UnFollow(id) {
    return await api.put(`user/unFollow/${id}`);
  }

  static async Profile(id) {
    return await api.get(`ifn-012_F0/${id}`);
  }

  static async BulletinList(id, data) {
    return await api.get(`ifn-012_F1/${id}`, data);
  }

  static async ChallengeList(id, data) {
    return await api.get(`ifn-022_F0/${id}`, data);
  }
}
