import React, { useEffect, useMemo, useState } from "react";
import useDisplay from "../hooks/useDisplay";
import { useHistory, useParams, useLocation } from "react-router-dom";
import AppLayout from "../components/atoms/layouts/appLayout";
import AppContainer from "../components/atoms/layouts/appContainer";
import TextBtn from "../components/atoms/button/textBtn";
import Header from "../components/molecules/header/header";
import PretendardText from "../components/atoms/pretendardText";
import FileForm from "../components/atoms/fileForm/fileForm";
import ChallengeFileForm from "../components/atoms/fileForm/challengeFileForm";
import TextArea from "../components/atoms/input/textarea";
import { Toggle } from "../components/atoms/toggle/toggle";
import MainBtn from "../components/atoms/button/mainBtn";
import { getExtensionOfFilename, videoExtension } from "../utils/file";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import ChallengeApi from "../api/challengeApi";
import Loading from "../utils/loading";

function ChallengeCertification() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();
  // 챌린지 인증 정보
  const { id } = useParams();

  const [certificationId, setCertificationId] = useState(null);

  const [file, setFile] = useState({
    file: "",
    fileUrl: "",
  });
  const [thum, setThum] = useState({
    file: "",
    fileUrl: "",
  });
  const [review, setReview] = useState();
  const [useFlag, setUseFlag] = useState(true);

  const [isMobileChk, setIsMobileChk] = useState(false);
  const [playTime, setPlayTime] = useState("00:00");

  useEffect(() => {
    const certificationId = location?.state ? location.state.certificationId : null;
    setCertificationId(certificationId);

    if (certificationId) {
      getCertificationInfo(certificationId);
    }

    const isMobileChk = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    setIsMobileChk(isMobileChk);

    window.scrollTo(0, 0);
  }, []);

  // init
  const getCertificationInfo = async (certificationId) => {
    try {
      const response = await ChallengeApi.CertificationInfo(certificationId);
      const data = response.data.data;

      setFile({
        file: "",
        fileUrl: data.fileUrl,
      });
      setThum({
        file: "",
        fileUrl: data.thumbFileUrl,
      });
      setReview(data.contents);
      setUseFlag(data.exposure);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const inputFile = (e) => {
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (data) => {
      file.file = file;
      if (videoExtension.includes(getExtensionOfFilename(file.name))) {
        file.fileUrl = URL.createObjectURL(file.file);
      } else {
        file.fileUrl = data.target.result;
        thum.fileUrl = "";
      }
      setFile({ ...file });
      setThum({
        file: "",
        fileUrl: "",
      });
    };
    e.target.value = "";
  };

  const inputThum = (e) => {
    if (!videoExtension.includes(getExtensionOfFilename(file.file.name))) {
      toast("썸네일은 인증파일이 영상일 경우 등록가능합니다.");
      return;
    }

    const fileTemp = e.target.files[0];
    if (videoExtension.includes(getExtensionOfFilename(fileTemp.name))) {
      toast("썸네일은 이미지만 등록가능합니다.");
      return;
    }
    const fileReader = new FileReader();
    fileReader.readAsDataURL(fileTemp);
    fileReader.onload = (data) => {
      thum.file = fileTemp;
      thum.fileUrl = data.target.result;
      setThum({ ...thum });
    };
    e.target.value = "";
  };

  // 인증하기
  const btnFlag = useMemo(() => {
    // 게시글 수정이 아니면서 챌린지가 없는 경우
    if (!certificationId && id === null) {
      return true;
    }

    // 파일이 없는 경우
    if (!file.fileUrl) {
      return true;
    }
    // 영상인데 썸네일이 없는 경우
    if (videoExtension.includes(getExtensionOfFilename(file.file ? file.file.name : file.fileUrl)) && !thum.fileUrl) {
      return true;
    }
    // 후기가 없는 경우
    if (!review) {
      return true;
    }

    return false;
  }, [file, thum, review]);

  const btnClick = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();

      if (file.file) {
        formData.append("file", file.file);
      }

      if (thum.file) {
        formData.append("thumbFile", thum.file);
      }

      formData.append("contents", review);
      formData.append("exposure", useFlag);

      if (certificationId) {
        await ChallengeApi.putCertification(certificationId, formData);
        toast(ko.update);
      } else {
        await ChallengeApi.postCertification(id, formData);
        toast(ko.create);
      }
      setIsLoading(false);
      history.goBack();
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  // 비디오 전체 길이를 가져오는 함수
  const setTotalTime = () => {
    // 비디오 링크를 클릭하여 해당 비디오 페이지에 들어왔을때
    const videoContainer = document.getElementById("video");

    if (videoContainer) {
      let durationTemp = videoContainer.duration;
      return formatDate(durationTemp);
    } else {
      return "00:00";
    }
  };

  useEffect(() => {
    setTimeout(() => {
      let timeTemp = setTotalTime();

      setPlayTime(timeTemp);
    }, 500);
  }, [file]);

  // 초를 시,분,초로 변환
  const formatDate = (seconds) => {
    const secondsNumber = parseInt(seconds, 10);
    let hours = Math.floor(secondsNumber / 3600);
    let minutes = Math.floor((secondsNumber - hours * 3600) / 60);
    let totalSeconds = secondsNumber - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (totalSeconds < 10) {
      totalSeconds = `0${totalSeconds}`;
    }

    if (hours == "00") {
      return `${isNaN(minutes) ? "00" : minutes}:${isNaN(totalSeconds) ? "00" : totalSeconds}`;
    } else {
      return `${isNaN(hours) ? "00" : hours}:${isNaN(minutes) ? "00" : minutes}:${
        isNaN(totalSeconds) ? "00" : totalSeconds
      }`;
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  return (
    <AppLayout>
      <AppContainer style={{}}>
        {/* 헤더 */}
        <Header title={certificationId ? "인증 수정하기" : "챌린지 인증하기"} />

        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 20,
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <div
            style={{
              marginTop: 24,
            }}
          >
            <PretendardText
              style={{
                fontSize: 13,
                lineHeight: "18.75px",
                color: "#778088",
                fontWeight: 600,
              }}
            >
              이미지 또는 동영상
            </PretendardText>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 11,
              }}
            >
              <ChallengeFileForm handleChange={inputFile} />

              {file.fileUrl ? (
                <div
                  style={{
                    marginLeft: 8,
                    width: 100,
                    height: 100,
                    // borderRadius: 12,
                    // backgroundImage: `url( ${file.fileUrl} )`,
                    // backgroundSize: "100% 100%",
                    position: "relative",
                  }}
                >
                  {videoExtension.includes(getExtensionOfFilename(file.file ? file.file.name : file.fileUrl)) ? (
                    <div
                      style={{
                        borderRadius: 12,
                        width: "100%",
                        height: "100%",
                        position: "relative",
                      }}
                    >
                      <video
                        src={file.fileUrl}
                        style={{
                          borderRadius: 12,
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          display: isMobileChk ? "none" : "block",
                        }}
                        id="video"
                      />

                      {isMobileChk ? (
                        <img
                          src={file.fileUrl}
                          alt=""
                          style={{
                            borderRadius: 12,
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      ) : null}
                      <div
                        style={{
                          position: "absolute",
                          borderRadius: 23,
                          padding: "5px 8px 5px 8px",
                          bottom: 6,
                          right: 6,
                          background: "rgba(0,0,0,0.5)",
                        }}
                      >
                        <PretendardText
                          style={{
                            color: "#FFFFFF",
                            fontSize: "11px",
                            lineHeight: "14px",
                            fontWeight: "600",
                          }}
                        >
                          {playTime}
                        </PretendardText>
                      </div>
                    </div>
                  ) : (
                    <img
                      src={file.fileUrl}
                      alt=""
                      style={{
                        borderRadius: 12,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  )}

                  <img
                    onClick={() => {
                      file.file = "";
                      file.fileUrl = "";
                      setFile({ ...file });
                    }}
                    src="/assets/icons/imageDel.png"
                    style={{
                      height: 18,
                      width: 18,
                      position: "absolute",
                      top: 9,
                      right: 9,
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>

          {videoExtension.includes(getExtensionOfFilename(file.file ? file.file.name : file.fileUrl)) ? (
            <div
              style={{
                marginTop: 24,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#778088",
                  fontWeight: 600,
                }}
              >
                썸네일(인증이 동영상일 경우)
              </PretendardText>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginTop: 11,
                }}
              >
                <ChallengeFileForm handleChange={inputThum} id={"thum"} accept="image/jpg, image/png, image/jpeg" />

                {thum.fileUrl ? (
                  <div
                    style={{
                      marginLeft: 8,
                      width: 100,
                      height: 100,
                      borderRadius: 12,
                      backgroundImage: `url( ${thum.fileUrl} )`,
                      backgroundSize: "100% 100%",
                      position: "relative",
                    }}
                  >
                    <img
                      onClick={() => {
                        thum.file = "";
                        thum.fileUrl = "";
                        setThum({ ...thum });
                      }}
                      src="/assets/icons/imageDel.png"
                      style={{
                        height: 18,
                        width: 18,
                        position: "absolute",
                        top: 9,
                        right: 9,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}

          <div
            style={{
              marginTop: "16px",
            }}
          >
            <PretendardText
              style={{
                fontSize: 13,
                lineHeight: "18.75px",
                color: "#778088",
                fontWeight: 600,
                marginBottom: "4px",
              }}
            >
              후기
            </PretendardText>
            <TextArea
              type={"text"}
              placeholder={"후기를 입력하세요"}
              value={review}
              onChange={(e) => {
                setReview(e.target.value);
              }}
            />
          </div>

          <div
            style={{
              marginTop: "16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <PretendardText
              style={{
                fontSize: 13,
                lineHeight: "18.75px",
                color: "#778088",
                fontWeight: 600,
              }}
            >
              공개여부
            </PretendardText>
            <Toggle
              isOn={useFlag}
              onClick={() => {
                setUseFlag(!useFlag);
              }}
            ></Toggle>
          </div>

          <MainBtn
            onClick={() => {
              btnClick();
            }}
            style={{
              marginTop: isMobile ? "50px" : "auto",
            }}
            disabled={btnFlag || isLoading}
          >
            {isLoading ? <Loading /> : ""}
            <PretendardText
              style={{
                fontSize: 15,
                lineHeight: "20.3px",
                color: "white",
                fontWeight: 700,
              }}
            >
              {certificationId ? "수정하기" : "인증하기"}
            </PretendardText>
          </MainBtn>
        </div>
      </AppContainer>
    </AppLayout>
  );
}

export default ChallengeCertification;
