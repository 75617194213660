import React, { useState, useEffect } from "react";

import PretendardText from "../../atoms/pretendardText";
import TextBtn from "../../atoms/button/textBtn";

const MerchOptionSection = ({
  index,
  data,
  style,
  selectedOptionList,
  setSelectedOptionList,
}) => {
  return (
    <div
      style={{
        borderRadius: 8,
        border: "2px solid #ECEDF0",
        padding: "14px 16px",
        ...style,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: 8,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <PretendardText
            style={{
              fontSize: 14,
              lineHeight: "21.5px",
              color: "#262C31",
              fontWeight: 500,
            }}
          >
            {data.optionTitle}
          </PretendardText>
          <PretendardText
            style={{
              marginLeft: 4,
              fontSize: 14,
              lineHeight: "21.5px",
              color: "#262C31",
              fontWeight: 500,
            }}
          >
            {data.option.name}
          </PretendardText>
        </div>
        <TextBtn
          onClick={() => {
            selectedOptionList.splice(index, 1);
            setSelectedOptionList([...selectedOptionList]);
          }}
        >
          <img
            style={{
              width: 20,
              height: 20,
              marginLeft: 12,
            }}
            src="/assets/icons/delete.png"
            alt="delete"
          />
        </TextBtn>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TextBtn
            onClick={() => {
              if (data.amount <= 1) {
                return;
              }

              selectedOptionList[index] = {
                ...data,
                amount: (data.amount -= 1),
              };
              setSelectedOptionList([...selectedOptionList]);
            }}
          >
            <img
              style={{
                width: 15,
                height: 15,
              }}
              src="/assets/icons/minus.svg"
              alt="minus"
            />
          </TextBtn>
          <div
            style={{
              margin: "0px 11px",
              border: "1px solid #D8DBE5",
              borderRadius: 6,
              padding: "4px 11px 3px 11px",
            }}
          >
            <PretendardText
              style={{
                fontSize: 15,
                lineHeight: "22.3px",
                color: "#262C31",
                fontWeight: 500,
              }}
            >
              {data.amount}
            </PretendardText>
          </div>
          <TextBtn
            onClick={() => {
              selectedOptionList[index] = {
                ...data,
                amount: (data.amount += 1),
              };
              setSelectedOptionList([...selectedOptionList]);
            }}
          >
            <img
              style={{
                width: 15,
                height: 15,
              }}
              src="/assets/icons/plus.svg"
              alt="plus"
            />
          </TextBtn>
        </div>
        <PretendardText
          style={{
            fontSize: 15,
            color: "#5A636A",
            fontWeight: 700,
          }}
        >
          {Number(data.option.price).toLocaleString("ko-KR")}원
        </PretendardText>
      </div>
    </div>
  );
};

export default MerchOptionSection;
