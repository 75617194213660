import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import Empty from "../components/molecules/empty";
import TextBtn from "../components/atoms/button/textBtn";
import Tab from "../components/molecules/tab";
import ProfileChallengeBox from "../components/organisms/profileChallengeBox";
import SearchChallengeBox from "../components/organisms/searchChallengeBox";

import { useInView } from "react-intersection-observer";
import InqueryApi from "../api/inqueryApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import { route } from "../routes/route";
import MoreApi from "../api/moreApi";

function MyReviewList(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();

  const [tabList, setTabList] = useState([
    { label: "작성 가능한 리뷰", value: true },
    { label: "작성한 리뷰", value: false },
  ]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [ref, inView] = useInView();
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    const tabListTemp = location?.state ? location.state.tabListTemp : null;

    if (tabListTemp) {
      setTabList(tabListTemp);
    }
    window.scrollTo(0, 0);
  }, []);

  const moveLoaction = (data) => {
    history.replace({
      pathname: route.my_review_list,
      state: {
        tabListTemp: data,
      },
    });
  };

  const tabClickFunc = (label) => {
    let temp = tabList.map((item) =>
      item.label === label
        ? { label: item.label, value: true }
        : { label: item.label, value: false }
    );

    setTabList(temp);
    moveLoaction(temp);
  };

  const setPRData = async () => {
    try {
      setLoading(true);
      const response = await MoreApi.GetPReview({
        page: currentPage,
        size: 10,
      });

      const data = response.data.data;
      setDataList(dataList.concat(data.content));
      setIsLast(data.last);
      setLoading(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const setUnPRData = async () => {
    try {
      setLoading(true);
      const response = await MoreApi.GetUnPReview({
        page: currentPage,
        size: 10,
      });

      const data = response.data.data;
      setDataList(dataList.concat(data.content));
      setIsLast(data.last);
      setLoading(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    if (tabList[0].value) {
      setPRData();
    } else {
      setUnPRData();
    }
  }, [currentPage, tabList]);

  useEffect(() => {
    if (inView && !isLast && !loading) {
      setCurrentPage(currentPage + 1);
      if (tabList[0].value) {
        setPRData();
      } else {
        setUnPRData();
      }
    }
  }, [inView, loading]);

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header title={"리뷰"} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {tabList.map((item, index) => {
                return (
                  <Tab
                    onClick={() => {
                      tabClickFunc(item.label);
                      setDataList([]);
                      setIsLast(false);
                      setCurrentPage(0);
                    }}
                    key={index}
                    value={item.value}
                  >
                    <PretendardText
                      style={{
                        fontSize: 14,
                        lineHeight: "20px",
                        color: item.value ? "#262C31" : "#ADB4BA",
                        fontWeight: 600,
                      }}
                    >
                      {item.label}
                    </PretendardText>
                  </Tab>
                );
              })}
            </div>
          </div>

          <div
            style={{
              marginTop: "24px",
              padding: "0px 20px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {dataList.length > 0 ? (
              dataList.map((item, index) => {
                return (
                  <SearchChallengeBox
                    key={index}
                    isEnd={(index + 1) % 2 === 0}
                    marginBottom={"24px"}
                    data={item.challengeInfo}
                    onClick={() => {
                      if (tabList[0].value) {
                        history.push({
                          pathname: route.challenge_review_write,
                          state: {
                            id: item.id,
                          },
                        });
                      } else {
                        history.push({
                          pathname:
                            route.challenge_review_detail + `/${item.reviewId}`,
                        });
                      }
                    }}
                  />
                );
              })
            ) : (
              <Empty
                style={{
                  height: "70vh",
                }}
                text={"리뷰가 없어요"}
              />
            )}

            <div ref={ref}>{/* loading */}</div>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default MyReviewList;
