import React, { useEffect, useState } from "react";
import TextBtn from "../atoms/button/textBtn";
import PretendardText from "../atoms/pretendardText";
import ReplyBox from "./replyBox";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { route } from "../../routes/route";
import ChallengeApi from "../../api/challengeApi";
import { toast } from "react-toastify";
import { ko } from "../../utils/ko";

function CommentBox({
  isFirst,
  writeCommentFunc,
  item,
  setUpdateCommentId,
  setUpdateReplyId,
  setDeleteCommentId,
  setDeleteReplyId,
  openFlag,
  setOpenFlag,
  setReplyListP,
}) {
  const history = useHistory();

  const numbers = [1, 2];
  const [replyOpen, setReplyOpen] = useState(false);
  const [moreOpen, setMoreOpen] = useState(false);
  const [isMyComment, setIsMyComment] = useState(false);

  // 대댓글
  const [replyList, setReplyList] = useState([]);
  // init
  const getReplyList = async () => {
    try {
      const response = await ChallengeApi.ReplyList(item.id);
      const data = response.data.data;

      setReplyList(data);
      setReplyListP(data);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    getReplyList();
  }, [item]);

  return (
    <>
      <div
        style={{
          position: "relative",

          marginTop: isFirst ? "" : "36px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextBtn
              style={{
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                if (item.userType === "INFLUENCER") {
                  history.push({
                    pathname:
                      route.influencer_profile + `/${item.commonMemberId}`,
                  });
                } else {
                  history.push(
                    route.general_user_profile + `/${item.commonMemberId}`
                  );
                }
              }}
            >
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  marginRight: "4px",
                }}
              >
                <img
                  src={item.profileUrl}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              <PretendardText
                style={{
                  color: "rgba(38, 44, 49, 1)",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: "600",
                  width: "49px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.nickName}
              </PretendardText>
            </TextBtn>
            {item.userType === "INFLUENCER" ? (
              <img
                src="/assets/icons/card_checkstar.svg"
                alt=""
                style={{
                  width: "17px",
                  height: "17px",
                  marginLeft: "3px",
                }}
              />
            ) : null}
          </div>
          <TextBtn
            onClick={() => {
              if (openFlag.id === item.id && openFlag.type === "comment") {
                setOpenFlag({
                  type: "",
                  id: 0,
                });
              } else {
                setOpenFlag({
                  type: "comment",
                  id: item.id,
                });
              }
            }}
          >
            <img
              src="/assets/icons/ic_24_more.png"
              alt=""
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          </TextBtn>
        </div>
        <div
          style={{
            paddingLeft: "24px",
            paddingRight: "28px",
          }}
        >
          <PretendardText
            style={{
              marginTop: "3px",
              fontSize: "14px",
              lineHeight: "21.5px",
              fontWeight: "500",
            }}
          >
            {/* 정말 멋져요~! 이대로 인증성공까지 하세요~ 정말 멋져요~! 이대로 인증성공까지 하세요~정말 멋져요~! 이대로 인증성공까지 하세요~ 정말 멋져요~! 이대로 인증성공까지 하세요~ */}
            {item.contents}
          </PretendardText>
          <div
            style={{
              display: "flex",
              marginTop: "7px",
            }}
          >
            <PretendardText
              style={{
                fontSize: "12px",
                lineHeight: "16.5px",
                fontWeight: "500",
                color: "#ADB4BA",
                marginRight: "10px",
              }}
            >
              {item.dateTitle}
            </PretendardText>

            <TextBtn
              onClick={() => {
                writeCommentFunc(item.id);
              }}
            >
              <PretendardText
                style={{
                  fontSize: "12px",
                  lineHeight: "16.5px",
                  fontWeight: "500",
                  color: "#ADB4BA",
                }}
              >
                대댓글달기
              </PretendardText>
            </TextBtn>
          </div>
          {replyList?.length > 0 ? (
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                alignItems: "center",
                height: "17px",
              }}
            >
              {!replyOpen ? (
                <>
                  <div
                    style={{
                      width: "25px",
                      backgroundColor: "#ADB4BA",
                      height: "1px",
                      marginRight: "6px",
                    }}
                  ></div>
                  <TextBtn
                    onClick={() => {
                      setReplyOpen(true);
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: "12px",
                        lineHeight: "16.5px",
                        fontWeight: "500",
                        color: "#778088",
                      }}
                    >
                      {replyList?.length}개의 대댓글 보기
                    </PretendardText>
                  </TextBtn>
                </>
              ) : (
                ""
              )}
            </div>
          ) : null}
        </div>
        {openFlag.id === item.id && openFlag.type === "comment" ? (
          <div
            style={{
              position: "absolute",
              top: "31px",
              right: "0",
              width: "120px",
              borderRadius: "6px",
              boxShadow: "0px 0px 8px rgba(0,0,0,0.3)",
              backgroundColor: "#FFFFFF",
              padding: "10px 14px 10px 14px",
              zIndex: 1,
            }}
          >
            {item.isWriter ? (
              <>
                <TextBtn
                  onClick={() => {
                    setDeleteCommentId(item.id);
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "500",
                      color: "#262C31",
                      height: "28px",
                      padding: "4px 6px 4px 6px",
                    }}
                  >
                    삭제하기
                  </PretendardText>
                </TextBtn>
                <TextBtn
                  style={{
                    marginTop: "8px",
                  }}
                  onClick={() => {
                    setUpdateReplyId(0);
                    setUpdateCommentId(item.id);
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "500",
                      color: "#262C31",
                      height: "28px",
                      padding: "4px 6px 4px 6px",
                    }}
                  >
                    수정하기
                  </PretendardText>
                </TextBtn>
              </>
            ) : (
              <TextBtn
                onClick={() => {
                  history.push({
                    pathname: route.report_write,
                    state: {
                      id: item.id,
                      reportType: "COMMENT",
                    },
                  });
                }}
              >
                <PretendardText
                  style={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: "500",
                    color: "#262C31",
                    height: "28px",
                    padding: "4px 6px 4px 6px",
                  }}
                >
                  신고하기
                </PretendardText>
              </TextBtn>
            )}
          </div>
        ) : (
          ""
        )}
      </div>

      {replyOpen
        ? replyList.map((item, index) => {
            return (
              <ReplyBox
                item={item}
                key={index}
                isLast={replyList.length - 1 === index}
                setReplyOpen={setReplyOpen}
                writeCommentFunc={writeCommentFunc}
                // 대댓글 삭제
                setDeleteReplyId={setDeleteReplyId}
                // 대댓글 수정
                setUpdateCommentId={setUpdateCommentId}
                setUpdateReplyId={setUpdateReplyId}
                // 태그 오픈
                openFlag={openFlag}
                setOpenFlag={setOpenFlag}
              ></ReplyBox>
            );
          })
        : ""}
    </>
  );
}

export default CommentBox;
