import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PretendardText from "../../atoms/pretendardText";

import moment from "moment";

const PointSection = ({ data }) => {
  const history = useHistory();

  return (
    <div
      style={{
        width: "100%",
        padding: "22px 20px 23px 20px",
        borderBottom: "1px solid #DBDDDF",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 15,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <PretendardText
            style={{
              fontSize: 14,
              lineHeight: "16.71px",
              color: "#ADB4BA",
              fontWeight: 500,
              marginBottom: 5,
            }}
          >
            {moment(data.createdAt).format("YYYY.MM.DD")}
          </PretendardText>
          <PretendardText
            style={{
              fontSize: 16,
              lineHeight: "22.96px",
              color: "#262C31",
              fontWeight: 600,
              marginBottom: 11,
            }}
          >
            {data.reason}
          </PretendardText>
          <PretendardText
            style={{
              fontSize: 13,
              lineHeight: "18.75px",
              color: "#ADB4BA",
              fontWeight: 600,
            }}
          >
            유효기간 &nbsp;&nbsp;&nbsp;{data.expireDate}
          </PretendardText>
        </div>
        {data.isIncrease ? (
          <PretendardText
            style={{
              fontSize: 18,
              lineHeight: "25.2px",
              color: "#5ECECE",
              fontWeight: 600,
            }}
          >
            +{Number(data.amount).toLocaleString("ko-KR")}P
          </PretendardText>
        ) : (
          <PretendardText
            style={{
              fontSize: 18,
              lineHeight: "25.2px",
              color: "#F24147",
              fontWeight: 600,
            }}
          >
            -{Number(data.amount).toLocaleString("ko-KR")}P
          </PretendardText>
        )}
      </div>
    </div>
  );
};

export default PointSection;
