import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import TitleInput from "../components/molecules/input/titleInput";
import FileForm from "../components/atoms/fileForm/fileForm";
import TextArea from "../components/atoms/input/textarea";
import UserApi from "../api/userApi";
import { route } from "../routes/route";
import { ko } from "../utils/ko";

function ProfileRegisterInfluencer(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  const [nickName, setNickName] = useState("");
  const [nickNameErr, setNickNameErr] = useState("");
  const [nickChk, setNickChk] = useState(false);

  const [file, setFile] = useState();
  const [imageUrl, setImageUrl] = useState();

  const [intro, setIntro] = useState("");
  const [link01, setLink01] = useState("");
  const [link02, setLink02] = useState("");
  const [link03, setLink03] = useState("");

  const [dataTemp, setDataTemp] = useState();

  useEffect(() => {
    if (nickName.length > 0) {
      setNickNameErr("");
      setNickChk(false);
    }
  }, [nickName]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const rDataTemp = localStorage.getItem("rDataTemp");

    if (rDataTemp) {
      setDataTemp(JSON.parse(rDataTemp));
    }
  }, []);

  const handleChange = (e) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (data) => {
      setFile(file);
      setImageUrl(data.target.result);
    };
    e.target.value = "";
  };

  const nickCheckApi = async () => {
    try {
      if (nickNameErr != "" || nickName == "") {
        return;
      }

      const data = {
        nickName: nickName,
      };

      const response = await UserApi.RNickNameCheck(data);

      if (response.data.data.isDuplicated) {
        setNickNameErr("이메일이 중복되었습니다.");
        setNickChk(false);
      } else {
        setNickNameErr("");
        setNickChk(true);
      }
    } catch (err) {
      setNickChk(false);
    }
  };

  const registerApi = async () => {
    try {
      let birthTemp = dataTemp.birth;
      let birthTemp01 = birthTemp.slice(0, 4);
      let birthTemp02 = birthTemp.slice(4, 6);
      let birthTemp03 = birthTemp.slice(6, 8);

      const formData = new FormData();
      formData.append("agreeToAdNotice", dataTemp.ad);
      formData.append("agreeToServiceNotice", dataTemp.service);
      formData.append("agreeToSms", dataTemp.marketing);
      formData.append("birth", `${birthTemp01}-${birthTemp02}-${birthTemp03}`);

      if (file) {
        formData.append("file", file);
      }
      formData.append("introduce", intro);

      let linkTemp = "";

      if (link01) {
        linkTemp += link01;
      }

      if (link02) {
        link01 ? (linkTemp += `, ${link02}`) : (linkTemp += `${link02}`);
      }

      if (link03) {
        link01 || link02 ? (linkTemp += `, ${link03}`) : (linkTemp += `${link03}`);
      }

      formData.append("links", linkTemp);

      formData.append("email", dataTemp.email);
      formData.append("gender", dataTemp.gender);
      formData.append("name ", dataTemp.name);
      formData.append("nickName", nickName);
      formData.append("password", dataTemp.pw);
      formData.append("phoneNumber", dataTemp.phone);

      const response = await UserApi.IFRegister(formData);

      history.push({
        pathname: route.login,
      });

      toast("회원가입 완료");
      localStorage.removeItem("rDataTemp");
    } catch (err) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          {/* <Header /> */}

          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                marginTop: 90,
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 32,
                  lineHeight: "44.16px",
                  color: "#262C31",
                  fontWeight: 600,
                  marginBottom: 6,
                }}
              >
                프로필 등록
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "21.5px",
                  color: "#5A636A",
                  fontWeight: 500,
                }}
              >
                헬랄라 시작을 앞서 프로필을 등록해볼까요?
              </PretendardText>

              <div
                style={{
                  marginTop: 41,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 13,
                    lineHeight: "18.75px",
                    color: "#778088",
                    fontWeight: 600,
                  }}
                >
                  프로필 이미지 선택
                </PretendardText>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginTop: 8,
                  }}
                >
                  <FileForm handleChange={handleChange} />

                  {imageUrl ? (
                    <div
                      style={{
                        marginLeft: 8,
                        width: 80,
                        height: 80,
                        borderRadius: 12,
                        backgroundImage: `url( ${imageUrl} )`,
                        backgroundSize: "100% 100%",
                        position: "relative",
                      }}
                    >
                      <img
                        onClick={() => {
                          setFile(null);
                          setImageUrl("");
                        }}
                        src="/assets/icons/imageDel.png"
                        style={{
                          height: 20,
                          width: 20,
                          position: "absolute",
                          top: 4,
                          right: 4,
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </div>

              <div
                style={{
                  marginTop: 17,
                }}
              >
                <TitleInput
                  title={"닉네임"}
                  placeholder="10자이하로 입력하세요"
                  type={"text"}
                  onChange={(e) => {
                    setNickName(e.target.value);
                  }}
                  value={nickName}
                  btn={true}
                  btnText={"중복확인"}
                  onClick={() => {
                    nickCheckApi();
                  }}
                  maxLength={"10"}
                  warningMessage={nickNameErr}
                  btnStyle={{
                    marginTop: nickNameErr || nickChk ? -23 : 0,
                  }}
                  btnTextStyle={{
                    lineHeight: (nickNameErr || nickChk) && isMobile ? "17.75px" : "18.75px",
                  }}
                  checkMessage={nickChk ? "사용가능한 닉네임입니다." : ""}
                />
              </div>

              <div>
                <div
                  style={{
                    marginTop: 17,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 13,
                      lineHeight: "18.75px",
                      color: "#778088",
                      fontWeight: 600,
                      marginBottom: 4,
                    }}
                  >
                    소개글
                  </PretendardText>
                  <TextArea
                    style={{
                      height: 112,
                    }}
                    value={intro}
                    onChange={(e) => {
                      setIntro(e.target.value);
                    }}
                    type={"text"}
                    placeholder={"소개글을 입력하세요"}
                  />
                </div>

                <div
                  style={{
                    marginTop: 17,
                  }}
                >
                  <TitleInput
                    title={"링크"}
                    placeholder="관련 링크를 입력하세요"
                    type={"text"}
                    onChange={(e) => {
                      setLink01(e.target.value);
                    }}
                    value={link01}
                  />
                </div>
                <div
                  style={{
                    marginTop: 17,
                  }}
                >
                  <TitleInput
                    title={"링크"}
                    placeholder="관련 링크를 입력하세요"
                    type={"text"}
                    onChange={(e) => {
                      setLink02(e.target.value);
                    }}
                    value={link02}
                  />
                </div>
                <div
                  style={{
                    marginTop: 17,
                  }}
                >
                  <TitleInput
                    title={"링크"}
                    placeholder="관련 링크를 입력하세요"
                    type={"text"}
                    onChange={(e) => {
                      setLink03(e.target.value);
                    }}
                    value={link03}
                  />
                </div>
              </div>
            </div>

            <MainBtn
              disabled={nickNameErr === "" && nickName && nickChk ? false : true}
              style={{
                marginTop: 43,
              }}
              onClick={() => {
                registerApi();
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                시작하기
              </PretendardText>
            </MainBtn>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default ProfileRegisterInfluencer;
