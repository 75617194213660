import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";
import MainLayout from "../components/atoms/layouts/mainLayout";
import PretendardText from "../components/atoms/pretendardText";
import PCLeftLayout from "../components/atoms/layouts/pcLeftLayout";
import AppLayout from "../components/atoms/layouts/appLayout";
import Banner from "../components/molecules/banner/banner";
import MainBtn from "../components/atoms/button/mainBtn";
import WhiteBoldBtn from "../components/atoms/button/whiteBoldBtn";
import AppContainer from "../components/atoms/layouts/appContainer";
import { route } from "../routes/route";

function InfluencerMain(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem("rDataTemp");

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AppLayout>
        <AppContainer
          style={{
            backgroundImage: "url(/assets/images/m_bg.png)",
            backgroundSize: "100% 100%",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/assets/images/logo.png"
              style={{
                height: 70,
                width: 230,
              }}
            />
            <PretendardText
              style={{
                fontSize: 15,
                lineHeight: "22.25px",
                color: "white",
                fontWeight: 600,
                marginTop: 19,
              }}
            >
              헬스라이프 스타일, 즐겁고 신나게!
            </PretendardText>
            <PretendardText
              style={{
                fontSize: 14,
                lineHeight: "21.5px",
                color: "rgba(255, 255, 255, 0.9)",
                fontWeight: 500,
                marginTop: 28,
                textAlign: "center",
              }}
            >
              헬랄라크루 회원가입 페이지입니다.
              <br />
              헬랄라유저와 동반 성장을 지원합니다.
            </PretendardText>
          </div>

          <MainBtn
            style={{
              marginBottom: 36,
            }}
            onClick={() => {
              history.push({
                pathname: route.register_influencer,
              });
            }}
          >
            <PretendardText
              style={{
                fontSize: 15,
                lineHeight: "20.3px",
                color: "white",
                fontWeight: 700,
              }}
            >
              회원가입하기
            </PretendardText>
          </MainBtn>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default InfluencerMain;
