import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";

import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";

import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import NoticeApi from "../api/noticeApi";

function NoticeDetail(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();

  const [noticeInfo, setNoticeInfo] = useState({
    content: "",
    createdAt: "2023-01-01",
    id: 0,
    managerEmail: "",
    title: "",
    updatedAt: "2023-01-01",
  });

  useEffect(() => {
    const noticeId = location?.state ? location.state.noticeId : null;

    noticeDetailFunc(noticeId);
    window.scrollTo(0, 0);
  }, []);

  const noticeDetailFunc = async (noticeId) => {
    try {
      const response = await NoticeApi.Detail(noticeId);
      setNoticeInfo(response.data.data);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                marginTop: 17,
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <div
                style={{
                  padding: "0px 20px",
                  borderBottom: "1px solid #ECEDF0",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "22.96px",
                    color: "#262C31",
                    fontWeight: 600,
                    marginBottom: 8,
                    wordBreak: "break-all",
                  }}
                >
                  {noticeInfo.title}
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 13,
                    lineHeight: "18.75px",
                    color: "#778088",
                    fontWeight: 400,
                    marginBottom: 22,
                  }}
                >
                  {moment(noticeInfo.createdAt).format("YYYY.MM.DD")}
                </PretendardText>
              </div>

              <div
                style={{
                  padding: "30px 20px",
                }}
              >
                <div
                  className="ql-editor"
                  style={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: noticeInfo.content,
                  }}
                />
              </div>
            </div>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default NoticeDetail;
