import React from "react";
import { ClipLoader } from "react-spinners";

function Loading({ style }) {
  return (
    <div class="contentWrap">
      <div
        style={{
          position: "relative",
          paddingTop: "5px",
          paddingRight: "5px",
          // top: "50%",
          // left: "50%",
          // transform: "translate(-50%, -50%)",
          ...style,
        }}
      >
        <ClipLoader color="#000000" size={30} />
      </div>
    </div>
  );
}

export default Loading;
