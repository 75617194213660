import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import useDisplay from "../../../hooks/useDisplay";
import PretendardText from "../../atoms/pretendardText";
import styled from "styled-components";
import MainBtn from "../../atoms/button/mainBtn";
import Select from "../../atoms/select/select";

import OptionSection from "../../molecules/option/optionSection";
import OptionSelect from "../../atoms/select/optionSelect";
import MerchOptionSection from "../../molecules/option/merchOptionSection";
import { route } from "../../../routes/route";
import { toast } from "react-toastify";

function ProductModal({ isOpen, setIsOptionModal, merch, challengeId }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  const [requireList, setRequireList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedOptionList, setSelectedOptionList] = useState([]);
  const [languageClicked, setLanguageClicked] = useState(0);

  const lastChildId = useMemo(() => {
    if (merch && merch.merchOptions.length > 0) {
      let temp = merch.merchOptions[merch.merchOptions.length - 1];

      return temp.id === languageClicked && selectedOptionList.length <= 1;
    } else {
      return false;
    }
  }, [languageClicked, selectedOptionList]);

  useEffect(() => {
    if (merch?.merchOptions.length <= 0) {
      const data = {
        optionTitle: merch?.name,
        optionId: "",
        option: {
          price: merch?.originPrice,
        },
        amount: 1,
      };
      selectedOptionList.push(data);
      setSelectedOptionList([...selectedOptionList]);
    }
  }, [merch]);

  const totalPrice = useMemo(() => {
    if (merch) {
      let merchTemp = merch.merchOptions.filter((item) => item.required);

      if (
        merchTemp[0]?.merchOptionValueDtoList &&
        merchTemp[0]?.merchOptionValueDtoList.length > 0
      ) {
        let merchTemp02 = merchTemp[0]?.merchOptionValueDtoList.map(
          (item) => item.id
        );

        setRequireList(merchTemp02);
      }
    }

    let optionTotal = 0;

    for (let index = 0; index < selectedOptionList.length; index++) {
      const element = selectedOptionList[index];
      if (!element.option.price) {
        element.option.price = 0;
      }
      optionTotal += element.option.price * element.amount;
    }

    if (merch?.merchOptions.length <= 0) {
      return optionTotal;
    } else {
      return merch?.originPrice + optionTotal;
    }
  }, [merch, selectedOptionList]);

  const nextStepFunc = () => {
    let requireCheck = true;
    if (merch.merchOptions.length > 0) {
      if (merch.merchOptions.some((item) => item.required === true)) {
        selectedOptionList.map((item) => {
          if (requireList.some((item02) => item02 === item.option.id)) {
            requireCheck = false;
          }
        });

        if (requireCheck) {
          return toast("필수 옵션을 선택해주세요.");
        }
      }
    }

    sessionStorage.setItem("payInfo", JSON.stringify(selectedOptionList));
    sessionStorage.setItem("totalPrice", totalPrice);
    history.push({
      pathname: route.payment + `/${challengeId}`,
    });
  };

  return (
    <>
      {isOpen ? (
        <div
          style={{
            position: "absolute",
            left: "0",
            top: "0",
            width: "100%",
            height: "100%",
            zIndex: "9999",
            display: "block",
          }}
        >
          <div
            className="dim"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              background: "rgba(0, 0, 0, 0.7)",
            }}
          ></div>

          <ModalLayoutWrapper isMobile={isMobile}>
            <img
              src="/assets/icons/ic_24_x.svg"
              alt=""
              style={{
                position: "absolute",
                width: "24px",
                height: "24px",
                top: "10px",
                right: "20px",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsOptionModal(false);
              }}
            />
            <PretendardText
              style={{
                fontWeight: "600",
                fontSize: "24px",
                lineHeight: "33.6px",
                color: "#262C31",
                marginTop: "13%",
                marginBottom: 3,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              상품선택
            </PretendardText>
            <PretendardText
              style={{
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "21.5px",
                color: "#5A636A",
                marginBottom: 24,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              제품 구매 후 챌린지 참여가 가능합니다.
            </PretendardText>

            <ModalScrollLayoutWrapper
              isMobile={isMobile}
              lastChildId={lastChildId}
              isList={selectedOptionList.length > 0 ? true : false}
            >
              {merch.merchOptions.map((item, index) => {
                if (
                  item.merchOptionValueDtoList.every(
                    (item) => item.merchStatus === "HIDDEN"
                  )
                ) {
                  return;
                }

                let merchOptionValueDtoList =
                  item.merchOptionValueDtoList.filter(
                    (item) => item.merchStatus !== "HIDDEN"
                  );

                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: index === 0 ? "" : 28,
                      zIndex: "0",
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 13,
                        lineHeight: "18.2px",
                        color: "#262C31",
                        fontWeight: 500,
                        marginBottom: 4,
                      }}
                    >
                      {item.name} {item.required ? "(필수)" : "(선택)"}
                    </PretendardText>
                    <OptionSelect
                      optionId={item.id}
                      selectedOptionList={selectedOptionList}
                      setSelectedOptionList={setSelectedOptionList}
                      dataList={merchOptionValueDtoList}
                      merchTitle={item.name}
                      placeholder={"옵션을 선택하세요"}
                      setLanguageClicked={setLanguageClicked}
                      languageClicked={languageClicked}
                    />
                  </div>
                );
              })}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 22,
                }}
              >
                {selectedOptionList.map((item, index) => {
                  return (
                    <MerchOptionSection
                      key={index}
                      style={{
                        marginBottom: 12,
                      }}
                      data={item}
                      index={index}
                      selectedOptionList={selectedOptionList}
                      setSelectedOptionList={setSelectedOptionList}
                    />
                  );
                })}
                {/* <OptionSection
                  style={{
                    marginBottom: 12,
                  }}
                /> */}
              </div>
            </ModalScrollLayoutWrapper>
            <div
              style={{
                position: "sticky",
                padding: "12px 20px",
                width: "100%",
                background: "#fff",
                bottom: 0,
                left: 0,
                zIndex: 99,
                borderTop: "1px solid  #D8DBE5",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: merch?.originPrice < merch?.price ? 13 : 5,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "22.96px",
                    color: "#262C31",
                    fontWeight: 600,
                  }}
                >
                  총 상품 금액
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 20,
                    lineHeight: "28px",
                    color: "#262C31",
                    fontWeight: 600,
                  }}
                >
                  {Number(totalPrice).toLocaleString("ko-KR")}원
                </PretendardText>
              </div>
              {merch?.originPrice < merch?.price ? null : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginBottom: 13,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/assets/icons/sale_text.svg"
                      alt=""
                      style={{
                        width: "66.72px",
                        height: "18px",
                        marginRight: 9.28,
                      }}
                    />

                    <PretendardText
                      style={{
                        fontSize: 20,
                        lineHeight: "28px",
                        color: "#FF3333",
                        fontWeight: 600,
                      }}
                    >
                      {merch?.merchOptions.length <= 0
                        ? Number(
                            merch?.price * selectedOptionList[0]?.amount
                          ).toLocaleString("ko-KR")
                        : Number(
                            Number(totalPrice) -
                              (merch?.originPrice - merch?.price)
                          ).toLocaleString("ko-KR")}
                      원
                    </PretendardText>
                  </div>
                </div>
              )}

              <MainBtn
                onClick={() => {
                  nextStepFunc();
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 15,
                    lineHeight: "20.3px",
                    color: "white",
                    fontWeight: 700,
                  }}
                >
                  참가하기
                </PretendardText>
              </MainBtn>
            </div>
          </ModalLayoutWrapper>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default ProductModal;

const ModalLayoutWrapper = styled.div.attrs((props) => {})`
  position: fixed;
  bottom: 0px;
  background: rgb(255, 255, 255);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: ${(props) => (props.isMobile ? "100vw" : "486px")};
  height: ${(props) => (props.isMobile ? "80%" : "664px")};
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const ModalScrollLayoutWrapper = styled.div.attrs((props) => {})`
  overflow-y: auto;
  min-height: ${(props) => (props.isMobile ? "530px" : "415px")};

  padding-bottom: ${(props) =>
    props.isMobile && props.lastChildId
      ? props.isList
        ? "140px"
        : "230px"
      : "50px"};

  padding-left: 20px;
  padding-right: 20px;

  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-thumb {
    // background: rgba(220, 20, 60); /* 스크롤바 색상 */
    border-radius: 0px; /* 스크롤바 둥근 테두리 */
  }

  ::-webkit-scrollbar-track {
    // background: rgba(220, 20, 60, 0.1); /*스크롤바 뒷 배경 색상*/
  }
`;
