import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import AppContainer from "../components/atoms/layouts/appContainer";
import TextBtn from "../components/atoms/button/textBtn";

import { route } from "../routes/route";
import MoreApi from "../api/moreApi";
import ConfirmModal from "../components/templates/modals/confirmModal";

function InfluencerMore(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();

  const [logoutModal, setLogoutModal] = useState(false);
  const [baseInfo, setBaseInfo] = useState({
    activate: true,
    agreeToAdNotice: true,
    agreeToServiceNotice: true,
    agreeToSms: true,
    birth: "",
    createdAt: "",
    email: "",
    fileUrl: null,
    followerCount: 0,
    gender: 1,
    homeExposure: null,
    id: 0,
    introduce: null,
    likeCount: null,
    links: null,
    name: null,
    nickName: "",
    onGoingChallengesCount: null,
    originFileName: null,
    phoneNumber: "",
    point: 0,
    progressChallenge: null,
    resigned: false,
    resignedDate: null,
    resignedReason: null,
    resignedReasonEnum: null,
    reviewCount: 0,
    serverFileName: null,
    storeListCount: 0,
    updatedAt: "",
    userFollowed: null,
  });

  useEffect(() => {
    getMyInfoFunc();

    window.scrollTo(0, 0);
  }, []);

  const getMyInfoFunc = async () => {
    try {
      let response = await MoreApi.Base();

      setBaseInfo(response.data.data);
    } catch (error) {}
  };

  const logoutFunc = async () => {
    localStorage.removeItem("autoLogin");
    localStorage.removeItem("account");
    localStorage.removeItem("password");

    sessionStorage.removeItem("token");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("memberType");

    history.push({
      pathname: route.main,
    });
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <div
            style={{
              paddingBottom: 60,
              paddingLeft: 20,
              paddingRight: 20,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                width: "100%",
                height: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TextBtn>
                <img
                  src="/assets/icons/left_btn.png"
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </TextBtn>

              <TextBtn
                onClick={() => {
                  history.push({
                    pathname: route.alarm_list,
                  });
                }}
              >
                <img
                  src="/assets/icons/bell.png"
                  style={{
                    height: 24,
                    width: 24,
                    cursor: "pointer",
                  }}
                />
              </TextBtn>
            </div>
            <TextBtn
              onClick={() => {
                history.push({
                  pathname: route.account_manager,
                });
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
                marginTop: 9,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 20,
                  lineHeight: "28px",
                  color: "#262C31",
                  fontWeight: 600,
                }}
              >
                {baseInfo.nickName}
              </PretendardText>
              <img
                src="/assets/icons/right_arrow.png"
                style={{
                  height: 16,
                  width: 16,
                }}
              />
            </TextBtn>
            <PretendardText
              style={{
                fontSize: 15,
                lineHeight: "22.3px",
                color: "#778088",
                fontWeight: 500,
              }}
            >
              {baseInfo.email}
            </PretendardText>

            <div
              style={{
                flex: 1,
                marginTop: 30,
              }}
            >
              <TextBtn
                onClick={() => {
                  history.push({
                    pathname: route.my_storage_list,
                  });
                }}
                style={{
                  height: 41,
                  alignItems: "center",
                  marginTop: 14,
                }}
              >
                <StyledTextBtnText>저장목록</StyledTextBtnText>
              </TextBtn>
              <TextBtn
                onClick={() => {
                  history.push({
                    pathname: route.influencer_profile_edit,
                  });
                }}
                style={{
                  height: 41,
                  alignItems: "center",
                  marginTop: 14,
                }}
              >
                <StyledTextBtnText>프로필 관리</StyledTextBtnText>
              </TextBtn>
              <TextBtn
                onClick={() => {
                  history.push({
                    pathname: route.notice_list,
                  });
                }}
                style={{
                  height: 41,
                  alignItems: "center",
                  marginTop: 14,
                }}
              >
                <StyledTextBtnText>공지사항</StyledTextBtnText>
              </TextBtn>
              <TextBtn
                onClick={() => {
                  history.push({
                    pathname: route.inquiry_list,
                  });
                }}
                style={{
                  height: 41,
                  alignItems: "center",
                  marginTop: 14,
                }}
              >
                <StyledTextBtnText>1:1 문의하기</StyledTextBtnText>
              </TextBtn>
              <TextBtn
                onClick={() => {
                  history.push({
                    pathname: route.alarm_setting,
                  });
                }}
                style={{
                  height: 41,
                  alignItems: "center",
                  marginTop: 14,
                }}
              >
                <StyledTextBtnText>알림설정</StyledTextBtnText>
              </TextBtn>
              <TextBtn
                onClick={() => {
                  history.push({
                    pathname: route.term_list,
                  });
                }}
                style={{
                  height: 41,
                  alignItems: "center",
                  marginTop: 14,
                }}
              >
                <StyledTextBtnText>약관 및 정책</StyledTextBtnText>
              </TextBtn>
              {/* <TextBtn
                onClick={() => {
                  history.push({
                    pathname: route.term_content,
                    state: {
                      title: "오픈소스 라이선스",
                    },
                  });
                }}
                style={{
                  height: 41,
                  alignItems: "center",
                  marginTop: 14,
                }}
              >
                <StyledTextBtnText>오픈소스 라이선스</StyledTextBtnText>
              </TextBtn> */}

              {/* <TextBtn
              style={{
                height: 41,
                alignItems: "center",
                marginTop: 14,
              }}
            >
              <StyledTextBtnText>앱 버전 정보</StyledTextBtnText>
            </TextBtn> */}
            </div>

            <TextBtn
              onClick={() => {
                history.push({
                  pathname: route.withdraw,
                });
              }}
              style={{
                height: 41,
                alignItems: "center",
                marginTop: 60,
              }}
            >
              <StyledTextBtnGray>탈퇴하기</StyledTextBtnGray>
            </TextBtn>
            <TextBtn
              onClick={() => {
                setLogoutModal(true);
              }}
              style={{
                height: 41,
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <StyledTextBtnGray>로그아웃</StyledTextBtnGray>
            </TextBtn>
          </div>

          {logoutModal ? (
            <ConfirmModal
              title={"로그아웃 하시겠습니까?"}
              onClickText={"닫기"}
              onCancleText={"로그아웃"}
              onClick={() => {
                setLogoutModal(false);
              }}
              onCancle={() => {
                logoutFunc();
              }}
            />
          ) : null}
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default InfluencerMore;

const StyledTextBtnText = styled(PretendardText).attrs((props) => {})`
  font-size: 15px;
  line-height: 22.3px;
  color: #262c31;
  font-weight: 500;
`;

const StyledTextBtnGray = styled(PretendardText).attrs((props) => {})`
  font-size: 13px;
  line-height: 18.75px;
  color: #adb4ba;
  font-weight: 600;
`;
