import React, { useState, useEffect } from "react";

import useDisplay from "../../hooks/useDisplay";
import AppLayout from "../../components/atoms/layouts/appLayout";
import AppContainer from "../../components/atoms/layouts/appContainer";
import BottomTab from "../../components/organisms/bottomTab/bottomTab";
import ScrollContainer from "react-indiana-drag-scroll";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Keyboard, Pagination, Navigation } from "swiper/modules";
import PretendardText from "../../components/atoms/pretendardText";
import BlackBtn from "../../components/atoms/button/blackBtn";
import MainBtn from "../../components/atoms/button/mainBtn";
import WhiteBoldBtn from "../../components/atoms/button/whiteBoldBtn";
import ProfileCard from "../../components/organisms/profileCard";
import ChallengeCard from "../../components/organisms/challengeCard";
import CertificationCard from "../../components/organisms/certificationCard";
import WelcomeModal from "../../components/templates/modals/welcomeModal";
import TextBtn from "../../components/atoms/button/textBtn";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { route } from "../../routes/route";
import HomeApi from "../../api/homeApi";
import { toast } from "react-toastify";
import Empty from "../../components/molecules/empty";
import { ko } from "../../utils/ko";
import PopupApi from "../../api/popupApi";
import PopupModal from "../../components/templates/modals/popupModal";
import MainChallengeSection from "../../components/molecules/challenge/mainChallengeSection";
import TermApi from "../../api/termApi";

function Home(props) {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  useEffect(() => {
    getPopupList();
    window.scrollTo(0, 0);
  }, []);

  const [activeIndex, setActiveIndex] = useState(1);

  const [bannerList, setBannerList] = useState([]);
  const [influencerList, setInfluencerList] = useState([]);
  const [shortFormList, setShortFormList] = useState([]);
  const [challengeList, setChallengeList] = useState([]);
  const [popupList, setPopupList] = useState([]);
  const [popupDelList, setPopupDelList] = useState([]);
  const [popupTodayDelList, setPopupTodayDelList] = useState([]);
  const [mainChallengeList, setMainChallengeList] = useState([]);
  const [baseInfo, setBaseInfo] = useState({
    address: "서울특별시 강남구 도산대로 145 (신사동) 인우빌딩 13층 1303호",
    businessNumber: "480-86-02455",
    ceo: "손진국",
    companyName: "(주)스타일디택트",
    createdAt: "2023-08-16T18:05:38.708951",
    email: "sonjk@styledtact.com",
    id: 1,
    titlePhoneNumber: "02-6959-1816",
    updatedAt: "2023-08-16T18:38:36.328188",
  });

  const getPopupList = async () => {
    try {
      const popupDate = localStorage.getItem("popup_date");
      let date = toStringByFormatting(new Date());
      let popupDelList;

      if (popupDate !== date) {
        localStorage.removeItem("popup_list");
      } else {
        popupDelList = localStorage.getItem("popup_list");
      }

      if (popupDelList) {
        let temp = popupDelList.split(",");
        const response = await PopupApi.List();

        let data;
        if (response.data.data.length > 0) {
          data = response.data.data.map((item) => {
            if (temp.some((item02) => item02 == item.id)) {
              return null;
            } else {
              return item;
            }
          });
        } else {
          data = response.data.data;
        }

        setPopupList(data);
      } else {
        const response = await PopupApi.List();
        const data = response.data.data;
        setPopupList(data);
      }
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const setData = async () => {
    try {
      const response = await HomeApi.getData();
      const data = response.data.data;
      if (data.bannerList?.length <= 0) {
        setBannerList([
          {
            imageUrl: "/assets/images/empty_banner.png",
          },
        ]);
      } else {
        setBannerList(data.bannerList);
      }
      setInfluencerList(data.influencerList);
      setShortFormList(data.shortFormList);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const setDataChallenge = async () => {
    try {
      const response = await HomeApi.getDataChallenge();
      const data = response.data.data.content;

      setChallengeList(data);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    setData();
    setDataChallenge();
    setMainAllChallenge();
    baseInfoFunc();
  }, []);

  const baseInfoFunc = async () => {
    try {
      let response = await TermApi.BaseInfo();

      setBaseInfo(response.data.data);
    } catch (error) {}
  };

  const setMainAllChallenge = async () => {
    try {
      const response = await HomeApi.mainAllGetDataChallenge();

      const data = response.data.data.content;
      setMainChallengeList(data);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const handleOpenNewTab = (url) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  const popupCloseFunc = (id) => {
    try {
      let date = toStringByFormatting(new Date());
      let popupId = localStorage.getItem("popup_list");

      if (popupId) {
        let popupIdTemp = [...popupId, id];
        localStorage.setItem("popup_list", popupIdTemp);
      } else {
        let popupIdTemp = [id];
        localStorage.setItem("popup_list", popupIdTemp);
      }

      localStorage.setItem("popup_date", date);
    } catch (err) {}
  };

  function toStringByFormatting(source, delimiter = "-") {
    const year = source.getFullYear();
    const month = leftPad(source.getMonth() + 1);
    const day = leftPad(source.getDate());

    return year + "/" + month + "/" + day;
  }

  function leftPad(value) {
    if (value >= 10) {
      return value;
    }

    return `0${value}`;
  }

  return (
    <>
      <AppLayout style={{ backgroundColor: "#F5F5F5" }}>
        <AppContainer
          style={{
            backgroundColor: "#F5F5F5",
          }}
        >
          {/* 헤더 */}
          <div
            style={{
              height: "50px",
              paddingLeft: "20px",
              paddingRight: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img src="/assets/images/home_logo.png" alt="" />
            <TextBtn
              onClick={() => {
                history.push(route.challenge_search);
              }}
            >
              <img
                src="/assets/icons/ic_24_search.svg"
                alt=""
                style={{
                  cursor: "pointer",
                }}
              />
            </TextBtn>
          </div>
          {/* 프로필 */}
          <div
            style={{
              marginTop: "31px",
            }}
          >
            <ScrollContainer className="scroll-container">
              <div
                style={{
                  display: "flex",
                  width: isMobile ? "100vw" : "100%",
                }}
              >
                {influencerList.map((item, index) => {
                  return (
                    <ProfileCard
                      key={index}
                      item={item}
                      isFirst={index === 0}
                      isLast={influencerList.length - 1 === index}
                    ></ProfileCard>
                  );
                })}
              </div>
            </ScrollContainer>
          </div>
          {/* 배너 */}
          <div
            style={{
              marginTop: "37px",
              position: "relative",
            }}
          >
            <Swiper
              style={{
                width: isMobile ? "100vw" : "100%",
                height: "100%",
              }}
              slidesPerView={1.1}
              centeredSlides={true}
              spaceBetween={8}
              onSlideChange={(swiper) => {
                setActiveIndex(swiper.activeIndex + 1);
              }}
            >
              {bannerList.map((item, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    style={{
                      width: "335px",
                      height: "168px",
                      overflow: "hidden",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (
                        item.link.includes("http") ||
                        item.link.includes("https")
                      ) {
                        handleOpenNewTab(item.link);
                      } else {
                        handleOpenNewTab(`https://${item.link}`);
                      }
                    }}
                  >
                    <img
                      src={item.imageUrl}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <PretendardText
              style={{
                width: "33px",
                height: "21px",
                border: "1px grey",
                borderRadius: "16px",
                backgroundColor: "grey",
                color: "#ffffff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                bottom: "16px",
                right: isMobile ? "36px" : "90px",
                zIndex: "1",

                fontSize: 12,
                lineHeight: "16.5px",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              {activeIndex + "/" + bannerList.length}
            </PretendardText>
          </div>
          {/* HOT 챌린지 ~ */}
          <div
            style={{
              marginTop: "40px",
            }}
          >
            <PretendardText
              style={{
                fontSize: 20,
                lineHeight: "28px",
                fontWeight: 600,
                paddingLeft: "20px",
              }}
            >
              🔥헬랄라 진행 HOT 챌린지
            </PretendardText>
            {mainChallengeList.length > 0 ? (
              <>
                <ScrollContainer className="scroll-container">
                  <div
                    style={{
                      display: "flex",
                      width: isMobile ? "100vw" : "100%",
                      marginTop: "19px",
                    }}
                  >
                    {mainChallengeList.map((item, index) => {
                      return (
                        <div key={index}>
                          <div
                            style={{
                              marginLeft: index === 0 ? "20px" : "14px",
                              paddingRight:
                                mainChallengeList.length - 1 === index
                                  ? "10px"
                                  : "",
                              width: "161px",
                            }}
                          >
                            <MainChallengeSection item={item} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </ScrollContainer>
              </>
            ) : (
              <Empty text={"콘텐츠가 없어요"} style={{ margin: "72px 0px" }} />
            )}
          </div>

          {/* 헬랄라 크루와 ~ */}
          <div
            style={{
              marginTop: "40px",
            }}
          >
            <PretendardText
              style={{
                fontSize: 20,
                lineHeight: "28px",
                fontWeight: 600,
                paddingLeft: "20px",
              }}
            >
              👟헬랄라 크루와 챌린지 해요!
            </PretendardText>
            {shortFormList.length > 0 ? (
              <>
                <ScrollContainer className="scroll-container">
                  <div
                    style={{
                      display: "flex",
                      width: isMobile ? "100vw" : "100%",
                      marginTop: "19px",
                    }}
                  >
                    {shortFormList.map((item, index) => {
                      return (
                        <div key={index}>
                          <div
                            style={{
                              marginLeft: index === 0 ? "20px" : "14px",
                              paddingRight:
                                shortFormList.length - 1 === index
                                  ? "10px"
                                  : "",
                              width: "150px",
                            }}
                          >
                            <ChallengeCard item={item}></ChallengeCard>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </ScrollContainer>
                <TextBtn
                  style={{
                    background: "rgba(219, 221, 223, 1)",
                    marginTop: "16px",
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "8px",
                    height: "48px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push({
                      pathname: route.bottom_search_certification,
                    });
                  }}
                >
                  <PretendardText
                    style={{
                      color: "rgba(90, 99, 106, 1)",
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "600",
                    }}
                  >
                    챌린지 게시글 더보기
                  </PretendardText>
                </TextBtn>
              </>
            ) : (
              <Empty text={"콘텐츠가 없어요"} style={{ margin: "72px 0px" }} />
            )}
          </div>
          {/* 함께하는 ~ */}
          <div
            style={{
              marginTop: "40px",
            }}
          >
            <PretendardText
              style={{
                fontSize: 20,
                lineHeight: "28px",
                fontWeight: 600,
                paddingLeft: "20px",
                color: "#000000",
              }}
            >
              함께하는 챌린지
            </PretendardText>
            <PretendardText
              style={{
                marginTop: "6px",
                fontSize: 14,
                lineHeight: "21.5px",
                fontWeight: 500,
                paddingLeft: "20px",
                color: "#778088",
              }}
            >
              내가 참가한 챌린지에 실시간으로 올라오는<br></br> 인증영상을 보고
              소통하세요!
            </PretendardText>
            <div
              style={{
                marginTop: "19px",
              }}
            >
              <ScrollContainer className="scroll-container">
                <div
                  style={{
                    display: "flex",
                    width: isMobile ? "100vw" : "100%",
                    marginTop: "19px",
                  }}
                >
                  {challengeList.length > 0 ? (
                    challengeList.map((item, index) => {
                      return (
                        <CertificationCard
                          key={index}
                          isFirst={index === 0}
                          isLast={challengeList.length - 1 === index}
                          item={item}
                        ></CertificationCard>
                      );
                    })
                  ) : (
                    <Empty
                      text={"콘텐츠가 없어요"}
                      style={{ margin: "40px 0px 80px 0px" }}
                    />
                  )}
                </div>
              </ScrollContainer>
            </div>
            <div
              style={{
                background: "#FFFFFF",
                padding: "18px 20px 90px 20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "20px",
                  color: "#5A636A",
                  fontWeight: 600,
                  marginBottom: 15,
                }}
              >
                사업자 정보
              </PretendardText>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 12,
                    lineHeight: "15.6px",
                    color: "#ADB4BA",
                    fontWeight: 600,
                    minWidth: 97,
                  }}
                >
                  상호명
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 12,
                    lineHeight: "15.6px",
                    color: "#ADB4BA",
                    fontWeight: 400,
                  }}
                >
                  {baseInfo.companyName}
                </PretendardText>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 12,
                    lineHeight: "15.6px",
                    color: "#ADB4BA",
                    fontWeight: 600,
                    minWidth: 97,
                  }}
                >
                  대표자
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 12,
                    lineHeight: "15.6px",
                    color: "#ADB4BA",
                    fontWeight: 400,
                  }}
                >
                  {baseInfo.ceo}
                </PretendardText>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 12,
                    lineHeight: "15.6px",
                    color: "#ADB4BA",
                    fontWeight: 600,
                    minWidth: 97,
                  }}
                >
                  사업자등록번호
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 12,
                    lineHeight: "15.6px",
                    color: "#ADB4BA",
                    fontWeight: 400,
                  }}
                >
                  {baseInfo.businessNumber}
                </PretendardText>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 12,
                    lineHeight: "15.6px",
                    color: "#ADB4BA",
                    fontWeight: 600,
                    minWidth: 97,
                  }}
                >
                  주소
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 12,
                    lineHeight: "15.6px",
                    color: "#ADB4BA",
                    fontWeight: 400,
                  }}
                >
                  {baseInfo.address}
                </PretendardText>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 12,
                    lineHeight: "15.6px",
                    color: "#ADB4BA",
                    fontWeight: 600,
                    minWidth: 97,
                  }}
                >
                  전화번호
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 12,
                    lineHeight: "15.6px",
                    color: "#ADB4BA",
                    fontWeight: 400,
                  }}
                >
                  {baseInfo.titlePhoneNumber}
                </PretendardText>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 12,
                    lineHeight: "15.6px",
                    color: "#ADB4BA",
                    fontWeight: 600,
                    minWidth: 97,
                  }}
                >
                  이메일
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 12,
                    lineHeight: "15.6px",
                    color: "#ADB4BA",
                    fontWeight: 400,
                  }}
                >
                  {baseInfo.email}
                </PretendardText>
              </div>
            </div>
          </div>
          {/* 메뉴 탭 */}
          <BottomTab />

          {popupList.length > 0
            ? popupList.map((item, index) => {
                if (item == null) {
                  return;
                }

                if (popupDelList.some((item02) => item02 === item.id)) {
                  return;
                }

                return (
                  <PopupModal
                    key={index}
                    data={item}
                    btnOnPress={() => {
                      popupCloseFunc(item.id);
                      setPopupDelList([...popupDelList, item.id]);
                    }}
                    btnConfirmPress={() => {
                      setPopupDelList([...popupDelList, item.id]);
                    }}
                  />
                );
              })
            : null}
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default Home;
