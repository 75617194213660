import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import Empty from "../components/molecules/empty";
import DeliverySection from "../components/molecules/delivery/deliverySection";
import { route } from "../routes/route";
import MoreApi from "../api/moreApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import ConfirmModal from "../components/templates/modals/confirmModal";

function DeliveryManager(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    getDMList();

    window.scrollTo(0, 0);
  }, []);

  const getDMList = async () => {
    try {
      const response = await MoreApi.DMList();

      setDataList(response.data.data);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const [deleteModal, setDeleteModal] = useState(0);
  const delDMFunc = async () => {
    try {
      await MoreApi.DMDel(deleteModal);
      toast("삭제되었습니다.");
      getDMList();
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header title={"배송지 관리"} />

          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                marginTop: 13,
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              {dataList.length > 0 ? (
                dataList.map((item, index) => {
                  return (
                    <DeliverySection
                      key={index}
                      data={item}
                      style={{
                        marginBottom: dataList.length === index + 1 ? 20 : 24,
                      }}
                      delDMFunc={setDeleteModal}
                    />
                  );
                })
              ) : (
                <Empty text={"배송지가 없어요"} />
              )}
            </div>
          </div>

          <div
            style={{
              position: "sticky",
              padding: "10px 20px",
              width: "100%",
              background: "#fff",
              bottom: 0,
              left: 0,
            }}
          >
            <MainBtn
              onClick={() => {
                history.push({
                  pathname: route.delivery_edit,
                });
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                배송지 추가
              </PretendardText>
            </MainBtn>
          </div>
          {deleteModal !== 0 ? (
            <ConfirmModal              
              title={"배송지를 삭제하시겠습니까?"}              
              onClickText={"닫기"}
              onCancleText={"삭제"}
              onClick={() => {
                setDeleteModal(0);
              }}
              onCancle={() => {
                delDMFunc()
                setDeleteModal(0);                
              }}
            />
          ) : null}
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default DeliveryManager;
