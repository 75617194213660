import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import CertificationBox from "../components/organisms/certificationBox";
import TextBtn from "../components/atoms/button/textBtn";
import EntryCertificationBox from "../components/organisms/entryCertificationBox";
import ChallengeApi from "../api/challengeApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import Empty from "../components/molecules/empty";

function EntryCertification(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // 챌린지 인증 상세
  const { id } = useParams();
  const [certificationList, setCertificationList] = useState([]);

  // init
  const getCertificationList = async () => {
    try {
      const response = await ChallengeApi.CertificationList(id);
      const data = response.data.data;
      setCertificationList(data.content);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    getCertificationList();
  }, []);

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header title={"참가자 인증 내역"} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {certificationList.length > 0 ? (
                  certificationList?.map((item, index) => {
                    return (
                      <EntryCertificationBox
                        key={index}
                        isEnd={(index + 1) % 3 === 0}
                        item={item}
                      />
                    );
                  })
                ) : (
                  <Empty
                    text={"인증이 없어요"}
                    style={{
                      height: "60vh",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default EntryCertification;
