import React, { useEffect, useMemo } from "react";
import useDisplay from "../../hooks/useDisplay";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PretendardText from "../atoms/pretendardText";
import TextBtn from "../atoms/button/textBtn";
import { route } from "../../routes/route";
import moment from "moment";
import { getExtensionOfFilename, videoExtension } from "../../utils/file";

function ChallengeManagerBox({ isAdmin, item }) {
  const history = useHistory();

  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <TextBtn
      onClick={() => {
        history.push(route.challenge_detail + `/${item.id}`);
      }}
    >
      <div
        style={{
          marginBottom: "12px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "121px",
              height: "121px",
              overflow: "hidden",
              borderRadius: "8px",
            }}
          >
            <img
              src={
                videoExtension.includes(getExtensionOfFilename(item.fileUrl))
                  ? item.thumbFileUrl
                  : item.fileUrl
              }
              alt=""
              style={{
                width: "100%",
                height: "100%",
              }}
            />
            <PretendardText
              style={{
                fontSize: 11,
                lineHeight: "14px",
                color: "#FFFFFF",
                fontWeight: 600,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                borderRadius: "6px",
                padding: "5px 8px",
                position: "absolute",
                top: "10px",
                left: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {item.challengeTypeTitle}
            </PretendardText>
          </div>
          <div
            style={{
              width: isMobile ? "218px" : "310px",
              marginLeft: "12px",
              paddingTop: "2.5px",
              paddingBottom: "2.5px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 13,
                    lineHeight: "18.75px",
                    color: "rgba(38, 44, 49, 1)",
                    fontWeight: 600,
                  }}
                >
                  {item.challengeStatusTitle}
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 13,
                    lineHeight: "18.75px",
                    color: "rgba(119, 128, 136, 1)",
                    fontWeight: 600,
                    marginLeft: "7px",
                  }}
                >
                  {item?.challengeCategoryTitle}
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 13,
                    lineHeight: "18.75px",
                    color: "rgba(119, 128, 136, 1)",
                    fontWeight: 600,
                    marginLeft: "7px",
                  }}
                >
                  {item?.challengePeriodTitle}
                </PretendardText>
              </div>
            </div>
            <PretendardText
              style={{
                marginTop: "8px",
                fontSize: 15,
                lineHeight: "20.3px",
                color: "#262C31",
                fontWeight: 600,
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                display: "-webkit-box",
              }}
            >
              {item?.title}
            </PretendardText>
            <PretendardText
              style={{
                marginTop: "3px",
                fontSize: 14,
                lineHeight: "21.5px",
                color: "#6E7385",
                fontWeight: 500,
              }}
            >
              {moment(item.startDate).format("YYYY.MM.DD")}~
              {moment(item.endDate).format("YYYY.MM.DD")}
            </PretendardText>
            {item.adminCreated ? (
              <PretendardText
                style={{
                  marginTop: "5px",
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#66CCCC",
                  fontWeight: 600,
                }}
              >
                *관리자가 생성한 챌린지 입니다.
              </PretendardText>
            ) : !item.authored ? (
              <PretendardText
                style={{
                  marginTop: "5px",
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#66CCCC",
                  fontWeight: 600,
                }}
              >
                *미승인된 챌린지 입니다.
              </PretendardText>
            ) : null}
          </div>
        </div>
      </div>
    </TextBtn>
  );
}

export default ChallengeManagerBox;
