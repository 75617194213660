const prefix = "/healala";

export const route = {
  // 공통
  login: prefix + "/login",

  // 일반회원
  main: prefix + "/main",
  register: prefix + "/register",
  register_social: prefix + "/register_social",
  id_find: prefix + "/id_find",
  pw_find: prefix + "/pw_find",
  id_find_complete: prefix + "/id_find_complete",
  pw_reset: prefix + "/pw_reset",
  profile_register: prefix + "/profile_register",
  profile_register_social: prefix + "/profile_register_social",
  term_detail: prefix + "/term_detail",
  bottom_home: prefix + "/bottom_home",
  bottom_search: prefix + "/bottom_search",
  bottom_search_certification: prefix + "/bottom_search_certification",
  bottom_challenge: prefix + "/bottom_challenge",
  challenge_detail: prefix + "/challenge_detail",
  payment: prefix + "/payment",
  refund: prefix + "/refund",
  challenge_review_write: prefix + "/challenge_review_write",
  entry_certification: prefix + "/entry_certification",
  general_user_profile: prefix + "/general_user_profile",
  challenge_review_list: prefix + "/challenge_review_list",
  challenge_review_detail: prefix + "/challenge_review_detail",
  bottom_myChallenge: prefix + "/bottom_myChallenge",
  profile_edit: prefix + "/profile_edit",
  bottom_more: prefix + "/bottom_more",
  account_manager: prefix + "/account_manager",
  pw_change: prefix + "/pw_change",
  notice_list: prefix + "/notice_list",
  notice_detail: prefix + "/notice_detail",
  alarm_list: prefix + "/alarm_list",
  alarm_detail: prefix + "/alarm_detail",
  alarm_setting: prefix + "/alarm_setting",
  term_list: prefix + "/term_list",
  term_content: prefix + "/term_content",
  withdraw: prefix + "/withdraw",
  inquiry_list: prefix + "/inquiry_list",
  inquiry_write: prefix + "/inquiry_write",
  point_list: prefix + "/point_list",
  follow_list: prefix + "/follow_list",
  delivery_manager: prefix + "/delivery_manager",
  delivery_edit: prefix + "/delivery_edit",
  order_list: prefix + "/order_list",
  order_detail: prefix + "/order_detail",
  my_review_list: prefix + "/my_review_list",
  my_storage_list: prefix + "/my_storage_list",
  report_write: prefix + "/report_write",
  short_form: prefix + "/short_form",
  all_short_form: prefix + "/all_short_form",
  short_form_detail: prefix + "/short_form_detail",
  influencer_profile: prefix + "/influencer_profile",
  influencer_search: prefix + "/influencer_search",
  challenge_search: prefix + "/challenge_search",
  certification: prefix + "/certification",
  certification_detail: prefix + "/certification_detail",
  challenge_certification: prefix + "/challenge_certification",

  // 인플루언서
  influencer_main: prefix + "/influencer_main",
  bottom_manager_challenge: prefix + "/bottom_manager_challenge",
  bottom_my_profile: prefix + "/bottom_my_profile",
  register_influencer: prefix + "/influencer/register",
  profile_register_influencer: prefix + "/influencer/profile_register",
  influencer_more: prefix + "/influencer/more",

  create_challenge: prefix + "/create_challenge",
  update_challenge: prefix + "/update_challenge",
  restart_challenge: prefix + "/restart_challenge",
  board_upload: prefix + "/board_upload",

  influencer_profile_edit: prefix + "/influencer_profile_edit",
  payment_complete: prefix + "/payment_complete",
};
