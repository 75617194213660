import api from "./api.js";

export default class HomeApi {
  static async getData(data) {
    return await api.get(`mai-001_F0`, data);
  }
  static async getDataChallenge(data) {
    return await api.get(`mai-001_F1`, data);
  }
  static async getDataShortForm(id, challengeId, data) {
    return await api.get(`mai-002_F0/${id}/${challengeId}`, data);
  }

  static async allGetDataShortForm(data) {
    return await api.get(`mai-002_F4`, data);
  }

  static async mainAllGetDataChallenge() {
    return await api.get(`challenges/exposure`, { page: 0, size: 1000 });
  }
}
