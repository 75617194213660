import React, { useState, useEffect } from "react";
import useDisplay from "../../../hooks/useDisplay";
import styled from "styled-components";
import PretendardText from "../pretendardText";

function LeftLabelCheckbox({
  text,
  size,
  checked,
  onChange,
  inputStyle,
  onClick,
  textStyle,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
          ...inputStyle,
        }}
      >
        <StyledP
          onClick={onClick}
          style={{
            ...textStyle,
          }}
        >
          {text}
        </StyledP>

        {checked ? (
          <img
            onClick={onChange}
            src="/assets/icons/check_a.svg"
            style={{
              height: 20,
              width: 20,
            }}
          />
        ) : (
          <img
            onClick={onChange}
            src="/assets/icons/check_d.svg"
            style={{
              height: 20,
              width: 20,
            }}
          />
        )}
      </div>
    </>
  );
}

export default LeftLabelCheckbox;

const StyledP = styled(PretendardText)`
  color: #262c31;
  font-weight: 400;
  font-size: 15px;
  line-height: 22.3px;
  flex: 1;
`;
