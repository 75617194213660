import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import Empty from "../components/molecules/empty";
import OrderSection from "../components/molecules/order/orderSection";

import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import { route } from "../routes/route";
import { useInView } from "react-intersection-observer";
import MoreApi from "../api/moreApi";
import ChallengeApi from "../api/challengeApi";
import ConfirmModal from "../components/templates/modals/confirmModal";

function OrderList(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [ref, inView] = useInView();
  const [dataList, setDataList] = useState([]);

  const [saveOpen, setSaveOpen] = useState(false);
  const [cId, setCId] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setData = async () => {
    try {
      setLoading(true);
      const response = await MoreApi.GetOrderList({
        page: currentPage,
        size: 10,
      });

      const data = response.data.data;

      setDataList(dataList.concat(data.content));
      setIsLast(data.last);
      setLoading(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    setData();
  }, [currentPage]);

  useEffect(() => {
    if (inView && !isLast && !loading) {
      setCurrentPage(currentPage + 1);
      setData();
    }
  }, [inView, loading]);

  const depositRefundAPI = async () => {
    try {
      const response = await ChallengeApi.DepositRefund(cId);
      setCurrentPage(0);
      setDataList([]);
      setIsLast(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const depositRefundConfirm = async (cIdTemp) => {
    try {
      setCId(cIdTemp);
      setSaveOpen(true);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header title={"주문배송조회"} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              paddingTop: 22,
            }}
          >
            {dataList.length > 0 ? (
              dataList.map((item, index) => {
                return (
                  <OrderSection
                    key={index}
                    data={item}
                    depositRefundConfirm={depositRefundConfirm}
                  />
                );
              })
            ) : (
              <Empty text={"주문내역이 없어요"} />
            )}

            <div ref={ref}>{/* loading */}</div>
          </div>

          {saveOpen ? (
            <ConfirmModal
              title={"취소 요청하시겠어요?"}
              onClickText={"닫기"}
              onCancleText={"취소 요청하기"}
              onClick={() => {
                setSaveOpen(false);
              }}
              onCancle={() => {
                depositRefundAPI();
                setSaveOpen(false);
              }}
            />
          ) : null}
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default OrderList;
