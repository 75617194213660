import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import TitleInput from "../components/molecules/input/titleInput";
import FileForm from "../components/atoms/fileForm/fileForm";
import Input from "../components/atoms/input/input";
import TextArea from "../components/atoms/input/textarea";
import InqueryApi from "../api/inqueryApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";

function InquiryWrite(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  const [content, setContent] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const inqueryAddFunc = async () => {
    try {
      const formData = new FormData();
      formData.append("inquiry", content);

      await InqueryApi.Add(formData);

      history.goBack();
      toast("문의 완료");
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header title={"1:1 문의하기"} />

          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                marginTop: 13,
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#778088",
                  fontWeight: 600,
                  marginBottom: 4,
                }}
              >
                문의내용
              </PretendardText>
              <TextArea
                value={content}
                onChange={(e) => {
                  setContent(e.target.value);
                }}
                type={"text"}
                placeholder={
                  "상세히 입력해주시면 더 정확한 답변을 받으실 수 있습니다."
                }
              />
            </div>

            <MainBtn
              disabled={content ? false : true}
              onClick={() => {
                inqueryAddFunc();
              }}
              style={{
                marginTop: 22,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                등록하기
              </PretendardText>
            </MainBtn>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default InquiryWrite;
