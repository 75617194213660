import React, { useEffect, useState } from "react";
import useDisplay from "../hooks/useDisplay";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AppLayout from "../components/atoms/layouts/appLayout";
import AppContainer from "../components/atoms/layouts/appContainer";
import TextBtn from "../components/atoms/button/textBtn";
import PretendardText from "../components/atoms/pretendardText";
import Tab from "../components/molecules/tab";
import ProfileBoardBox from "../components/organisms/profileBoardBox";
import ProfileChallengeBox from "../components/organisms/profileChallengeBox";
import MainBtn from "../components/atoms/button/mainBtn";
import InfluencerApi from "../api/influencerApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";

import { useInView } from "react-intersection-observer";
import { route } from "../routes/route";
import Empty from "../components/molecules/empty";
import SearchChallengeBox from "../components/organisms/searchChallengeBox";
import { shareFunc } from "../utils/share";

function InfluencerProfile() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const { ifId } = useParams();
  let myID = sessionStorage.getItem("id");

  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();

  const [influencerId, setInfluencerId] = useState();
  const [isMoreBtn, setIsMoreBtn] = useState(false);
  const [isMyProfile, setIsMyProfile] = useState(false);
  const [moreIntro, setMoreIntro] = useState(false);
  const [profileInfo, setProfileInfo] = useState();
  const [introLine, setIntroLine] = useState(0);

  const [tabList, setTabList] = useState([
    { label: "게시글 전체", value: true },
    { label: "챌린지", value: false },
  ]);

  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [ref, inView] = useInView();
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    const tabListTemp = location?.state ? location.state.tabListTemp : null;

    const id = sessionStorage.getItem("id");

    if (ifId) {
      if (id == ifId) {
        setIsMyProfile(true);
      }

      setInfluencerId(ifId);
      profileDetailFunc(ifId);
    }

    if (tabListTemp) {
      setTabList(tabListTemp);
    }

    window.scrollTo(0, 0);
  }, []);

  const moveLoaction = (data) => {
    history.replace({
      pathname: route.influencer_profile + `/${influencerId}`,
      state: {
        tabListTemp: data,
      },
    });
  };

  const tabClickFunc = (label) => {
    let temp = tabList.map((item) =>
      item.label === label
        ? { label: item.label, value: true }
        : { label: item.label, value: false }
    );

    setTabList(temp);
    moveLoaction(temp);
  };

  const profileDetailFunc = async (influencerId) => {
    try {
      const response = await InfluencerApi.Profile(influencerId);

      setProfileInfo(response.data.data);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const handleOpenNewTab = (url) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  const unFollowFunc = async () => {
    try {
      await InfluencerApi.UnFollow(influencerId);
      profileDetailFunc(influencerId);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const followFunc = async () => {
    try {
      await InfluencerApi.Follow(influencerId);
      profileDetailFunc(influencerId);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const setPRData = async () => {
    try {
      setLoading(true);
      const response = await InfluencerApi.BulletinList(influencerId, {
        page: currentPage,
        size: 10,
      });

      const data = response.data.data;
      setDataList(dataList.concat(data.content));
      setIsLast(data.last);
      setLoading(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const setUnPRData = async () => {
    try {
      setLoading(true);
      const response = await InfluencerApi.ChallengeList(influencerId, {
        page: currentPage,
        size: 10,
      });

      const data = response.data.data;
      setDataList(dataList.concat(data.content));
      setIsLast(data.last);
      setLoading(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    if (tabList[0].value) {
      if (influencerId) {
        setPRData();
      }
    } else {
      if (influencerId) {
        setUnPRData();
      }
    }
  }, [currentPage, tabList, influencerId]);

  useEffect(() => {
    if (inView && !isLast && !loading) {
      setCurrentPage(currentPage + 1);
      if (tabList[0].value) {
        if (influencerId) {
          setPRData();
        }
      } else {
        if (influencerId) {
          setUnPRData();
        }
      }
    }
  }, [inView, loading, influencerId]);

  useEffect(() => {
    setTimeout(() => {
      let line = countLines();

      setIntroLine(line);
    }, 100);
  }, []);

  const countLines = () => {
    var el = document.getElementById("content");
    if (el) {
      var divHeight = el.offsetHeight;
      var lineHeight = parseInt(el.style.lineHeight);
      var lines = divHeight / lineHeight;

      return lines.toFixed(0);
    } else {
      return 0;
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer style={{}}>
          {/* 헤더 */}
          <div
            style={{
              height: "50px",
              paddingLeft: "20px",
              paddingRight: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <TextBtn>
              <img
                src="/assets/icons/left_btn.png"
                alt=""
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  history.goBack();
                }}
              />
            </TextBtn>
            <TextBtn
              onClick={() => {
                setIsMoreBtn(!isMoreBtn);
              }}
            >
              <img
                src="/assets/icons/more.png"
                style={{
                  height: 24,
                  width: 24,
                }}
              />
            </TextBtn>
            {isMoreBtn ? (
              <div
                style={{
                  position: "absolute",
                  bottom: isMyProfile ? -34 : -75,
                  right: 26,
                  minWidth: "120px",
                  borderRadius: "6px",
                  boxShadow: "0px 0px 8px rgba(0,0,0,0.3)",
                  backgroundColor: "#FFFFFF",
                  padding: "10px 14px 10px 14px",
                }}
              >
                {isMyProfile ? (
                  <TextBtn
                    onClick={() => {
                      history.push({
                        pathname: route.influencer_profile_edit,
                      });
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "600",
                        color: "#262C31",
                        height: "28px",
                        padding: "4px 6px 4px 6px",
                      }}
                    >
                      프로필 수정하기
                    </PretendardText>
                  </TextBtn>
                ) : (
                  <>
                    <TextBtn
                      onClick={() => {
                        history.push({
                          pathname: route.report_write,
                          state: {
                            id: profileInfo.id,
                            reportType: "MEMBER",
                          },
                        });
                      }}
                    >
                      <PretendardText
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          fontWeight: "600",
                          color: "#262C31",
                          height: "28px",
                          padding: "4px 6px 4px 6px",
                        }}
                      >
                        신고하기
                      </PretendardText>
                    </TextBtn>
                    <TextBtn
                      style={{
                        marginTop: "8px",
                      }}
                      onClick={() => {
                        shareFunc();
                      }}
                    >
                      <PretendardText
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          fontWeight: "600",
                          color: "#262C31",
                          height: "28px",
                          padding: "4px 6px 4px 6px",
                        }}
                      >
                        공유하기
                      </PretendardText>
                    </TextBtn>
                  </>
                )}
              </div>
            ) : (
              ""
            )}
          </div>

          {/* 프로필 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 31,
              marginBottom: 13,
            }}
          >
            <img
              src={
                profileInfo && profileInfo.fileUrl
                  ? profileInfo.fileUrl
                  : "/assets/images/profile_temp.png"
              }
              style={{
                height: 80,
                width: 80,
                borderRadius: 100,
                marginBottom: 10,
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 20,
                  lineHeight: "28px",
                  color: "#262C31",
                  fontWeight: 600,
                }}
              >
                {profileInfo ? profileInfo.nickName : ""}
              </PretendardText>
              <img
                src="/assets/icons/card_checkstar.svg"
                alt=""
                style={{
                  width: "17px",
                  height: "17px",
                  marginLeft: "3px",
                }}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "0px 55px",
              width: "100%",
            }}
          >
            <PretendardText
              id={"content"}
              style={{
                fontSize: 13,
                lineHeight: "18.75px",
                color: "#778088",
                fontWeight: 600,
                overflow: "hidden",
                textOverflow: introLine == 0 ? "" : moreIntro ? "" : "ellipsis",
                WebkitLineClamp: introLine == 0 ? "" : moreIntro ? "" : "2",
                WebkitBoxOrient:
                  introLine == 0 ? "" : moreIntro ? "" : "vertical",
                display: introLine == 0 ? "" : moreIntro ? "" : "-webkit-box",
                textAlign: "center",
                whiteSpace: "pre",
              }}
            >
              {profileInfo ? profileInfo.introduce : ""}
            </PretendardText>

            {introLine > 2 ? (
              <TextBtn
                onClick={() => {
                  setMoreIntro(!moreIntro);
                }}
              >
                <img
                  src="/assets/icons/ic_16_under_arrow_grey.png"
                  alt=""
                  style={{
                    width: "16px",
                    height: "16px",
                    transform: moreIntro ? "rotate(180deg)" : "",
                  }}
                />
              </TextBtn>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "13px",
            }}
          >
            <div
              style={{
                width: "111px",
                height: "59px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#ADB4BA",
                  fontWeight: 700,
                }}
              >
                Followers
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "22.96px",
                  color: "#262C31",
                  fontWeight: 600,
                }}
              >
                {profileInfo ? profileInfo.followerCount : "0"}
              </PretendardText>
            </div>
            <div
              style={{
                width: "111px",
                height: "59px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#ADB4BA",
                  fontWeight: 700,
                }}
              >
                Like
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "22.96px",
                  color: "#262C31",
                  fontWeight: 600,
                }}
              >
                {profileInfo ? profileInfo.likeCount : "0"}
              </PretendardText>
            </div>
            <div
              style={{
                width: "111px",
                height: "59px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "18.75px",
                  color: "#ADB4BA",
                  fontWeight: 700,
                }}
              >
                Challenge
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "22.96px",
                  color: "#262C31",
                  fontWeight: 600,
                }}
              >
                {profileInfo ? profileInfo.onGoingChallengesCount : "0"}
              </PretendardText>
            </div>
          </div>
          <div
            style={{
              marginTop: "14px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {myID == profileInfo?.id ? null : profileInfo &&
              profileInfo.userFollowed ? (
              <TextBtn
                onClick={() => {
                  unFollowFunc();
                }}
                style={{
                  width: "160px",
                  height: "40px",
                  fontSize: 15,
                  lineHeight: "20.3px",
                  fontWeight: 700,
                  border: "2px solid #F24147",
                  borderRadius: 8,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FF3333",
                    fontWeight: 700,
                    fontSize: 15,
                    lineHeight: "20.3px",
                  }}
                >
                  팔로우 취소
                </PretendardText>
              </TextBtn>
            ) : (
              <MainBtn
                onClick={() => {
                  followFunc();
                }}
                style={{
                  width: "160px",
                  height: "40px",
                  fontSize: 15,
                  lineHeight: "20.3px",
                  fontWeight: 700,
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontWeight: 700,
                    fontSize: 15,
                    lineHeight: "20.3px",
                  }}
                >
                  팔로우하기
                </PretendardText>
              </MainBtn>
            )}

            {profileInfo && profileInfo.links
              ? profileInfo.links.length > 0
                ? profileInfo.links.map((item, index) => {
                    return (
                      <TextBtn
                        key={index}
                        onClick={() => {
                          if (item.includes("http") || item.includes("https")) {
                            handleOpenNewTab(item);
                          } else {
                            handleOpenNewTab(`https://${item}`);
                          }
                        }}
                      >
                        <img
                          src="/assets/icons/btn_circle.png"
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            marginLeft: "10px",
                          }}
                        />
                      </TextBtn>
                    );
                  })
                : null
              : null}
          </div>

          {/* 탭 */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "42px",
            }}
          >
            {tabList.map((item, index) => {
              return (
                <Tab
                  onClick={() => {
                    tabClickFunc(item.label);
                    setDataList([]);
                    setIsLast(false);
                    setCurrentPage(0);
                  }}
                  key={index}
                  value={item.value}
                >
                  <PretendardText
                    style={{
                      fontSize: 14,
                      lineHeight: "20px",
                      color: item.value ? "#262C31" : "#ADB4BA",
                      fontWeight: 600,
                    }}
                  >
                    {item.label}
                  </PretendardText>
                </Tab>
              );
            })}
          </div>

          {/* 게시글 */}
          {tabList[0].value ? (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {dataList.length > 0 ? (
                dataList.map((item, index) => {
                  return (
                    <ProfileBoardBox
                      data={item}
                      key={index}
                      isEnd={
                        isMobile ? (index + 1) % 3 === 0 : (index + 1) % 4 === 0
                      }
                    />
                  );
                })
              ) : (
                <Empty
                  style={{
                    height: "30vh",
                  }}
                  text={"게시글이 없습니다."}
                />
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                padding: "0px 20px",
                marginTop: 18,
              }}
            >
              {dataList.length > 0 ? (
                dataList.map((item, index) => {
                  return (
                    <SearchChallengeBox
                      key={index}
                      isEnd={(index + 1) % 2 === 0}
                      marginBottom={"24px"}
                      data={item}
                    />
                  );
                })
              ) : (
                <Empty
                  style={{
                    height: "30vh",
                  }}
                  text={"챌린지가 없습니다."}
                />
              )}
            </div>
          )}

          <div ref={ref}>{/* loading */}</div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default InfluencerProfile;
