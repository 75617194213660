import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import PretendardText from "../../atoms/pretendardText";
import TextBtn from "../../atoms/button/textBtn";
import styled from "styled-components";
import moment from "moment";
import { route } from "../../../routes/route";
import { getExtensionOfFilename, videoExtension } from "../../../utils/file";

const ChallengeSection = ({ item, style, index }) => {
  const history = useHistory();

  const dDay = useMemo(() => {
    return moment(item?.startDate).diff(
      moment(new Date().toDateString()),
      "days"
    );
  }, [item]);

  return (
    <TextBtn
      onClick={() => {
        history.push({
          pathname: route.challenge_detail + `/${item.id}`,
        });
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "47.8%",
        marginRight: (index + 1) % 2 === 0 ? 0 : 13,
        marginBottom: 32,
        ...style,
      }}
    >
      <div
        style={{
          width: "100%",
          height: 161,
          position: "relative",
          borderRadius: 8,
          marginBottom: 15,
        }}
      >
        <img
          src={
            videoExtension.includes(getExtensionOfFilename(item.fileUrl))
              ? item.thumbFileUrl
              : item.fileUrl
          }
          alt=""
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 8,
          }}
        />

        <div
          style={{
            position: "absolute",
            padding: "2px 6px",
            background: "#778088",
            borderRadius: 6,
            top: 12,
            left: 12,
          }}
        >
          <PretendardText
            style={{
              fontSize: 13,
              lineHeight: "18.75px",
              fontWeight: 700,
              color: "white",
            }}
          >
            {item?.challengeStatusTitle}
          </PretendardText>
        </div>

        <div
          style={{
            position: "absolute",
            padding: "5px 8px",
            background: "rgba(0,0,0,0.7)",
            borderRadius: 6,
            top: 11,
            left: 64,
          }}
        >
          <PretendardText
            style={{
              fontSize: 11,
              lineHeight: "14px",
              fontWeight: 600,
              color: "white",
            }}
          >
            {item?.challengeTypeTitle}
          </PretendardText>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 3,
        }}
      >
        <PretendardText
          style={{
            fontSize: 13,
            lineHeight: "18.75px",
            fontWeight: 600,
            color: "#5A636A",
            marginRight: 7,
          }}
        >
          {item?.challengeCategoryTitle}
        </PretendardText>
        <PretendardText
          style={{
            fontSize: 13,
            lineHeight: "18.75px",
            fontWeight: 600,
            color: "#5A636A",
            marginRight: 7,
          }}
        >
          {item?.challengePeriodTitle}
        </PretendardText>
        {item?.challengeStatus === "BEFORE" && dDay > 0 ? (
          <PretendardText
            style={{
              fontSize: 13,
              lineHeight: "18.75px",
              fontWeight: 700,
              color: "#5ECECE",
            }}
          >
            {`D-${dDay}`}
          </PretendardText>
        ) : null}
      </div>

      <StyledTextTitle>{item?.title}</StyledTextTitle>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: 13,
        }}
      >
        {item?.tags.split(",").map((item, index) => {
          return (
            <div
              key={index}
              style={{
                padding: "2px 7px",
                background: "rgba(173, 180, 186, 0.2)",
                borderRadius: 4,
                marginRight: 4,
                marginBottom: 4,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "16.8px",
                  fontWeight: 600,
                  color: "#778088",
                }}
              >
                {item}
              </PretendardText>
            </div>
          );
        })}
      </div>
    </TextBtn>
  );
};

export default ChallengeSection;

const StyledTextTitle = styled(PretendardText).attrs((props) => {})`
  font-size: 16px;
  line-height: 22.96px;
  font-weight: 600;

  display: -webkit-box;
  display: -ms-flexbox;
  display: box;

  overflow: hidden;
  vertical-align: top;
  text-overflow: ellipsis;
  word-break: break-all;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;
