import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import Empty from "../components/molecules/empty";
import TextBtn from "../components/atoms/button/textBtn";
import Tab from "../components/molecules/tab";
import SearchChallengeBox from "../components/organisms/searchChallengeBox";
import ChallengeCard from "../components/organisms/challengeCard";

import { useInView } from "react-intersection-observer";
import InqueryApi from "../api/inqueryApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import { route } from "../routes/route";
import MoreApi from "../api/moreApi";
import UserApi from "../api/userApi";
import StoreChallengeCard from "../components/organisms/storeChallengeCard";
import StoreChallengeCard02 from "../components/organisms/storeChallengeCard02";

function MyStorageList(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();

  const [tabList, setTabList] = useState([
    { label: "챌린지", value: true },
    { label: "게시물", value: false },
  ]);

  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [ref, inView] = useInView();
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    const tabListTemp = location?.state ? location.state.tabListTemp : null;

    if (tabListTemp) {
      setTabList(tabListTemp);
    }

    window.scrollTo(0, 0);
  }, []);

  const moveLoaction = (data) => {
    history.replace({
      pathname: route.my_storage_list,
      state: {
        tabListTemp: data,
      },
    });
  };

  const tabClickFunc = (label) => {
    let temp = tabList.map((item) =>
      item.label === label
        ? { label: item.label, value: true }
        : { label: item.label, value: false }
    );

    setTabList(temp);
    moveLoaction(temp);
  };

  const setPRData = async () => {
    try {
      setLoading(true);
      const response = await MoreApi.GetSChallenge({
        page: currentPage,
        size: 10,
      });

      const data = response.data.data;
      setDataList(dataList.concat(data.content));
      setIsLast(data.last);
      setLoading(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const setUnPRData = async () => {
    try {
      setLoading(true);
      const response = await MoreApi.GetSPosts({
        page: currentPage,
        size: 10,
      });

      const data = response.data.data;
      setDataList(dataList.concat(data.content));
      setIsLast(data.last);
      setLoading(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    if (tabList[0].value) {
      setPRData();
    } else {
      setUnPRData();
    }
  }, [currentPage, tabList]);

  useEffect(() => {
    if (inView && !isLast && !loading) {
      setCurrentPage(currentPage + 1);
      if (tabList[0].value) {
        setPRData();
      } else {
        setUnPRData();
      }
    }
  }, [inView, loading]);

  const storeClickFunc = async (isStored, id, storeType, parentId) => {
    try {
      const formData = new FormData();
      formData.append("isCreate", false);
      formData.append("objectId", id);
      formData.append("storeType", storeType);

      await UserApi.putStore(formData);
      const dataListTemp = dataList.filter((item) => item.id !== parentId);
      setDataList(dataListTemp);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header title={"저장목록"} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {tabList.map((item, index) => {
                return (
                  <Tab
                    onClick={() => {
                      tabClickFunc(item.label);
                      setDataList([]);
                      setIsLast(false);
                      setCurrentPage(0);
                    }}
                    key={index}
                    value={item.value}
                  >
                    <PretendardText
                      style={{
                        fontSize: 14,
                        lineHeight: "20px",
                        color: item.value ? "#262C31" : "#ADB4BA",
                        fontWeight: 600,
                      }}
                    >
                      {item.label}
                    </PretendardText>
                  </Tab>
                );
              })}
            </div>
          </div>

          <div
            style={{
              marginTop: "24px",
              padding: "0px 20px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {dataList && dataList.length > 0 ? (
              tabList[0].value ? (
                dataList.map((item, index) => {
                  if (item.challengeDto) {
                    return (
                      <SearchChallengeBox
                        key={index}
                        isEnd={(index + 1) % 2 === 0}
                        marginBottom={"24px"}
                        data={item.challengeDto}
                      />
                    );
                  }
                })
              ) : (
                dataList.map((item, index) => {
                  if (item.shortForm || item.certification) {
                    return (
                      <TextBtn
                        key={index}
                        style={{
                          width: isMobile ? "50%" : "216px",
                        }}
                      >
                        <div
                          style={{
                            marginRight: index % 2 === 0 ? "13px" : "",
                            marginTop: index === 0 || index === 1 ? "" : "13px",
                            width: isMobile ? "100%" : "216px",
                            height: "228px",
                          }}
                        >
                          {item.shortForm ? (
                            <StoreChallengeCard
                              item={item}
                              storeClickFunc={storeClickFunc}
                            />
                          ) : (
                            <StoreChallengeCard02
                              item={item}
                              storeClickFunc={storeClickFunc}
                            />
                          )}
                        </div>
                      </TextBtn>
                    );
                  }
                })
              )
            ) : (
              <Empty
                style={{
                  height: "70vh",
                }}
                text={
                  tabList[0].value
                    ? "저장한 챌린지가 없어요"
                    : "저장한 게시물이 없어요"
                }
              />
            )}

            <div ref={ref}>{/* loading */}</div>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default MyStorageList;
