import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import AppLayout from "../components/atoms/layouts/appLayout";
import AppContainer from "../components/atoms/layouts/appContainer";
import useDisplay from "../hooks/useDisplay";
import TextBtn from "../components/atoms/button/textBtn";
import InputSearch from "../components/atoms/input/inputSearch";
import PretendardText from "../components/atoms/pretendardText";
import SearchChallengeBox from "../components/organisms/searchChallengeBox";
import { useInView } from "react-intersection-observer";
import ChallengeApi from "../api/challengeApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import Empty from "../components/molecules/empty";
// 무한스크롤 테스트

function ChallengeSearch() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [keyword, setKeyword] = useState("");
  const inputSearchFunc = (value) => {
    setKeyword(value);
  };

  const [isSearch, setIsSearch] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [ref, inView] = useInView();
  const [dataList, setDataList] = useState([]);

  const setData = async () => {
    try {
      setLoading(true);
      const response = await ChallengeApi.getSearchData({
        page: currentPage,
        size: 10,
        keyword: keyword,
      });
      const data = response.data.data;
      setDataList(dataList.concat(data.content));
      setIsLast(data.last);
      setTotalCount(data.totalElements);

      setLoading(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    if (isSearch) {
      setData();
    }
  }, [currentPage]);

  useEffect(() => {
    if (inView && !isLast && !loading && isSearch) {
      setCurrentPage(currentPage + 1);
      setData();
    }
  }, [inView, loading]);

  // 검색 시
  const setDataToKeyword = async () => {
    try {
      setIsSearch(true);
      setCurrentPage(0);

      const response = await ChallengeApi.getSearchData({
        page: 0,
        size: 10,
        keyword: keyword,
      });
      const data = response.data.data;
      setDataList(data.content);
      setTotalCount(data.totalElements);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer style={{}}>
          {/* 헤더 */}
          <div
            style={{
              height: "50px",
              paddingLeft: "20px",
              paddingRight: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <TextBtn>
              <img
                src="/assets/icons/left_btn.png"
                alt=""
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "21px",
                }}
                onClick={() => {
                  history.goBack();
                }}
              />
            </TextBtn>

            <InputSearch
              style={{
                height: "45px",
              }}
              placeholder={"챌린지명을 입력하세요!"}
              value={keyword}
              onChange={inputSearchFunc}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setDataToKeyword();
                }
              }}
              searchStyle={{
                top: 9,
              }}
              setDataToKeyword={setDataToKeyword}
            ></InputSearch>
          </div>

          {/* 검색결과 */}
          <div
            style={{
              marginTop: "24px",
              padding: "0px 20px",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <PretendardText
                style={{
                  color: "#778088",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: "600",
                }}
              >
                검색결과
              </PretendardText>
              <PretendardText
                style={{
                  color: "#778088",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: "600",
                  marginLeft: "5px",
                }}
              >
                {totalCount}
              </PretendardText>
            </div>

            <div
              style={{
                marginTop: "16px",
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {dataList.length > 0 ? (
                dataList.map((item, index) => {
                  return (
                    <SearchChallengeBox
                      key={index}
                      isEnd={(index + 1) % 2 === 0}
                      marginBottom={"36px"}
                      data={item}
                    ></SearchChallengeBox>
                  );
                })
              ) : (
                <Empty
                  text={"콘텐츠가 없어요"}
                  style={{ margin: "200px 0px" }}
                />
              )}
              <div ref={ref}>{/* loading */}</div>
            </div>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default ChallengeSearch;
