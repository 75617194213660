import { toast } from "react-toastify";

function shareFunc() {
  const windowUrl = window.location.href;

  const shareObject = {
    title: "healala", // 공유될 제목
    text: "", // 공유될 설명
    url: windowUrl, // 공유될 URL
  };

  if (window.navigator.share) {
    window.navigator.share(shareObject);
  } else {
    // navigator를 지원하지 않는 경우
    window.navigator.clipboard
      .writeText(windowUrl)
      .then(() => {
        alert("주소가 복사되었습니다");
      })
      .catch((error) => {
        return;
      });
  }
}

export { shareFunc };
