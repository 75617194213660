import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";

import useDisplay from "../../../hooks/useDisplay";
import PretendardText from "../../atoms/pretendardText";
import { route } from "../../../routes/route";

function BottomTab(props) {
  const [itemClickMenu, setItemClickMenu] = useState([
    { label: route.bottom_home, value: false },
    { label: route.bottom_search, value: false },
    { label: route.bottom_challenge, value: false },
    { label: route.bottom_myChallenge, value: false },
    { label: route.bottom_more, value: false },
  ]);

  const history = useHistory();
  const location = useLocation();
  const [isInfluencer, setIsInfluencer] = useState(false);

  useEffect(() => {
    menuController(location);

    const memberType = sessionStorage.getItem("memberType");

    if (memberType === "INFLUENCER") {
      setIsInfluencer(true);
    }
  }, [location.pathname]);

  const menuController = (location) => {
    if (location.pathname.includes(route.bottom_home)) {
      menuClickedEvent(route.bottom_home);
    } else if (location.pathname.includes(route.bottom_search)) {
      menuClickedEvent(route.bottom_search);
    } else if (location.pathname.includes(route.bottom_challenge)) {
      menuClickedEvent(route.bottom_challenge);
    } else if (
      location.pathname.includes(route.bottom_myChallenge) ||
      location.pathname.includes(route.bottom_manager_challenge)
    ) {
      menuClickedEvent(route.bottom_myChallenge);
    } else if (
      location.pathname.includes(route.bottom_more) ||
      location.pathname.includes(route.bottom_my_profile)
    ) {
      menuClickedEvent(route.bottom_more);
    } else {
      menuClickedEvent("");
    }
  };

  const menuClickedEvent = (clickedValue) => {
    const menuTemp = itemClickMenu.map((item) =>
      item.label === clickedValue
        ? { label: item.label, value: true }
        : { label: item.label, value: false }
    );

    setItemClickMenu(menuTemp);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: 56,
          borderTop: "1px solid #DBDDDF",
          position: "fixed",
          bottom: 0,
          ...props.style,
          maxWidth: 486,
          paddingTop: 7,
          width: "100%",
          background: "#ffffff",
          zIndex: 9999,
        }}
      >
        <BtnContainer
          onClick={() => {
            history.push({
              pathname: route.bottom_home,
            });
          }}
        >
          <img
            src={
              itemClickMenu[0].value
                ? "/assets/icons/bottomTab/home_a.png"
                : "/assets/icons/bottomTab/home.png"
            }
            style={{
              height: 24,
              width: 24,
              marginBottom: 3,
            }}
          />
          <BottomText clicked={itemClickMenu[0].value}>홈</BottomText>
        </BtnContainer>

        <BtnContainer
          onClick={() => {
            history.push({
              pathname: route.bottom_search_certification,
            });
          }}
        >
          <img
            src={
              itemClickMenu[1].value
                ? "/assets/icons/bottomTab/search_a.png"
                : "/assets/icons/bottomTab/search.png"
            }
            style={{
              height: 24,
              width: 24,
              marginBottom: 3,
            }}
          />
          <BottomText clicked={itemClickMenu[1].value}>탐색</BottomText>
        </BtnContainer>

        <BtnContainer
          onClick={() => {
            history.push({
              pathname: route.bottom_challenge,
            });
          }}
        >
          <img
            src={
              itemClickMenu[2].value
                ? "/assets/icons/bottomTab/challenge_a.png"
                : "/assets/icons/bottomTab/challenge.png"
            }
            style={{
              height: 24,
              width: 24,
              marginBottom: 3,
            }}
          />
          <BottomText clicked={itemClickMenu[2].value}>챌린지</BottomText>
        </BtnContainer>

        <BtnContainer
          onClick={() => {
            history.push({
              pathname: isInfluencer
                ? route.bottom_manager_challenge
                : route.bottom_myChallenge,
            });
          }}
        >
          <img
            src={
              itemClickMenu[3].value
                ? "/assets/icons/bottomTab/my_challenge_a.png"
                : "/assets/icons/bottomTab/my_challenge.png"
            }
            style={{
              height: 24,
              width: 24,
              marginBottom: 3,
            }}
          />
          <BottomText clicked={itemClickMenu[3].value}>
            {isInfluencer ? "챌린지 관리" : "내 챌린지"}
          </BottomText>
        </BtnContainer>

        <BtnContainer
          onClick={() => {
            history.push({
              pathname: isInfluencer
                ? route.bottom_my_profile
                : route.bottom_more,
            });
          }}
        >
          <img
            src={
              itemClickMenu[4].value
                ? isInfluencer
                  ? "/assets/icons/bottomTab/my_profile_a.png"
                  : "/assets/icons/bottomTab/more_a.png"
                : isInfluencer
                ? "/assets/icons/bottomTab/my_profile.png"
                : "/assets/icons/bottomTab/more.png"
            }
            style={{
              height: 24,
              width: 24,
              marginBottom: 3,
            }}
          />
          <BottomText clicked={itemClickMenu[4].value}>
            {isInfluencer ? "내프로필" : "더보기"}
          </BottomText>
        </BtnContainer>
      </div>
    </>
  );
}

export default BottomTab;

const BtnContainer = styled.div.attrs((props) => {})`
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const BottomText = styled(PretendardText).attrs((props) => {})`
  font-weight: 500;
  font-size: 12px;
  line-height: 16.5px;
  color: ${(props) =>
    props.clicked === true ? "#262C31" : "rgb(173, 180, 186)"};
`;
