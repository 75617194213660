import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { getExtensionOfFilename, videoExtension } from "../../../utils/file";

import PretendardText from "../../atoms/pretendardText";

const ClPaymentSection = ({ data, style, index }) => {
  const history = useHistory();
  const [tagList, setTagList] = useState([]);

  useEffect(() => {
    if (data) {
      let temp = data.tags?.split(",");
      setTagList(temp);
    }
  }, [data]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ...style,
      }}
    >
      <img
        src={
          videoExtension.includes(getExtensionOfFilename(data.fileUrl))
            ? data.thumbFileUrl
            : data.fileUrl
        }
        style={{
          height: 96,
          width: 96,
          borderRadius: 8,
          marginRight: 13,
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 6,
          }}
        >
          <PretendardText
            style={{
              fontSize: 13,
              lineHeight: "18.75px",
              fontWeight: 600,
              color: "#262C31",
              marginRight: 7,
            }}
          >
            {data ? data.challengeStatusTitle : ""}
          </PretendardText>
          <PretendardText
            style={{
              fontSize: 13,
              lineHeight: "18.75px",
              fontWeight: 600,
              color: "#778088",
              marginRight: 7,
            }}
          >
            {data ? data.challengeCategoryTitle : ""}
          </PretendardText>
          <PretendardText
            style={{
              fontSize: 13,
              lineHeight: "18.75px",
              fontWeight: 600,
              color: "#778088",
              marginRight: 7,
            }}
          >
            {data ? data.challengePeriodTitle : ""}
          </PretendardText>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 8,
          }}
        >
          <StyledTextTitle>{data ? data.title : ""}</StyledTextTitle>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {tagList?.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  padding: "2px 7px",
                  background: "rgba(173, 180, 186, 0.2)",
                  borderRadius: 4,
                  marginRight: 4,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 12,
                    lineHeight: "16.8px",
                    fontWeight: 600,
                    color: "#778088",
                  }}
                >
                  {item}
                </PretendardText>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ClPaymentSection;

const StyledTextTitle = styled(PretendardText).attrs((props) => {})`
  font-size: 15px;
  line-height: 20.3px;
  font-weight: 600;
  // height: 40.59px;

  display: -webkit-box;
  display: -ms-flexbox;
  display: box;

  overflow: hidden;
  vertical-align: top;
  text-overflow: ellipsis;
  word-break: break-all;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;
