import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import RightLabelRadio from "../components/molecules/radio/rightLabelRadio";
import Input from "../components/atoms/input/input";
import ConfirmModal from "../components/templates/modals/confirmModal";
import { route } from "../routes/route";
import UserApi from "../api/userApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";

function Withdraw(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  const [dataList, setDataList] = useState([
    { value: false, label: "원하는 서비스가 없어요.", type: "SERVICE" },
    { value: false, label: "헬랄라를 사용하기가 어려워요.", type: "DIFFICULT" },
    {
      value: false,
      label: "헬랄라에서 불쾌한 경험을 했어요.",
      type: "UNPLEASANT",
    },
    {
      value: false,
      label: "새로운 계정을 만들고 싶어요.",
      type: "NEW_ACCOUNT",
    },
    { value: false, label: "기타", type: "ETC" },
  ]);

  const [reason, setReason] = useState("");
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const radioClickFunc = (text) => {
    const temp = dataList.map((item) => {
      return item.label === text
        ? {
            value: true,
            label: item.label,
            type: item.type,
          }
        : {
            value: false,
            label: item.label,
            type: item.type,
          };
    });

    setDataList(temp);
  };

  const logoutFunc = async () => {
    localStorage.removeItem("autoLogin");
    localStorage.removeItem("account");
    localStorage.removeItem("password");

    sessionStorage.removeItem("token");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("memberType");

    history.push({
      pathname: route.main,
    });
  };

  const withdrawFunc = async () => {
    try {
      let reasonType = dataList.find((item) => item.value === true);

      let formData = new FormData();

      formData.append("resignedReason", reasonType.type);
      formData.append("resignedReasonDtl", reason);

      let response = await UserApi.Withdraw(formData);
      logoutFunc();
    } catch (error) {
      if (error.response?.status === 400) {
        toast(`등록된 챌린지를 먼저 삭제해주세요.`);
      } else {
        toast(ko.errorText_500);
      }
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header title={"탈퇴하기"} />

          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                marginTop: 40,
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 32,
                  lineHeight: "44.16px",
                  color: "#262C31",
                  fontWeight: 600,
                  marginBottom: 6,
                }}
              >
                헬랄라 떠나기
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "21.5px",
                  color: "#5A636A",
                  fontWeight: 500,
                }}
              >
                회원탈퇴 사유를 알려주시면
                <br />더 나은 서비스로 발전하겠습니다.
              </PretendardText>

              <div
                style={{
                  marginTop: 50,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {dataList.map((item, index) => {
                  return (
                    <div
                      style={{
                        marginBottom: dataList.length === index + 1 ? 10 : 24,
                      }}
                    >
                      <RightLabelRadio
                        checked={item.value}
                        text={item.label}
                        onClick={() => {
                          radioClickFunc(item.label);
                        }}
                      />
                    </div>
                  );
                })}

                {dataList[4].value ? (
                  <Input
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    type={"text"}
                    placeholder={"기타 사유를 입력해주세요."}
                  />
                ) : null}
              </div>
            </div>

            <MainBtn
              onClick={() => {
                setIsModal(true);
              }}
              style={{
                marginTop: 22,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                탈퇴하기
              </PretendardText>
            </MainBtn>
          </div>

          {isModal ? (
            <ConfirmModal
              setVisible={setIsModal}
              title={"정말 탈퇴하시겠어요?"}
              content={
                "탈퇴 시 계정복구가 불가하며 데이터가 모두 삭제됩니다. 그래도 탈퇴하시겠습니까?"
              }
              onClickText={"탈퇴하기"}
              onCancleText={"취소"}
              onClick={() => {
                withdrawFunc();
              }}
              onCancle={() => {
                setIsModal(false);
              }}
            />
          ) : null}
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default Withdraw;
