import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import TitleInput from "../components/molecules/input/titleInput";
import FileForm from "../components/atoms/fileForm/fileForm";
import { route } from "../routes/route";

function IdFindComplete(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();

  const [email, setEmail] = useState("");

  useEffect(() => {
    const email = location?.state ? location.state.email : null;

    if (email) {
      setEmail(email);
    }

    window.scrollTo(0, 0);
  }, []);

  function emailFilter(email) {
    let tempEmail = email.split("@");

    let tempEmail01 = tempEmail[0];
    let tempEmail02 = tempEmail[1];

    let temp03 = "";

    temp03 = String(tempEmail01).slice(0, -3);

    return temp03 + "●●●@" + tempEmail02;
  }

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header />

          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                marginTop: 40,
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 32,
                  lineHeight: "44.16px",
                  color: "#262C31",
                  fontWeight: 600,
                  marginBottom: 6,
                }}
              >
                이메일 찾기
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "21.5px",
                  color: "#5A636A",
                  fontWeight: 500,
                }}
              >
                조회된 이메일입니다.
              </PretendardText>

              <div
                style={{
                  marginTop: 32,
                }}
              >
                {email && email.length > 0
                  ? email.map((item, index) => {
                      return (
                        <PretendardText
                          key={index}
                          style={{
                            fontSize: 16,
                            lineHeight: "37.28px",
                            color: "#262C31",
                            fontWeight: 400,
                          }}
                        >
                          {emailFilter(item.email)}
                        </PretendardText>
                      );
                    })
                  : null}
              </div>

              <div
                style={{
                  marginTop: 59,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 15,
                    lineHeight: "22.3px",
                    color: "#262C31",
                    fontWeight: 500,
                    marginRight: 15,
                  }}
                >
                  비밀번호를 잊으셨나요?
                </PretendardText>

                <PretendardText
                  onClick={() => {
                    history.push({
                      pathname: route.pw_find,
                    });
                  }}
                  style={{
                    cursor: "pointer",
                    fontSize: 15,
                    lineHeight: "20.3px",
                    color: "#FF3333",
                    fontWeight: 700,
                  }}
                >
                  비밀번호찾기
                </PretendardText>
              </div>
            </div>

            <MainBtn
              onClick={() => {
                history.push({
                  pathname: route.login,
                });
              }}
              style={{
                marginTop: 22,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                로그인하러가기
              </PretendardText>
            </MainBtn>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default IdFindComplete;
