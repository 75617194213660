import api from "./api.js";

export default class ShortFormApi {
    static async getShortFormList(data) {
        return await api.get(`ifn-001_F0`, data);
    }  
    static async getCertificationList(data) {
        return await api.get(`ifn-007_F0`, data);
    }  
    static async getShortFormDetail(id, data) {
        return await api.get(`ifn-002_F0/${id}`, data);
    }    
}