import React, { useEffect, useState } from "react";
import useDisplay from "../hooks/useDisplay";
import { useHistory, useParams } from "react-router-dom";
import AppLayout from "../components/atoms/layouts/appLayout";
import AppContainer from "../components/atoms/layouts/appContainer";
import TextBtn from "../components/atoms/button/textBtn";
import PretendardText from "../components/atoms/pretendardText";
import Tab from "../components/molecules/tab";
import GUserChallengeBox from "../components/organisms/gUserChallengeBox";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import UserApi from "../api/userApi";
import MoreApi from "../api/moreApi";
import MypageChallengeBox from "../components/organisms/mypageChallengeBox";
import GeneralChallengeBox from "../components/organisms/generalChallengeBox";
import { shareFunc } from "../utils/share";
import { route } from "../routes/route";

function GeneralUserProfile() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [tabList, setTabList] = useState([
    { label: "진행중", value: true, count: 0 },
    { label: "진행완료", value: false, count: 0 },
    { label: "전체", value: false, count: 0 },
  ]);

  const tabClickFunc = (label) => {
    let temp = tabList.map((item) => {
      if (item.label === label) {
        return {
          ...item,
          value: true,
        };
      } else {
        return {
          ...item,
          value: false,
        };
      }
    });

    setTabList(temp);
  };

  const [isMoreBtn, setIsMoreBtn] = useState(false);
  const [isMyProfile, setIsMyProfile] = useState(false);

  const numbers = [1, 2, 3, 4, 5];
  const { id } = useParams();
  // 채린지 정보
  const [AccountInfo, setAccountInfo] = useState({});
  const [pointInfo, setPointInfo] = useState({});
  const [challengeList, setChallengeList] = useState([]);

  // init
  const getAccountInfo = async () => {
    try {
      const response = await MoreApi.generalAccountInfo(id);
      setAccountInfo(response.data.data);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const getPointInfo = async () => {
    try {
      const response = await UserApi.generalPointInfo(id);
      const data = response.data.data;
      setPointInfo(data);
      tabList[0].count = data.ongiongCount;
      tabList[1].count = data.endCount;
      tabList[2].count = data.totalCount;
      setTabList([...tabList]);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const getChallengeList = async () => {
    try {
      const tab = tabList.find((item) => item.value);
      let response = null;
      switch (tab.label) {
        case "진행중":
          response = await UserApi.generalChallengeListProgress(id, {
            size: 1000,
          });
          break;
        case "진행완료":
          response = await UserApi.generalChallengeListEnd(id, {
            size: 1000,
          });
          break;
        case "전체":
          response = await UserApi.generalChallengeListAll(id, {
            size: 1000,
          });
          break;

        default:
          break;
      }
      const data = response.data.data;
      setChallengeList(data.content);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    getAccountInfo();
    getPointInfo();
  }, []);

  useEffect(() => {
    getChallengeList();
  }, [tabList]);

  return (
    <>
      <AppLayout>
        <AppContainer style={{}}>
          {/* 헤더 */}
          <div
            style={{
              height: "50px",
              paddingLeft: "20px",
              paddingRight: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <TextBtn>
              <img
                src="/assets/icons/left_btn.png"
                alt=""
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  history.goBack();
                }}
              />
            </TextBtn>
            <TextBtn
              onClick={() => {
                setIsMoreBtn(!isMoreBtn);
              }}
            >
              <img
                src="/assets/icons/more.png"
                style={{
                  height: 24,
                  width: 24,
                }}
              />
            </TextBtn>
            {isMoreBtn ? (
              <div
                style={{
                  position: "absolute",
                  bottom: isMyProfile ? -38 : -75,
                  right: 26,
                  minWidth: "120px",
                  borderRadius: "6px",
                  boxShadow: "0px 0px 8px rgba(0,0,0,0.3)",
                  backgroundColor: "#FFFFFF",
                  padding: "10px 14px 10px 14px",
                }}
              >
                {isMyProfile ? (
                  <TextBtn
                    onClick={() => {
                      history.push("/profile_edit");
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "600",
                        color: "#262C31",
                        height: "28px",
                        padding: "4px 6px 4px 6px",
                      }}
                    >
                      프로필 수정하기
                    </PretendardText>
                  </TextBtn>
                ) : (
                  <>
                    <TextBtn
                      onClick={() => {
                        history.push({
                          pathname: route.report_write,
                          state: {
                            id: AccountInfo.commonMemberDto?.id,
                            reportType: "MEMBER",
                          },
                        });
                      }}
                    >
                      <PretendardText
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          fontWeight: "600",
                          color: "#262C31",
                          height: "28px",
                          padding: "4px 6px 4px 6px",
                        }}
                      >
                        신고하기
                      </PretendardText>
                    </TextBtn>
                    <TextBtn
                      style={{
                        marginTop: "8px",
                      }}
                      onClick={() => {
                        shareFunc();
                      }}
                    >
                      <PretendardText
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          fontWeight: "600",
                          color: "#262C31",
                          height: "28px",
                          padding: "4px 6px 4px 6px",
                        }}
                      >
                        공유하기
                      </PretendardText>
                    </TextBtn>
                  </>
                )}
              </div>
            ) : (
              ""
            )}
          </div>

          {/* 프로필 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "12% 0px",
            }}
          >
            <img
              src={
                AccountInfo.commonMemberDto?.fileUrl
                  ? AccountInfo.commonMemberDto?.fileUrl
                  : "/assets/images/profile_temp.png"
              }
              style={{
                height: 80,
                width: 80,
                borderRadius: 100,
                marginBottom: 10,
              }}
            />
            <PretendardText
              style={{
                fontSize: 20,
                lineHeight: "28px",
                color: "#262C31",
                fontWeight: 600,
              }}
            >
              {AccountInfo.commonMemberDto?.nickName}
            </PretendardText>
          </div>

          {/* 탭 */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {tabList.map((item, index) => {
              return (
                <Tab
                  onClick={() => {
                    tabClickFunc(item.label);
                  }}
                  key={index}
                  value={item.value}
                >
                  <PretendardText
                    style={{
                      fontSize: 14,
                      lineHeight: "20px",
                      color: item.value ? "#262C31" : "#ADB4BA",
                      fontWeight: 600,
                    }}
                  >
                    {item.label}
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: 14,
                      lineHeight: "20px",
                      color: item.value ? "#FF3333" : "#ADB4BA",
                      fontWeight: 600,
                      marginLeft: 5,
                    }}
                  >
                    {item.count}
                  </PretendardText>
                </Tab>
              );
            })}
          </div>

          <div
            style={{
              paddingTop: 29,
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            {challengeList.map((item, index) => {
              return <GeneralChallengeBox key={index} isProgress={false} item={item}></GeneralChallengeBox>;
            })}
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default GeneralUserProfile;
