import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import TitleInput from "../components/molecules/input/titleInput";
import FileForm from "../components/atoms/fileForm/fileForm";
import Empty from "../components/molecules/empty";
import NoticeSection from "../components/molecules/notice/noticeSection";

import { useInView } from "react-intersection-observer";
import InqueryApi from "../api/inqueryApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";
import { route } from "../routes/route";
import NoticeApi from "../api/noticeApi";

function NoticeList(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [ref, inView] = useInView();
  const [noticeData, setNoticeData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setData = async () => {
    try {
      setLoading(true);
      const response = await NoticeApi.List({
        page: currentPage,
        size: 10,
      });

      const data = response.data.data;
      setNoticeData(noticeData.concat(data.content));
      setIsLast(data.last);
      setLoading(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    setData();
  }, [currentPage]);

  useEffect(() => {
    if (inView && !isLast && !loading) {
      setCurrentPage(currentPage + 1);
      setData();
    }
  }, [inView, loading]);

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header title={"공지사항"} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              width: "100vw",
            }}
          >
            {noticeData.length > 0 ? (
              noticeData.map((item, index) => {
                return <NoticeSection key={index} data={item} />;
              })
            ) : (
              <Empty text={"공지사항이 없어요"} />
            )}

            <div ref={ref}>{/* loading */}</div>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default NoticeList;
