import api from "./api.js";

export default class BannerApi {
  static async List() {
    return await api.get(`banners`, {
      page: 0,
      size: 1000,
    });
  }
}
