import React, { useState } from "react";
import TextBtn from "../atoms/button/textBtn";
import PretendardText from "../atoms/pretendardText";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { route } from "../../routes/route";

function ReplyBox({
  isLast,
  setReplyOpen,
  item,
  writeCommentFunc,
  setDeleteReplyId,
  setUpdateReplyId,
  setUpdateCommentId,
  openFlag,
  setOpenFlag,
}) {
  const history = useHistory();

  const [moreOpen, setMoreOpen] = useState(false);
  const [isMyComment, setIsMyComment] = useState(false);

  return (
    <div
      style={{
        position: "relative",
        paddingLeft: "25px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <TextBtn
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              if (item.userType === "INFLUENCER") {
                history.push({
                  pathname:
                    route.influencer_profile + `/${item.commonMemberId}`,
                });
              } else {
                history.push(
                  route.general_user_profile + `/${item.commonMemberId}`
                );
              }
            }}
          >
            <div
              style={{
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                overflow: "hidden",
                marginRight: "4px",
              }}
            >
              <img
                src={item.profileUrl}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
            <PretendardText
              style={{
                color: "rgba(38, 44, 49, 1)",
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "600",
                width: "49px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {item.nickName}
            </PretendardText>
          </TextBtn>
          {item.userType === "INFLUENCER" ? (
            <img
              src="/assets/icons/card_checkstar.svg"
              alt=""
              style={{
                width: "17px",
                height: "17px",
                marginLeft: "3px",
              }}
            />
          ) : null}
        </div>
        <TextBtn
          onClick={() => {
            if (openFlag.id === item.id && openFlag.type === "reply") {
              setOpenFlag({
                type: "",
                id: 0,
              });
            } else {
              setOpenFlag({
                type: "reply",
                id: item.id,
              });
            }
          }}
        >
          <img
            src="/assets/icons/ic_24_more.png"
            alt=""
            style={{
              width: "24px",
              height: "24px",
            }}
          />
        </TextBtn>
      </div>
      <div
        style={{
          paddingLeft: "24px",
          paddingRight: "28px",
        }}
      >
        <PretendardText
          style={{
            marginTop: "3px",
            fontSize: "14px",
            lineHeight: "21.5px",
            fontWeight: "500",
          }}
        >
          {item.contents}
        </PretendardText>
        <div
          style={{
            display: "flex",
            marginTop: "7px",
          }}
        >
          <PretendardText
            style={{
              fontSize: "12px",
              lineHeight: "16.5px",
              fontWeight: "500",
              color: "#ADB4BA",
              marginRight: "10px",
            }}
          >
            {item.dateTitle}
          </PretendardText>

          {/* <TextBtn onClick={() => { writeCommentFunc(item.id) }}>
                        <PretendardText style={{
                            fontSize: "12px",
                            lineHeight: "16.5px",
                            fontWeight: "500",
                            color: '#ADB4BA'
                        }}>
                            대댓글달기
                        </PretendardText>
                    </TextBtn> */}
        </div>
      </div>
      <div
        style={{
          marginTop: "10px",
          justifyContent: "end",
          height: "17px",
          display: "flex",
        }}
      >
        {isLast ? (
          <TextBtn
            onClick={() => {
              setReplyOpen(false);
            }}
          >
            <PretendardText
              style={{
                fontSize: "12px",
                lineHeight: "16.5px",
                fontWeight: "500",
                color: "#262C31",
              }}
            >
              닫기
            </PretendardText>
          </TextBtn>
        ) : (
          ""
        )}
      </div>
      {openFlag.id === item.id && openFlag.type === "reply" ? (
        <div
          style={{
            position: "absolute",
            top: "31px",
            right: "0",
            width: "120px",
            borderRadius: "6px",
            boxShadow: "0px 0px 8px rgba(0,0,0,0.3)",
            backgroundColor: "#FFFFFF",
            padding: "10px 14px 10px 14px",
            zIndex: 1,
          }}
        >
          {item.isWriter ? (
            <>
              <TextBtn
                onClick={() => {
                  setDeleteReplyId(item.id);
                }}
              >
                <PretendardText
                  style={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: "500",
                    color: "#262C31",
                    height: "28px",
                    padding: "4px 6px 4px 6px",
                  }}
                >
                  삭제하기
                </PretendardText>
              </TextBtn>
              <TextBtn
                style={{
                  marginTop: "8px",
                }}
                onClick={() => {
                  setUpdateCommentId(0);
                  setUpdateReplyId(item.id);
                }}
              >
                <PretendardText
                  style={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: "500",
                    color: "#262C31",
                    height: "28px",
                    padding: "4px 6px 4px 6px",
                  }}
                >
                  수정하기
                </PretendardText>
              </TextBtn>
            </>
          ) : (
            <TextBtn
              onClick={() => {
                history.push({
                  pathname: route.report_write,
                  state: {
                    id: item.id,
                    reportType: "REPLY",
                  },
                });
              }}
            >
              <PretendardText
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: "500",
                  color: "#262C31",
                  height: "28px",
                  padding: "4px 6px 4px 6px",
                }}
              >
                신고하기
              </PretendardText>
            </TextBtn>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ReplyBox;
