import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PretendardText from "../../atoms/pretendardText";
import TextBtn from "../../atoms/button/textBtn";
import { route } from "../../../routes/route";
import moment from "moment";
import useDisplay from "../../../hooks/useDisplay";

const AlarmSection = ({ data }) => {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <TextBtn
      onClick={() => {
        history.push({
          pathname: route.alarm_detail,
          state: {
            alarmId: data.id,
          },
        });
      }}
      style={{
        width: "100%",
      }}
    >
      <div
        style={{
          padding: "25px 20px",
          borderBottom: "1px solid #DBDDDF",
          background: !data.read ? "rgba(94, 206, 206, 0.05)" : "#fff",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <PretendardText
            style={{
              fontSize: 15,
              lineHeight: "22.25px",
              color: "#262C31",
              fontWeight: 600,
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: "1",
              maxWidth: isMobile ? "100%" : "420px",
              WebkitBoxOrient: "vertical",
              display: "-webkit-box",
            }}
          >
            {data.title}
          </PretendardText>
          {!data.read ? (
            <img
              src="/assets/icons/new.svg"
              style={{
                height: 14,
                width: 14,
                marginLeft: 4,
              }}
            />
          ) : null}
        </div>
        <PretendardText
          style={{
            fontSize: 13,
            lineHeight: "18.75px",
            color: "#778088",
            fontWeight: 400,
          }}
        >
          {moment(data.createdAt).format("YYYY.MM.DD")}
        </PretendardText>
      </div>
    </TextBtn>
  );
};

export default AlarmSection;
