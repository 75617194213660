import React, { useState, useEffect, useMemo } from "react";

import useDisplay from "../../hooks/useDisplay";
import AppLayout from "../../components/atoms/layouts/appLayout";
import AppContainer from "../../components/atoms/layouts/appContainer";
import BottomTab from "../../components/organisms/bottomTab/bottomTab";
import PretendardText from "../../components/atoms/pretendardText";
import ScrollContainer from "react-indiana-drag-scroll";
import FilterSection from "../../components/molecules/challenge/filterSection";
import ShortFormCard from "../../components/organisms/shortFormCard";
import WelcomeModal from "../../components/templates/modals/welcomeModal";
import { route } from "../../routes/route";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useInView } from "react-intersection-observer";
import ShortFormApi from "../../api/shortForm";
import { toast } from "react-toastify";
import { ko } from "../../utils/ko";
import UserApi from "../../api/userApi";
import TextBtn from "../../components/atoms/button/textBtn";
import Empty from "../../components/molecules/empty";

function Search(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [filterList, setFilterList] = useState([
    { id: 1, label: "⛳️ 전체", value: true },
    { id: 2, label: "🍽️️ 식습관", value: false },
    { id: 3, label: "👟️ 운동", value: false },
    { id: 4, label: "💪 건강", value: false },
    { id: 5, label: "👣 생활", value: false },
    { id: 6, label: "🚲 취미", value: false },
    { id: 7, label: "💛 멘탈", value: false },
  ]);

  const filterClickFunc = (value) => {
    let temp = filterList.map((item) =>
      item.id === value
        ? { id: item.id, label: item.label, value: true }
        : { id: item.id, label: item.label, value: false }
    );

    setFilterList(temp);
  };

  const challengeCategory = useMemo(() => {
    const element = filterList.find((item) => item.value).id;
    switch (element) {
      case 1:
        return "";
      case 2:
        return "EATING_HABITS";
      case 3:
        return "EXERCISE";
      case 4:
        return "HEALTH";
      case 5:
        return "HOBBY";
      case 6:
        return "LIFE";
      case 7:
        return "MENTAL";
      default:
        return "";
    }
  }, [filterList]);

  // 챌린지 목록
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [ref, inView] = useInView();
  const [shortFormList, setShortFormList] = useState([]);

  const setDataToCategory = async () => {
    try {
      const response = await ShortFormApi.getShortFormList({
        page: currentPage,
        size: 10,
        challengeCategory: challengeCategory,
      });
      const data = response.data.data;
      setShortFormList(data.content);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const setData = async () => {
    try {
      setLoading(true);
      const response = await ShortFormApi.getShortFormList({
        page: currentPage,
        size: 10,
        challengeCategory: challengeCategory,
      });
      const data = response.data.data;
      setShortFormList(shortFormList.concat(data.content));
      setIsLast(data.last);
      setLoading(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    setCurrentPage(0);
    setDataToCategory();
  }, [filterList]);

  useEffect(() => {
    setData();
  }, [currentPage]);

  useEffect(() => {
    if (inView && !isLast && !loading) {
      setCurrentPage(currentPage + 1);
      // setData()
    }
  }, [inView, loading]);

  // 숏폼 저장
  const storeClickFunc = async (isStored, id) => {
    try {
      const formData = new FormData();
      formData.append("isCreate", !isStored);
      formData.append("objectId", id);
      formData.append("storeType", "SHORT_FORM");

      await UserApi.putStore(formData);
      const shortForm = shortFormList.find((item) => item.id === id);
      shortForm.isStored = !isStored;
      setShortFormList([...shortFormList]);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  // 숏폼 좋아요
  const likeClickFunc = async (isLiked, id) => {
    try {
      const formData = new FormData();
      formData.append("isCreate", !isLiked);
      formData.append("objectId", id);
      formData.append("likeType", "SHORT_FORM");

      await UserApi.putLike(formData);
      const shortForm = shortFormList.find((item) => item.id === id);
      shortForm.isLiked = !isLiked;
      if (!isLiked) {
        shortForm.likeAmount += 1;
      } else {
        shortForm.likeAmount -= 1;
      }

      setShortFormList([...shortFormList]);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout style={{}}>
        <AppContainer
          style={{
            paddingBottom: "86px",
          }}
        >
          <div
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
              width: isMobile ? "100vw" : "100%",
            }}
          >
            {/* 헤더 */}
            <div
              style={{
                height: "50px",
                paddingLeft: "20px",
                paddingRight: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <PretendardText
                style={{
                  color: "rgba(0, 0, 0, 1)",
                  fontSize: "20px",
                  lineHeight: "28px",
                  fontWeight: "600",
                }}
              >
                탐색
              </PretendardText>
              <TextBtn
                onClick={() => {
                  history.push(route.influencer_search);
                }}
              >
                <img
                  src="/assets/icons/ic_24_search.svg"
                  alt=""
                  style={{
                    cursor: "pointer",
                  }}
                />
              </TextBtn>
            </div>
            {/* 상단 탭 */}
            <div
              style={{
                marginTop: "19px",
                paddingLeft: "20px",
              }}
            >
              <div
                style={{
                  width: "158px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <PretendardText
                  style={{
                    color: "rgba(173, 180, 186, 1)",
                    fontSize: "20px",
                    lineHeight: "28px",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push(route.bottom_search_certification);
                  }}
                >
                  챌린지인증
                </PretendardText>
                <PretendardText
                  style={{
                    color: "rgba(38, 44, 49, 1)",
                    fontSize: "20px",
                    lineHeight: "28px",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => {}}
                >
                  챌린지
                  <div
                    style={{
                      marginTop: "6px",
                      width: "52px",
                      height: "2px",
                      backgroundColor: "rgba(38, 44, 49, 1)",
                    }}
                  ></div>
                </PretendardText>
              </div>
              <div
                style={{
                  marginTop: "18px",
                }}
              >
                <ScrollContainer className="scroll-container">
                  <div
                    style={{
                      marginBottom: "16px",
                      display: "flex",
                      width: isMobile ? "100vw" : "100%",
                    }}
                  >
                    {filterList.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            marginLeft: index === 0 ? "" : "9px",
                            paddingRight:
                              filterList.length === index + 1 ? "20px" : "0px",
                          }}
                        >
                          <FilterSection
                            onClick={() => {
                              filterClickFunc(item.id);
                            }}
                            data={item}
                          />
                        </div>
                      );
                    })}
                  </div>
                </ScrollContainer>
              </div>
            </div>
          </div>
          {/* 컨텐츠 */}
          <div
            style={{
              marginTop: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            {shortFormList.length > 0 ? (
              shortFormList.map((item, index) => {
                return (
                  <ShortFormCard
                    item={item}
                    key={index}
                    isLike={false}
                    isComplete={true}
                    stateTitle={"완료"}
                    storeClickFunc={storeClickFunc}
                    likeClickFunc={likeClickFunc}
                  ></ShortFormCard>
                );
              })
            ) : (
              <Empty
                text={"콘텐츠가 없어요"}
                style={{
                  height: "40vh",
                }}
              />
            )}
          </div>
          <div ref={ref}>{/* loading */}</div>
          <BottomTab />
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default Search;
