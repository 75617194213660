import api from "./api.js";

export default class TermApi {
  static async List() {
    return await api.get(`terms`);
  }

  static async BaseInfo() {
    return await api.get(`user/baseInfo`);
  }
}
