import api from "./api.js";

export default class MoreApi {
  static async Base() {
    return await api.get(`user/MOR-001_F0`);
  }

  static async GetAlarmList() {
    return await api.get(`user/MOR-013_F0`);
  }

  static async SAlarmUpdate(data) {
    return await api.put(`user/MOR-013_U0`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async AAlarmUpdate(data) {
    return await api.put(`user/MOR-013_U1`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async AccountInfo() {
    return await api.get(`user/MOR-002_F0`);
  }

  static async generalAccountInfo(id) {
    return await api.get(`chn-013_F0/${id}`);
  }

  static async UPhoneCertify(data) {
    return await api.get(`user/MOR-002_F1`, data);
  }

  static async UpdatePhone(data) {
    return await api.put(`user/MOR-002_U0`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async UPWPhoneCertify(data) {
    return await api.get(`user/MOR-012_F0`, data);
  }

  static async UpdatePW(data) {
    return await api.put(`user/MOR-012_U0`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async GetPoint() {
    return await api.get(`user/MOR-003_F0`);
  }

  static async PointList(data) {
    return await api.get(`user/MOR-003_F1`, data);
  }

  static async DMList() {
    return await api.get(`user/MOR-008_F0`);
  }

  static async DMAdd(data) {
    return await api.post(`user/MOR-018_I0`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async DMDel(id) {
    return await api.del(`user/MOR-008_D0/${id}`);
  }

  static async DMEdit(id, data) {
    return await api.put(`user/MOR-018_U0/${id}`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async FInfluencerList(data) {
    return await api.get(`user/MOR-007_F0`, data);
  }

  static async GetOrderList(data) {
    return await api.get(`user/MOR-006_F0`, data);
  }

  static async GetOrderDetail(id) {
    return await api.get(`user/MOR-016_F0/${id}`);
  }

  static async GetPReview(data) {
    return await api.get(`user/MOR-004_F0`, data);
  }

  static async GetUnPReview(data) {
    return await api.get(`user/MOR-004_F1`, data);
  }

  static async GetSChallenge(data) {
    return await api.get(`user/MOR-005_F0`, data);
  }

  static async GetSPosts(data) {
    return await api.get(`user/MOR-015_F0`, data);
  }
}
