import styled from "styled-components";
import useDisplay from "../../../hooks/useDisplay";
import { forwardRef } from "react";

const AppLayout = forwardRef(function AppLayout(props, ref) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <>
      <AppLayoutWrapper
        ref={ref}
        style={{
          ...props.style,
        }}
        onScroll={props.onScroll}
      >
        {props.children}
      </AppLayoutWrapper>
    </>
  );
});

export default AppLayout;

const AppLayoutWrapper = styled.div.attrs((props) => {})`
  position: relative;
  background: rgb(255, 255, 255);
  max-width: 486px;
  min-height: calc(var(--vh, 1vh) * 100);
  height: auto;
  flex: 1 1 0%;
  overflow-y: auto;
  overflow-x: hidden;

  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */

  ::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
    width: 0px;
  }

  ::-webkit-scrollbar-thumb {
    // background: rgba(220, 20, 60); /* 스크롤바 색상 */
    border-radius: 0px; /* 스크롤바 둥근 테두리 */
  }

  ::-webkit-scrollbar-track {
    // background: rgba(220, 20, 60, 0.1); /*스크롤바 뒷 배경 색상*/
  }
`;
