import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import TermApi from "../api/termApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";

function TermContent(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const history = useHistory();
  const location = useLocation();

  const [title, setTitle] = useState("");
  const [termInfo, setTermInfo] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);

    const { title } = location.state;
    setTitle(title);

    termGetFunc(title);
  }, [location.state]);

  const termGetFunc = async (title) => {
    try {
      let response = await TermApi.List();

      if (response.data.data.length > 0) {
        let termTemp = response.data.data.find((item) => item.title === title);
        setTermInfo(termTemp);
      }
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header title={title} />

          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "22.3px",
                  color: "#262C31",
                  fontWeight: 400,
                }}
              >
                {termInfo && termInfo.content ? termInfo.content : ""}
              </PretendardText>
            </div>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default TermContent;
