import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";

import PretendardText from "../../atoms/pretendardText";
import useDisplay from "../../../hooks/useDisplay";
import MainBtn from "../../atoms/button/mainBtn";
import GrayBtn from "../../atoms/button/grayBtn";

const ConfirmModal = ({
  setVisible,
  title,
  content,
  contentStyle,
  content02,
  content03,
  content04,
  onClick,
  onCancle,
  onClickText,
  onCancleText,
  isNoneCloseBtn,
}) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <ModalWrapper>
      <ModalPosition isMobile={isMobile}>
        <ModalContain isMobile={isMobile}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {title ? (
              <PretendardText
                style={{
                  textAlign: "center",
                  fontSize: 18,
                  lineHeight: "25.2px",
                  color: "#262C31",
                  fontWeight: 600,
                  marginBottom: 5,
                }}
              >
                {title}
              </PretendardText>
            ) : null}

            {content ? (
              <PretendardText
                style={{
                  textAlign: "center",
                  fontSize: 15,
                  lineHeight: "22.3px",
                  color: "#ADB4BA",
                  fontWeight: 500,
                  ...contentStyle,
                }}
              >
                {content}
              </PretendardText>
            ) : null}

            {content02 ? (
              <PretendardText
                style={{
                  textAlign: "left",
                  fontSize: 15,
                  lineHeight: "22.3px",
                  color: "#ADB4BA",
                  fontWeight: 500,
                }}
              >
                {content02}
              </PretendardText>
            ) : null}

            {content03 ? (
              <PretendardText
                style={{
                  textAlign: "left",
                  fontSize: 15,
                  lineHeight: "22.3px",
                  color: "#ADB4BA",
                  fontWeight: 500,
                }}
              >
                {content03}
              </PretendardText>
            ) : null}

            {content04 ? (
              <PretendardText
                style={{
                  textAlign: "left",
                  fontSize: 15,
                  lineHeight: "22.3px",
                  color: "#ADB4BA",
                  fontWeight: 500,
                }}
              >
                {content04}
              </PretendardText>
            ) : null}

            <div
              style={{
                marginTop: 18,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <GrayBtn
                onClick={onClick}
                style={{
                  marginRight: 4,
                  display: isNoneCloseBtn ? "none" : "flex",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 15,
                    lineHeight: "20.3px",
                    color: "white",
                    fontWeight: 700,
                  }}
                >
                  {onClickText}
                </PretendardText>
              </GrayBtn>

              <MainBtn
                onClick={() => {
                  onCancle();
                }}
                style={{
                  marginLeft: 4,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 15,
                    lineHeight: "20.3px",
                    color: "white",
                    fontWeight: 700,
                  }}
                >
                  {onCancleText}
                </PretendardText>
              </MainBtn>
            </div>
          </div>
        </ModalContain>
      </ModalPosition>
    </ModalWrapper>
  );
};

export default ConfirmModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  background: rgba(40, 40, 40, 0.5);
  backdrop-filter: blur(2px);

  overflow: hidden;
  touch-action: none;
`;

const ModalPosition = styled.div.attrs((props) => {})`
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
`;

const ModalContain = styled.div.attrs((props) => {})`
  width: ${(props) => (props.isMobile ? "80vw" : "360px")};
  height: auto;
  padding: 30px 20px 20px 20px;
  box-shadow: 0 0 8.5px 1.5px rgba(165, 161, 155, 0.1);
  background-color: #fff;
  border-radius: 10px;

  overflow: auto;
`;
