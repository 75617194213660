import api from "./api.js";

export default class OrderApi {
  static async GetOrderInfo(id) {
    return await api.get(`user/chn-012_F0/${id}`);
  }

  static async GetChallengInfo(id) {
    return await api.get(`user/chn-012_F1/${id}`);
  }

  static async Refund(id, data) {
    return await api.put(`user/chn-012_U0/${id}`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async OrderInfoIdRefund(id, data) {
    return await api.put(`user/refund/${id}`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }
}
