import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import TitleInput from "../components/molecules/input/titleInput";
import FileForm from "../components/atoms/fileForm/fileForm";
import { route } from "../routes/route";
import UserApi from "../api/userApi";
import { toast } from "react-toastify";

let timer;

function IdFind(props) {
  let min = 3;
  let sec = 0;

  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [isPNC, setIsPNC] = useState(false);
  const [phoneNumberChk, setPhoneNumberChk] = useState(false);

  const [cNumber, setCNumber] = useState("");
  const [cNumberErr, setCNumberErr] = useState("");
  const [certifyValue, setCertifyValue] = useState("");

  const [minState, setMinState] = useState(3);
  const [secState, setSecState] = useState(0);

  const [btnDisabled, setBtnDisabled] = useState(false);
  const [isUser, setIsUser] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      clearTimer("타이머 종료");
    };
  }, []);

  useEffect(() => {
    if (phone.length > 0) {
      var regType1 = /^[0-9]+$/;
      if (!regType1.test(phone)) {
        return setPhoneErr("-없이 숫자만 입력해주세요.");
      }

      setPhoneErr("");

      setPhoneNumberChk(false);
      setIsPNC(false);
      setIsUser(false);
    }
  }, [phone]);

  const countTimerStart = () => {
    timer = setInterval(countTimer, 1000);
  };

  const countTimer = () => {
    if (sec != 0) {
      sec = sec - 1;
      setSecState(sec);
    } else {
      if (min != 0) {
        min = min - 1;
        sec = 59;

        setSecState(sec);
        setMinState(min);
      } else {
        clearTimer("타이머 종료");
        setPhoneErr("인증에 실패하였습니다. 인증을 다시 시도하세요.");
      }
    }
  };

  function clearTimer(text) {
    clearInterval(timer);

    if (text === "타이머 종료") {
      min = 3;
      setMinState(3);
      sec = 0;
      setSecState(0);
    }
  }

  useEffect(() => {
    if (name && phone && phoneErr == "" && phoneNumberChk) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [name, phone, phoneErr, phoneNumberChk]);

  const sendSMSAPI = async () => {
    try {
      if (phone.length != 11) {
        setIsPNC(false);
        setPhoneErr("휴대폰 번호가 올바르지 않습니다.");

        return;
      }

      let temp001 = await idFindFunc();

      if (!temp001) {
        return;
      }

      clearTimer("타이머 종료");

      const data = {
        phoneNumber: phone,
      };
      const response = await UserApi.FPhoneCertify(data);

      if (response.data.data.randomNumber) {
        setPhoneNumberChk(false);
        setCertifyValue(response.data.data.randomNumber);
        setPhoneErr("");
        setCNumber("");
        setCNumberErr("");

        setIsPNC(true);
        countTimerStart();
      }
    } catch (err) {
      setIsPNC(false);
      setPhoneErr("휴대폰 번호가 형식이 올바르지 않습니다.");
    }
  };

  const phoneNumberChkFunc = () => {
    if (certifyValue === cNumber) {
      setPhoneNumberChk(true);
      setCNumberErr("");

      clearTimer("완");
    } else {
      setCNumberErr("인증번호가 올바르지 않습니다.");
      setPhoneNumberChk(false);
    }
  };

  const idFindFunc = async () => {
    try {
      const requestDto = {
        name: name,
        phoneNumber: phone,
      };

      let response = await UserApi.EmailFind(requestDto);

      if (response.data.data.length > 0) {
        setIsUser(true);

        if (isUser) {
          history.push({
            pathname: route.id_find_complete,
            state: {
              email: response.data.data,
            },
          });
        }

        return true;
      } else {
        setIsUser(false);

        toast("등록된 회원정보가 없습니다.");
        return false;
      }
    } catch (error) {
      setIsUser(false);

      toast("등록된 회원정보가 없습니다.");
      return false;
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header />

          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                marginTop: 40,
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 32,
                  lineHeight: "44.16px",
                  color: "#262C31",
                  fontWeight: 600,
                  marginBottom: 6,
                }}
              >
                이메일 찾기
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "21.5px",
                  color: "#5A636A",
                  fontWeight: 500,
                }}
              >
                가입 시 입력한 이름과 휴대폰 번호를 입력하세요.
              </PretendardText>

              <div
                style={{
                  marginTop: 58,
                }}
              >
                <TitleInput
                  title={"이름"}
                  placeholder="이름을 입력하세요"
                  type={"text"}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                />

                <TitleInput
                  style={{
                    marginTop: 17,
                  }}
                  title={"휴대폰번호"}
                  placeholder="번호만 입력가능합니다"
                  type={"text"}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  value={phone}
                  btn={true}
                  btnText={isPNC ? "재전송" : "인증요청"}
                  onClick={() => {
                    sendSMSAPI();
                  }}
                  warningMessage={phoneErr}
                  maxLength={11}
                  btnStyle={{
                    marginTop: phoneErr ? -23 : 0,
                    background: isPNC ? "transparent" : "#262C31",
                    border: isPNC ? "2px solid #262C31" : "none",
                  }}
                  btnTextStyle={{
                    lineHeight: phoneErr && isMobile ? "17.75px" : "18.75px",
                    color: isPNC ? "#262C31" : "#ffffff",
                  }}
                  inputStyle={{
                    letterSpacing: "0.6px",
                  }}
                />

                {isPNC ? (
                  <TitleInput
                    style={{
                      marginTop: 17,
                    }}
                    title={"인증번호 입력"}
                    placeholder="인증번호 6자리를  입력하세요"
                    type={"text"}
                    disabled={phoneNumberChk ? true : false}
                    onChange={(e) => {
                      setCNumber(e.target.value);
                    }}
                    warningMessage={cNumberErr}
                    maxLength={6}
                    value={cNumber}
                    timer={phoneNumberChk ? false : true}
                    min={minState}
                    sec={secState}
                    btn={true}
                    btnText={phoneNumberChk ? "인증완료" : "인증하기"}
                    onClick={() => {
                      phoneNumberChkFunc();
                    }}
                    btnStyle={{
                      marginTop: cNumberErr || phoneNumberChk ? -23 : 0,
                      background: phoneNumberChk ? "#ADB4BA" : "#262C31",
                    }}
                    btnTextStyle={{
                      lineHeight:
                        cNumberErr || (phoneNumberChk && isMobile)
                          ? "17.75px"
                          : "18.75px",
                    }}
                    checkMessage={phoneNumberChk ? "인증되었습니다." : ""}
                  />
                ) : null}
              </div>
            </div>

            <MainBtn
              disabled={btnDisabled}
              onClick={() => {
                idFindFunc();
              }}
              style={{
                marginTop: 22,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                이메일 찾기
              </PretendardText>
            </MainBtn>
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default IdFind;
