import api from "./api.js";

export default class AlarmApi {
  static async List(data) {
    return await api.get(`user/pus-001_F0`, data);
  }

  static async Detail(id, data) {
    return await api.get(`user/pus-002_F0/${id}`, data);
  }
}
