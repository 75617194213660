import React, { useState, useEffect } from "react";
import useDisplay from "../../../hooks/useDisplay";
import styled from "styled-components";
import PretendardText from "../pretendardText";

function RightLabelCheckbox({ text, size, checked, onChange }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <>
      <StyledInput
        checked={checked}
        onChange={onChange}
        type="checkbox"
        id={text}
        name={text}
      />
      <StyledLabel size={size} htmlFor={text}>
        <StyledP>{text}</StyledP>
      </StyledLabel>
    </>
  );
}

export default RightLabelCheckbox;

const StyledLabel = styled.label.attrs((props) => {})`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

  &:before {
    display: block;
    content: "";
    height: ${(props) => (props.size ? props.size : "20px")};
    width: ${(props) => (props.size ? props.size : "20px")};
    background-size: 100% 100%;
    background-image: url("/assets/icons/check_d.svg");
  }

  &:after {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    opacity: 0;
    content: "";
    height: ${(props) => (props.size ? props.size : "20px")};
    width: ${(props) => (props.size ? props.size : "20px")};
    background-image: url("/assets/icons/check_a.svg");
    background-size: 100% 100%;
  }
`;

const StyledInput = styled.input`
  position: absolute;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
  width: 1px;

  &:checked + ${StyledLabel} {
    :after {
      opacity: 1;
    }
  }
`;

const StyledP = styled(PretendardText)`
  margin-left: 0.5rem;
  color: #262c31;
  font-weight: 500;
  font-size: 14px;
  line-height: 21.5px;
`;
