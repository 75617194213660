import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import useDisplay from "../../hooks/useDisplay";
import AppLayout from "../../components/atoms/layouts/appLayout";
import AppContainer from "../../components/atoms/layouts/appContainer";
import BottomTab from "../../components/organisms/bottomTab/bottomTab";
import PretendardText from "../../components/atoms/pretendardText";
import TextBtn from "../../components/atoms/button/textBtn";
import ChallengeManagerBox from "../../components/organisms/challengeManagerBox";
import { route } from "../../routes/route";
import { useInView } from "react-intersection-observer";
import UserApi from "../../api/userApi";
import { toast } from "react-toastify";
import { ko } from "../../utils/ko";

function ChallengeManager(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const numbers = [1, 2, 3, 4, 5, 1, 2, 3, 4, 5];

  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [ref, inView] = useInView();
  const [challengeList, setChallengeList] = useState([]);

  const setData = async () => {
    try {
      setLoading(true);
      const response = await UserApi.getMyChallengeList({
        page: currentPage,
        size: 10,
      });
      const data = response.data.data;
      setChallengeList(challengeList.concat(data.content));
      setIsLast(data.last);
      setLoading(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    setData();
  }, [currentPage]);

  useEffect(() => {
    if (inView && !isLast && !loading) {
      setCurrentPage(currentPage + 1);
    }
  }, [inView, loading]);

  return (
    <>
      <AppLayout>
        <AppContainer style={{}}>
          <div
            style={{
              position: "relative",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              paddingBottom: 60,
              paddingLeft: 20,
              paddingRight: 20,
              marginTop: 28,
            }}
          >
            <PretendardText
              style={{
                fontSize: 20,
                lineHeight: "28px",
                color: "#000000",
                fontWeight: 600,
              }}
            >
              챌린지 관리
            </PretendardText>

            <div
              style={{
                paddingTop: 22,
              }}
            >
              {challengeList.map((item, index) => {
                return (
                  <ChallengeManagerBox key={index} isAdmin={true} item={item} />
                );
              })}
            </div>
          </div>

          <BottomTab />
        </AppContainer>
        <TextBtn
          onClick={() => {
            history.push(route.create_challenge);
          }}
          style={{
            position: "absolute",
            width: 70,
            height: 70,
            left: isMobile ? "80%" : "82%",
            bottom: 60,
          }}
        >
          <img
            src="/assets/icons/plus_btn.png"
            alt=""
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </TextBtn>
      </AppLayout>
    </>
  );
}

export default ChallengeManager;
