import React, { useEffect } from "react";
import useDisplay from "../../hooks/useDisplay";
import PretendardText from "../atoms/pretendardText";
import TextBtn from "../atoms/button/textBtn";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { route } from "../../routes/route";
import { getExtensionOfFilename, videoExtension } from "../../utils/file";

function ProfileBoardBox({ isEnd, data }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <TextBtn
        style={{
          width: isMobile ? "32.7%" : "119px",
          marginRight: isEnd ? "" : "3px",
        }}
        onClick={() => {
          if (data.shortFormId) {
            history.push({
              pathname: route.short_form_detail + `/${data.shortFormId}`,
            });
          } else {
            history.push({
              pathname: route.certification_detail + `/${data.certificationId}`,
            });
          }
        }}
      >
        <div
          style={{
            marginTop: "3px",
            position: "relative",
            width: isMobile ? "100%" : "119px",
            height: isMobile ? "123px" : "119px",
            overflow: "hidden",
          }}
        >
          <img
            src={
              videoExtension.includes(getExtensionOfFilename(data.fileUrl))
                ? data.thumbFileUrl
                : data.fileUrl
            }
            alt=""
            style={{
              width: "100%",
              height: "100%",
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              left: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {false ? (
              <img
                src="/assets/icons/ic_16_ flag.png"
                alt=""
                style={{
                  width: "16px",
                  height: "16px",
                  marginRight: "3px",
                }}
              />
            ) : (
              ""
            )}
            <PretendardText
              style={{
                fontSize: 11,
                lineHeight: "14px",
                color: "#FFFFFF",
                fontWeight: 600,
              }}
            >
              {data.challengeStatus === "END"
                ? "완료"
                : data.challengeStatus === "BEFORE"
                ? "시작전"
                : "진행중"}
            </PretendardText>
          </div>
        </div>
      </TextBtn>
    </>
  );
}

export default ProfileBoardBox;
