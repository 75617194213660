import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PretendardText from "../atoms/pretendardText";

const Empty = (props) => {
  const history = useHistory();

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        ...props.style,
      }}
    >
      <img
        src="/assets/icons/empty.png"
        style={{
          height: 32,
          width: 32,
        }}
      />
      <PretendardText
        style={{
          fontSize: 14,
          lineHeight: "20px",
          color: "#DBDDDF",
          fontWeight: 600,
          marginTop: 8,
        }}
      >
        {props.text}
      </PretendardText>
    </div>
  );
};

export default Empty;
