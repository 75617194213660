import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useDisplay from "../hooks/useDisplay";

import PretendardText from "../components/atoms/pretendardText";
import AppLayout from "../components/atoms/layouts/appLayout";
import MainBtn from "../components/atoms/button/mainBtn";
import AppContainer from "../components/atoms/layouts/appContainer";

import Header from "../components/molecules/header/header";
import TitleInput from "../components/molecules/input/titleInput";
import Input from "../components/atoms/input/input";
import DaumPostcode from "../components/templates/modals/daumPostcode";
import MoreApi from "../api/moreApi";
import { toast } from "react-toastify";
import { ko } from "../utils/ko";

function DeliveryEdit(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const location = useLocation();

  const [dmId, setDmId] = useState();

  const [dvName, setDvName] = useState("");
  const [orderName, setOrderName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [address, setAddress] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [isModalCount, setIsModalCount] = useState(0);

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setIsModal(false);
    setZipCode(data.zonecode);
    setAddress(fullAddress);
  };

  useEffect(() => {
    const id = location?.state ? location.state.id : null;
    const data = location?.state ? location.state.data : null;

    if (id && data) {
      setDmId(id);

      setDvName(data.address);
      setOrderName(data.receiverName);
      setPhone(data.phoneNumber);
      setAddress(data.addressName);
      setAddressDetail(data.detailAddress);
      setZipCode(data.zipCode);
    }

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (phone.length > 0) {
      var regType1 = /^[0-9]+$/;
      if (!regType1.test(phone)) {
        return setPhoneErr("-없이 숫자만 입력해주세요.");
      }

      setPhoneErr("");
    }
  }, [phone]);

  useEffect(() => {
    if (dvName && orderName && phone && phoneErr == "" && address) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [dvName, orderName, address, addressDetail, phone, phoneErr]);

  const addFunc = async () => {
    try {
      let formData = new FormData();

      formData.append("address", dvName);
      formData.append("addressName", address);
      formData.append("detailAddress", addressDetail);
      formData.append("phoneNumber", phone);
      formData.append("receiverName", orderName);
      formData.append("zipCode", zipCode);

      await MoreApi.DMAdd(formData);

      if (isModalCount === 0) {
        history.goBack();
      } else {
        history.goBack();
        history.goBack();
      }

      toast("주소 등록 완료");
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const editFunc = async () => {
    try {
      let formData = new FormData();

      formData.append("address", dvName);
      formData.append("addressName", address);
      formData.append("detailAddress", addressDetail);
      formData.append("phoneNumber", phone);
      formData.append("receiverName", orderName);
      formData.append("zipCode", zipCode);

      await MoreApi.DMEdit(dmId, formData);

      history.go(-1);

      toast("주소 수정 완료");
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <AppLayout>
        <AppContainer>
          <Header />

          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                marginTop: 40,
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 32,
                  lineHeight: "44.16px",
                  color: "#262C31",
                  fontWeight: 600,
                }}
              >
                배송지 관리
              </PretendardText>

              <div
                style={{
                  marginTop: 53,
                }}
              >
                <TitleInput
                  title={"배송지명"}
                  placeholder="배송지명"
                  type={"text"}
                  onChange={(e) => {
                    setDvName(e.target.value);
                  }}
                  value={dvName}
                />

                <TitleInput
                  style={{
                    marginTop: 17,
                  }}
                  title={"주문자명"}
                  placeholder="주문자명"
                  type={"text"}
                  onChange={(e) => {
                    setOrderName(e.target.value);
                  }}
                  value={orderName}
                />

                <TitleInput
                  style={{
                    marginTop: 17,
                  }}
                  title={"휴대폰번호"}
                  placeholder="숫자만 입력 가능합니다."
                  type={"text"}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  value={phone}
                  warningMessage={phoneErr}
                  maxLength={11}
                  inputStyle={{
                    letterSpacing: "0.6px",
                  }}
                />

                <TitleInput
                  style={{
                    marginTop: 17,
                  }}
                  title={"주소"}
                  placeholder="주소를 입력하세요"
                  type={"text"}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  value={address}
                  btn={true}
                  btnText={"검색"}
                  onClick={() => {
                    setIsModal(true);
                    setIsModalCount(1);
                  }}
                  btnStyle={{
                    width: 63,
                    marginLeft: 16,
                  }}
                  inputOnclick={true}
                />

                <Input
                  style={{
                    marginTop: 8,
                  }}
                  value={addressDetail}
                  onChange={(e) => {
                    setAddressDetail(e.target.value);
                  }}
                  type={"text"}
                  placeholder={"상세 주소를 입력해주세요"}
                />
              </div>
            </div>
            <MainBtn
              disabled={btnDisabled}
              onClick={() => {
                if (dmId) {
                  editFunc();
                } else {
                  addFunc();
                }
              }}
              style={{
                marginTop: 40,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "20.3px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                {dmId ? "수정하기" : "저장하기"}
              </PretendardText>
            </MainBtn>

            {isModal ? <DaumPostcode handleComplete={handleComplete} setIsModal={setIsModal} /> : null}
          </div>
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default DeliveryEdit;
