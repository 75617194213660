import React, { useState, useEffect, useMemo } from "react";

import useDisplay from "../../hooks/useDisplay";
import AppLayout from "../../components/atoms/layouts/appLayout";
import AppContainer from "../../components/atoms/layouts/appContainer";
import BottomTab from "../../components/organisms/bottomTab/bottomTab";
import PretendardText from "../../components/atoms/pretendardText";
import ScrollContainer from "react-indiana-drag-scroll";
import TextBtn from "../../components/atoms/button/textBtn";
import Empty from "../../components/molecules/empty";
import styled from "styled-components";
import ChallengeSection from "../../components/molecules/challenge/challengeSection";
import FilterSection from "../../components/molecules/challenge/filterSection";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ChallengeApi from "../../api/challengeApi";
import { toast } from "react-toastify";
import { ko } from "../../utils/ko";
import { useInView } from "react-intersection-observer";
import { route } from "../../routes/route";
import ProfileCard from "../../components/organisms/profileCard";
import ProfileCard02 from "../../components/organisms/profileCard02";
// 무한스크롤 테스트
function Challenge(props) {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [filterList, setFilterList] = useState([
    { id: 1, label: "⛳️ 전체", value: true },
    { id: 2, label: "🍽️️ 식습관", value: false },
    { id: 3, label: "👟️ 운동", value: false },
    { id: 4, label: "💪 건강", value: false },
    { id: 5, label: "👣 생활", value: false },
    { id: 6, label: "🚲 취미", value: false },
    { id: 7, label: "💛 멘탈", value: false },
  ]);

  const filterClickFunc = (value) => {
    let temp = filterList.map((item) =>
      item.id === value
        ? { id: item.id, label: item.label, value: true }
        : { id: item.id, label: item.label, value: false }
    );

    setFilterList(temp);
  };

  const challengeCategory = useMemo(() => {
    const element = filterList.find((item) => item.value).id;
    switch (element) {
      case 1:
        return "";
      case 2:
        return "EATING_HABITS";
      case 3:
        return "EXERCISE";
      case 4:
        return "HEALTH";
      case 5:
        return "HOBBY";
      case 6:
        return "LIFE";
      case 7:
        return "MENTAL";
      default:
        return "";
    }
  }, [filterList]);

  // 챌린지 목록
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [ref, inView] = useInView();
  const [dataList, setDataList] = useState([]);

  const setDataToCategory = async () => {
    try {
      const response = await ChallengeApi.getList({
        page: currentPage,
        size: 10,
        challengeCategory: challengeCategory,
      });
      const data = response.data.data;
      setDataList(data.content);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const setData = async () => {
    try {
      setLoading(true);
      const response = await ChallengeApi.getList({
        page: currentPage,
        size: 10,
        challengeCategory: challengeCategory,
      });
      const data = response.data.data;
      setDataList(dataList.concat(data.content));
      setIsLast(data.last);
      setLoading(false);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    setCurrentPage(0);
    setDataToCategory();
  }, [filterList]);

  useEffect(() => {
    setData();
  }, [currentPage]);

  useEffect(() => {
    if (inView && !isLast && !loading) {
      setCurrentPage(currentPage + 1);
      // setData()
    }
  }, [inView, loading]);

  // 인플루언서 목록
  const [influencerList, setInfluencerList] = useState([]);

  const setDataInfluencer = async () => {
    try {
      const response = await ChallengeApi.getListInfluencer({
        size: 1000,
      });
      const data = response.data.data;
      setInfluencerList(data.content);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  useEffect(() => {
    setDataInfluencer();
  }, []);

  return (
    <>
      <AppLayout>
        <AppContainer
          style={{
            paddingBottom: "60px",
          }}
        >
          <div
            style={{
              height: "50px",
              paddingLeft: "20px",
              paddingRight: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <PretendardText
              style={{
                fontSize: 20,
                lineHeight: "28px",
                fontWeight: 600,
                color: "#000000",
              }}
            >
              챌린지
            </PretendardText>

            <TextBtn
              onClick={() => {
                history.push(route.challenge_search);
              }}
            >
              <img
                src="/assets/icons/ic_24_search.svg"
                alt=""
                style={{
                  cursor: "pointer",
                }}
              />
            </TextBtn>
          </div>

          {/* 프로필 */}
          <ScrollContainer className="scroll-container">
            <div
              style={{
                marginTop: "31px",
                marginBottom: "35px",
                display: "flex",
                width: isMobile ? "100vw" : "100%",
              }}
            >
              {influencerList.map((item, index) => {
                return (
                  <ProfileCard02
                    key={index}
                    item={item}
                    isFirst={index === 0}
                    isLast={influencerList.length - 1 === index}
                  />
                );
              })}
            </div>
          </ScrollContainer>

          {/* 필터 */}
          <ScrollContainer className="scroll-container">
            <div
              style={{
                marginBottom: "16px",
                display: "flex",
                width: isMobile ? "100vw" : "100%",
              }}
            >
              {filterList.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      marginLeft: index === 0 ? "20px" : "9px",
                      paddingRight:
                        filterList.length === index + 1 ? "20px" : "0px",
                    }}
                  >
                    <FilterSection
                      onClick={() => {
                        filterClickFunc(item.id);
                      }}
                      data={item}
                    />
                  </div>
                );
              })}
            </div>
          </ScrollContainer>

          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              padding: "0px 20px",
            }}
          >
            {dataList.length > 0 ? (
              dataList.map((item, index) => {
                return (
                  <ChallengeSection key={index} index={index} item={item} />
                );
              })
            ) : (
              <Empty text={"챌린지가 없어요"} />
            )}
            <div ref={ref}>{/* loading */}</div>
          </div>

          <BottomTab />
        </AppContainer>
      </AppLayout>
    </>
  );
}

export default Challenge;
